import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardHeader, CardBody, Button, Form, FormGroup, FormInput, FormSelect } from "shards-react";

class Paso2 extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Form>
      <FormGroup>
        <label>Marca</label>
        <FormSelect>
          <option value="ford">Ford</option>
          <option value="chevrolet">Chevrolet</option>
          <option value="jony">Jony</option>
        </FormSelect>
      </FormGroup>

      <FormGroup>
        <label>Modelo</label>
        <FormSelect>
          <option value="focus">Focus</option>
          <option value="cristian">Cristian</option>
        </FormSelect>
      </FormGroup>
        
      <FormGroup>
        <label>Versión</label>
        <FormSelect>
          <option value="polymer">Polymer</option>
          <option value="trade">Trade</option>
        </FormSelect>
      </FormGroup>

      <FormGroup>
        <label>Título</label>
        <FormInput name="jony" required/>
      </FormGroup>
    </Form>
    );
  }
}


export default Paso2;
