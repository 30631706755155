import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Field, reduxForm} from "redux-form";
import { RedNuestrosAutos } from "../RedNuestrosAutos";
import { Table, Input, Space, Tabs, Radio } from 'antd';
import PortalsIcon from "../_components/PortalsIcon";
import PolyLoader from '../_components/PolyLoader';
import {
  Row,
  Col,
  Card,
  Button,
  CardFooter,
  CardHeader,
  CardBody
} from "shards-react";
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import Cookies from "universal-cookie";
import PageTitle from "../_components/PageTitle";
const cookies = new Cookies();
const { TabPane } = Tabs;

const renderField = (field) => (
    <Input placeholder="Escribe aca" {...field.input} disabled={field.disabled} className="w-100"></Input>
  )
  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
const ConsultasView = props => {
  const { handleSubmit, handleChange,  pristine, reset, submitting } = props
 
  const [loading, setLoading] = useState(false);

  const [notAllowed, setNotAllowed] = useState(false);

  const [globalID, setGlobalID] = useState(props.match.params.id);
  const [mercadolibre, setMercadoLibre] = useState([]);
  const [nuestrosautos, setNuestrosAutos] = useState([]);
  const [autocosmos, setAutoCosmos] = useState([]);
  const [demotores, setDeMotores] = useState([]);

  const [dlz, setDlz] = useState([{}]);

  // Hilo
  const [data, setData] = useState([]);

  // Mensajes
  const [msj, setMsj] = useState([]);

  // Receptor
  const [preceptor, setReceptor] = useState('');

  // Sender
  const [snd, setSnd] = useState([]);

  // Publication
  const [pub, setPub] = useState([]);

  // Bloqueado
  const [blocked, setBlocked] = useState(false);
  
  const [cargando, setCargando] = useState(false);

  useEffect(() => {
   console.log(props, 'polimer');
    // Obtengo la informacion de la conversacion
    if (props.match.params.id == 'notallowed') {
        // Error 404
        setNotAllowed(true);
    }else{
        dataget();
        setNotAllowed(false);
    }
  }, []); 

  useEffect(() => {
    if(globalID !== props.match.params.id) {
        setGlobalID(props.match.params.id)
        if (props.match.params.id == 'notallowed') {
            // Error 404
            setNotAllowed(true);
        }else{
            dataget();
            setNotAllowed(false);
        }
        
    }
   }, [props.match.params.id]);

   function dataget() {
    axios.get('https://nuestrosautos.cca.org.ar/conversation/' + props.match.params.id + "/" + cookies.get("dealer").id + "?token=" +
    props.user.token).then(response => {
 
      // Obtengo la data del receptor
      var receptors = response.data.participantes_data;
 
      // Receptor found
      var receptor_found = false;
 
      receptors.map((receptor, index) => {
          console.log(receptor.reference_id, '' + cookies.get("dealer").id);
         //Comparo con el id dealer para determinar en base a foreach
         if('' + cookies.get("dealer").id == receptor.reference_id && receptor.type == "dealer") {
             // Confirmadisimo
             receptor_found = true;
             setReceptor(receptor.id);
         }
       })
     
       if(receptor_found == false) {
            let path = "/consultas/view/notallowed";
            props.history.push(path);
       }
 
      setData(response.data.hilo);
      setMsj(response.data.mensajes)
      setSnd(response.data.sender)
      setPub(response.data.mascara.publication);

      if(response.data.bloqueado == true) {
        // Bloqueado
        setBlocked(true);
      }
    })
  }

  function enviar(e) {
    setCargando(true);
    return sleep(1000).then(() => { 
        
        axios.post('https://nuestrosautos.cca.org.ar/conversations/' + props.match.params.id + "/" + cookies.get("dealer").id + "?token=" +
        props.user.token, {
        text: e.msg
    }).then(response => {
        
        return sleep(5000).then(() => { 
            dataget();
            setCargando(false);
        })
        
    }).catch(e => {
        setCargando(false);
    });
       
    })
  }
  
  if (loading == true) {
    return (<div style={{height:'500px', textAlign:'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}><img src="/loading.gif" style={{maxWidth:'200px', width:'100%'}}/></div>);
  }

  return (
   <div>
       {notAllowed == false ?
       <form onSubmit={handleSubmit(enviar)}>
                <Row>
                   
                    <PolyLoader open={snd.nombre == undefined || cargando == true} text="Cargando"></PolyLoader>

                    <Col sm="8">
                        <Card small className="card-post mb-4 mt-4">
                            <CardHeader>
                                <h4 className="card-title text-capitalize">Conversación con {snd.nombre + ' ' + snd.apellido}</h4>
                            </CardHeader>
                            <CardBody>
                            
                                <div class="chabox" style={{overflowY: "scroll"}}>
                                    <div className="chat_wrapper">
                                        {msj.map((person, index) => (
                                            <>
                                                {person.user_id == preceptor ?
                                                    <p className="card_message_2">
                                                        {person.body}
                                                    </p>
                                                :
                                                    <p className="card_message_1">
                                                        {person.body}
                                                    </p>
                                                }
                                            </>
                                        ))}
                                        
                                       
                                    </div>
                                </div>

                                
                            </CardBody>
                            <CardFooter style={{background: '#eee'}}>
                                <Row>
                                
                                    <Col sm="8">
                                        <Field
                                            name="msg"
                                            component={renderField}
                                            props={{
                                                disabled: blocked, 
                                              }}
                                            type="text"
                                        />
                                    </Col>
                                    <Col sm="4">
                                        <Button className="w-100" type="submit" disabled={pristine || submitting}>Enviar</Button>
                                    </Col>
                                
                                </Row>
                            </CardFooter>
                        </Card>
                    </Col>

                    <Col sm="4">
                        <Card small className="card-post mb-4 mt-4">
                            <CardHeader>
                                <h4 className="card-title">Información</h4>
                            </CardHeader>
                            <CardBody className="border-top">
                              
                                <div>
                                   
                                    <p className="t-2 pt-1 mb-0 text-left">Plataforma</p>
                                    <p className="t-1 text-left"> <PortalsIcon
                                        api="mercadolibre"
                                        url={'http://mercadolibre.com.ar/'}
                                    ></PortalsIcon> MercadoLibre</p>
                                </div>

                                <div>
                                    <p className="t-2 pt-1 mb-0 text-left">Nombre</p>
                                    <p className="t-1 text-left">{snd.nombre + ' ' + snd.apellido}</p>
                                </div>

                                <div>
                                    <p className="t-2 pt-1 mb-0 text-left">Email</p>
                                    <p className="t-1 text-left">{snd.email}</p>
                                </div>

                                <div>
                                    <p className="t-2 pt-1 mb-0 text-left">Teléfono</p>
                                    <p className="t-1 text-left">{snd.phone}</p>
                                </div>

                                    
                            </CardBody>
                        </Card>

                        <Card small className="card-post mb-3 mt-4">
                            <CardBody className="border-top">
                              <Row>
                                  <Col sm="4">
                                    <img className="rounded w-100" src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Tesla_Model_S_%28Facelift_ab_04-2016%29_trimmed.jpg/300px-Tesla_Model_S_%28Facelift_ab_04-2016%29_trimmed.jpg"></img>
                                  </Col>
                                  <Col sm="8">
                                    <div>
                                        {pub.data && <p className="t-2 pt-1 mb-0 text-left">{pub.data.title}</p> }
                                            
                                        <a href={pub.permalink} target="_blank" className="t-1 text-left">Ver publicacion</a>
                                    </div>
                                  </Col>
                              </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                </form>
                :
                <div>
                <Row noGutters className="page-header py-4" style={{ width: "100%" }}>
                    <PageTitle sm="6" title="No se puede acceder a la conversación" className="text-sm-left" /> 
                </Row>
                <p>Con la agencia que usted se encuentra logueado actualmente no se ha podido acceder al recurso. Por favor, cambie de concesionaria o contacte a soporte para más información.</p>
                </div>
                }
   </div>
  )
  
}


function mapState(state, props) {
   
    const { users, authentication, vehicles, adminuser } = state;
  
    const { user } = authentication;

    var initl = {};

    return { user, users, adminuser, initialValues: initl};
  }
  const actionCreators = {
  
  };

const po = reduxForm({
  form: 'ConsultasView', 
  enableReinitialize: true
})(ConsultasView);

const pox = connect(
    mapState,
    actionCreators
  )(po);
  
export { pox as ConsultasView };