import { Button, Card, CardBody, Col, Row } from "shards-react";
import React, { useEffect ,useState} from "react";
import PageTitle from "../_components/PageTitle";
import { Select, Table ,Icon,Input, Checkbox, Radio} from "antd";
import PoppupListItems from "./PoppupListItems";
import axios from "axios";
import {API_URL} from '../_config'
import Highlighter from 'react-highlight-words';


export const MergeItems = (props) => {
  const [selectionType, setSelectionType] = useState(false);
  const [activePoppup, setActivePoppup] = useState(false)
  const [dataListEndpoint,setDataListEndpoint] = useState([])
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedRowsKeysState, setSelectedRowsKeysState] = useState([])
  const [selectedVehicles, setSelectedVehicles] = useState(2)

  useEffect(() => {
    if(props.match.params.id === "modelos"){
      axios({
        method: "get",
        url: API_URL + "vehicles/free/getmod"
      }).then(response => {
        setDataListEndpoint(response.data)
      });
    }
    else{
      axios({
        method: "get",
        url: API_URL + "vehicles/free/getbra"
      }).then(response => {
        setDataListEndpoint(response.data)
    })}

  }, [props.match.params.id])


const ActiveFusion = () => {
    setSelectionType(true)
    setActivePoppup(true)
  }
  const DesactiveFusion = () => {
    setSelectionType(false)
    setActivePoppup(false)
    setSelectedRowsKeysState([])
    setSelectedRows([])
  }
  

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            setSearchInput(node);
          }}
          placeholder={`Buscar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Resetear
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key : "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "name",
      dataIndex: "name",
      key : "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...getColumnSearchProps('name')

    },
    {

      title: "type_vehicle_id",
      dataIndex: "type_vehicle_id",
      key : "type_vehicle_id",
    },
  
    {
      title: "mercadolibre_name",
      dataIndex: "mercadolibre_name",
      key : "mercadolibre_name",
               render: text =><>{text === null ? <p style={{color : "#ff0042", fontSize: "12px",fontWeight: "semiBold"}}>Vacío</p> : <p>{text}</p>}</>,
    },
    {
      title: "mercadolibre_id",
      dataIndex: "mercadolibre_id",
      key: "mercadolibre_id",
         render: text =><>{text === null ? <p style={{color : "#ff0042", fontSize: "12px",fontWeight: "semiBold"}}>Vacío</p> : <p>{text}</p>}</>,
    },

    {
      title: "autocosmos_name",
      dataIndex: "autocosmos_name",
      key: "autocosmos_name",
                  render: text =><>{text === null ? <p style={{color : "#ff0042", fontSize: "12px",fontWeight: "semiBold"}}>Vacío</p> : <p>{text}</p>}</>,
    },
    {
      title: "autocosmos_id",
      dataIndex: "autocosmos_id",
      key: "autocosmos_id",
                  render: text =><>{text === null ? <p style={{color : "#ff0042", fontSize: "12px",fontWeight: "semiBold"}}>Vacío</p> : <p>{text}</p>}</>,
    },
    {
      title: "deautos_name",
      dataIndex: "deautos_name",
      key: "deautos_name",
                  render: text =><>{text === null ? <p style={{color : "#ff0042", fontSize: "12px",fontWeight: "semiBold"}}>Vacío</p> : <p>{text}</p>}</>,
    },
    {
      title: "deautos_id",
      dataIndex: "deautos_id",
      key: "deautos_id",
                  render: text =><>{text === null ? <p style={{color : "#ff0042", fontSize: "12px",fontWeight: "semiBold"}}>Vacío</p> : <p>{text}</p>}</>,
    },
    {
      title: "demotores_name",
      dataIndex: "demotores_name",
      key: "demotores_name",
                  render: text =><>{text === null ? <p style={{color : "#ff0042", fontSize: "12px",fontWeight: "semiBold"}}>Vacío</p> : <p>{text}</p>}</>,
    },
    {
      title: "demotores_id",
      dataIndex: "demotores_id",
      key: "demotores_id",
                  render: text =><>{text === null ? <p style={{color : "#ff0042", fontSize: "12px",fontWeight: "semiBold"}}>Vacío</p> : <p>{text}</p>}</>,
    },
    {
      title: "olx_name",
      dataIndex: "olx_name",
      key: "olx_name",
                  render: text =><>{text === null ? <p style={{color : "#ff0042", fontSize: "12px",fontWeight: "semiBold"}}>Vacío</p> : <p>{text}</p>}</>,
    },
    {
      title: "olx_id",
      dataIndex: "olx_id",
      key: "olx_id",
                  render: text =><>{text === null ? <p style={{color : "#ff0042", fontSize: "12px",fontWeight: "semiBold"}}>Vacío</p> : <p>{text}</p>}</>,
    },
    {
      title: "autofoco_name",
      dataIndex: "autofoco_name",
      key: "autofoco_name",
      render: text =><>{text === null ? <p style={{color : "#ff0042", fontSize: "12px",fontWeight: "semiBold"}}>Vacío</p> : <p>{text}</p>}</>,
    },
  ];

  const dataFilter = dataListEndpoint && selectedVehicles === 2 ? dataListEndpoint.filter(v => v.type_vehicle_id === 2 ) : dataListEndpoint.filter(v => v.type_vehicle_id === 3 ) 

  const rowSelection = {
    selectedRowsKeys : selectedRowsKeysState,
    selectedRows,
    onChange: (selectedRowKeys,selectedRows) => {
      setSelectedRows(selectedRows)
      setSelectedRowsKeysState(selectedRowKeys)
    }
  };
  const handleChange = (e) => {
    setSelectedVehicles(e.target.value)
    setSelectedRows([])
    setSelectionType(undefined)
  }

return(
  <div style={{position: "relative"}}>
    <span  style={{paddingTop: "10px" }}>
    <i
            className="material-icons"
            title="Atras"
            style={{ cursor: "pointer", display: "inline", fontSize: "33px",color :"#3E5170" }}
            onClick={()=> props.history.push("/admin_listas_uni")}
          >
          arrow_back
          </i>
    </span>
    <Row noGutters className="page-header py-4" style={{ width: "100%", position : "relative" }}>
            <PageTitle sm="6" title={`Lista de ${props.match.params.id} para unificar`} className="text-sm-left" />
            <Col sm="4"></Col>
            <Col sm="2">
              {selectionType && <Button onClick={DesactiveFusion}>Cancelar</Button> }
              {!selectionType && <Button onClick={ActiveFusion}>Unificar {props.match.params.id}</Button> }
            </Col>
    </Row>
    <Row>
              {props.match.params.id === "marcas" && <Col className="py-3">
                  <div>
                    <div className="d-flex align-items-center" >
                    <div>
                      <h6>
                          Selecciona el tipo que quieres mostrar: 
                        </h6>
                    </div>
                      <div className="pl-4">
                        <Radio.Group onChange={handleChange} value={selectedVehicles}>
                          <Radio value={2}>Autos - Camionetas - Motos </Radio>
                          <Radio value={3}>Camiones</Radio>
                      </Radio.Group>
                      </div>
                    </div>
                  </div>
            </Col>
            }
          </Row>
    <Row>
            <Col>
              <Card small className="mb-4">
                <CardBody className="p-0 pb-3">
                  <div class="table-responsive">
                  <Table  rowKey={(key) => key.id}
                          locale={{ emptyText: "Obteniendo datos..." }}
                          rowSelection={selectionType ? rowSelection : undefined}
                          columns={columns}
                          dataSource={ props.match.params.id === "marcas"? dataFilter : dataListEndpoint } />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {activePoppup && <PoppupListItems setItemsKeys={setSelectedRowsKeysState} type={props.match.params.id} itemsSelected={selectedRows} keysSelected={selectedRowsKeysState} setItemsSelected={setSelectedRows} DesactiveFusion={DesactiveFusion}/> }
          
  </div>
  ) 
};

