import React, { useMemo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useCache } from 'react-api-cache'
import axios from "axios";
import { API_URL } from "../_config";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormInput,
  FormGroup,
  ListGroup,
  ListGroupItem
} from "shards-react";
import SearchResults from "react-filter-search";
import { Redirect } from "react-router-dom";
import PageTitle from "../_components/utils/PageTitle";
import Cache from 'react-api-cache'
import "../SelectDealerPage/SelectDealerPage.css";
import { alertActions } from "../_actions";
import Cookies from "universal-cookie";
import { AutoComplete } from 'antd';
import { store } from "../_helpers";
const cookies = new Cookies();
const date = new Date();

const SelectDealerPage = props => {
  const [loading, setLoading] = useState(false);
  const [dealer, setDealer] = useState(false);
  const [dealer_selected, setDealer_selected] = useState([]);
  const [data, setData] = useState([]);
  const [completeData, setCompleteData] = useState([]);
  const [value, setValue] = useState('');


  const upload_dealer = (el, event) => {
    event.preventDefault();
    if (cookies.get('dealer')) {

      cookies.remove("dealer");

    }

    cookies.set("dealer", el, { maxAge: "86400", path: "/" });

    window.location.href = "/panel";
  }

  const cancelar = () => {

    setDealer_selected([]);
    setDealer(false);

  }

  const pre_select = id => {
    // Primero busco los datos de este dealer
    const pro_dealer = completeData.filter(word => word.id == id);
    const el = pro_dealer[0];
    var dealer_selected = {
      id: el.id,
      name: el.name,
      picture: el.cropped_image_url,
      dealerUrl: el.url_company,
      dealerLogo: el.cropped_image_url ? (
        `<img className="user-avatar rounded-circle mr-2"  style="
          max-width: 6.5rem;
          max-height: 2.5rem;
          border-radius: 6px;
          border: 1px solid rgb(238, 238, 238);" 
         src="${el.cropped_image_url}" alt="${el.name}">`
      ) : (
        <p>{el.name}</p>
      )
    };
    setDealer_selected(dealer_selected);
    setDealer(true);
  }

  useEffect(() => {
    if (cookies.get('dealer')) {

      cookies.remove("dealer");

    }



    // Llamo para verificar si esta logueado
    axios({
      method: "get",
      url:
        API_URL + "/api/user?token=" +
        store.getState().authentication.user.token
    }).then(response => {


    });


    if (props.user.is_admin == false && props.user.dealer && !props.user.dealersList) {
      // No tiene nada que hacer aca
      axios({
        method: "get",
        url:
          API_URL + "api/dealer/" +
          props.user.dealer.dealer_id +
          "/2?token=" +
          store.getState().authentication.user.token
      }).then(response => {

        var dealer_selected = {
          id: props.user.dealer.dealer_id,
          name: props.user.dealer.dealer_name,
          picture: props.user.dealer.dealer_original_image_url,
          dealerUrl: props.user.dealer.url_company,
          dealerLogo:
            `<img className="user-avatar rounded-circle mr-2"  style="
                      max-width: 6.5rem;
                      max-height: 2.5rem;
                      border-radius: 6px;
                      border: 1px solid rgb(238, 238, 238);" 
                     src="${response.data.cropped_image_url}" alt="${props.user.dealer.dealer_name}">`

        };


        cookies.set("dealer", dealer_selected, { maxAge: "86400" });

        window.location.href = "/panel";
      });
    }


    if (props.user.dealer && props.user.dealersList && props.user.dealersList.length == 1) {
      //La lista es igual a 1
      axios({
        method: "get",
        url:
          API_URL + "api/dealer/" +
          props.user.dealer.dealer_id +
          "/2?token=" +
          store.getState().authentication.user.token
      }).then(response => {

        var dealer_selected = {
          id: props.user.dealer.dealer_id,
          name: props.user.dealer.dealer_name,
          picture: props.user.dealer.dealer_original_image_url,
          dealerUrl: props.user.dealer.url_company,
          dealerLogo:
            `<img className="user-avatar rounded-circle mr-2"  style="
                      max-width: 6.5rem;
                      max-height: 2.5rem;
                      border-radius: 6px;
                      border: 1px solid rgb(238, 238, 238);" 
                     src="${response.data.cropped_image_url}" alt="${props.user.dealer.dealer_name}">`

        };


        cookies.set("dealer", dealer_selected, { maxAge: "86400" });

        window.location.href = "/panel";
      });
    }

    if (localStorage.getItem("dealer_list") === null) {

      fetch(
        API_URL + "api/v2/dealer?token=" +
        props.user.token
      )
        .then(response => response.json())
        .then(json => {
          var pre = [];
          json.map(function (item, i) {
            pre.push({ value: item.id, text: item.name })
          })
          setData(pre);
          setCompleteData(json);
          setLoading(false);
          localStorage.setItem('dealer_list', JSON.stringify(json));
        });
      //...

    } else {
      var data_map = JSON.parse(localStorage.getItem("dealer_list"));
      var pre = [];
      data_map.map(function (item, i) {
        pre.push({ value: item.id, text: item.name })
      })
      setData(pre);
      setCompleteData(data_map)
      setLoading(false);
    }


    return () => {
      setDealer_selected([]);
      setDealer(false);
      setData([]);
      setCompleteData([]);

      console.log("cleaned up");
    };
  }, []);

  const handleChange = event => {
    setValue(event.target.value);

  };

  const onSelect = event => {
    console.log(event)
  }

  const info_card = () => {
    if (dealer == true) {
      return (
        <div className={'text-center'}>
          <h5 class="card-title">Usted ha seleccionado:</h5>
          <img
            src={dealer_selected.picture}
            style={{ maxWidth: "100px", width: "100%", borderRadius: '6px' }}
          ></img>
          <h3 className="mb-4 mt-3">{dealer_selected.name}</h3>
          <div className="d-flex align-items-center justify-content-center">
            <Button className="mr-2" theme="secondary" onClick={event => cancelar()}>
              Cancelar
            </Button>
            <Button onClick={event => upload_dealer(dealer_selected, event)}>
              Aceptar y continuar
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div className={'text-center'}>
          <h5 class="card-title">Seleccione una Concesionaria</h5>
          <AutoComplete
            backfill
            optionLabelProp="children"
            dataSource={data}
            onSelect={pre_select}
            className={'w-100'}
            filterOption={true}
            placeholder="Nombre de la concesionaria..."
            optionFilterProp="children"
          />
        </div>
      );
    }
  }

  return info_card();

}

function mapState(state) {
  const { users, authentication, alert } = state;
  const { user } = authentication;

  return { user, users, alert };
}
const actionCreators = {
  showAlert: alertActions.show,
  hideAlert: alertActions.hide
};

const connectedDashboard = connect(
  mapState,
  actionCreators
)(SelectDealerPage);
export { connectedDashboard as SelectDealerPage };
