import React from 'react'
import { Field, reduxForm } from 'redux-form';
import RenderFieldMyDealer from '../RenderFieldMyDealer';
import validate from '../validate';

const WizardFormSecondPageMyDealer = ({onSubmit,previousPage}) => {
  return (
    <div>
        <h6>
          Datos de contacto:
        </h6>
        <form onSubmit={onSubmit}>
        <Field
        name="contacto"
        type="text"
        component={RenderFieldMyDealer}
        label="Contacto:"
      />
        <Field
        name="email"
        type="Email"
        component={RenderFieldMyDealer}
        label="Email"
      />
        <Field
        name="telefono"
        type="telefono"
        component={RenderFieldMyDealer}
        label="Teléfono"
      />
        <Field
        name="telefono2"
        type="telefono"
        component={RenderFieldMyDealer}
        label="Segundo Teléfono"
      />
        <Field
        name="celular"
        type="telefono"
        component={RenderFieldMyDealer}
        label="Celular"
      />
        <Field
        name="horarios"
        type="text"
        component={RenderFieldMyDealer}
        label="Horarios"
      />
        <Field
        name="facebook"
        type="text"
        component={RenderFieldMyDealer}
        label="Facebook"
      />
        <Field
        name="twitter"
        type="text"
        component={RenderFieldMyDealer}
        label="Twitter"
      />
        <Field
        name="youtube"
        type="text"
        component={RenderFieldMyDealer}
        label="Youtube"
      />
        <Field
        name="instagram"
        type="text"
        component={RenderFieldMyDealer}
        label="Instagram"
      />
      <div  style={{paddingTop : "20px"}}>

      <button className="btn btn-primary"  style={{ marginRight : "10px" }}  onClick={previousPage}> Atras </button>

      <button className="btn btn-primary" type="submit"> Siguiente </button>

      </div>
    </form>
    </div>
  )
}

export default reduxForm({
  form: 'wizardFormMyDealer', 
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  // validate
})(WizardFormSecondPageMyDealer)
