//import config from "config";
import { store } from "../_helpers";
import { API_URL } from "../_config";
import Cookies from "universal-cookie";
import axios from "axios";
const cookies = new Cookies();
const config = {
  site_uri: API_URL + "api/"
};
export const vehiclesServices = {
  showVehicle
};

function showVehicle($id) {
  console.log("caminnaa el service");
  //API_URL/api2/dealer/1/vehicles/
  return axios({
    method: "get",
    url: `${config.site_uri}/api/dealer/${
      cookies.get("dealer").id
    }/vehicles/${$id}/?token=${store.getState().authentication.user.token}`
  })
    .then(response => {
      //console.log("DATA DE SERVICE", response);

      if (response.status == 200) {
        return response.data;
      } else if (response.status == "error") {
      }
    })
    .catch(err => {
      console.log(err);
      window.alert("no funciona");
    });
}
