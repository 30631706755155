export const vehiclesConstants = {
  GETVEHICLES_REQUEST: "GETVEHICLES_REQUEST",
  GETVEHICLES_SUCCESS: "GETVEHICLES_SUCCESS",
  GETVEHICLES_FAILURE: "GETVEHICLES_FAILURE",
  GETVEHICLE_SHOW: "GETVEHICLE_SHOW",
  GETSHAREDVEHICLES_SUCCESS: "GETSHAREDVEHICLES_SUCCESS",
  GETSHAREDVEHICLES_REQUEST: "GETSHAREDVEHICLES_REQUEST",
  GETSHAREDVEHICLES_FAILURE: "GETSHAREDVEHICLES_FAILURE",
  GETCONCESIONARIAS_SUCCESS: "GETCONCESIONARIAS_SUCCESS",
  GETDATADETAILVEHICLE : "GETDATADETAILVEHICLE",
  GETDATADEALERVEHICLES : "GETDATADEALERVEHICLES"
};
