import React from "react";
import { NavItem, NavLink, Badge, Row, Col, Card, CardBody, Collapse, DropdownItem, Button, Modal, ModalBody, ModalHeader  } from "shards-react";
import axios from 'axios';
import { database } from "../init-fcm";
import { store } from "../_helpers";
import { Drawer, Divider} from 'antd';
import Cookies from "universal-cookie";
import {
  reduxForm,
  Field,
  getFormValues,
  change,
  formValueSelector
} from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { notificacionsActions } from '../_actions';
import PolyLoader from '../_components/PolyLoader';
import { withRouter } from 'react-router-dom';

const cookies = new Cookies();

class ModalNotifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      visible_drawer: false,
      data: [],
      selectedTitle: '',
      selectedBody: '',
      selectedDate: '',
      cargar: false,
      open: false,
      allowCampana: false,
      actual_id: 0
    };

    this.toggleNotifications = this.toggleNotifications.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggle_drawer = this.toggle_drawer.bind(this);
  }

  componentDidMount() {
  
    if(database && database != undefined && database !== null) {
      const dato  = JSON.parse(localStorage.getItem('user'));
      let self = this;
      const user_id = dato.user.id;

      database.collection("notificaciones").where("id_user", "==", user_id)
        .onSnapshot(function(snapshot) {
          var nots = [];
          snapshot.forEach(function(doc) {
              nots.push(doc.data());
          });
          console.log(nots);
          console.log('coverlog')
          self.setState({ 
            data: nots
          });
      });
    }
   
  }

  componentDidUpdate(nextProps, prevState) {
    if(nextProps.notifications!==this.props.notifications){
      console.log(this.props.notifications)
     
      if(!cookies.get('notificacion_' + this.props.notifications.list[0].id)) {
        this.setState({ 
          allowCampana: true,
          actual_id: this.props.notifications.list[0].id
        });
      }
    }
  }

  toggleNotifications() {
    
  }

  reintentar(id, url, type) {

    this.setState({
      cargar: true
    });

      // Primero se reintenta la operacion
      axios({
        method: type,
        url: url + "?token=" + store.getState().authentication.user.token
      }).then(response => {
        // Funciono, se devuelve Ok  
        alert('La operacion tuvo exito, ya puede verla reflejada en el sistema')
        // Saco el loader
        this.setState({
          cargar: false
        });
      }).catch(err => {
        // No funciono, se devueve el mensaje
        alert('La operacion ha fallado nuevamente, por favor, le solicitamos que contacte a soporte para solucionar el tema.')
        // Saco el loader
        this.setState({
          cargar: false
        });
      });



  }

  toggle_drawer() {

    if(this.state.visible == true) {
      this.setState({
        visible: false
      });
    }

    this.setState({
      visible_drawer: !this.state.visible_drawer
    });
    
  }

  toggle(person) {
    console.log(this.state);
    if(this.state.open == false) {
      console.log(person);
      console.log('adore');

    
    this.setState({
      visible_drawer: false,
      open: true,
      selectedTitle: person.title,
      selectedBody: person.text,
      selectedButton: person.button,
      selectedButton_for: person.button_for,
      selectedButton_id: person.id,
      selectedButton_api: person.button_api,
      selectedDate: person.created_at
    });

    
  }else{

    // Llamo denuevo
    //this.props.getNoti();   

    var dato = JSON.parse(localStorage.getItem('user'));

    var user_id = dato.user.id;

   
    
    this.setState({
      visible_drawer: false,
      open: false,
      selectedTitle: '',
      selectedBody: '',
      selectedDate: '',
      selectedButton: '',
      selectedButton_for: '',
      selectedButton_id: '',
      selectedButton_api: '',
    });
  }

  }

  toggleAndRead(person) {
    
    if(this.state.open == false) {

      var dato = JSON.parse(localStorage.getItem('user'));

      var user_id = dato.user.id;

    
        this.setState({
          visible_drawer: false,
          open: true,
          selectedTitle: person.title,
          selectedBody: person.text,
          selectedButton: person.button,
          selectedButton_id: person.id,
          selectedButton_for: person.button_for,
          selectedButton_api: person.button_api,
          selectedDate: person.created_at
        });
        
   

     
    }else{
      this.setState({
        visible_drawer: false,
        open: false,
        selectedTitle: '',
        selectedBody: '',
        selectedDate: '',
        selectedButton: '',
        selectedButton_id: '',
        selectedButton_for: '',
        selectedButton_api: '',
      });
    }
  }

  routeChange(person) {
    
    if(person.leido == false) {
      database.ref('notificaciones/notificacion_' + person.id).set({
        ...person, leido: true
      });
    }

    const datosx  = JSON.parse(localStorage.getItem('user'));

    const userr_id = datosx.user.id;

    axios({
      method: "get",
      url:
        "https://nuestrosautos.cca.org.ar/notificaciones/leer/" + person.id + "/" + userr_id + "?token=" + store.getState().authentication.user.token
    }).then(response => {
      let path = "/consultas/view/" + person.id;
      this.props.history.push(path);
    });
  }
 
  render() {

    const { open, visible_drawer, visible, data } = this.state;

    const dato  = JSON.parse(localStorage.getItem('user'));

    const user_id = dato.user.id;
   
    return (
      <>
      
      <Modal open={this.props.open} toggle={() => console.log('Toggle')}>
            <ModalHeader>{this.props.data.selectedTitle}  <i style={{position:'absolute', right: '20px', top: '15px', fontSize: '25px', cursor: 'pointer'}} class="material-icons" onClick={() => this.props.closeModal()}>clear</i></ModalHeader>
            <ModalBody>
              <div dangerouslySetInnerHTML={{__html: this.props.data.selectedBody}}></div>
              <br/>
              <h6 style={{color: '#9e9e9e', textAlign: 'right'}}>{this.props.data.selectedDate}</h6>
              <Row>
               
                  <a style={{margin: 5}} target="_blank" href="https://api.whatsapp.com/send?phone=5491134409047" class="btn btn-primary">
                    Contactar a soporte
                  </a>
                
                  {this.props.data.selectedButton !== null && this.props.data.selectedButton !== undefined && this.props.data.selectedButton !== "" && this.props.data.selectedButton == 1 &&
                    this.props.data.selectedButton_for == 'all' ? (
                      <a onClick={() => this.reintentar(this.props.data.selectedButton_id, this.props.data.selectedButton_api, 'post')} style={{margin: 5, color: 'white'}} class="btn btn-primary">
                        Reintentar
                      </a>  
                    ):(
                      <>
                        {this.props.data.selectedButton_for == 'admin' && JSON.parse(localStorage.getItem('user')).is_admin == true &&
                          <a onClick={() => this.reintentar(this.props.data.selectedButton_id, this.props.data.selectedButton_api, 'post')} style={{margin: 5, color: 'white'}} class="btn btn-primary">
                            Reintentar.
                          </a> 
                        }
                      </>
                    )
                  }
                
              </Row>
              </ModalBody>
          </Modal>
      </>
    );
  } 
}

function mapState(state, ownprops) {
  const { users, authentication, notifications } = state;
  const { user } = authentication;
  return {
    user,
    users,
    notifications
  };
}

const actionCreators = {
  getNoti: notificacionsActions.getNotifications
};

const connectEditVehicles = connect(
  mapState,
  actionCreators
);

export default withRouter(compose(
  connectEditVehicles,
  reduxForm({
    form: "notifications", 
    enableReinitialize: true
  })
)(ModalNotifications));

