import React from 'react';
import { compose, withHandlers, withState, withPropsOnChange } from 'recompose';
import { FormFeedback } from 'reactstrap';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './rich.css';       
const WysiwygEditor =
  (
    {
      input: { value, onChange },
      meta: { touched, error },
      editorState,
      onEditorStateChange
    }
  ) => (
    <div className="WysiwygEditor">
      {
        editorState &&
        <Editor
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          toolbar={{
            options: ['inline', 'textAlign', 'colorPicker', 'link'],
            inline: {
  
              options: ['bold', 'italic', 'underline', 'strikethrough'],
              
            },
          }}
          editorClassName="form-control"
        />
      }
      {touched && error && <FormFeedback>{error}</FormFeedback>}
    </div>
  );

export default compose(
  withState("editorState", "setEditorState", EditorState.createEmpty()),
  withPropsOnChange(
    ['input'],
    ({ input: { value }, meta: { dirty }, setEditorState }) => {
      if (dirty) {
        return;
      }
      if (!value) {
        return;
      }
      const contentBlock = htmlToDraft(value);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  ),
  withHandlers({
    onEditorStateChange: ({ input: { onChange }, setEditorState }) => (editorState) => {
      setEditorState(editorState);
      const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      onChange(html);
    },
  })
)(WysiwygEditor);