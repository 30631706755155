import React from "react";
import { withRouter } from "react-router-dom";
import { API_URL } from "../_config";
import axios from "axios";
import PropTypes from "prop-types";
import CardFlip from "../_components/CardFlip";
import PortalsIcon from "../_components/PortalsIcon";
import { Timeline, Row } from "antd";
import openNotificationWithIcon from "../_common/OpenNotificationWithIcon";
import FacebookLibrary from "../_common/FacebookLibrary";
import ModalQr from "./ModalQr/ModalQr";

import {
  Badge,
  Col,
  Card,
  ButtonToolbar,
  FormSelect,
  Button,
  Container,
  CardFooter,
  ButtonGroup,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader
} from "shards-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "universal-cookie";
import {
  faTrash,
  faShare,
  faPen,
  faClone,
  faUndo,
  faSms,
  faQrcode,
  faCheck
} from "@fortawesome/free-solid-svg-icons";
const cookies = new Cookies();
var $res = [];
/*
var $apis = [
  // { key: "ml", name: "Mercadolibre", state: false },
  //{ key: "ac", name: "Autocosmos", state: true }
];
*/
//const loadingLine = (items, apis) => ();
var listo = [];
class VehiclesList extends React.Component {
  constructor() {
    super();

    this.state = {
      isFlipped: false,
      sharebox: "",
      vehicles_loaded: false,
      delete: false,
      delete_portals_form: "",
      delete_value: "",
      mvisible: false,
      open: false,
      openModalQr: false,
      carSelected : "",
      listapis: [],
      apisReady: []
    };
    // console.log("APISSSSS", $apis);
    this.toggle = this.toggle.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.routeChange = this.routeChange.bind(this);
    this.share = this.share.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteSend = this.handleDeleteSend.bind(this);
    this.deleteAll = this.deleteAll.bind(this);
    this.modalQr = this.modalQr.bind(this);
    this.deleteFacebook = this.deleteFacebook.bind(this);
    this.handleChangeformSelectApisDelete = this.handleChangeformSelectApisDelete.bind(
      this
    );
  }
  toggle() {
    this.props.refresh();
    this.setState({
      open: !this.state.open,
      delete_portals_form: "",
      delete_value: "",
      apisReady: []
    });
  }

  modalQr(){
    this.setState({openModalQr : !this.state.openModalQr})
  }

  handleLoad() {
    this.setState(prevState => ({
      vehicles_loaded: !prevState.vehicles_loaded
    }));
  }

  handleClick(e) {
    e.preventDefault();
    this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
  }

  handleDelete(e) {
    e.preventDefault();
    this.setState(prevState => ({ delete: !prevState.delete }));
  }

  deleteFacebook(e) {
    const utoken = this.props.user.token;
    const item_id = this.props.item.id;

    return new Promise(function(fulfill, values) {
      console.log(values);
      window.FB.init({
        appId: "857034091332515",
        autoLogAppEvents: true,
        xfbml: true,
        status: true,
        version: "v4.0",
        cookie: true
      });

      window.FB.getLoginStatus(function(response) {
        if (response.status === "connected") {
          axios({
            method: "get",
            url:
            API_URL +
              "facebook/" +
              "pages_react?token_fb=" +
              window.FB.getAccessToken() +
              "&token=" +
              utoken
          }).then(response => {
            response.data.forEach(function(userPage) {
              axios({
                method: "delete",
                url:
                API_URL +
               "api/dealer/" +
                  cookies.get("dealer").id +
                  "/facebook/" +
                  "vehicle/" +
                  item_id +
                  "/delete?token_fb=" +
                  window.FB.getAccessToken() +
                  "&token=" +
                  utoken
              })
                .then(response => {
                  fulfill({
                    pt: e,
                    result: {
                      status: 200,
                      data: {
                        ms:
                          "Se eliminó la publicación de Facebook correctamente."
                      }
                    }
                  });
                })
                .catch(error => {
                  fulfill({
                    pt: e,
                    result: {
                      status: 400,
                      data: {
                        ms:
                          "No se pudo eliminar la publicación de Facebook correctamente."
                      }
                    }
                  });
                });
            });
          });
        } else {
          window.FB.login(function(response) {
            if (response.authResponse) {
              console.log(window.FB.getAccessToken());

              axios({
                method: "get",
                url:
                  API_URL +
                  "facebook/" +
                  "pages_react?token_fb=" +
                  window.FB.getAccessToken() +
                  "&token=" +
                  utoken
              }).then(response => {
                response.data.forEach(function(userPage) {
                  axios({
                    method: "delete",
                    url:
                      API_URL +
               "api/dealer/" +
                      cookies.get("dealer").id +
                      "/facebook/" +
                      "vehicle/" +
                      item_id +
                      "/delete?token_fb=" +
                      window.FB.getAccessToken() +
                      "&token=" +
                      utoken
                  })
                    .then(response => {
                      fulfill({
                        pt: e,
                        result: {
                          status: 200,
                          data: {
                            ms:
                              "Se eliminó la publicación de Facebook correctamente."
                          }
                        }
                      });
                    })
                    .catch(error => {
                      fulfill({
                        pt: e,
                        result: {
                          status: 400,
                          data: {
                            ms:
                              "No se pudo eliminar la publicación de Facebook correctamente."
                          }
                        }
                      });
                    });
                });
              });
            } else {
              fulfill({
                pt: e,
                result: {
                  data: {
                    status: 400,
                    ms:
                      "No conseguimos autorizacion para realizar la operacion."
                  }
                }
              });
            }
          });
        }
      });
    });
  }

  deleteAll(details, e) {
    var url =
      API_URL +
      "api/dealer/" +
      cookies.get("dealer").id +
      "/sale/" +
      this.props.item.id +
      "/sellAll/" +
      details +
      "/" +
      e +
      "?token=" +
      this.props.user.token;
    return new Promise(function(fulfill, values) {
      /* //console.log('START execution with value =', value);
            setTimeout(function() {
                fulfill({ value: value, result: value * value });
            }, 0 | Math.random() * 100);*/
      axios
        .get(url)
        .then(res => {
          fulfill({ pt: e, result: res });
        })
        .catch(err => {
          fulfill({ pt: e, result: err.response });
        });
    });
  }
  handleDeleteSend = event => {
    event.preventDefault();

    if (!this.state.delete_value) {
      return openNotificationWithIcon(
        "error",
        "Ocurrio un error",
        "Debes seleccionar una opción antes de finalizar"
      );
    }
    listo = [];
    $res = [];
    this.setState({
      open: true,
      apisReady: [],
      isFlipped: false,
      delete: false
    });

    console.log("lo que puso ", this.state.delete_value);
    let apis = [
      { nam: "Mercadolibre", key: "ml", val: this.props.item.permalink_ml },
      { nam: "Autocosmos", key: "ac", val: this.props.item.permalink_ac },
      { nam: "Deautos", key: "da", val: this.props.item.permalink_da },
      { nam: "Demotores", key: "dm", val: this.props.item.permalink_dm },
      { nam: "Facebook", key: "fb", val: this.props.item.permalink_fb },
      { nam: "Olx", key: "ol", val: this.props.item.permalink_olx },
      { nam: "LaVoz", key: "lavoz", val: this.props.item.permalink_lavoz },
      { nam: "Instagram", key: "in", val: this.props.item.permalink_instagram },
      { nam: "NuestrosAutos", key: "na", val: true }
    ];
    /*this.setState({
      apisReady: []
    });

*/
    apis.map(p => {
      if (p.val !== null) {
        if (p.key == "fb") {
          $res.push(this.deleteFacebook("fb"));
        } else {
          $res.push(this.deleteAll(this.state.delete_value, p.key));
        }
      }
    });

    Promise.all($res)
      .then(function(results) {
        console.log("LO QUE VIE 222", results);

        results.forEach(function(obj) {
          console.log(
            "END execution with value =",
            obj.result,
            "and result =",
            obj.pt
          );
          let fil = apis.filter(function(e) {
            return e["key"] == obj.pt;
          });
          listo.push({
            key: obj.pt,
            name: fil[0].nam,
            ms: obj.result.data.ms,
            state: obj.result.status
          });
        });
        console.log("COMPLENTED");
      })
      .then(() => {
        this.setState({
          apisReady: listo
        });
      });
  };
  routeChange() {
    let path = "/vehiculos/editar/" + this.props.item.id;
    this.props.history.push(path);
  }

  clonar(id) {
    // Clonar parcialmente un vehiculo
    axios({
      method: "get",
      url:
        API_URL +
        "api/dealer/" +
        cookies.get("dealer").id +
        "/cloneVehicle/" +
        id +
        "?token=" +
        this.props.user.token
    })
      .then(response => {
        if (response.data.error == false) {
          // Todo ok
          localStorage.removeItem("vehicle_list");
          this.props.history.push("/vehiculos/editar/" + response.data.data.id);
        } else {
          alert(response.data.message);
        }
      })
      .catch(error => {
        alert(error);
      });
  }

  share(url) {
    this.setState({ sharebox: url });
  }

  handleChangeforSelect = event => {
    this.share(event.target.value);
  };

  handleChangeforSelectDelete = event => {
    if (event.target.value && event.target.value == "vendido") {
      this.setState({
        delete_portals_form: event.target.value
      });
    } else {
      this.setState({
        delete_portals_form: event.target.value,
        delete_value: event.target.value
      });
    }
  };
  handleChangeformSelectApisDelete = event => {
    this.setState({ delete_value: event.target.value });
  };

  render() {
    var item = this.props.item;

    if (item.image) {
      var imagex = item.image;
    } else {
      var imagex = "/img404.png";
    }
    return (
      <>
        <FacebookLibrary></FacebookLibrary>
        <CardFlip
          isDeleted={this.state.delete}
          infinite={false}
          isFlipped={this.state.isFlipped}
          flipDirection="horizontal"
        >
          <Card key="front" style={{ minHeight: "401px" }}>
            <div
              style={{
                background:
                  "url(" + imagex + ") center center / cover no-repeat",
                width: "100%",
                height: "200px",
                borderRadius: "5px 5px 0px 0px"
              }}
            ></div>
            <Container fluid className="card-detail p-3">
              <Badge
                style={{
                  top: "0.9375rem",
                  right: "0.9375rem",
                  position: "absolute",
                  fontSize: "11px",
                  padding: "4px 6px",
                  color: "#fff",
                  background: "#333",
                  border: "none",
                  opacity: ".8"
                }}
                pill
              >
                {item.created}
              </Badge>
              <p className="t-2 mb-0 mt-1">{item.brand}</p>
              <h4 className="card-title mb-2" style={{ fontWeight: "700" }}>
                {item.model}
              </h4>
              <p className="t-4 fw-400 mb-1">
                {item.price} {item.currency_name}, Año {item.year},{" "}
                {item.kilometers} KMs
              </p>
              <Row className="mb-2 mt-2">
                <PortalsIcon
                  api="mercadolibre"
                  url={item.permalink_ml}
                ></PortalsIcon>
                <PortalsIcon
                  api="demotores"
                  url={item.permalink_dm}
                ></PortalsIcon>
                <PortalsIcon
                  api="facebook"
                  url={item.permalink_fb}
                ></PortalsIcon>
                <PortalsIcon
                  api="instagram"
                  url={item.permalink_instagram}
                ></PortalsIcon>
                <PortalsIcon
                  api="nuestrosautos"
                  url={"https://nuestrosautos.com.ar/detalle/" + item.id}
                ></PortalsIcon>
                {item.status_olx == "active" ? (
                  item.permalink_olx !== null ? (
                    <PortalsIcon
                      api="olx"
                      url={
                        "https://www.olx.com.ar/profile/" +
                        item.permalink_olx.id_olx
                      }
                    ></PortalsIcon>
                  ) : (
                    <PortalsIcon
                      api="olx"
                      url={"https://www.olx.com.ar"}
                    ></PortalsIcon>
                  )
                ) : (
                  <></>
                )}
                <PortalsIcon
                  api="autocosmos"
                  url={item.permalink_ac}
                ></PortalsIcon>
                <PortalsIcon
                  api="lavoz"
                  url={item.permalink_lavoz}
                ></PortalsIcon>
                <PortalsIcon
                  api="Deautos"
                  url={item.permalink_da}
                ></PortalsIcon>
                <PortalsIcon
                  api="www"
                  url={
                    "https://" +
                    item.url_company +
                    ".nuestrosautos.cca.org.ar/" +
                    item.id +
                    " /" +
                    item.dealer_id +
                    "#true"
                  }
                ></PortalsIcon>
              </Row>
              <CardFooter
                style={{
                  padding: "0px"
                }}
              >
                <ButtonToolbar>
                  <ButtonGroup style={{ width: "100%" }}>
                    <Button onClick={this.handleClick} theme="primary">
                      <FontAwesomeIcon icon={faShare} />
                    </Button>
                    <Button theme="secondary" onClick={this.routeChange}>
                      <FontAwesomeIcon icon={faPen} />
                    </Button>
                    <Button
                      theme="secondary"
                      onClick={() =>
                        window.confirm(
                          "¿Seguro que desea clonar este vehículo?"
                        )
                          ? this.clonar(item.id)
                          : ""
                      }
                      title="Clonar Vehiculo"
                    >
                      <FontAwesomeIcon icon={faClone} />
                    </Button>
                    <Button onClick={this.handleDelete} theme="secondary">
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                    <Button onClick={() => {
                      this.setState({carSelected : item.id})
                      this.modalQr()
                    }} theme="secondary">
                      <FontAwesomeIcon icon={faQrcode} />
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </CardFooter>
            </Container>
          </Card>
          <Card key="back" style={{ minHeight: "401px" }}>
            <div className="container-fluid">
              <h6 className="mt-3">Versión</h6>
              <p>{item.version}</p>
              <hr className="mt-1 mb-2" />
              <h6 className="mt-3">¿Que vás a compartir?</h6>
              <FormSelect onChange={this.handleChangeforSelect}>
                <option disabled selected>
                  Seleccione una opción
                </option>
                <PortalsIcon
                  type="option"
                  api="mercadolibre"
                  url={item.permalink_ml}
                ></PortalsIcon>
                <PortalsIcon
                  type="option"
                  api="demotores"
                  url={item.permalink_dm}
                ></PortalsIcon>
                <PortalsIcon
                  type="option"
                  api="facebook"
                  url={item.permalink_fb}
                ></PortalsIcon>
                <PortalsIcon
                  type="option"
                  api="instagram"
                  url={item.permalink_instagram}
                ></PortalsIcon>
                <PortalsIcon
                  type="option"
                  api="nuestrosautos"
                  url={"https://nuestrosautos.com.ar/detalle/" + item.id}
                ></PortalsIcon>
                <PortalsIcon
                  type="option"
                  api="olx"
                  url={item.permalink_olx}
                ></PortalsIcon>
                <PortalsIcon
                  type="option"
                  api="autocosmos"
                  url={item.permalink_ac}
                ></PortalsIcon>
              </FormSelect>
              {this.state.sharebox == "" ? (
                <div></div>
              ) : (
                <ListGroup>
                  <ListGroupItem>
                    <svg
                      xmlns="https://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
                    </svg>
                    <a
                      target="_blank"
                      style={{ color: "black", marginLeft: "5px" }}
                      href={
                        "https://api.whatsapp.com/send?text=" +
                        this.state.sharebox
                      }
                    >
                      WhatsApp
                    </a>
                  </ListGroupItem>
                  <ListGroupItem>
                    <svg
                      xmlns="https://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      A
                    >
                      <path d="M0 3v18h24v-18h-24zm6.623 7.929l-4.623 5.712v-9.458l4.623 3.746zm-4.141-5.929h19.035l-9.517 7.713-9.518-7.713zm5.694 7.188l3.824 3.099 3.83-3.104 5.612 6.817h-18.779l5.513-6.812zm9.208-1.264l4.616-3.741v9.348l-4.616-5.607z" />
                    </svg>
                    <a
                      style={{ color: "black", marginLeft: "5px" }}
                      target="_blank"
                      href={
                        "mailto:?subject=Nueva%20Publicaci%C3%B3n&body=Les%20comparto%20mi%20publicacion%20" +
                        this.state.sharebox
                      }
                    >
                      Email
                    </a>
                  </ListGroupItem>
                  <ListGroupItem>
                    <svg
                      width="24"
                      height="24"
                      xmlns="https://www.w3.org/2000/svg"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                    >
                      <path d="M17.843 1c2.159 0 3.912 1.753 3.912 3.912 0 .395-.053 1.704-1.195 2.813l-8.465 8.465c-.596.671-2.12 1.279-3.299.099-1.178-1.177-.586-2.685.088-3.29l4.409-4.409.707.707-3.164 3.163.014.003-1.411 1.413.004.003c-.97 1.151.618 2.93 1.977 1.572l8.383-8.384c.656-.652.94-1.393.94-2.155 0-1.601-1.299-2.9-2.9-2.9-.783 0-1.495.311-2.018.818l-.003-.003c-.573.573-11.502 11.494-11.534 11.527l-.002-.002c-.795.812-1.286 1.923-1.286 3.148 0 2.483 2.017 4.5 4.5 4.5.65 0 1.84.007 3.52-1.668l10.273-10.267.707.707-10.477 10.477c-1.004 1.077-2.435 1.751-4.023 1.751-3.035 0-5.5-2.465-5.5-5.5 0-1.577.666-3 1.731-4.004 10.668-10.667 10.835-10.839 11.295-11.297.277-.278 1.215-1.199 2.817-1.199" />
                    </svg>
                    <a
                      style={{ color: "black", marginLeft: "5px" }}
                      target="_blank"
                      href={this.state.sharebox}
                    >
                      Ir al enlace
                    </a>
                  </ListGroupItem>
                </ListGroup>
              )}
              <Button
                onClick={this.handleClick}
                theme="secondary"
                style={{
                  position: "absolute",
                  bottom: "15px",
                  width: "auto",
                  right: "15px"
                }}
              >
                <FontAwesomeIcon icon={faUndo} />
              </Button>
            </div>
          </Card>
          <Card key="deleted" style={{ minHeight: "401px" }}>
            <div className="container-fluid">
              <h6 className="mt-3">
                ¿Por qué quieres finalizar la publicacion?
              </h6>
              <FormSelect
                className="mb-4"
                onChange={this.handleChangeforSelectDelete}
              >
                <option disabled selected value="">
                  Seleccione una opción
                </option>
                <option value="vendido">Lo vendí</option>
                <option value="Novendi">No lo vendí</option>
                <option value="Error">Fue un error</option>
              </FormSelect>
              {this.state.delete_portals_form != "vendido" ? (
                <div></div>
              ) : (
                <div>
                  <h6 className="mt-3">¿Por donde lo vendiste?</h6>
                  <FormSelect
                    className="mb-4"
                    onChange={this.handleChangeformSelectApisDelete}
                  >
                    <option disabled selected>
                      Seleccione una opción
                    </option>
                    <option value="mercadolibre">MercadoLibre</option>
                    <option value="olx">OLX</option>
                    <option value="facebook">Facebook</option>
                    <option value="demotores">Demotores</option>
                    <option value="autocosmos">Autocosmos</option>
                    <option value="nuestrosautos">Nuestrosautos</option>
                    <option value="agencia">Agencia</option>
                  </FormSelect>
                </div>
              )}
              <ButtonToolbar
                style={{
                  position: "absolute",
                  bottom: "15px"
                }}
              >
                <ButtonGroup style={{ width: "100%" }}>
                  <Button onClick={this.handleDelete} theme="secondary">
                    <FontAwesomeIcon icon={faUndo} /> Cancelar
                  </Button>
                  <Button
                    className="active"
                    theme="primary"
                    onClick={e => {
                      this.handleDeleteSend(e);
                    }}
                  >
                    <FontAwesomeIcon icon={faCheck} /> Finalizar
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </div>
          </Card>
        </CardFlip>

        <Modal
          open={this.state.open}
          toggle={() => console.log("Toggle")}
          size="lg"
        >
          <ModalHeader>Eliminando publicaciones</ModalHeader>
          <ModalBody>
            {this.state.open ? (
              <Timeline>
                {this.state.apisReady.length > 0 ? (
                  this.state.apisReady.map(item => {
                    //loading item.status
                    console.log("Resulta23" + item.state);
                    let colr = item.state == "200" ? "blue" : "red";
                    return (
                      <Timeline.Item color={colr} key={item.key}>
                        {item.name} - {item.ms}
                      </Timeline.Item>
                    );
                  })
                ) : (
                  <img
                    style={{
                      width: "50px"
                    }}
                    src="/img/loadingnew2.gif"
                  />
                )}
              </Timeline>
            ) : (
              ""
            )}
            {this.state.apisReady.length > 0 ? (
              <div className="col-md-4 col-sm-4 col-xs-4 pl-0">
                <Button
                  onClick={this.toggle}
                  outline
                  theme="success"
                  type="submit"
                >
                  Salir
                </Button>
              </div>
            ) : (
              ""
            )}
          </ModalBody>
        </Modal>
        <ModalQr idCar={this.state.carSelected} modalOpen={this.state.openModalQr} modalShowHide={this.modalQr} />
      </>
    );
  }
}

VehiclesList.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object
};

export default withRouter(VehiclesList);
