import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { reduxForm, Field, isDirty, getFormValues } from "redux-form";
import InputField from "../_common/InputField";
import SelectSimple from "../_common/SelectSimple"; 
import PageTitle from "../_components/utils/PageTitle";
import { Input, Pagination, Table, Alert, Button, Icon, notification } from 'antd';
import { adminuserActions } from "../_actions";
import { store } from "../_helpers";
import CheckBoxSimple from "../_common/CheckBoxSimple";
import {TextareaSimple} from "../_common";
import { API_URL } from "../_config";
import {Form} from "./";
import axios from "axios";
import "react-tabs/style/react-tabs.css";
import Highlighter from 'react-highlight-words';
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader
} from "shards-react";

const PedidoInformes = props => {
  const { handleSubmit, handleChange,  pristine, reset, submitting } = props
  const send_data = values => {

    if(!values.sociocca || !values.solicitante || !values.email || !values.tipo || !values.notas) {
        return notification.open({
            message: 'Complete los campos requeridos',
            description:
            'Para poder enviar su solicitud, precisamos que rellene todos campos requeridos',
            icon: <Icon type="minus-circle" style={{ color: '#f54842' }} />,
            top: 70
          });
    }
    notification.open({
        message: 'Cargando',
        description:
          'Espere un momento por favor',
        icon: <Icon type="loading" style={{ color: '#4caf50' }} />,
        top: 70
      });
      
      axios
        .post(
          API_URL + "informes/send?token=" +
          store.getState().authentication.user.token, {data: values}
        )
        .then(response => {
              notification.destroy()
              notification.open({
                message: 'Operación exitosa',
                icon: <Icon type="check-circle" style={{ color: '#4caf50' }} />,
                top: 70
              });
        });
  }
 
 

  return (
    <>
        <Row noGutters className="page-header py-4" style={{ width: "100%" }}>
            <PageTitle sm="5" title="Pedido de Informes" className="text-sm-left" />
            <Col sm="5">
           
            </Col>
            <Col sm="2">
        
            </Col>
          </Row>


        <Alert
            message="Nota"
            description="Debe completar la informacion requerida segun el tipo de informe."
            type="info"
            style={{width:'100%', marginBottom: '10px'}}
        />
       <Form onSubmit={send_data}></Form>

    </>
  )
   
}


function mapState(state, props) {
   
    const { users, authentication, vehicles, adminuser } = state;
  
    const { user } = authentication;

    var initl = {};

    return { user, users, adminuser};
  }
  const actionCreators = {
  
  };

const po = reduxForm({
  form: 'PedidoInformes', 
  enableReinitialize: true
})(PedidoInformes);

const pox = connect(
    mapState,
    actionCreators
  )(po);
  
export { pox as PedidoInformes };