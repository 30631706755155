import React from "react";
import { connect } from "react-redux";
//import $ from 'jquery'
import { store } from "../_helpers";
import { Table, Divider, Tag, Input, Button as BTX, Icon } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import axios from "axios";
import { adminuserActions } from "../_actions";
import "./Usuarios.css";
import "react-tabs/style/react-tabs.css";
import {
  Badge,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Card,
  CardImg,
  ButtonToolbar,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  FormCheckbox,
  Container,
  FormTextarea,
  CardHeader,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  ButtonGroup
} from "shards-react";
import {
  CrearABMFormulario,
  ConsABMFormulario,
  PermABMFormulario,
  EditarABMFormulario
} from ".";
import PageTitle from "../_components/PageTitle";
import { API_URL } from "../_config";
import PolyLoader from '../_components/PolyLoader'
import openNotificationWithIcon from "../_common/OpenNotificationWithIcon";

class ABMFormularios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      nav_number: 0,
      modalDeleteForm: false,
      formSelected : "",
      cons: false,
      perm: false,
      id_user: 0,
      permisos_usados: {},
      dealers_usados: {},
      minValue: 0,
      maxValue: 9,
      minValue2: 0,
      maxValue2: 9,
      valor: "",
      searchText: "",
      searchedColumn: "",
      search: false,
      modal_id: undefined,
      data: []
    };

    //const items = this.props.vehicles;
    //console.log('aca ?', items);
    this.toggle = this.toggle.bind(this);
    this.create_user = this.create_user.bind(this);
    this.assign_cons = this.assign_cons.bind(this);
    this.assign_perms = this.assign_perms.bind(this);
    this.mostrar_coso = this.mostrar_coso.bind(this);
    this.eliminar = this.eliminar.bind(this);
    this.set_coso = this.set_coso.bind(this);
    this.getFormularios = this.getFormularios.bind(this);
    this.dataRefressh = this.dataRefressh.bind(this);
    this.showHideModalDeleteForm = this.showHideModalDeleteForm.bind(this);
  }

  componentWillMount() {
    this.dataRefressh(true)
    }
   async getFormularios(load){
     if(load) this.setState({load  : true})
     await axios({
        method: "get",
        url:
          API_URL + "api/services?token=" +
          store.getState().authentication.user.token
      }).then(response => {
        this.setState({
          data: response.data.data
        });
      });
    }
  
    dataRefressh(loadclose, notificationuse,event,title,description){
      this.getFormularios(true).then(res =>{
        if(loadclose)
        setTimeout(()=> {this.setState({load : false})},1900)
      }).then(res => {
        if(notificationuse){
          setTimeout(()=> {
            openNotificationWithIcon(event,title, description)
          },1400)
        }
      })
    }

  mostrar_coso(item) {
    this.setState({
      modal_id: undefined
    });

    if (this.state.modal_id == undefined) {
      this.setState({
        modal_id: (
          <EditarABMFormulario
            open={true}
            key={"modal" + item}
            id_user={item}
            getFormularios={this.getFormularios}
          />
        )
      });
    }
  }

  set_coso(item) {
    this.setState({
      modal_id: item
    });

    if (this.state.modal_id != undefined) {
      console.log("pex");
    }
  }

  eliminar(item) {
    axios
    .delete(
      API_URL + "api/services/" +
      item +
      "?token=" +
      store.getState().authentication.user.token
      )
      .then(response => {
        this.setState({open : false, modalDeleteForm: false})
          this.dataRefressh(true,true,"success","Listo!", "Formulario Eliminado con exito!")
        }).catch(error => {
          this.setState({open : false, modalDeleteForm: false})
            this.dataRefressh(true,true,"error","Ooops! hemos tenido un error", `${error.response || error}`)
      })
    }
    

    create_user(values) {
      axios
      .post(
        API_URL + "api/services?token=" +
        store.getState().authentication.user.token,
        {
          elementos: values.elementos,
          id: this.props.id_user,
          importe: values.importe,
          type: values.type
        }
        )
        .then(response => {
          this.setState({
            id_user: response.data.id,
            cons: true,
            perm: true
          });
        }).then(res =>{
          this.setState({open : false})
          this.dataRefressh(true,true,"success","Listo!", "Formulario creado con exito!")
        } 
        ).catch(error => {
          this.setState({open : false})
          this.dataRefressh(true,true,"error","Ooops! hemos tenido un error", `${error.response || error}`)
        })
      }

  assign_cons(values) {
    axios
      .post(
        API_URL + "api/user/" +
          this.state.id_user +
          "/dealers?token=" +
          store.getState().authentication.user.token,
        values
      )
      .then(response => {
        console.log(response);
      });
  }

  assign_perms(values) {
    axios
      .post(
        API_URL + "api/user/" +
          this.state.id_user +
          "/perms?token=" +
          store.getState().authentication.user.token,
        values
      )
      .then(response => {
        console.log(response);
      });
  }

  getSize = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Buscar por ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: "100%", marginBottom: 8, display: "block" }}
        />

        <BTX
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: "100%", marginBottom: 8 }}
        >
          Buscar
        </BTX>
        <BTX
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: "100%" }}
        >
          Resetear
        </BTX>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  toggle() {
    this.setState({
      open: !this.state.open
    });
  }
  showHideModalDeleteForm(value){
    this.setState({
      modalDeleteForm : !this.state.modalDeleteForm,
      formSelected : value
    })
  }
  render() {
    const { open } = this.state;

    const columns = [
      {
        title: "#",
        dataIndex: "id",
        key: "id",
        render: text => <a>{text}</a>,
        ...this.getColumnSearchProps("id")
      },
      {
        title: "Elementos",
        dataIndex: "elementos",
        key: "elementos",
        sorter: (a, b) => a.elementos.localeCompare(b.elementos),
        ...this.getColumnSearchProps("elementos")
      },
      {
        title: "Importe por Unidad",
        dataIndex: "importe",
        key: "importe",
        sorter: (a, b) => a.importe.localeCompare(b.importe),
        ...this.getColumnSearchProps("importe")
      },
      {
        title: "Acción",
        dataIndex: "id",
        key: "accion",
        render: text => (
          <td className="pof" style={{ padding: "0.45rem !important" }}>
            <i
              className="material-icons"
              title="Editar"
              style={{ cursor: "pointer", display: "inline", fontSize: "23px" }}
              onClick={() => {
                this.mostrar_coso(text);
              }}
            >
              edit
            </i>
            <i
              className="material-icons"
              title="Eliminar"
              style={{ cursor: "pointer", display: "inline", fontSize: "23px" }}
              onClick={() => this.showHideModalDeleteForm(text)}
            >
              delete
            </i>
          </td>
        )
      }
    ];

    return (
      <div>
        <>
          <Row noGutters className="page-header py-4" style={{ width: "100%" }}>
            <PageTitle sm="4" title="ABMFormularios" className="text-sm-left" />
            <Col sm="6"></Col>
            <Col sm="2">
              <Button onClick={this.toggle}>Crear</Button>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card small className="mb-4">
                <CardBody className="p-0 pb-3">
                  <div class="table-responsive">
                    <Table
                      columns={columns}
                      locale={{ emptyText: "Obteniendo datos..." }}
                      dataSource={this.state.data}
                      pagination={{ pageSize: 20 }}
                      defaultSorting={[{ id: "total", desc: true }]}
                    />
                  </div>

                  {this.state.modal_id != undefined ? (
                    this.state.modal_id
                  ) : (
                    <div></div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal open={open} toggle={() => console.log("Toggle")}>
            <ModalHeader>
              Crear{" "}
              <i
                style={{
                  position: "absolute",
                  right: "20px",
                  top: "15px",
                  fontSize: "25px",
                  cursor: "pointer"
                }}
                class="material-icons"
                onClick={this.toggle}
              >
                clear
              </i>
            </ModalHeader>
            <ModalBody>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    active={this.state.nav_number == 0 ? true : false}
                    onClick={() => {
                      this.setState({
                        nav_number: 0
                      });
                    }}
                  >
                    Datos
                  </NavLink>
                </NavItem>
              </Nav>
              <br />
              <br />

              <CrearABMFormulario onSubmit={this.create_user} />
            </ModalBody>
          </Modal>

          <br />
          <br />
        </>
        <PolyLoader open={this.state.load} text="Obteniendo datos" />
          {/* Modal eliminar formulario */}
          <Modal size="md" open={this.state.modalDeleteForm} toggle={() => {}}>
          <ModalHeader>
              Eliminar Formulario
              <i
                style={{
                  position: "absolute",
                  right: "20px",
                  top: "15px",
                  fontSize: "25px",
                  cursor: "pointer"
                }}
                class="material-icons"
                onClick={this.showHideModalDeleteForm}
              >
                clear
              </i>
            </ModalHeader>
            <ModalBody>
              <div>
                <div>
                  <h5>¿Estas seguro que deseas eliminar este Formulario?</h5>
                </div>
                <div>
                  <button  className="btn btn-secondary m-2" onClick={this.showHideModalDeleteForm} >Cancelar</button>
                  <button className="btn btn-primary  m-2" onClick={() => this.eliminar(this.state.formSelected)}>Confirmar</button>
                </div>
              </div>
            </ModalBody>
          </Modal>
      </div>
    );
  }
}

function mapState(state) {
  const { users, authentication, vehicles, adminuser } = state;

  const { user } = authentication;
  console.log("esto ->", vehicles);

  return { user, users, adminuser };
}

const actionCreators = {
  getUsers: adminuserActions.getUsers
};

const connectedABMFormularios = connect(
  mapState,
  actionCreators
)(ABMFormularios);
export { connectedABMFormularios as ABMFormularios };
