import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardHeader, CardBody, Button, Container, FormCheckbox, FormTextarea} from "shards-react";

class Paso7 extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = { descripcion: null };
      }
    
      handleChange(e) {
        this.setState({ descripcion: e.target.descripcion });
      }

  render() {
    const { descripcion } = this.state;
    return (
    <div>
      <h6>Descripción</h6>
      <FormTextarea onChange={this.handleChange} rows='5' />
      <br />
    </div>
    );
  }
}


export default Paso7;
