import React from 'react';

const RenderFieldMyDealer = ({ input, label, type, meta: { touched, error } }) => (
  <div style={{padding : "10px"}}>
    <label>{label}</label>
    <div>
      <input {...input} placeholder={label} className="form-control"  type={type} />
      {touched && error && <span style={{fontSize: "10px" ,color: "red"}}>{error}</span>}
    </div>
  </div>
);

export default RenderFieldMyDealer;