import React, { useState, useEffect, useRef } from "react";
import {
  reduxForm,
  Field,
  getFormValues,
  change,
  formValueSelector
} from "redux-form";
import { store } from "../_helpers";
import SelectSimpleParser from "../_common/SelectSimpleParser";
import { compose } from "redux";
import { notification, Timeline } from "antd";
import { connect } from "react-redux";
import CheckBoxSimple from "../_common/CheckBoxSimple";
import { history } from "../_helpers";
import SelectSimple from "../_common/SelectSimple";
import SelectDynamic from "../_common/SelectDynamic";
import { TextareaSimple } from "../_common";
import Validate from "../_common/Validate";
import InputField from "../_common/InputField";
import openNotificationWithIcon from "../_common/OpenNotificationWithIcon";
import ImagesUploader from "react-images-uploader";
import { UploadWrapper } from "./";
import FacebookLibrary from "../_common/FacebookLibrary";
import "react-images-uploader/styles.css";
import "react-images-uploader/font.css";
import axios from "axios";
import { editarVehiclesServices } from "../_services";
import { API_URL } from "../_config";
import { SubirImagenes } from "./SubirImagenes";

import {
  Row,
  Col,
  Card,
  FormCheckbox,
  Container,
  FormTextarea,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  Alert,
  Modal,
  ModalBody,
  ModalHeader
} from "shards-react";
import PageTitle from "../_components/PageTitle";
import { vehiclesActions, notificacionsActions } from "../_actions";
import "./Vehicles.css";
import { PublishInput } from "./PublishInput";
import Cookies from "universal-cookie";
import { RedNuestrosAutos } from "../RedNuestrosAutos";
import {PREAPI_URL } from "../_config";
const cookies = new Cookies();
const required = value => {
  if (value) {
    return undefined;
  } else {
    return "Ese campo es requerido";
  }
};

const number = value =>
  value && isNaN(Number(value)) ? "Solo puede contener números" : undefined;
let types = require("./type.json");
let features = require("../_data/features.json");
var endImages = [];
var listo = [];
var $res = [];
function EditVehiclesFrom(props) {
  const [apisNA, setApisNA] = useState([]);
  const [mOpen, setMOpen] = useState(false);
  const [apisReady, setApisReady] = useState([]);
  const [mercadolibreP, setMercadolibrep] = useState(true);
  const [brand, setBrand] = useState("");
  const [initF, setInitF] = useState(props.showVehicle);
  const [typeVehicles, setTypeVehicles] = useState(1);
  const [iniFeature, setInifeature] = useState([]);
  const [flt, setFtl] = useState([]);
  const [brands, setBrands] = useState([]);
  const [publishsIns, setPublish] = useState(new Map());
  const [changedComponent, setChangedComponent] = useState(false);
  const [oneExit, setOneExit] = useState(false);
  const [pristine_modificado, set_pristine_modificado] = useState(props.pristine);
  const [intent, setIntent] = useState(0);
  /* mercadolibre: false,
    nuestrosAutos: false,
    facebook: false,
    autoCosmos: false,
    deAutos: false,
    deMotores: false,
    olx: false,
    instagram: false
  ]);*/
  const prevScrollY = useRef(0);
  const [goingUp, setGoingUp] = useState(false);

  const [condicion, setCondicion] = useState(0);
  const [tituloML, setTituloML] = useState("");
  const [models, setModels] = useState([]);
  const [cargando, setCargando] = useState(true);
  const [versiones, setVersiones] = useState([]);
  const [version, setVersion] = useState([]);
  const [model, setModel] = useState("");
  const [newversion, setNewv] = useState(true);
  const [eliminarURL, setEliminarURL] = useState(false);
  const [colors, setColors] = useState([]);
  const [anio, setAnio] = useState(0);
  const [ac, setAc] = useState(false);
  const [avisoDeGuardar, setAvisoDeGuardar] = useState(false);
  const [asiento_regulable, setAsiento_regulable] = useState(false);
  const [
    handleChangeasiento_regulable,
    sethandleChangeAsiento_regulable
  ] = useState(false);
  let featl = [];

  const [posibility, setPosibility] = useState(false);
  const [showBoton, setShowBoton] = useState(true);

  const [titleText, setTitleText] = useState("");

  const [brandText, setBrandText] = useState("");
  const [modelText, setModelText] = useState("");
  useEffect(() => {
    if(newversion == false) {
      props.dispatch(change("editVehicules", "version_id", '999999999'));
    }else{
      props.dispatch(change("editVehicules", "version_id", ''));
      props.dispatch(change("editVehicules", "new_version", ""));
    }
  }, [newversion]);

  useEffect(() => {
    props.dispatch(change("editVehicules", "titleML", titleText));
  }, [titleText]);
    useEffect(() => {
        set_pristine_modificado(props.pristine)
    }, [props.pristine]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && goingUp) {
        setGoingUp(false);
      }
      if (prevScrollY.current > currentScrollY && !goingUp) {
        setGoingUp(true);
      }

      prevScrollY.current = currentScrollY;

      if (currentScrollY > 4500) {
        // Ocultar boton
        setShowBoton(false);
      } else {
        setShowBoton(true);
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [goingUp]);

  //const [versions, setVersio] = useState([]);
  const renderTextArea = ({ input, meta: { touched, error, warning } }) => (
    <div>
      <label>Descripción</label>
      <div>
        <textarea
          {...input}
          placeholder="Descripción"
          className="form-control"
          row="40"
        ></textarea>
        {touched &&
          ((error && <span>{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
  function getResponse(e) {
    setTypeVehicles(e.target.value);
  }
  function cambio1() {}

  function updateData(data) {
    const id = props.vehiculo;
    var caracteristicas = [];

    data.fit.map(function(it, ix) {
      caracteristicas.push(ix);
    });
    localStorage.removeItem("vehicle_list");
    axios
      .post(
        API_URL +
          "api/dealer/" +
          cookies.get("dealer").id +
          "/vehicles/" +
          id +
          "/edit?token=" +
          props.user.token,
        {
          data: data,
          features: caracteristicas,
          note: data.note,
          agregarurl: 1,
          noteHtml: "",
          noteOLX: data.noteOLX,
          note_id: data.note_id
        }
      )

      .then(response => {
        console.log("|||OK", response);
        localStorage.removeItem("vehicle_list");

        // setApisNA(response.data);
        listo.push({
          key: 0,
          name: "NuestrosAutos",
          ms: response.data.ms,
          state: 200
        });
        setApisReady([
          {
            key: 0,
            name: "NuestrosAutos",
            ms: response.data.ms,
            state: 200
          }
        ]);
      })
      .catch(error => {
        setMOpen(false);
        console.log("ERROR ||", error);
        return false;
      });

    return true;
  }

  function reportar_error(portal, mensaje) {
    const id = props.vehiculo;

    var dato = JSON.parse(localStorage.getItem("user"));

    var user_id = dato.user.id;

    var api =
      API_URL +
      "api/dealer/" +
      cookies.get("dealer").id +
      "/vehicles/" +
      id +
      "/update-portals-all/" +
      portal +
      "?token=" +
      props.user.token;

    axios
      .post(
        PREAPI_URL + "ac_notificacions?token=" +
          store.getState().authentication.user.token,
        {
          title: "Error al editar en " + portal,
          text:
            mensaje.data.ms +
            " Vehículo (" +
            props.vehiculo +
            "): " +
            props.showVehicle.titleML,
          type: "error",
          leido: 0,
          button: true,
          button_for: "all",
          button_api: api,
          destino: user_id
        }
      )
      .then(response => {
        // Ok
        props.getNoti();
      });
  }
  /*
   */
  function editAll(details, e) {
    const id = props.vehiculo;
    var url =
      API_URL +
      "api/dealer/" +
      cookies.get("dealer").id +
      "/vehicles/" +
      id +
      "/update-portals-all/" +
      e +
      "?token=" +
      props.user.token;
    return new Promise(function(fulfill, values) {
      /* //console.log('START execution with value =', value);
            setTimeout(function() {
                fulfill({ value: value, result: value * value });
            }, 0 | Math.random() * 100);*/
      axios
        .post(url, { primary: false })
        .then(res => {
          fulfill({ pt: e, result: res });
        })
        .catch(err => {
          fulfill({ pt: e, result: err.response });
          reportar_error(e, err.response);
        });
    });
  }

  function sendEdit(values) {
    setMOpen(true);
    setApisReady([]);
    listo = [];
    $res = [];

    let apis = [
      {
        nam: "Mercadolibre",
        key: "ml",
        val: props.showVehicle.publish.mercadolibre
      },
      {
        nam: "Autocosmos",
        key: "ac",
        val: props.showVehicle.publish.autoCosmos
      },
      { nam: "Demotores", key: "dm", val: props.showVehicle.publish.deMotores },
      // { nam: "Facebook", key: "fb", val: props.showVehicle.publish.facebook},
      { nam: "Olx", key: "ol", val: props.showVehicle.publish.olx },
      { nam: "lavoz", key: "lavoz", val: props.showVehicle.publish.lavoz },
      { nam: "DeAutos", key: "deautos", val: props.showVehicle.publish.deAutos }
      
    ];
    if (updateData(values)) {
      apis.map(p => {
        if (p.val !== false) {
          $res.push(editAll(values, p.key));
        }
      });

      // listo.push(apisReady[0]);
      //console.log("PASOX@");
      //console.log($res.length);
      if ($res.length == 0) {
        const timer = setTimeout(() => {
          setOneExit(true);
        }, 2000);
      } else {
        Promise.all($res)
          .then(function(results) {
            results.forEach(function(obj) {
              console.log(
                "END execution with value =",
                obj.result,
                "and result =",
                obj.pt
              );
              let fil = apis.filter(function(e) {
                return e["key"] == obj.pt;
              });
              listo.push({
                key: obj.pt,
                name: fil[0].nam,
                ms: obj.result.data.ms,
                state: obj.result.status
              });
            });
            console.log("COMPLENTED");
          })
          .then(() => {
            /* this.setState({
            apisReady: listo
          });*/

            setApisReady(listo);
          });
      }
    }
    /////
  }

  function selectBrands(e, init = null) {
    setIntent(intent + 1);
    if (init != null) {
      setBrand(e);
      var idazo = e;
    } else {
      setBrand(e.target.value);
      var idazo = e.target.value;
    }

    console.log(e);
    console.log("i tried");

    if (
      localStorage.getItem("brands_list") === null ||
      localStorage.getItem("models_list") === null
    ) {
      axios({
        method: "get",
        url:
          PREAPI_URL + "api/vehicle/brands?token=" +
          props.user.token
      }).then(response => {
        // Procedo a guardarlo en una cookie
        localStorage.setItem("brands_list", JSON.stringify(response.data));
      });

      // Se obtienen los modelos para guardarlos en una cookie
      axios({
        method: "get",
        url:
          PREAPI_URL + "api/v2/vehicle/models?token=" +
          props.user.token
      }).then(response => {
        // Procedo a guardarlo en una cookie
        localStorage.setItem("models_list", JSON.stringify(response.data));
      });

      // Se obtienen los colores para guardarlos en una cookie
      axios({
        method: "get",
        url:
          PREAPI_URL + "api/vehicle/colors?token=" +
          props.user.token
      }).then(response => {
        // Procedo a guardarlo en una cookie
        localStorage.setItem("colors_list", JSON.stringify(response.data));
      });

      const lcd1 = JSON.parse(localStorage.getItem("brands_list"));
      var lcd_search1 = lcd1.find(element => element.id == idazo);

      if (intent >= 1) {
        setTitleText(lcd_search1.name);
      }
      setBrandText(lcd_search1.name);
    } else {
      const lcd1 = JSON.parse(localStorage.getItem("brands_list"));
      var lcd_search1 = lcd1.find(element => element.id == idazo);
      if (intent >= 1) {
        setTitleText(lcd_search1.name);
      }
      setBrandText(lcd_search1.name);
    }

    if (localStorage.getItem("models_list") === null) {
      axios({
        method: "get",
        url:
          PREAPI_URL + "api/v2/vehicle/models?token=" +
          props.user.token
      }).then(response => {
        //console.log()
        //this.setState({ models: response });
        setModels(response);
        console.log("MODELOS", response);
        //const model = response.data;
      });
    } else {
      setModels({ data: JSON.parse(localStorage.getItem("models_list")) });
    }
  }
  function selectNumericVersion(e) {
    const lcd1 = version.data;
    var lcd_search1 = lcd1.find(element => element.id == e.target.value);

    setTitleText(brandText + " " + modelText + " " + lcd_search1.name);
  }

  function selectTextVersion(e) {
    setTitleText(brandText + " " + modelText + " " + e.target.value);
    // props.dispatch(change('publisVehicules', 'titleML', props.formValues.titleML + ' ' + e.target.value));
  }

  function selectModel(e, init = null) {
    setIntent(intent + 1);

    let $model;
    if (init != null) {
      setModel(e);
      $model = e;
      var idazo = e;
    } else {
      setModel(e.target.value);
      $model = e.target.value;
      var idazo = e.target.value;
    }

    const lcd2 = JSON.parse(localStorage.getItem("models_list"));
    var lcd_search2 = lcd2.find(element => element.id == idazo);

    if (intent >= 1) {
      setTitleText(brandText + " " + lcd_search2.name);
    }
    setModelText(lcd_search2.name);
    axios({
      method: "get",
      url:
        PREAPI_URL + "api/v2/vehicles/model/" +
        $model +
        "/versions?token=" +
        props.user.token
    }).then(response => {
      //this.setState({ versions: 'camiislsl ' });
      //const model = response.data;
      // this.setState({ versions: response });
      setVersion(response);
      console.log("VERSIONES ", versiones);
      // console.log('versiones ', this.state.versions);
    });
  }

  function handleCheck(val) {
    if (flt.find(element => element.id == val)) return true;
    else return false;

    //return $item.indexOf(va) > -1 ;
  }

  const DEALER_URL = cookies.get("dealer").dealerUrl;

  useEffect(() => {
    if(props.showVehicle.note != undefined && props.showVehicle.note != null) {
      var url = 'https://' + DEALER_URL + '.nuestrosautos.cca.org.ar';

      // Ahora con el length de la url, voy a obtener un pedazo del final
      var posible_url = props.showVehicle.note.substr(props.showVehicle.note.length - url.length)

      if (posible_url == url) {
        // Hay coincidencia
        setEliminarURL(true);
      } else {
        setEliminarURL(false);
      }
    }
  }, [props.showVehicle.note])

  function agregarURL(){
    // Genero la url
    var url = 'https://' + DEALER_URL + '.nuestrosautos.cca.org.ar';

    props.showVehicle.note = props.showVehicle.note + ' ' + url;
    // La guardo
    props.dispatch(change("editVehicules", "note", props.showVehicle.note));

      set_pristine_modificado(false);

  }

  function sacarURL() {
    var url = 'https://' + DEALER_URL + '.nuestrosautos.cca.org.ar';

    props.showVehicle.note = props.showVehicle.note.slice(0, props.showVehicle.note.length - url.length);
    // La guardo
    props.dispatch(change("editVehicules", "note", props.showVehicle.note));

      set_pristine_modificado(false);

  }
  function handleChangeac() {}
  function imageTrue(starts) {
    console.log("|||||IMAGENES ||||", starts);
  }

  const { handleSubmit, initialValues, pristine, submitting, error } = props;

  const { images } = initialValues;

  useEffect(() => {
    let isCancelled = false;
    if (props.showVehicle && !isCancelled) {
      if (JSON.stringify(props.showVehicle.brand_id) != null) {
        console.log("homej");
        console.log(props.showVehicle);

        if (props.showVehicle.new_version !== "") {
          console.log("alone");
          console.log(props.showVehicle.new_version);
          console.log("homex");
          setNewv(false);
        }

        // Tsst

        if (props.showVehicle.features) {
          setFtl(props.showVehicle.features);
        }
        // handlePublish(props.showVehicle.publish);
        Object.entries(props.showVehicle.publish).map(function([key, value]) {
          if (!isCancelled) {
            setPublish(publishsIns.set(key, value));
          }
        });
        if (!isCancelled) {
          setTypeVehicles(props.showVehicle.type);
          selectBrands(props.showVehicle.brand_id, 1);
          selectModel(props.showVehicle.model_id, 1);
        }
        if (initialValues.images) {
          // CARGO LAS IMAGES
          initialValues.images.map(images => {
            endImages.push(images.url);
          });
        }

        notification.destroy();
      }
    }

    console.log(props.showVehicle.features);
    console.log("enyeitado");

    return () => {
      isCancelled = true;
    };
  }, [props.showVehicle]);

  useEffect(() => {
      console.log(props.imagenesData);
      console.log('aca se labura')
    if (localStorage.getItem("brands_list") === null) {
      axios({
        method: "get",
        url:
          PREAPI_URL + "api/vehicle/brands?token=" +
          props.user.token
      }).then(response => {
        setBrands(response.data);
        localStorage.setItem("brands_list", JSON.stringify(response.data));
      });
    } else {
      setBrands({ data: JSON.parse(localStorage.getItem("brands_list")) });
    }

    if (localStorage.getItem("colors_list") === null) {
      axios({
        method: "get",
        url:
          PREAPI_URL + "api/vehicle/colors?token=" +
          props.user.token
      }).then(response => {
        setColors(response.data);
        localStorage.setItem("colors_list", JSON.stringify(response.data));
      });
    } else {
      setColors(JSON.parse(localStorage.getItem("colors_list")));
    }
  }, []);
  function changePublish(event) {
    console.info("QUE ??????||", event.target.checked, event.target.name);
  }
  return (
    <div>
      <FacebookLibrary />
      <>
        <form onSubmit={handleSubmit(sendEdit)}>
          {pristine_modificado == false && showBoton == true && (
            <Button
              style={{
                cursor: "pointer",
                position: "fixed",
                top: "48px",
                width: "300px",
                right: "23px",
                zIndex: "1000"
              }}
              disabled={pristine_modificado || submitting}
              className="btn btn-primary mb-4 mt-4"
              type="submit"
              block
            >
              Guardar
            </Button>
          )}

          <Row noGutters className="page-header py-4" style={{ width: "100%" }}>
            <PageTitle
              sm="4"
              title="Editar Vehículo"
              className="text-sm-left"
            />
            <Col sm="8">
              <h6
                onClick={() => {
                  history.push("/vehiculos");
                }}
                className="text-sm-right"
                style={{ paddingTop: "3px", cursor: "pointer" }}
              >
                Volver a Vehículos
              </h6>
            </Col>
          </Row>

          <Card>
            <Container>
              <>
                <h6 className="mt-4">Obligatorio = (*)</h6>
                <br />
                <h4>Datos del Vehículo (*)</h4>
                <hr />

                <FormGroup>
                  <Field
                    name="sale_condition"
                    validate={[required]}
                    label="Condición (*)"
                    children={[
                      ["0km", "0km"],
                      ["Usado", "Usado"],
                      ["Consignación", "Consignación"]
                    ]}
                    component={SelectSimple}
                  ></Field>
                </FormGroup>

                <FormGroup>
                  <label>Tipo (*)</label>
                  <Field
                    name="type"
                    item={types}
                    validate={[required]}
                    onChange={e => getResponse(e)}
                    component={SelectDynamic}
                  ></Field>
                </FormGroup>
                <FormGroup>
                  <label>Marca (*)</label>

                  <Field
                    name="brand_id"
                    item={brands}
                    validate={[required]}
                    onChange={e => selectBrands(e)}
                    component={SelectDynamic}
                    filter={"type_vehicle_id|" + typeVehicles}
                  ></Field>
                </FormGroup>

                <FormGroup>
                  <label>Modelo (*)</label>
                  <Field
                    name="model_id"
                    item={models}
                    validate={[required]}
                    onChange={e => selectModel(e)}
                    component={SelectDynamic}
                    filter={"type_vehicle_brand_id|" + brand}
                  ></Field>
                </FormGroup>

                <FormGroup>
                  <label>
                    Versión
                    <b>
                      (Quiero ingresar una nueva Versión
                      <input
                        type="checkbox"
                        checked={!newversion}
                        onClick={event =>
                          setNewv(event.target.checked ? false : true)
                        }
                      />
                      )
                    </b>
                  </label>

                  <Field
                    name="version_id"
                    item={version}
                    validate={[required]}
                    onChange={e => selectNumericVersion(e)}
                    component={SelectDynamic}
                    onoff={!newversion}
                  ></Field>
                </FormGroup>
                {newversion == false && (
                  <Field
                    name="new_version"
                    validate={[required]}
                    type="text"
                    onChange={e => selectTextVersion(e)}
                    label="Ingresar la nueva versión"
                    component={InputField}
                    value="otro"
                  ></Field>
                )}

                <FormGroup>
                  <Field
                    name="color_id"
                    children={colors}
                    validate={[required]}
                    component={SelectSimpleParser}
                    label="Color (*)"
                    filter="color_id"
                  ></Field>
                </FormGroup>
                <FormGroup>
                  <Field
                    name="titleML"
                    label="Título de MercadoLibre/OLX (*)"
                    type="text"
                    validate={[required]}
                    component={InputField}
                  />
                </FormGroup>

                <div className="row">
                  <div className="col-md-8">
                    <FormGroup>
                      <Field
                        name="price"
                        type="text"
                        label="Precio"
                        validate={[required, number]}
                        component={InputField}
                      />
                    </FormGroup>
                    <Field
                      name="chasis_number"
                      label="Nº de Chasis"
                      type="text"
                      component={InputField}
                    />
                  </div>
                  <div className="col-md-4">
                    <FormGroup>
                      <Field
                        name="currency_id"
                        children={[[1, "ARS"], [2, "USD"]]}
                        component={SelectSimple}
                        validate={[required]}
                        label="Moneda (*)"
                      />
                    </FormGroup>
                    <Field
                      name="kilometers"
                      label="Kilómetros (*)"
                      type="number"
                      validate={[required, number]}
                      component={InputField}
                    />
                  </div>
                </div>
                <Row>
                  <div className="col-md-4">
                    <FormGroup>
                      <Field
                        name="year"
                        type="text"
                        validate={[required]}
                        label="Año (*)"
                        component={InputField}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-md-4">
                    <FormGroup>
                      <Field
                        name="doors"
                        children={[[2, 2], [3, 3], [4, 4], [5, 5]]}
                        component={SelectSimple}
                        label="Cant. de puertas (*)"
                        validate={[required]}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-md-4">
                    <FormGroup>
                      <Field
                        name="type_vehicle_fuel_id"
                        children={[
                          [2, "Diésel"],
                          [1, "Nafta"],
                          [3, "Nafta/Gnc"]
                        ]}
                        component={SelectSimple}
                        label="Combustible (*)"
                        validate={[required]}
                      />
                    </FormGroup>
                  </div>
                </Row>

                <FormGroup>
                  <Field
                    name="owner"
                    label="Títular"
                    type="text"
                    component={InputField}
                  />
                </FormGroup>

                <FormGroup>
                  <Field
                    name="direction"
                    children={[
                      ["Mecánica", "Mecánica"],
                      ["Hidráulica", "Hidráulica"],
                      ["Asistida", "Asistida"],
                      ["Eléctrica", "Eléctrica"]
                    ]}
                    component={SelectSimple}
                    label="Dirección (*)"
                    validate={[required]}
                  />
                </FormGroup>

                <FormGroup>
                  <Field
                    name="transmission"
                    children={[
                      ["CVT", "CVT"],
                      ["Secuencial", "Secuencial"],
                      ["Manual", "Manual"],
                      ["Automática", "Automática"],
                      ["N/E Autos", "N/E Autos"]
                    ]}
                    component={SelectSimple}
                    label="Transmisión (*)"
                  />
                </FormGroup>

                <FormGroup>
                  <Field
                    name="heating"
                    children={[
                      ["N/E", "N/E"],
                      ["Doble aire", "Doble aire"],
                      ["Calefacción", "Calefacción"],
                      ["Aire acondicionado", "Aire acondicionado"]
                    ]}
                    component={SelectSimple}
                    label="Climatización"
                  />
                </FormGroup>
              </>
            </Container>
          </Card>
          <br />
          <Card id="editartema">
            <CardBody>
              <Row>
                <Col sm={10}>
                  <h4>Descripción</h4>
                </Col>
                <Col sm={2}>
                  {eliminarURL == true ?
                      <Button theme="secondary" onClick={() => sacarURL()}>Eliminar URL</Button>
                  :
                      <Button className="active"
                              theme="primary" onClick={() => agregarURL()}>Agregar URL</Button>
                  }

                </Col>
              </Row>
              <hr />

              <Field name="note" onChange={(e) => {
                props.showVehicle.note = e.target.value;
                e.target.value.length > 0 ? 
                (set_pristine_modificado(false) ): ( 
                  setAvisoDeGuardar(true) 
                  ) 
                  e.target.value ? setAvisoDeGuardar(false) : set_pristine_modificado(false)
              }} label="Nota" component={TextareaSimple} />
              {avisoDeGuardar && <p className="textAviso pt-2">Hey! si no ingresas descripción recuerda guardar los cambios! :) </p>}

              {props.showVehicle.noteOLX != null && (
                <>
                  <hr className="mt-5 mb-5" />
                  <Field
                    name="noteOLX"
                    label="Descripción para OLX"
                    component={TextareaSimple}
                  />
                </>
              )}
            </CardBody>
          </Card>
          <br />
          <Card>
            <CardBody>
              <h4>Fotos y vídeos</h4>
              <hr />
              <FormGroup>
                <FormGroup>
                  <Field
                    name="video_id"
                    type="text"
                    label="Link de Youtube"
                    component={InputField}
                  />
                </FormGroup>
              </FormGroup>
              <br />
           
                    <SubirImagenes prop={props} enviarData={() => handleSubmit(sendEdit)} imagenesData={props.imagenesData} id_vehicle={props.vehiculo}/>
                  

            </CardBody>
          </Card>
          <br />
          <Card>
            <CardBody>
              <h4>Caracteristicas</h4>
              <hr />
              <br />

              {features.map(function(d, x) {
                if (d.type_vehicle_id == typeVehicles) {
                  return (
                    <div>
                      <br />
                      <h5> {d.name}</h5>
                      <hr />
                      <br />
                      <Row>
                        {d.children.map(function(a, j) {
                          return (
                            <div className="col-md-6">
                              <Field
                                label={a.name}
                                activado={handleCheck(a.id)}
                                id={a.id}
                                name={"fit." + a.id}
                                key={"fit." + a.id}
                                component={CheckBoxSimple}
                                type="checkbox"
                              ></Field>
                            </div>
                          );
                        })}
                      </Row>
                    </div>
                  );
                }
              })}
            </CardBody>
          </Card>

          <Button
            style={{ cursor: "pointer" }}
            disabled={pristine_modificado || submitting}
            className="btn btn-primary mb-4 mt-4"
            type="submit"
            block
          >
            Guardar
          </Button>
        </form>
        {props.vehiculo &&
          <PublishInput
          className="mb-4 mt-4"
          refreshMovida={props.refreshMovida}
          pristinex={props.pristine}
          vehiculo={props.vehiculo}
          year={props.years}
          prop={props}
        ></PublishInput>
        }
      

        <br />
      </>
      <Modal open={mOpen} toggle={() => console.log("Toggle")} size="lg">
        <ModalHeader>Editando Publicacion</ModalHeader>

        <ModalBody>
          {apisReady.length > 1 || oneExit == true ? (
            ""
          ) : (
            <a
              className="nav-link  text-nowrap px-3"
              href="#"
              style={{
                paddingBottom: "4px",
                marginBottom: "43px",
                marginLeft: "-40px"
              }}
            >
              <img
                class="user-avatar rounded-circle mr-2"
                src="/img/loadingnew2.gif"
                alt="User Avatar"
                style={{
                  width: "60px",
                  /* font-size: 17px!important; */
                  marginTop: "-7px"
                }}
              />
              <span style={{ fontSize: "19px" }}>
                Espera mientras procesamos la informacion.
              </span>
            </a>
          )}
          {mOpen ? (
            <Timeline>
              {apisReady.length > 0
                ? apisReady.map(item => {
                    //loading item.status
                    console.log("Resulta23" + item.state);
                    let colr = item.state == "200" ? "blue" : "red";
                    return (
                      <Timeline.Item color={colr} key={item.key}>
                        {item.name} - {item.ms}
                      </Timeline.Item>
                    );
                  })
                : ""}
            </Timeline>
          ) : (
            ""
          )}
          {apisReady.length > 1 || oneExit == true ? (
            <div className="col-md-4 col-sm-4 col-xs-4 pl-0">
              <Button
                onClick={() => {
                  setMOpen(false);
                  set_pristine_modificado(true)
                  setAvisoDeGuardar(false)
                  props.refreshMovida()
                }}
                outline
                theme="success"
                type="submit"
              >
                Salir
              </Button>
            </div>
          ) : (
            ""
          )}
        </ModalBody>
      </Modal>
    </div>
  );
}
function mapState(state, ownprops) {
  const { users, authentication, vehicles, form, notificaciones } = state;
  const selector = formValueSelector("editVehicules");
  const { user } = authentication;
  
  const initialValues = ownprops.showVehicle;
 
  const { editVehicules } = form;
  const chris = selector(state, "brand_id");
  const years = selector(state, "year");
  const formValues = getFormValues("editVehicules")(state) || {};
  const inifeatures = features;
  console.log("Vehicles form showw !@", initialValues);
  //console.log("Vehicles form showw", inifeatures[0]);
  // let inifeatures2 = JSON.stringify(showVehicle.features);

  //features
  /*
  ;*/
  return {
    years,
    user,
    users,
    initialValues,
    notificaciones,
    formValues,
    chris,
    editVehicules
  };
}

const actionCreators = {
  getNoti: notificacionsActions.getNotifications
};

const connectEditVehicles = connect(
  mapState,
  actionCreators
);

export default compose(
  connectEditVehicles,
  reduxForm({
    // dataas: ["nuevos", "olivder"],
    form: "editVehicules", //                 <------ same form name
    //destroyOnUnmount: false, //        <------ preserve form data
    //forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    enableReinitialize: true, // que se cargue los initialstate

    Validate
  })
)(EditVehiclesFrom);
