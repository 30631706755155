import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardHeader, CardBody, Button, Container, FormCheckbox } from "shards-react";

class Paso5 extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
          ac: false,
          asiento_regulable: false,
        };
    
        this.handleChangeac = this.handleChangeac.bind(this);
        this.handleChangeasiento_regulable = this.handleChangeasiento_regulable.bind(this);
      }
      handleChangeac() {
        this.setState({
          ac: !this.state.ac,
        });
      }

      handleChangeasiento_regulable() {
        this.setState({
          asiento_regulable: !this.state.asiento_regulable,
        });
      }
  render() {
    return (
    <div>
      <h6>Seguridad</h6>
      <div style={{maxHeight: '300px', overflow: 'visible'}}>
        <FormCheckbox
            toggle
            checked={this.state.ac}
            onChange={this.handleChangeac}>
            Airbag conductor
        </FormCheckbox>

       
        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            Airbag pasajero
        </FormCheckbox>

        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            Apoya cabeza en asientos traseros
        </FormCheckbox>

        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            Control de estabilidad
        </FormCheckbox>

        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            Faros antinieblas delanteros
        </FormCheckbox>

        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            Frenos ABS
        </FormCheckbox>

        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            Regulador de velocidad
        </FormCheckbox>

        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            Airbag de cortina
        </FormCheckbox>


        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
             Airbag trasero
        </FormCheckbox>


        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            Blindado
        </FormCheckbox>

        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            Control de tracción
        </FormCheckbox>

        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            Faros antinieblas traseros
        </FormCheckbox>

        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            Inmovilizador de motor
        </FormCheckbox>

        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            Repartidor electrónico de fuerza de frenado
        </FormCheckbox>

        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            Airbag laterales
        </FormCheckbox>

        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            Alarma
        </FormCheckbox>

        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            Cierre de puertas automático en movimiento
        </FormCheckbox>

        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            Doble tracción
        </FormCheckbox>

        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            Faros de xenón
        </FormCheckbox>

        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            Isofix
        </FormCheckbox>

        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
             Tercer stop
        </FormCheckbox>

</div>

    </div>
    );
  }
}


export default Paso5;
