import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";
import InputField from "../_common/InputField";
import SelectSimple from "../_common/SelectSimple";
import CheckBoxSimple from "../_common/CheckBoxSimple";
import { API_URL } from "../_config";
import {
  Row,
  Col,
  Card,
} from "shards-react";
import { store } from "../_helpers";
import axios from "axios";

const PermABMFormulario = props => {
  const { handleSubmit, pristine, reset, submitting } = props
  const [perms, setPerms] = useState([{}]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      axios({
        method: "get",
        url:
          API_URL + "api/group?token=" +
          store.getState().authentication.user.token
      }).then(response => {
        setPerms(response.data);
        setLoading(false);
      });
    }, 1000);
  }, []); 
  
   if (loading == true) {
    return (<div style={{height:'500px', textAlign:'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}><img src="/loading.gif" style={{maxWidth:'200px', width:'100%'}}/></div>);
  }



  return (
    <form onSubmit={handleSubmit}>
      <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.2.0/css/font-awesome.min.css" rel="stylesheet"></link>
      <div>
        
        
      {perms.map(function(item, i){
              return (
                <Field
                  name={'perm' + item.id}
                  label={item.name}
                  component={CheckBoxSimple}
                  type="checkbox"
                ></Field>
              )
        })}
        
  


  
        </div>
  
     
      
        <br/>
        <button type="submit" style={{borderColor: '#e1e5eb', color: '#585858'}} disabled={pristine || submitting}>
            Actualizar
        </button>
    </form>
  )
}

function mapState(state, props) {
   
    const { users, authentication, vehicles, adminuser } = state;
  
    const { user } = authentication;

    var initl = {};
    
    props.activedX.map(function(item, i){
      
      initl = Object.assign({
        ['perm' + item.id]: true
      }, initl);
    });

   
  
    return { user, users, adminuser, initialValues: initl};
  }
  const actionCreators = {
   
  };

const po = reduxForm({
  form: 'PermABMFormulario' 
})(PermABMFormulario);

const pox = connect(
    mapState,
    actionCreators
  )(po);
  
export { pox as PermABMFormulario };