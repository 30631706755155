import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";
import InputField from "../_common/InputField";
import SelectSimple from "../_common/SelectSimple";
import CheckBoxSimple from "../_common/CheckBoxSimple";
import { Row, Col, Card, Button } from "shards-react";
import { store } from "../_helpers";
import { API_URL, PREAPI_URL } from "../_config";

import axios from "axios";
import { Alert } from "shards-react";
const PermUsuario = props => {
  const { handleSubmit, pristine, reset, submitting ,messagePerm,closeMessagePerm} = props;
  const [perms, setPerms] = useState([{}]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      axios({
        method: "get",
        url:
          API_URL + "api/group?token=" + store.getState().authentication.user.token
      }).then(response => {
        setPerms(response.data);
        setLoading(false);
      });
    }, 1000);
  }, []);

  if (loading == true) {
    return (
      <div
        style={{
          height: "500px",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          display: "flex"
        }}
      >
        <img src="/loading.gif" style={{ maxWidth: "200px", width: "100%" }} />
      </div>
    );
  }

  return (
    <>
     <Alert class="my-4" dismissible={() => {closeMessagePerm()}} open={messagePerm == 'actualizado'} theme="success">
              Operación Exitosa
      </Alert>
    <form onSubmit={handleSubmit}>
      <link
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.2.0/css/font-awesome.min.css"
        rel="stylesheet"
      ></link>
      <div>
        {perms.map(function(item, i) {
          return (
            <Field
            key={item.id}
              name={"perm" + item.id}
              label={item.name}
              component={CheckBoxSimple}
              type="checkbox"
            ></Field>
          );
        })}
      </div>
      <br />
      <Button type="submit" disabled={pristine || submitting}>
        Actualizar
      </Button>
    </form>
    </>
  );
};

function mapState(state, props) {
  const { users, authentication, vehicles, adminuser } = state;

  const { user } = authentication;

  var initl = {};

  props.activedX.map(function(item, i) {
    initl = Object.assign(
      {
        ["perm" + item.id]: true
      },
      initl
    );
  });

  return { user, users, adminuser, initialValues: initl };
}
const actionCreators = {};

const po = reduxForm({
  form: "PermUsuario"
})(PermUsuario);

const pox = connect(
  mapState,
  actionCreators
)(po);

export { pox as PermUsuario };
