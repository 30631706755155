import React from "react";
import { connect } from "react-redux";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import { store } from "../_helpers";
import axios from "axios";
import { Input, Pagination, Table, Button, Icon, notification } from "antd";
import { vehiclesActions } from "../_actions";
import "react-tabs/style/react-tabs.css";
import Highlighter from "react-highlight-words";
import { CrearConcesionaria } from "./";
import { EditarConcesionaria } from "./";
import { alertActions } from "../_actions";
import { API_URL } from "../_config";
import {
  Badge,
  Nav,
  NavItem,
  NavLink,
  Row,
  Button as Vutton,
  Col,
  Card,
  CardImg,
  ButtonToolbar,
  CardBody,
  CardTitle,
  CardSubtitle,
  Modal,
  ModalBody,
  ModalHeader,
  FormCheckbox,
  Container,
  FormTextarea,
  CardHeader,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  ButtonGroup
} from "shards-react";
import StepZilla from "react-stepzilla";
import PageTitle from "../_components/PageTitle";
import SearchResults from "react-filter-search";
import openNotificationWithIcon from "../_common/OpenNotificationWithIcon";

import "./Concesionarias.css";

const { Search } = Input;
const numEachPage = 4;

class Concesionarias extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      modaDeleteConc: false,
      concSelected : "",
      open2: false,
      minValue: 0,
      maxValue: 9,
      minValue2: 0,
      maxValue2: 9,
      valor: "",
      searchText: "",
      searchedColumn: "",
      search: false,
      editar_data: []
    };

    //const items = this.props.concesionarias;
    //console.log('aca ?', items);
    this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle2.bind(this);
    this.set_edit_data = this.set_edit_data.bind(this);
    this.create_conse = this.create_conse.bind(this);
    this.edit_conse = this.edit_conse.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.searchys = this.searchys.bind(this);
    this.showDeleteConc = this.showDeleteConc.bind(this);
  }

  componentDidMount() {
    this.props.getConcesionarias();
  }

  componentWillUpdate() {}

  renderVehicles() {
    /*return this.props.vehicles.map((vehicle) => {
      return (
        <tr key={vehicle.id}>
          <td>{vehicle.brand_name}</td>
        </tr>
      )
    })*/
  }
  toggle() {
    if (this.state.open == true) {
      this.props.getConcesionarias();
    }
    this.setState({
      open: !this.state.open
    });
  }
  toggle2() {
    if (this.state.open == true) {
      this.props.getConcesionarias();
    }
    this.setState({
      open2: !this.state.open2
    });
  }

  searchys(value) {
    if (value == "") {
      this.setState({
        valor: value,
        search: false
      });
    } else {
      this.setState({
        valor: value,
        search: true
      });
    }
  }
  handleChange = value => {
    if (value <= 1) {
      this.setState({
        minValue: 0,
        maxValue: 9
      });
    } else {
      this.setState({
        minValue: this.state.maxValue,
        maxValue: value * 9
      });
    }
  };

  handleChange2 = value => {
    if (value <= 1) {
      this.setState({
        minValue2: 0,
        maxValue2: 9
      });
    } else {
      this.setState({
        minValue2: this.state.maxValue2,
        maxValue2: value * 9
      });
    }
  };

  getSize = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Buscar por ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: "100%", marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: "100%", marginBottom: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: "100%" }}
        >
          Resetear
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  set_edit_data = id => {
    axios({
      method: "get",
      url:
        API_URL + "api/dealer/" +
        id +
        "/2?token=" +
        store.getState().authentication.user.token
    }).then(response => {
      this.setState({
        editar_data: response.data,
        open2: true
      });
    });
  };

  delete = id => {
    axios({
      method: "DELETE",
      url:
        API_URL + "api/dealer/"+
        id+
        "/?token="+
        store.getState().authentication.user.token
    }).then(response => {
      this.props.getConcesionarias();
    }).then(res => openNotificationWithIcon('success', 'Listo!','Concesionaria Eliminada correctamente'))
    .catch(error => {
      this.setState({modaDeleteConc : false})
      openNotificationWithIcon('error', 'Oops! hemos tenido un error al eliminar la concesionaria', `${error.response || error}`)
    })
  };

  create_conse(values) {
    axios
      .post(
        API_URL + "api/dealer?token=" +
          store.getState().authentication.user.token,
        {
          dealer: { ...values, instagram: "" }
        }
      )
      .then(response => {
        this.props.getConcesionarias();
        this.setState({
          open: false
        });
      }).then(res => {
        openNotificationWithIcon('success', 'Listo!', 'Concesionaria Creada correctamente!')
      }).catch(error => {
        this.setState({
          open: false
        })
        openNotificationWithIcon('error', 'Oops! hemos tenido un error', `${error.response || error}`)
      })
  }

  edit_conse(values) {
    axios
      .post(
        API_URL + "api/dealer/" +
          this.state.editar_data.id +
          "?token=" +
          store.getState().authentication.user.token,
        {
          dealer: values
        }
      )
      .then(response => {
        this.props.getConcesionarias();
        this.setState({
          open2: false,
          open: false
        });
      }).then(res => openNotificationWithIcon('success', 'Listo!', 'Concesionaria Actualizada correctamente!') )
      .catch(error => {
        this.setState({
          open2: false,
          open: false
        })
        openNotificationWithIcon('error', 'Ooops! hemos tenido un error', `${error.response || error}`)
      })
  }
  showDeleteConc(value){
    this.setState({modaDeleteConc : !this.state.modaDeleteConc, concSelected : value})
  }
  render() {
    const { open2 } = this.state;
    const columns = [
      {
        title: "Socio",
        dataIndex: "socio",
        key: "socio",
        ...this.getColumnSearchProps("socio")
      },
      {
        title: "Empresa",
        dataIndex: "empresa",
        key: "empresa",
        sorter: (a, b) => a.empresa.localeCompare(b.empresa),
        ...this.getColumnSearchProps("empresa")
      },
      {
        title: "A/N",
        dataIndex: "an",
        key: "an",
        sorter: (a, b) => a.an.localeCompare(b.an),
        ...this.getColumnSearchProps("an")
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        sorter: (a, b) => a.email.localeCompare(b.email),
        ...this.getColumnSearchProps("email")
      },
      {
        title: "Teléfono",
        dataIndex: "telefono",
        key: "telefono",
        sorter: (a, b) => a.an.localeCompare(b.an),
        ...this.getColumnSearchProps("telefono")
      },
      {
        title: "Horarios",
        dataIndex: "horarios",
        key: "horarios",
        ...this.getColumnSearchProps("horarios")
      },
      {
        title: "Acción",
        dataIndex: "accion",
        key: "accion",
        render: text => (
          <td className="pof" style={{ padding: "0.45rem !important" }}>
            <i
              onClick={() => this.set_edit_data(text)}
              className="material-icons"
              title="Editar"
              style={{ cursor: "pointer", display: "inline", fontSize: "23px" }}
            >
              edit
            </i>
            <i
              onClick={() => this.showDeleteConc(text)}
              className="material-icons"
              title="Eliminar"
              style={{ cursor: "pointer", display: "inline", fontSize: "23px" }}
            >
              delete
            </i>
          </td>
        )
      }
    ];

    var data = [
      this.props.vehicles.concesionarias.map((item, i) => ({
        socio: item.partner,
        empresa: item.company,
        an: item.contact,
        email: item.email,
        telefono: item.cellphone,
        horarios: item.work_hours,
        accion: item.id
      }))
    ];

    return (

      <div>
        <Row noGutters className="page-header py-4" style={{ width: "100%" }}>
          <PageTitle sm="5" title="Concesionarias" className="text-sm-left" />
          <Col sm="5"></Col>
          <Col sm="2">
            <Vutton theme="primary" onClick={this.toggle}>
              Crear Concesionaria
            </Vutton>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3">
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    locale={{ emptyText: "Obteniendo datos..." }}
                    dataSource={data[0]}
                    pagination={{ pageSize: 20 }}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <br />
        <br />

        <Modal
          size="lg"
          open={this.state.open}
          toggle={() => console.log("Toggle")}
        >
          <ModalHeader>
            Agregar Concesionaria{" "}
            <i
              style={{
                position: "absolute",
                right: "20px",
                top: "15px",
                fontSize: "25px",
                cursor: "pointer"
              }}
              class="material-icons"
              onClick={this.toggle}
            >
              clear
            </i>{" "}
            <i
              style={{
                position: "absolute",
                right: "20px",
                top: "15px",
                fontSize: "25px",
                cursor: "pointer"
              }}
              class="material-icons"
              onClick={this.toggle}
            >
              clear
            </i>
          </ModalHeader>
          <ModalBody>
            <CrearConcesionaria
              onSubmit={this.create_conse}
            ></CrearConcesionaria>
          </ModalBody>
        </Modal>

        <Modal size="lg" open={this.state.open2} toggle={this.toggle2}>
          <ModalHeader>
            Editar Concesionaria{" "}
            <i
              style={{
                position: "absolute",
                right: "20px",
                top: "15px",
                fontSize: "25px",
                cursor: "pointer"
              }}
              class="material-icons"
              onClick={this.toggle2}
            >
              clear
            </i>
          </ModalHeader>
          <ModalBody>
            <EditarConcesionaria
              activedX={this.state.editar_data}
              onSubmit={this.edit_conse}
            ></EditarConcesionaria>
          </ModalBody>
        </Modal>
         
      
       {/* Modal eliminar concesionaria */}
       <Modal size="md" open={this.state.modaDeleteConc} toggle={() => {}}>
          <ModalHeader>
              Eliminar Concesionaria
              <i
                style={{
                  position: "absolute",
                  right: "20px",
                  top: "15px",
                  fontSize: "25px",
                  cursor: "pointer"
                }}
                class="material-icons"
                onClick={this.showDeleteConc}
              >
                clear
              </i>
            </ModalHeader>
            <ModalBody>
              <div>
                <div>
                  <h5>¿Estas seguro que deseas eliminar esta Concesinaria?</h5>
                  <p>Recuerda que si eliminas esta concesionaria, ya no se encontrara en la plataforma.</p>
                </div>
                <div>
                  <button  className="btn btn-secondary m-2" onClick={this.showDeleteConc} >Cancelar</button>
                  <button className="btn btn-primary  m-2" onClick={() => this.delete(this.state.concSelected)}>Confirmar</button>
                </div>
              </div>
            </ModalBody>
          </Modal>
          </div>
    );
  }
}

function mapState(state) {
  const { users, authentication, vehicles, loaded } = state;

  const { user } = authentication;
  console.log("esto ->", vehicles);

  return { user, users, vehicles, loaded };
}
const actionCreators = {
  getConcesionarias: vehiclesActions.getConcesionarias,
  showAlert: alertActions.show,
  hideAlert: alertActions.hide
};

const connectedVehicles = connect(
  mapState,
  actionCreators
)(Concesionarias);
export { connectedVehicles as Concesionarias };
