import React from "react";
import { connect } from "react-redux";
//import $ from 'jquery'
import { store } from "../_helpers";
import { Table, Divider, Tag, Icon, notification } from "antd";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import axios from "axios";
import { adminuserActions } from "../_actions";
import "./Usuarios.css";
import "react-tabs/style/react-tabs.css";
import {
  Badge,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Card,
  CardImg,
  ButtonToolbar,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  FormCheckbox,
  Container,
  FormTextarea,
  CardHeader,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  ButtonGroup
} from "shards-react";
import { CrearPerfil, ConsUsuario, PermUsuario, EditarPerfil } from ".";
import PageTitle from "../_components/PageTitle";
import { API_URL } from "../_config";

class Notificaciones extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      nav_number: 0,
      cons: false,
      perm: false,
      id_user: 0,
      permisos_usados: {},
      dealers_usados: {},
      modal_id: undefined
    };

    //const items = this.props.vehicles;
    //console.log('aca ?', items);
    this.toggle = this.toggle.bind(this);
    this.create_user = this.create_user.bind(this);
    this.assign_cons = this.assign_cons.bind(this);
    this.assign_perms = this.assign_perms.bind(this);
    this.mostrar_coso = this.mostrar_coso.bind(this);
    this.eliminar = this.eliminar.bind(this);
    this.set_coso = this.set_coso.bind(this);
  }

  componentWillMount() {
    this.props.getNotificaciones();
  }

  mostrar_coso(item) {
    this.setState({
      modal_id: undefined
    });

    if (this.state.modal_id == undefined) {
      this.setState({
        modal_id: (
          <EditarPerfil open={true} key={"modal" + item} id_user={item} />
        )
      });
    }
  }

  set_coso(item) {
    this.setState({
      modal_id: item
    });

    if (this.state.modal_id != undefined) {
      console.log("pex");
    }
  }

  eliminar(item) {
    notification.open({
      message: "Cargando",
      description: "Espere un momento por favor",
      icon: <Icon type="loading" style={{ color: "#4caf50" }} />,
      style: { zIndex: 100000 },
      top: 70
    });

    axios
      .delete(
        API_URL + "/ac_notificacions/" +
        item +
        "?token=" +
        store.getState().authentication.user.token
      )
      .then(response => {
        this.props.getNotificaciones();
        notification.destroy();
        notification.open({
          message: "Operación exitosa",
          icon: <Icon type="check-circle" style={{ color: "#4caf50" }} />,
          top: 70,
          style: { zIndex: 100000 }
        });
        this.forceUpdate();
      })
      .catch(error => {
        notification.destroy();
        return notification.open({
          message: "Ha ocurrido un error",
          description:
            "No hemos podido completar la solicitud, por favor, intente más tarde",
          icon: <Icon type="minus-circle" style={{ color: "#f54842" }} />,
          style: { zIndex: 100000 },
          top: 70
        });
      });
  }

  enable(item) {
    notification.open({
      message: "Cargando",
      description: "Espere un momento por favor",
      icon: <Icon type="loading" style={{ color: "#4caf50" }} />,
      style: { zIndex: 100000 },
      top: 70
    });

    axios
      .delete(
        API_URL + "/enablenotification/" +
        item +
        "?token=" +
        store.getState().authentication.user.token
      )
      .then(response => {
        this.props.getNotificaciones();
        notification.destroy();
        notification.open({
          message: "Operación exitosa",
          icon: <Icon type="check-circle" style={{ color: "#4caf50" }} />,
          top: 70,
          style: { zIndex: 100000 }
        });
        this.forceUpdate();
      })
      .catch(error => {
        notification.destroy();
        return notification.open({
          message: "Ha ocurrido un error",
          description:
            "No hemos podido completar la solicitud, por favor, intente más tarde",
          icon: <Icon type="minus-circle" style={{ color: "#f54842" }} />,
          style: { zIndex: 100000 },
          top: 70
        });
      });
  }

  disable(item) {
    notification.open({
      message: "Cargando",
      description: "Espere un momento por favor",
      icon: <Icon type="loading" style={{ color: "#4caf50" }} />,
      style: { zIndex: 100000 },
      top: 70
    });

    axios
      .delete(
        API_URL + "/disablenotification/" +
        item +
        "?token=" +
        store.getState().authentication.user.token
      )
      .then(response => {
        this.props.getNotificaciones();
        notification.destroy();
        notification.open({
          message: "Operación exitosa",
          icon: <Icon type="check-circle" style={{ color: "#4caf50" }} />,
          top: 70,
          style: { zIndex: 100000 }
        });
        this.forceUpdate();
      })
      .catch(error => {
        notification.destroy();
        return notification.open({
          message: "Ha ocurrido un error",
          description:
            "No hemos podido completar la solicitud, por favor, intente más tarde",
          icon: <Icon type="minus-circle" style={{ color: "#f54842" }} />,
          style: { zIndex: 100000 },
          top: 70
        });
      });
  }

  create_user(values) {
    console.log("wggg");
    console.log(values);

    notification.open({
      message: "Cargando",
      description: "Espere un momento por favor",
      icon: <Icon type="loading" style={{ color: "#4caf50" }} />,
      style: { zIndex: 100000 },
      top: 70
    });

    axios
      .post(
        API_URL + "/ac_notificacions?token=" +
        store.getState().authentication.user.token,
        {
          title: values.title,
          text: values.text,
          type: values.type,
          leido: 0
        }
      )
      .then(response => {
        this.props.getNotificaciones();
        this.setState({
          id_user: response.data.id,
          open: false
        });
        notification.destroy();
        notification.open({
          message: "Operación exitosa",
          icon: <Icon type="check-circle" style={{ color: "#4caf50" }} />,
          top: 70,
          style: { zIndex: 100000 }
        });
      })
      .catch(error => {
        notification.destroy();
        return notification.open({
          message: "Ha ocurrido un error",
          description:
            "No hemos podido completar la solicitud, por favor, intente más tarde",
          icon: <Icon type="minus-circle" style={{ color: "#f54842" }} />,
          style: { zIndex: 100000 },
          top: 70
        });
      });
  }

  assign_cons(values) {
    axios
      .post(
        API_URL + "/api/user/" +
        this.state.id_user +
        "/dealers?token=" +
        store.getState().authentication.user.token,
        values
      )
      .then(response => {
        console.log(response);
      });
  }

  assign_perms(values) {
    axios
      .post(
        API_URL + "/api/user/" +
        this.state.id_user +
        "/perms?token=" +
        store.getState().authentication.user.token,
        values
      )
      .then(response => {
        console.log(response);
      });
  }

  toggle() {
    this.setState({
      open: !this.state.open
    });
  }

  render() {
    const { open } = this.state;

    const columns = [
      {
        title: "#",
        dataIndex: "id",
        key: "id",
        sorter: (a, b) => a.id.localeCompare(b.id)
      },
      {
        title: "Nombre",
        dataIndex: "title",
        key: "title",
        sorter: (a, b) => a.title.localeCompare(b.title)
      },
      {
        title: "Tipo",
        dataIndex: "type",
        key: "type",
        sorter: (a, b) => a.type.localeCompare(b.type)
      },
      {
        title: "Fecha",
        dataIndex: "date",
        key: "date",
        sorter: (a, b) => a.date.localeCompare(b.date)
      },
      {
        title: "Destino",
        dataIndex: "destino",
        key: "destino",
        sorter: (a, b) => a.destino.localeCompare(b.destino)
      },
      {
        title: "Leído",
        dataIndex: "leido",
        key: "leido",
        render: text => {
          if (text == 1) {
            return "Leído.";
          } else {
            return "No leído.";
          }
        }
      },
      {
        title: "Acción",
        dataIndex: "accion",
        key: "accion",
        render: (text, mm) => (
          <td className="pof" style={{ padding: "0.45rem !important" }}>
            {mm.type == "warning" && (
              <>
                {mm.active == 1 ? (
                  <i
                    className="material-icons"
                    title="Ocultar"
                    style={{
                      cursor: "pointer",
                      display: "inline",
                      fontSize: "23px"
                    }}
                    onClick={() => {
                      this.disable(text);
                    }}
                  >
                    visibility_off
                  </i>
                ) : (
                  <i
                    className="material-icons"
                    title="Mostrar"
                    style={{
                      cursor: "pointer",
                      display: "inline",
                      fontSize: "23px"
                    }}
                    onClick={() => {
                      this.enable(text);
                    }}
                  >
                    visibility
                  </i>
                )}
              </>
            )}
            <i
              className="material-icons"
              title="Editar"
              style={{ cursor: "pointer", display: "inline", fontSize: "23px" }}
              onClick={() => {
                this.mostrar_coso(text);
              }}
            >
              edit
            </i>
            <i
              className="material-icons"
              title="Eliminar"
              style={{ cursor: "pointer", display: "inline", fontSize: "23px" }}
              onClick={() => {
                this.eliminar(text);
              }}
            >
              delete
            </i>
          </td>
        )
      }
    ];

    var data = [
      this.props.adminuser.list.map((item, i) => ({
        id: item.id,
        title: item.title,
        type: item.type,
        date: item.created_at,
        leido: item.leido,
        destino: item.destino,
        accion: item.id,
        active: item.active
      }))
    ];

    return (
      <div>
        <>
          <Row noGutters className="page-header py-4" style={{ width: "100%" }}>
            <PageTitle sm="4" title="Notificaciones" className="text-sm-left" />
            <Col sm="6"></Col>
            <Col sm="2">
              <Button onClick={this.toggle}>Crear Notificación</Button>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card small className="mb-4">
                <CardBody className="p-0 pb-3">
                  <div class="table-responsive">
                    <Table
                      columns={columns}
                      locale={{ emptyText: "Obteniendo datos..." }}
                      dataSource={data[0]}
                      pagination={false}
                    />
                  </div>

                  {this.state.modal_id != undefined &&
                    this.state.modal_id != "edit" ? (
                    this.state.modal_id
                  ) : (
                    <div></div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal size="lg" open={open} toggle={() => console.log("Toggle")}>
            <ModalHeader>
              Agregar Notificación{" "}
              <i
                style={{
                  position: "absolute",
                  right: "20px",
                  top: "15px",
                  fontSize: "25px",
                  cursor: "pointer"
                }}
                class="material-icons"
                onClick={this.toggle}
              >
                clear
              </i>
            </ModalHeader>
            <ModalBody>
              <CrearPerfil onSubmit={this.create_user} />
            </ModalBody>
          </Modal>

          <br />
          <br />
        </>
      </div>
    );
  }
}

function mapState(state) {
  const { users, authentication, vehicles, adminuser } = state;

  const { user } = authentication;
  console.log("esto ->", vehicles);

  return { user, users, adminuser };
}

const actionCreators = {
  getNotificaciones: adminuserActions.getNotificaciones
};

const connectedUsuarios = connect(
  mapState,
  actionCreators
)(Notificaciones);
export { connectedUsuarios as Notificaciones };
