import React from 'react'
import {
    FormInput,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "shards-react";
import { Button } from 'antd';

const ModalUrlEdit = ({ modalShowHide, modalFuc, ownurl, change_url_company }) => {
    const [newUrlState, setNewUrlState] = React.useState("")
    const [activeButtonUrl, setActiveButtonUrl] = React.useState(true)

    const onChangeNewUrl = (e) => {
        newUrlState.length > 4 ? setActiveButtonUrl(false) : setActiveButtonUrl(true)
        setNewUrlState(e.target.value)
    }
    const submitNewUrl = () => {
        change_url_company(newUrlState)
        modalFuc()
    }
    return (
        <>
            <Modal open={modalShowHide} >
                <ModalHeader>
                    Editar URL de mi sitio web
                </ModalHeader>
                <ModalBody>
                    <h6>
                        Nombre actual de tu URL:
                    </h6>
                    <p>{`${ownurl === null || ownurl === "null" ? "Debes Asignarle un nombre a tu sitio web" : ownurl}`}</p>
                    <h6>
                        Ingresa el nuevo nombre de tu sitio web:
                    </h6>
                    <FormInput type="text" onChange={onChangeNewUrl} placeholder="Nuevo nombre" />
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-secondary" onClick={() => modalFuc()}>Cancelar</button>
                    <button className="btn btn-primary" disabled={activeButtonUrl} onClick={submitNewUrl}>Confirmar</button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default ModalUrlEdit
