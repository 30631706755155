import React, {useState, useEffect} from "react";
import {API_URL} from "../../_config";
import {store} from "../../_helpers";
import Cookies from "universal-cookie";
import axios from "axios";
import {Table, Icon, Tooltip, notification} from "antd";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button as BTX,
  ModalBody,
  Modal,
  ModalFooter,
  ModalHeader,
} from "shards-react";

const cookies = new Cookies();

const ServicesOfAgency = () => {
  const [serviciox, setServiciox] = useState([]);
  const [nuevosServicios, setNuevosServicios] = useState([]);
  const [servicesDeleteId, setServicesDeleteId] = useState(null);
  const [servicesDeleteModal, setServicesDeleteModal] = useState(false);

  const misActualesServicios = serviciox.filter((s) => s.status !== "inactive");

  const openNotificationWithIcon = (type, message = "Oops! Hemos tenido un error", description) => {
    notification.destroy();
    notification[type]({
      message: `${message}`,
      description: `${description}`,
      className: "custom-class",
      style: {
        marginTop: "40px",
        zIndex: "999",
      },
      duration: 5,
    });
  };

  useEffect(() => {
    getServicesList();
  }, []);

  function getServicesList() {
    return axios({
      method: "get",
      url: `${API_URL}api/dealer/${cookies.get("dealer").id}/cardealer/servicios?token=${
        store.getState().authentication.user.token
      }`,
    })
      .then((res) => setServiciox(res.data))
      .catch((error) =>
        openNotificationWithIcon(
          "erroe",
          "Error!",
          `Error al listar servicios, intentalo mas tarde. STATUS ${error.response.status} `,
        ),
      );
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setNuevosServicios(selectedRowKeys);
    },
    getCheckboxProps: (record) => {
      return {
        disabled: record.status === "active",
        name: record.detalle,
      };
    },
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id > b.id,
    },
    {
      title: "Servicios Disponibles",
      dataIndex: "detalle",
      key: "detalle",
      sorter: (a, b) => a.detalle.localeCompare(b.detalle),
    },
    {
      title: "Activos",
      dataIndex: "action",
      key: "action",
      render: (text, record) =>
        record.status === "active" && (
          <i
            className="material-icons"
            title="Desactivar"
            onClick={() => {
              setServicesDeleteModal(!servicesDeleteModal);
              setServicesDeleteId(record.id);
            }}
            style={{cursor: "pointer", display: "inline", fontSize: "23px"}}
          >
            {" "}
            visibility_off{" "}
          </i>
        ),
    },
  ];
  function saveServicesList() {
    const idActuales = misActualesServicios.map((s) => s.id);
    const listServicesNew = idActuales.concat(nuevosServicios);
    if (nuevosServicios.length > 0) {
      return axios({
        method: "post",
        url: `${API_URL}api/dealer/${cookies.get("dealer").id}/cardealer/servicios?token=${
          store.getState().authentication.user.token
        }`,
        data: {data: listServicesNew},
      })
        .then((res) => {
          if (res.status === 200) {
            openNotificationWithIcon(
              "success",
              "Nuevos Servicios",
              "Servicios guardados correctamente. ",
            );
            getServicesList();
          }
        })
        .catch((error) =>
          openNotificationWithIcon(
            "error",
            "Error Servicios",
            `Error al guardar tus servicios selecccionados. status=${error.response.status}`,
          ),
        );
    }
    return openNotificationWithIcon(
      "info",
      "Seleccionar o Quitar ",
      "Elige unos de los servicios para mostrar en tu sitio web. ",
    );
  }
  function deleteServiceId(id) {
    const newServices = misActualesServicios.filter((s) => s.id !== id);
    const ids = newServices.map((s) => s.id);
    return axios({
      method: "post",
      url: `${API_URL}api/dealer/${cookies.get("dealer").id}/cardealer/servicios?token=${
        store.getState().authentication.user.token
      }`,
      data: {data: ids},
    })
      .then((res) => {
        if (res.status === 200) {
          openNotificationWithIcon(
            "success",
            "Servicio Deshabilitado",
            "Tu servicio fue deshabilitado correctamente.",
          );
          getServicesList();
          setServicesDeleteModal(false);
        }
      })
      .catch((error) =>
        openNotificationWithIcon(
          "error",
          "Error Servicios",
          `Error al guardar tus servicios selecccionados. status=${error.response.status}`,
        ),
      );
  }

  return (
    <div>
      <Card>
        <CardBody>
          {serviciox.length > 0 && (
            <Tooltip
              overlayStyle={{zIndex: "10000"}}
              title="Nombres que se encuentran en color gris oscuro, son los que estan por default en tu sitio web, si quieres eliminarlos debes clikear el icono de la columna (No mostrar)."
            >
              <a style={{fontSize: "16px", marginTop: "2px", marginRight: "5px", float: "left"}}>
                <Icon type="question-circle"></Icon>
              </a>
            </Tooltip>
          )}
          <Row>
            <Col sm="8">
              <h4 style={{display: "inline"}}>Servicios</h4>
            </Col>
            <Col sm="4">
              {misActualesServicios.length > 6 ? (
                <p>Solo puedes seleccionar 6 servicios</p>
              ) : nuevosServicios.length > 0 ? (
                <BTX onClick={saveServicesList}>Guardar cambios</BTX>
              ) : (
                <></>
              )}
            </Col>
          </Row>

          <div style={{paddingTop: "15px"}}>
            <div class="table-responsive">
              <Table
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                columns={columns}
                rowKey="id"
                rowClassName={(record, index) => (record.status === "active" ? "data-row2" : "")}
                locale={{emptyText: "Obteniendo datos..."}}
                dataSource={serviciox}
                pagination={false}
              />
            </div>
          </div>
        </CardBody>
      </Card>
      <Modal open={servicesDeleteModal}>
        <ModalHeader>Deshabilitar Servicio</ModalHeader>
        <ModalBody>
          <p>¿Seguro que quieres deshabilitar este servicio?</p>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-secondary" onClick={() => setServicesDeleteModal(false)}>
            Cancelar
          </button>
          <BTX onClick={() => servicesDeleteId && deleteServiceId(servicesDeleteId)}>Eliminar</BTX>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ServicesOfAgency;
