import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

class SelectSimpleParser extends Component {
  constructor(props) {
    super(props);

    //this.getRef = this.getRef.bind(this);
  }

  render() {
    const {
      children,
      input,
      label,
      meta: { touched, error }
    } = this.props;

    const option = children.map(function(d, x) {
      return (
        <option key={x} value={d.id}>
          {d.name}
        </option>
      );
    });
    return (
      <div style={{marginBottom: 10}}>
        <label style={{marginBottom: 1}}>{label}</label>
        <div>
        <select {...input} className="form-control custom-select">
          <option value=""></option>
          {option}
        </select>
        {touched && error && <span className="red-snow">{error}</span>}
        </div>
      </div>
    );
  }
}

SelectSimpleParser.propTypes = {
  /**
   * The class name.
   */
  className: PropTypes.string,
  /**
   * The children nodes.
   */
  children: PropTypes.node,
  /**
   * The size.
   */
  size: PropTypes.string,
  /**
   * Whether it is valid, or not.
   */
  valid: PropTypes.bool,
  /**
   * Whether it is invalid, or not.
   */
  invalid: PropTypes.bool
  /**
   * The inner ref.
   */
};

export default SelectSimpleParser;
