import { sidebarConstants } from "../_constants";
let itemMenu = require("../_data/menu");

export const sidebarActions = {
  getSidebarItems,
  // addChangeListener,
  //removeChangeListener,
  toggleSidebar
};
/*
const getSidebarNavItems = () => {
  return [
    {
      title: "Polymer 1",
      to: "/usuarios",
      htmlBefore: '<i class="material-icons">edit</i>',
      htmlAfter: ""
    },
    {
      title: "Fideos  Marca Jony",
      to: "/usuarios",
      htmlBefore: '<i class="material-icons">edit</i>',
      htmlAfter: ""
    }
  ];
};

let _store = {
  menuVisible: false,
  navItems3: getSidebarNavItems()
};

function getSidebarItems() {
  //return _store.navItems;

  const items = _store.navItems;
  return { type: sidebarConstants._CHANGE, items: items };
}

function addChangeListener(callback) {
  //this.on(Constants.CHANGE, callback);
  return { type: sidebarConstants._CHANGE, callback };
}

function removeChangeListener(callback) {
  //this.removeListener(Constants.CHANGE, callback);
  return { type: sidebarConstants._CHANGE, callback };
}
*/

//console.log("|MENU|", itemMenu["items-1"]);

function getSidebarItems(it) {
  //return _store.navItems;

  //const items = _store.navItems;
  return { type: sidebarConstants._CHANGE, items: itemMenu["items-" + it] };
}
function toggleSidebar() {
  //this.removeListener(Constants.CHANGE, callback);
  return { type: sidebarConstants.TOGGLE_SIDEBAR };
}
