import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardHeader, CardBody, Button, Form, FormGroup, FormInput, FormSelect } from "shards-react";

class Paso1 extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Form>
      <FormGroup>
        <label>Tipo</label>
        <FormSelect>
          <option value="primera">Autos - Camionetas - Motos</option>
          <option value="segunda">Camiones</option>
        </FormSelect>
      </FormGroup>

      <FormGroup>
        <label>Condición</label>
        <FormSelect>
          <option value="0km">0km</option>
          <option value="usado">Usado</option>
          <option value="consignacion">Consignación</option>
        </FormSelect>
      </FormGroup>
    </Form>
    );
  }
}


export default Paso1;
