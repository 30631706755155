import { Upload, Popover, Modal, Button, Icon, notification } from "antd";
import en_US from "antd/es/locale-provider/en_US";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import React, { useState, useEffect } from "react";
import cloneDeep from "lodash/cloneDeep";
import "./Vehicles.css";
import Cookies from "universal-cookie";
import { store } from "../_helpers";
import { API_URL, PREAPI_URL } from "../_config";

import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardTitle,
  CardImg,
  CardBody,
  CardFooter,
  Row,
  Col
} from "shards-react";
import axios from "axios";

const cookies = new Cookies();

const openNotificationWithIcon = (type, til, msg) => {
  let $congf = {
    message: til,
    description: msg,
    top: 70
  };
  notification[type]($congf);
};

const fetchBlob = (uri, callback) => {
  var xhr = new XMLHttpRequest();
  xhr.open("GET", uri, true);
  xhr.responseType = "arraybuffer";

  xhr.onload = function(e) {
    if (this.status == 200) {
      var blob = this.response;
      if (callback) {
        callback(blob);
      }
    }
  };
  xhr.send();
};
const girar = (id, url) => {
  var rotation = 90;
  var srcOrientation = 5;
  var img = new Image();
  img.src = url;
  var img = new Image();

  img.onload = function() {
    var width = img.width,
      height = img.height,
      canvas = document.createElement("canvas"),
      ctx = canvas.getContext("2d");

    // set proper canvas dimensions before transform & export
    if (4 < srcOrientation && srcOrientation < 9) {
      canvas.width = height;
      canvas.height = width;
    } else {
      canvas.width = width;
      canvas.height = height;
    }

    // transform context before drawing image
    switch (srcOrientation) {
      case 2:
        ctx.transform(-1, 0, 0, 1, width, 0);
        break;
      case 3:
        ctx.transform(-1, 0, 0, -1, width, height);
        break;
      case 4:
        ctx.transform(1, 0, 0, -1, 0, height);
        break;
      case 5:
        ctx.transform(0, 1, 1, 0, 0, 0);
        break;
      case 6:
        ctx.transform(0, 1, -1, 0, height, 0);
        break;
      case 7:
        ctx.transform(0, -1, -1, 0, height, width);
        break;
      case 8:
        ctx.transform(0, -1, 1, 0, 0, width);
        break;
      default:
        break;
    }

    // draw image
    ctx.drawImage(img, 0, 0);

    // export base64
    console.log(canvas.toDataURL());
  };
};
const getBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

const UploadWrapper = React.memo(({ onChange: onChangeInitial, ...props }) => {
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState();
  const [checkList, setCheckList] = useState([]);

  useEffect(() => {
    localStorage.removeItem("vehicle_list");
    axios({
      method: "get",
      url:
        API_URL +
        "api/dealer/" +
        cookies.get("dealer").id +
        "/vehicles/" +
        props.id_vehicle +
        "/images?token=" +
        store.getState().authentication.user.token
    }).then(response => {
      setFileList(response.data.images);
      var data = [];
    });
  }, []);

  const onChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };
  const resetOrientation = (srcBase64, srcOrientation, id_photo) => {
    notification.open({
      message: "Girando imagen",
      description: "Este proceso puede demorar unos minutos",
      icon: <Icon type="loading" style={{ color: "#4caf50" }} />,
      top: 70
    });

    var img = new Image();
    img.src = srcBase64;
    img.onload = function() {
      var width = img.width,
        height = img.height,
        canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d");

      if (4 < srcOrientation && srcOrientation < 9) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      switch (srcOrientation) {
        case 2:
          ctx.transform(-1, 0, 0, 1, width, 0);
          break;
        case 3:
          ctx.transform(-1, 0, 0, -1, width, height);
          break;
        case 4:
          ctx.transform(1, 0, 0, -1, 0, height);
          break;
        case 5:
          ctx.transform(0, 1, 1, 0, 0, 0);
          break;
        case 6:
          ctx.transform(0, 1, -1, 0, height, 0);
          break;
        case 7:
          ctx.transform(0, -1, -1, 0, height, width);
          break;
        case 8:
          ctx.transform(0, -1, 1, 0, 0, width);
          break;
        default:
          break;
      }

      ctx.drawImage(img, 0, 0);

      const data = new FormData();
      data.append(
        "imageFiles",
        dataURLtoFile(canvas.toDataURL(), Math.random() + "_poly.png")
      );
      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };
      notification.open({
        message: "Cargando la imagen al servidor",
        description: "Este proceso puede demorar unos minutos",
        icon: <Icon type="loading" style={{ color: "#4caf50" }} />,
        top: 70
      });

      axios
        .post("https://admin.nuestrosautos.com.ar/upload/no.php", data, config)
        .then(response => {
          const url = response.data[0].fileName.path;
          axios
            .post(
              API_URL +
               "api/dealer/" +
                cookies.get("dealer").id +
                "/vehicle/" +
                props.id_vehicle +
                "/image/link/" +
                id_photo +
                "?token=" +
                store.getState().authentication.user.token,
              { url: url }
            )
            .then(response2 => {
              notification.destroy();
              notification.open({
                message: "Imagen girada exitosamente",
                description: "Los cambios fueron aplicados con exito",
                icon: <Icon type="check-circle" style={{ color: "#4caf50" }} />,
                top: 70
              });
              refresh_data();
            })
            .catch(err => {
              notification.destroy();
              notification.open({
                message: "No se pudo subir la imagen",
                description:
                  "Ha ocurrido un error en el proceso, por favor, intente más tarde",
                icon: <Icon type="stop" style={{ color: "#d32f2f" }} />,
                top: 70
              });
            });
        })
        .catch(err => {
          notification.destroy();
          notification.open({
            message: "No se pudo subir la imagen",
            description:
              "Ha ocurrido un error en el proceso, por favor, intente más tarde.",
            icon: <Icon type="stop" style={{ color: "#d32f2f" }} />,
            top: 70
          });
        });
    };
  };

  const subir = file => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      return notification.open({
        message: "Solamente se pueden subir imagenes .JPG o .PNG",
        description:
          "No se ha podido subir el fichero, ya que este no era .JPG o .PNG",
        icon: <Icon type="stop" style={{ color: "#d32f2f" }} />,
        top: 70
      });
    }
    notification.open({
      message: "Subiendo imagen",
      description: "Este proceso puede demorar unos minutos",
      icon: <Icon type="loading" style={{ color: "#4caf50" }} />,
      top: 70
    });

    const data = new FormData();
    data.append("imageFiles", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data"
      }
    };
    axios
      .post("https://admin.nuestrosautos.com.ar/upload/no.php", data, config)
      .then(response => {
        const url = response.data[0].fileName.path;
        axios
          .post(
            API_URL +
              "api/dealer/" +
              cookies.get("dealer").id +
              "/vehicle/" +
              props.id_vehicle +
              "/image/link?token=" +
              store.getState().authentication.user.token,
            { url: url }
          )
          .then(response2 => {
            notification.destroy();
            notification.open({
              message: "Imagen subida exitosamente",
              description: "La imagen que ingresó ya se subió exitosamente",
              icon: <Icon type="check-circle" style={{ color: "#4caf50" }} />,
              top: 70
            });
            refresh_data();
          })
          .catch(err => {
            notification.destroy();
            notification.open({
              message: "No se pudo subir la imagen",
              description:
                "Ha ocurrido un error en el proceso, por favor, intente más tarde",
              icon: <Icon type="stop" style={{ color: "#d32f2f" }} />,
              top: 70
            });
          });
      })
      .catch(err => {
        notification.destroy();
        notification.open({
          message: "No se pudo subir la imagen",
          description:
            "Ha ocurrido un error en el proceso, por favor, intente más tarde.",
          icon: <Icon type="stop" style={{ color: "#d32f2f" }} />,
          top: 70
        });
      });
  };

  const onRemove = file => {
    const newFileList = cloneDeep(fileList).filter(
      item => parseInt(item.image_order) !== file.image_order
    );
    onChange({ fileList: newFileList });
  };

  const onDragEnd = ({ source, destination }) => {
    notification.open({
      message: "Cambiando orden",
      icon: <Icon type="loading" style={{ color: "#4caf50" }} />,
      top: 70
    });

    if (source != null && destination != null) {
      const reorder = (list, startIndex, endIndex) => {
        const [removed] = list.splice(startIndex, 1);
        list.splice(endIndex, 0, removed);
        var data = new FormData();
        list.forEach(element => data.append("item[]", element.id));

        // API
        axios
          .post(
            API_URL +
              "api/dealer/" +
              cookies.get("dealer").id +
              "/cover_image/image/order-images?token=" +
              store.getState().authentication.user.token,
            data
          )
          .then(response2 => {
            notification.destroy();
            notification.open({
              message: "Se ha cambiado el orden exitosamente",
              icon: <Icon type="check-circle" style={{ color: "#4caf50" }} />,
              top: 70
            });
          })
          .catch(err => {
            notification.destroy();
            notification.open({
              message: "No se pudo cambiar el orden",
              description:
                "Ha ocurrido un error en el proceso, por favor, intente más tarde.",
              icon: <Icon type="stop" style={{ color: "#d32f2f" }} />,
              top: 70
            });
          });

        console.log(data);
        return list;
      };

      const newFileList = reorder(
        cloneDeep(fileList),
        source.index,
        destination.index
      );
      console.log(newFileList);
      onChange({ fileList: newFileList });
    } else {
      notification.destroy();
      notification.open({
        message: "Ooops...",
        description:
          "Para cambiar el orden, debe arrastrar la imagen hacia un espacio disponible",
        icon: <Icon type="exception" style={{ color: "#4caf50" }} />,
        top: 70
      });
    }
  };

  const onPreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
  };

  const refresh_data = () => {
    setFileList([]);
    axios({
      method: "get",
      url:
        API_URL +
        "api/dealer/" +
        cookies.get("dealer").id +
        "/vehicles/" +
        props.id_vehicle +
        "/images?token=" +
        store.getState().authentication.user.token
    }).then(response => {
      setFileList(response.data.images);
    });
  };

  const change_check = (id, state) => {
    notification.open({
      message: "Cargando",
      icon: <Icon type="loading" style={{ color: "#4caf50" }} />,
      top: 70
    });

    axios
      .post(
        API_URL +
          "api/dealer/" +
          cookies.get("dealer").id +
          "/cover_image/image/" +
          id +
          "?token=" +
          store.getState().authentication.user.token,
        {
          state: state
        }
      )
      .then(response => {
        notification.destroy();
        notification.open({
          message: "Operación exitosa",
          icon: <Icon type="check-circle" style={{ color: "#4caf50" }} />,
          top: 70
        });
        refresh_data();
      });
  };

  const remover = id => {
    notification.open({
      message: "Eliminando imagen",
      icon: <Icon type="loading" style={{ color: "#4caf50" }} />,
      top: 70
    });
    localStorage.removeItem("vehicle_list");
    axios
      .delete(
        API_URL +
          "/dealer/" +
          cookies.get("dealer").id +
          "/vehicles/image/" +
          id +
          "?token=" +
          store.getState().authentication.user.token
      )
      .then(response => {
        notification.destroy();
        notification.open({
          message: "Imagen eliminada exitosamente",
          icon: <Icon type="check-circle" style={{ color: "#4caf50" }} />,
          top: 70
        });
        refresh_data();
      });
  };

  return (
    <>
      <br />

      {!fileList && console.log(fileList)}
      {fileList && (
        <div
          style={{
            width: "100%",
            padding: "40px",
            border: "3px dashed #9a9a9a"
          }}
        >
          <Row>
            <Col sm="2">
              <Upload
                multiple
                {...props}
                style={{ width: "100%", height: "100%" }}
                fileList={fileList}
                showUploadList={false}
                beforeUpload={file => subir(file)}
              >
                <div
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    border: "2px dashed #eee",
                    borderRadius: "6px",
                    width: "100%",
                    height: "100%"
                  }}
                >
                  <i class="material-icons" style={{ fontSize: "50px" }}>
                    add
                  </i>
                </div>
              </Upload>
            </Col>

            {fileList.map(function(item, index) {
              return (
                <Col sm="2">
                  <Popover
                    content={
                      <div>
                        <p
                          style={{ cursor: "pointer" }}
                          onClick={() => remover(item.id)}
                        >
                          Eliminar
                        </p>
                        <p
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            resetOrientation(item.base64_image, 6, item.id)
                          }
                        >
                          Girar a la Izquierda
                        </p>
                        <p
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            resetOrientation(item.base64_image, 1, item.id)
                          }
                        >
                          Girar a la Derecha
                        </p>
                      </div>
                    }
                    title="Opciones"
                  >
                    <img
                      src={item.url}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "6px"
                      }}
                    ></img>
                  </Popover>
                </Col>
              );
            })}
          </Row>
        </div>
      )}

      <Modal
        visible={previewImage}
        width="60%"
        footer={null}
        onCancel={() => setPreviewImage(null)}
      >
        <img style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
});

function mapState(state) {
  const { users, authentication, vehicles } = state;

  const { user } = authentication;
  console.log("esto ->", vehicles);

  return { user, users, vehicles };
}
const actionCreators = {};

const connectedVehicles = connect(
  mapState,
  actionCreators
)(UploadWrapper);
export { connectedVehicles as UploadWrapper };
