import React, {useState, useEffect} from 'react'
import { reduxForm, Field, getFormValues } from "redux-form";
import { connect } from "react-redux";
import axios from 'axios';
import { store } from "../_helpers";
import InputField from "../_common/InputField";
import SelectSimple from "../_common/SelectSimple";
import SelectSimpleParser from "../_common/SelectSimpleParser";
import SelectDynamic from "../_common/SelectSimple";
import CheckBoxSimple from "../_common/CheckBoxSimple";
import { API_URL } from "../_config";
import {
  Row,
  Col,
  Card,
} from "shards-react";

var provincia = undefined;

const EditarServicio = props => {
 
    const [provincias, setProvincias] = useState([]);
    const [partidos, setPartido] = useState([]);
    const [barrios, setBarrio] = useState([]);
    const [whatsapp, setWhatsApp] = useState(props.activedX.whatsapp);

    useEffect(() => {
        axios({
            method: "get",
            url:
              API_URL + "api/region?token=" +
              store.getState().authentication.user.token
          }).then(response => {
            setProvincias(response.data);
          });
 
          axios({
            method: "get",
            url:
              API_URL + "api/region-city/" + props.activedX.region_id
          }).then(response => {
            setPartido(response.data);
          }); 

          axios({
            method: "get",
            url:
              API_URL + "api/region-state-city/" + props.activedX.region_city_id
          }).then(response => {
            setBarrio(response.data);
          });
    
  }, [props.activedX]);



  const { handleSubmit, pristine, reset, submitting, except } = props
  return (
    <form onSubmit={handleSubmit}>
      <div>
        
          <Field
            name="name"
            component={InputField}
            type="text"
            label="Descripción del servicio"
          />
          
        </div>
  
        <br/>
        <button type="submit" style={{borderColor: '#e1e5eb', color: '#585858'}} disabled={pristine || submitting}>
            Editar
        </button>
    </form>
  )
}

function mapState(state, props) {
   
  const { users, authentication, vehicles, adminuser } = state;

  const { user } = authentication;

  if(props.activedX) {
    return { user, users, adminuser, initialValues: props.activedX};
  }else{
    return { user, users, adminuser};
  }
}
const actionCreators = {

};

const po = reduxForm({
form: 'EditarServicio', 
enableReinitialize: true
})(EditarServicio);

const pox = connect(
  mapState,
  actionCreators
)(po);

export { pox as EditarServicio };

