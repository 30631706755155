import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import ResumenActividad from "../_components/Dashboard2/ResumenActividad";
import CanalTotal from "../_components/Dashboard2/CanalTotal";
import CanalTotalMensual from "../_components/Dashboard2/CanalTotalMensual";
import CanalPorMes from "../_components/Dashboard2/CanalPorMes";
import { store } from "../_helpers";
import PageTitle from "../_components/utils/PageTitle";
import { API_URL } from "../_config";
import Cookies from "universal-cookie";
import "../DashboardGeneral/DashboardGeneral.css";

class DashboardGeneral extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      response: {},
      us: '',
      v_current: '',
      v_porcentaje: '',
      na: '',
      q: '',
      sa: ''
    };
    
  }

  componentWillMount() {
    const cookies = new Cookies();
    if(cookies.get("dealer")) {
    axios.get(API_URL + 'dashboard?token=' + store.getState().authentication.user.token).then(response => {
      this.setState({
        response: response.data,
        us: response.data.totales_historico.US,
        v_current: response.data.porcentajes_mensuales.vehicles_current,
        v_porcentaje: response.data.porcentajes_mensuales.V_porcentaje.t,
        v_arrow: response.data.porcentajes_mensuales.V_porcentaje.arrow,
        na: response.data.totales_historico.NA,
        na_current: response.data.porcentajes_mensuales.vehicles_current,
        na_porcentaje: response.data.porcentajes_mensuales.V_porcentaje.t,
        na_arrow: response.data.porcentajes_mensuales.V_porcentaje.arrow,
        q: response.data.totales_historico.Q,
        q_current: response.data.porcentajes_mensuales.Q_current,
        q_porcentaje: response.data.porcentajes_mensuales.Q_porcentaje.t,
        q_arrow: response.data.porcentajes_mensuales.Q_porcentaje.arrow,
        sa: response.data.totales_historico.SA,
        sa_current: response.data.porcentajes_mensuales.sale_current,
        sa_porcentaje: response.data.porcentajes_mensuales.S_porcentaje.t,
        sa_arrow: response.data.porcentajes_mensuales.S_porcentaje.arrow
      });
  
      
    });
  }
  }

  render() {
    const totalvisitas = this.state.us;
    var resumen = "j";
    return (
      <>
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Resumen General"
            subtitle={'Bienvenido ' + this.props.user.user.first_name}
            className="text-sm-left"
          />
        </Row>
        <Row>
          <Col sm="6">

            <Card className="mb-4">
              <CardHeader className="border-bottom" style={{paddingLeft: '12.5px'}}>
                <h6 className="m-0" style={{textTransform: 'uppercase', fontWeight: 'bold'}}>Variación Mes Anterior</h6>
              </CardHeader>
              <CardBody className="p-0 pb-3">
                <Container>
                  <div className="table-responsive" style={{overflowY: 'hidden'}}>
                    <table>
                      <tbody>
                        <tr style={{textAlign: 'center'}}>
                          <th></th>
                          <th>Ult. mes</th>
                          <th>Variación</th>
                          <th>Total</th>
                        </tr>
                        <br/>
                        <tr>
                          <td style={{color: '#109618', fontWeight: 'bold'}}>Visitas en la página</td>
                          <td style={{textAlign: 'center'}}>{this.state.v_current}</td>
                          <td class="trr"><i class={"icono-arrow1-down " + this.state.v_arrow}></i> {this.state.v_porcentaje}%</td>
                          <th style={{textAlign: 'center'}}>{this.state.us}</th>
                        </tr>
                        <br/>
                        <tr>
                          <td style={{color: '#d2a82a', fontWeight: 'bold'}}>Autos Totales Publicados</td>
                          <td style={{textAlign: 'center'}}>{this.state.na_current}</td>
                          <td class="trr"><i class={"icono-arrow1-down " + this.state.na_arrow}></i> {this.state.na_porcentaje}%</td>
                          <th style={{textAlign: 'center'}}>{this.state.na}</th>
                        </tr>
                        <br/>
                        <tr>
                          <td style={{color: '#fd0202', fontWeight: 'bold'}}>Consultas Totales</td>
                          <td style={{textAlign: 'center'}}>{this.state.q_current}</td>
                          <td class="trr"><i class={"icono-arrow1-down " + this.state.q_arrow}></i> {this.state.q_porcentaje}%</td>
                          <th style={{textAlign: 'center'}}>{this.state.q}</th>
                        </tr>
                        <br/>
                        <tr>
                          <td style={{color: '#3b77d6', fontWeight: 'bold'}}>Autos Vendidos</td>
                          <td style={{textAlign: 'center'}} >{this.state.sa_current}</td>
                          <td class="trr"><i class={"icono-arrow1-down " + this.state.sa_arrow}></i> {this.state.sa_porcentaje}%</td>
                          <th style={{textAlign: 'center'}}>{this.state.sa}</th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Container>
              </CardBody>
            </Card>

            </Col>
            <Col sm="6">

            <Card className="mb-4">
              <CardHeader className="border-bottom" style={{paddingLeft: '12.5px'}}>
                <h6 className="m-0" style={{textTransform: 'uppercase', fontWeight: 'bold'}}>Resumen de Actividad</h6>
              </CardHeader>
              <CardBody className="p-0 pt-2 pb-3">
                <ResumenActividad query={this.state.response}/>
              </CardBody>
            </Card>


              </Col>
              </Row>
            

        <Row noGutters className="page-header py-4">
          <Col sm="4">

          <p className="text-sm-left" style={{fontSize: '1.325rem', color: 'black', marginLeft: '0px'}}>
              Autos publicados por canal
          </p>

          </Col>
          
        </Row>

        <Card className="mb-4">
          <CardBody className="p-2">
            <Container>
              <Row>
                <Col className="padding_flex">
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      background: "#0297c4",
                      borderRadius: "5px"
                    }}
                  ></div>
                  <span style={{ paddingLeft: "8px" }}>Facebook</span>
                </Col>

                <Col className="padding_flex" >
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      background: "#980198",
                      borderRadius: "5px"
                    }}
                  ></div>
                  <span style={{ paddingLeft: "8px" }}>OLX</span>
                </Col>

                <Col className="padding_flex" >
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      background: "#0f9517",
                      borderRadius: "5px"
                    }}
                  ></div>
                  <span style={{ paddingLeft: "8px" }}>Autocosmos</span>
                </Col>

                <Col className="padding_flex" >
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      background: "#0f9517",
                      borderRadius: "5px"
                    }}
                  ></div>
                  <span style={{ paddingLeft: "8px" }}>LaVoz</span>
                </Col>

                <Col className="padding_flex" >
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      background: "#fe9801",
                      borderRadius: "5px"
                    }}
                  ></div>
                  <span style={{ paddingLeft: "8px" }}>DeMotores</span>
                </Col>

                <Col className="padding_flex" >
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      background: "#db3811",
                      borderRadius: "5px"
                    }}
                  ></div>
                  <span style={{ paddingLeft: "8px" }}>MercadoLibre</span>
                </Col>

                <Col className="padding_flex" >
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      background: "#dbcb10",
                      borderRadius: "5px"
                    }}
                  ></div>
                  <span style={{ paddingLeft: "8px" }}>Instagram</span>
                </Col>

                <Col className="padding_flex" style={{ display: "inline-flex", paddingTop: '10px', paddingBottom: '10px' }}>
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      background: "#3265ca",
                      borderRadius: "5px"
                    }}
                  ></div>
                  <span style={{ paddingLeft: "8px" }}>NuestrosAutos</span>
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>

        <Card className="mb-4">
              <CardHeader className="border-bottom" style={{paddingLeft: '12.5px'}}>
                <h6 className="m-0" style={{textTransform: 'uppercase', fontWeight: 'bold'}}>AUTOS PUBLICADOS POR CANAL POR MES</h6>
              </CardHeader>
              <CardBody className="p-0 pb-3">
                <CanalPorMes />
              </CardBody>
            </Card>
          
        

        
        
        <Row>
          <Col sm="6">
           
            <Card className="mb-4">
              <CardHeader className="border-bottom" style={{paddingLeft: '12.5px'}}>
                <h6 className="m-0" style={{textTransform: 'uppercase', fontWeight: 'bold'}}>Ventas por canal total historico</h6>
              </CardHeader>
              <CardBody className="p-0 pb-3">
                <CanalTotal />
              </CardBody>
            </Card>
            </Col>
            <Col sm="6">
            <Card className="mb-4">
              <CardHeader className="border-bottom" style={{paddingLeft: '12.5px'}}>
                <h6 className="m-0" style={{textTransform: 'uppercase', fontWeight: 'bold'}}>Publicados por canal total</h6>
              </CardHeader>
              <CardBody className="p-0 pb-3">
                <CanalTotalMensual />
              </CardBody>
            </Card>
          </Col>
          </Row>
      </>
    );
  }
}

//export default Dashboard;

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}
const actionCreators = {};

const connectedDashboardGeneral = connect(
  mapState,
  actionCreators
)(DashboardGeneral);
export { connectedDashboardGeneral as DashboardGeneral };
