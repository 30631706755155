import Axios from 'axios';
import React  from 'react'
import { Modal,ModalBody,ModalHeader,} from "shards-react";
import { API_URL } from '../_config';


const ModalDeleteOfet = ({idData,modalSelectDelete,idAgency,tokenId,setModalSelectDelete,setEliminadoOVerificadoId}) => {
  const idOfet= idData[0] && idData[0].id
  const OfertaEliminada= () => {
    Axios.delete(`${API_URL}api/cardeals/${idOfet}/dealer/${idAgency}?token=${tokenId}`)
    .then(response => setEliminadoOVerificadoId(response.result))
    setModalSelectDelete(!modalSelectDelete)
  }
    return (
          <Modal size="md" open={modalSelectDelete} toggle={() => {}}>
          <ModalHeader>
              Eliminar Oferta
              <i
                style={{
                  position: "absolute",
                  right: "20px",
                  top: "15px",
                  fontSize: "25px",
                  cursor: "pointer"
                }}
                class="material-icons"
                onClick={() => setModalSelectDelete(!modalSelectDelete)}
              >
                clear
              </i>
            </ModalHeader>
            <ModalBody>
              <div>
                <div>
                  <h5>¿Seguro que deseas eliminar esta oferta?</h5>
                  <p>Recuerda que si eliminas esta oferta, ya no se encontrara en la plataforma.</p>
                </div>
                <div>
                  <button  className="btn btn-secondary m-2" onClick={() => setModalSelectDelete(!modalSelectDelete)} >Cancelar</button>
                  <button className="btn btn-primary  m-2" onClick={OfertaEliminada}>Confirmar</button>
                </div>
              </div>
            </ModalBody>
          </Modal>
    )
} 
export default ModalDeleteOfet