import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardHeader, CardBody, Button, Container, FormCheckbox } from "shards-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
         faCheckCircle
         } from '@fortawesome/free-solid-svg-icons'

class Paso9 extends React.Component {
    constructor(props) {
        super(props);
    

    }
 
  render() {
    return (
    <div>
        <center>
          <FontAwesomeIcon icon={faCheckCircle} style={{color: '#43a047', fontSize: '120px'}}/>
          <br />
          <h2>¡Felicidades!</h2>
          <p>Has publicado un vehiculo exitosamente</p>
          <Button theme="success">Quiero verla</Button>
        </center>
    </div>
    );
  }
}


export default Paso9;
