import React, { useState, useEffect } from "react";
import PolyLoader from "../_components/PolyLoader";
import NotFound from "../_components/NotFound";
import { connect } from "react-redux";
import EditVehiclesFrom from "./EditVehiclesFrom";
import { vehiclesActions, loadingGloabal } from "../_actions";
import axios from "axios";
import Cookies from "universal-cookie";
import { Upload, Popover, Modal, Button, Icon, notification } from "antd";
import { store } from "../_helpers";
import { API_URL } from "../_config";

const cookies = new Cookies();

const openNotificationWithIcon = (type, til, msg) => {
  let $congf = {
    message: til,
    description: msg,
    top: 70,
  };
  notification[type]($congf);
};

const useForceUpdate = () => "";

function EditVehicles(props) {
  //const { editVehicules } = props;

  const [shower, setShower] = useState([]);
  const [disableCoso, setdisableCoso] = useState(false);
  const [cargandorazo, setCargandorazo] = useState(true);
  const [cuatrocerocuatro, setcuatrocerocuatro] = useState(false);
  const [intent, setIntent] = useState(0);
  //const [fileList, setFileList] = useState(null);

  console.log("prods ->>>>", props);
  const forceUpdate = (NotificationUse = false, state, title, description) => {
    setCargandorazo(true);
    let movida = [];
    let data = [];
    axios
      .get(
        API_URL +
          "api/dealer/" +
          cookies.get("dealer").id +
          "/vehicles/" +
          props.match.params.id +
          "?token=" +
          props.user.token,
      )
      .then((response) => {
        data = response.data;
        if (JSON.stringify(data.brand_id) != null) {
          Object.entries(data).forEach(([key, value]) => {
            movida[key] = value;
          });
          data["fit"] = [];
          movida.features.map(function (item, index) {
            data["fit"][item.id] = true;
          });
          setShower(data);
          setCargandorazo(false);
        }
      })
      .then((res) => {
        if (NotificationUse) {
          setTimeout(() => {
            openNotificationWithIcon(state, title, description);
          }, 1400);
        }
      });

    refe();
  };

  const refe = () => {
    return (
      <div>
        <EditVehiclesFrom
          disableCoso={disableCoso}
          enableCoso={refu}
          refreshMovida={forceUpdate}
          //imagenesData={fileList}
          showVehicle={shower}
          onSubmit={editar}
          vehiculo={props.match.params.id}
        ></EditVehiclesFrom>
        <PolyLoader open={cargandorazo} text="Obteniendo datos"></PolyLoader>
        <NotFound open={cuatrocerocuatro && !cargandorazo}></NotFound>
      </div>
    );
  };
  console.log("rendering");

  const refu = () => {
    setdisableCoso(false);
  };

  useEffect(() => {
    //props.showVehicleID(props.match.params.id);
  }, []);

  const arrayToObject = (array) => {
    array.reduce((obj, item) => {
      console.log(obj);
      console.log(item);
      console.log("xkt");
      obj[item] = item;
      return obj;
    }, {});
  };

  useEffect(() => {
    let isCancelled = false;
    console.log(typeof props.showVehicle);
    console.log("briyi");
    let movida = [];
    let data = [];

    if (!isCancelled) {
      axios
        .get(
          API_URL +
            "api/dealer/" +
            cookies.get("dealer").id +
            "/vehicles/" +
            props.match.params.id +
            "?token=" +
            props.user.token,
        )
        .then((response) => {
          data = response.data;
          if (JSON.stringify(data.brand_id) != null) {
            Object.entries(data).forEach(([key, value]) => {
              movida[key] = value;
            });
            data["fit"] = [];
            movida.features.map(function (item, index) {
              data["fit"][item.id] = true;
            });
            setShower(data);
            setCargandorazo(false);
          }
        });
    } else {
      setIntent(intent + 1);

      if (intent == 1) {
        setcuatrocerocuatro(true);
        setCargandorazo(false);
      }
    }

    return () => {
      isCancelled = true;
    };
  }, [intent, props.match.params.id, props.showVehicle, props.user.token]);

  const editar = (data) => {
    localStorage.removeItem("vehicle_list");
    const id = props.match.params.id;
    var caracteristicas = [];

    data.fit.map(function (it, ix) {
      caracteristicas.push(ix);
    });

    axios
      .post(
        API_URL +
          "api/dealer/" +
          cookies.get("dealer").id +
          "/vehicles/" +
          id +
          "/edit?token=" +
          props.user.token,
        {
          data: data,
          features: caracteristicas,
          note: data.note,
          agregarurl: 1,
          noteHtml: "",
          noteOLX: data.noteOLX,
          note_id: data.note_id,
        },
      )

      .then((response) => {
        return;
      });
  };

  const { showVehicle } = props;

  return (
    <div>
      <EditVehiclesFrom
        disableCoso={disableCoso}
        enableCoso={refu}
        //imagenesData={fileList}
        refreshMovida={forceUpdate}
        showVehicle={shower}
        onSubmit={editar}
        vehiculo={props.match.params.id}
      ></EditVehiclesFrom>
      <PolyLoader open={cargandorazo} text="Obteniendo datos"></PolyLoader>
      <NotFound open={cuatrocerocuatro && !cargandorazo}></NotFound>
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  const { users, authentication } = state;

  const { user } = authentication;

  return { user, users };
}

const actionCreators = {
  loadingGShow: loadingGloabal.loadingGShow,
  loadingGHide: loadingGloabal.loadingGHide,
};

const connectedEditVehicles = connect(mapStateToProps, actionCreators)(EditVehicles);

export { connectedEditVehicles as EditVehicles };
