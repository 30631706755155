import { loadingGlobalConstants } from "../_constants";

import { vehiclesServices } from "../_services";
import { store } from "../_helpers";

//console.log('Cook', cookies.get('dealer').id)
/*
export function vehiclesActions() {

  return (dispatch, getState) => {
    axios.get(API_URL + 'api/churrasco?brand=&fuel=&model=&page=1&region=&tag=&type=&vendidos=&version=&year=')
      .then((response) => {
        dispatch({ type: vehiclesConstants.GETVEHICLES_SUCCESS, payload: response.data })
      })
  }

}

*/

export const loadingGloabal = {
  loadingGShow,
  loadingGHide
};

function loadingGShow() {
  return dispatch => {
    dispatch({
      type: loadingGlobalConstants.LOADING_SHOW,
      payload: true
    });
  };
}

function loadingGHide() {
  return dispatch => {
    dispatch({
      type: loadingGlobalConstants.LOADING_HIDE,
      payload: false
    });
  };
}
