import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import CardFlip from "../_components/CardFlip";
import { Badge, Card, FormSelect, Button, Container } from "shards-react";
import { API_URL } from "../_config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faUndo } from "@fortawesome/free-solid-svg-icons";
import { PreContacto } from "./PreContacto";
import { Row, Col } from "antd";

class RedVehiclesList extends React.Component {
  constructor() {
    super();
    this.state = {
      isFlipped: false,
      sharebox: "",
      vehicles_loaded: false,
      delete: false,
      delete_portals_form: false,
      modal_id: undefined
    };
    this.handleClick = this.handleClick.bind(this);
    this.routeChange = this.routeChange.bind(this);
    this.share = this.share.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.polymer = this.polymer.bind(this);
  }

  handleLoad() {
    this.setState(prevState => ({
      vehicles_loaded: !prevState.vehicles_loaded
    }));
  }

  handleClick(e) {
    e.preventDefault();
    this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
  }

  handleDelete(e) {
    e.preventDefault();
    this.setState(prevState => ({ delete: !prevState.delete }));
  }

  routeChange() {
    let path = "/vehiculos/editar/" + this.props.item.id;
    this.props.history.push(path);
  }

  share(url) {
    this.setState({ sharebox: url });
  }

  polymer(item, vehicle) {
    console.log(item);
    this.setState({
      isFlipped: false,
      modal_id: undefined
    });

    if (this.state.modal_id == undefined) {
      this.setState({
        modal_id: (
          <PreContacto
            carro={vehicle}
            open={true}
            key={"modal" + item}
            id_user={item}
          />
        )
      });
    }
  }

  handleChangeforSelect = event => {
    this.share(event.target.value);
  };

  handleChangeforSelectDelete = event => {
    if (event.target.value == "vendido") {
      this.setState(prevState => ({
        delete_portals_form: !prevState.delete_portals_form
      }));
    } else {
      this.setState({ delete_portals_form: false });
    }
  };

  view_ficha = (item, vehicle) => {
    const path = "/detalle/" + vehicle;
    this.props.history.push(path);
    window.scroll(0,0)
    // var win = window.open(
    //   API_URL + "print/vehicle/" + vehicle,
    //   "_blank"
    // );
    // win.focus();
  };

  render() {
    var item = this.props.item;

    if (item.image) {
      var imagex = item.image;
    } else {
      var imagex = "/img404.png";
    }
    return (
      <>
        <CardFlip
          isDeleted={this.state.delete}
          infinite={true}
          isFlipped={this.state.isFlipped}
          flipDirection="horizontal"
        >
          <Card
            onClick={this.handleClick}
            key="front"
            style={{ minHeight: "360px", paddingBottom: "30px;" }}
          >
            <div
              className="card-img"
              style={{
                background:
                  "url(" + imagex + ") center center / cover no-repeat",
                width: "100%",
                height: "200px"
              }}
            ></div>
            <Container fluid className="card-detail p-3">
              <Row
                className="d-flex justify-content-end"
                style={{
                  borderTop: "1px solid #ccc",
                  paddingTop: "3px",
                  marginBottom: "13px",
                  position: "absolute",
                  bottom: "0",
                  right: "20px",
                  left: "20px"
                }}
              >
                <Badge
                  style={{
                    fontSize: "11px",
                    padding: "6px",
                    color: "#333",
                    background: "none"
                  }}
                  pill
                >
                  {item.created}
                </Badge>
                <Button
                  onClick={() => this.view_ficha(item.dealer_id, item.id)}
                  style={{
                    padding: "3px",
                    borderRadius: "5px",
                    width: "24px",
                    height: "24px",
                    lineHeight: "24px",
                    marginRight: "4px"
                  }}
                  size="sm"
                >
                  <i
                    class="material-icons"
                    style={{ fontSize: "16px", top: "0" }}
                  >
                    remove_red_eye
                  </i>
                </Button>
                <Button
                  onClick={() => this.polymer(item.dealer_id, item.id)}
                  style={{
                    padding: "3px",
                    borderRadius: "5px",
                    width: "24px",
                    height: "24px",
                    lineHeight: "24px"
                  }}
                  size="sm"
                >
                  <i
                    class="material-icons"
                    style={{ fontSize: "16px", top: "0" }}
                  >
                    contact_support
                  </i>
                </Button>
              </Row>
              <p className="t-2 mb-0">
                {item.brand_name} {item.year}
              </p>
              <h4 className="card-title mb-2" style={{ fontWeight: "700" }}>
                {item.model}
              </h4>

              {item.version_id == 999999999 ? (
                <p className="t-3 mb-2">{item.new_version}</p>
              ) : (
                <p className="t-3 mb-2">{item.version_name}</p>
              )}
            </Container>
          </Card>
          <Card
            key="back"
            onClick={this.handleClick}
            style={{ minHeight: "360px" }}
          >
            <h5
              className="badge-primary active px-2 py-2 rounded-top text-right"
              style={{ width: "100%" }}
            >
              {item.price} {item.currency_name}
            </h5>
            <Container fluid className="card-detail p-3">
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12}>
                  <p className="t-3 pt-1 text-right">kms</p>
                </Col>
                <Col span={12}>
                  <p className="t-2 text-left"> {item.kilometers}</p>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12}>
                  <p className="t-3 pt-1 text-right">combustible</p>
                </Col>
                <Col span={12}>
                  <p className="t-2 text-left">{item.fuel}</p>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12}>
                  <p className="t-3 pt-1 text-right">puertas</p>
                </Col>
                <Col span={12}>
                  <p className="t-2 text-left">{item.doors}</p>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12}>
                  <p className="t-3 pt-1 text-right">color</p>
                </Col>
                <Col span={12}>
                  <p className="t-2 text-left">{item.color_name}</p>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12}>
                  <p className="t-3 pt-1 text-right">transmisión</p>
                </Col>
                <Col span={12}>
                  <p className="t-2 text-left">{item.transmission}</p>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col span={24}>
                  <p>Publicado por:</p>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <h5>{item.dealer_name}</h5>
                  <p className="t-2">
                    {item.region || item.barrio_name},
                    {item.city == "Capital Federal" ? item.cities : item.city || item.city_name}
                  </p>
                </Col>
              </Row>
            </Container>
          </Card>

          <Card key="deleted" style={{ minHeight: "360px" }}>
            <div className="container-fluid">
              <h6 className="mt-3">
                ¿Por qué quieres finalizar la publicacion?
              </h6>
              <FormSelect onChange={this.handleChangeforSelectDelete}>
                <option disabled selected>
                  Seleccione una opción
                </option>
                <option value="vendido">Lo vendí</option>
                <option>No lo vendí</option>
                <option>Fue un error</option>
              </FormSelect>
              <br />
              <br />
              <br />
              {this.state.delete_portals_form == false ? (
                <div></div>
              ) : (
                <div>
                  <h5 className="mt-3">¿Por donde lo vendiste?</h5>
                  <FormSelect>
                    <option>MercadoLibre</option>
                    <option>OLX</option>
                    <option>Facebook</option>
                    <option>Instagram</option>
                  </FormSelect>
                </div>
              )}
              <Button onClick={this.handleDelete} theme="secondary">
                <FontAwesomeIcon icon={faUndo} />
              </Button>
            </div>
          </Card>
        </CardFlip>
        <br />

        {this.state.modal_id != undefined && this.state.modal_id != "edit" ? (
          this.state.modal_id
        ) : (
          <div></div>
        )}
      </>
    );
  }
}

RedVehiclesList.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object
};

export default withRouter(RedVehiclesList);
