import React, { Component } from "react";
import "./UpImage.css";
import S3 from "react-aws-s3";
import { Icon, notification, Modal } from "antd";
import { API_URL } from "../../_config";
import CarFileList from "./CarFileList";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { DndProvider } from "react-dnd";
import update from "immutability-helper";
import { store } from "../../_helpers";
import axios from "axios";
import Cookies from "universal-cookie";
import { connect } from 'react-redux'
import { submit } from 'redux-form'
import {
  Row,
  Col,
  Button
} from "shards-react";

const cookies = new Cookies();

const openNotificationWithIcon = (type, til, msg) => {
  let $congf = {
    message: til,
    description: msg,
    top: 70
  };
  notification[type]($congf);
};
const config = {
  bucketName: "nuestrosautos",
  //dirName: "media" /* optional */,
  region: "us-east-1",
  accessKeyId: "AKIAISV2KGR6JFBZMWSQ",
  secretAccessKey: "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",
  s3Url: "https://s3.amazonaws.com/nuestrosautos" /* optional */
};
class UpImage extends Component {
  constructor(props) {
    super(props);
    this.state = { hightlight: false, updated: false, reflejar: false };

    this.fileInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.confirm_order = this.confirm_order.bind(this);
    this.subir = this.subir.bind(this);
    this.stringToHash = this.stringToHash.bind(this);
    this.onUpdateItem = this.onUpdateItem.bind(this);
    this.moveImage = this.moveImage.bind(this);
    this.handlePreview = this.handlePreview.bind(this);
  }

  componentDidMount() {
    this.setState(
      {
        previewImage: "",
        previewVisible: false,
        listFiles: this.props.filelist ? this.props.filelist : []
      },
      console.log("IMAGENES ready", this.state)
    );
  }

  /**
   * Abro el modal de carga
   */

  openFileDialog() {
    if (this.props.disabled) return;
    this.fileInputRef.current.click();
  }
  /**
   * Abro modal y muestro la imagen
   */
  handlePreview = async index => {
    let Img = this.state.listFiles.filter((_, i) => i === index)[0];

    this.setState({
      previewImage: Img.url,
      previewVisible: true
    });
  };
  /**
   * Cierro modal de la vista previa
   */
  handleCancel = () => this.setState({ previewVisible: false });

  onFilesAdded(evt) {
    if (this.props.disabled) return;
    const files = evt.target.files;
    if (this.props.onFilesAdded) {
      /**
       * cargo el archivo a la cola de subida
       */
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
  }

  onDragOver(evt) {
    evt.preventDefault();
    if (this.props.disabled) return;

    this.setState({ hightlight: true });
  }

  onDragLeave() {
    this.setState({ hightlight: false });
  }

  onDrop(event) {
    event.preventDefault();

    if (this.props.disabled) return;

    const files = event.dataTransfer.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
    this.setState({ hightlight: false });
  }

  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      let hash = this.stringToHash(list.item(i).name);
      this.subir(list.item(i), hash);

      let cnt = {
        hash: hash,
        id: hash,
        image_order: 1,
        url: list.item(i), //list.item(i) ? list.item(i).name : "",
        state: "load"
      };
      this.setState(state => {
        const listFiles = state.listFiles.push(cnt);
        console.log("Result", listFiles);
        return listFiles;
      });
      array.push(list.item(i));
    }

    return array;
  }
  stringToHash($string) {
    let string = $string + Date.now();

    let hash = 0;
    console.log();
    if (string.length == 0) return hash;

    for (let i = 0; i < string.length; i++) {
      let char = string.charCodeAt(i);
      hash = (hash << 15) - hash + char;
      hash = hash & hash;
    }

    return hash;
  }

  subir = (file, hash) => {

    const ReactS3Client = new S3(config);
    const newFileName = window.btoa(file.name + Date.now());
    ReactS3Client.uploadFile(file, newFileName)
      .then(data => {
        this.onUpdateItem(data.location, hash);
      })
      .catch(err => console.error("NO SUBIOOOOOO", err));
  };

  onUpdateItem = (i, hash) => {
        
    axios
      .post(
        API_URL +
          "api/dealer/" +
          cookies.get("dealer").id +
          "/vehicle/" +
          this.props.id_vehicle +
          "/image/link?token=" +
          store.getState().authentication.user.token,
        { url: i, id: true }
      )
      .then(response => {
        
        this.setState(state => {
          // Verifico si esta publicado en los portales
          if(this.props.prop.initialValues.publish.olx == true || this.props.prop.initialValues.publish.deMotores == true || this.props.prop.initialValues.publish.autoCosmos == true || this.props.prop.initialValues.publish.mercadolibre == true) {
            this.setState({reflejar: true});
          }
 
          const listFiles = this.state.listFiles.map((item, j) => {
            if (item.hash === hash) {
              console.log("aja", item.url.name);
              return {
                hash: hash,
                id: response.data.id,
                url: i, //list.item(i) ? list.item(i).name : "",
                state: "done"
              };
            } else {
              return item;
            }
          });

          return {
            listFiles
          };
        });

      })
      .catch(err => {
        notification.destroy();
        notification.open({
          message: "No se pudo subir la imagen",
          description:
            "Ha ocurrido un error en el proceso, por favor, intente más tarde",
          icon: <Icon type="stop" style={{ color: "#d32f2f" }} />,
          top: 70
        });
      });
  };

  removeItem = (index, hash, url) => {
    console.log(this.state.listFiles);
    axios
      .delete(
        API_URL +
          "api/dealer/" +
          cookies.get("dealer").id +
          "/vehicles/image/" +
          hash +
          "?token=" +
          store.getState().authentication.user.token
      )
      .then(response => {
        this.props.enviarData();
        this.setState({
          listFiles: this.state.listFiles.filter(i => i.id !== hash)
        });
      })
      .catch(err => {
        notification.destroy();
        notification.open({
          message: "No se pudo eliminar la imagen",
          description:
            "Ha ocurrido un error en el proceso, por favor, intente más tarde",
          icon: <Icon type="stop" style={{ color: "#d32f2f" }} />,
          top: 70
        });
      });
  };

  reflejar() {
      this.props.enviarData();
      this.setState({reflejar: false})
  }


  confirm_order() {

    let newItem = [];
    this.state.listFiles.map(j => {
      newItem.push(j.id);
    });

    axios
    .post(
      API_URL +
        "api/dealer/" +
        cookies.get("dealer").id +
        "/vehicle/" +
        this.props.id_vehicle +
        "/order-images?token=" +
        store.getState().authentication.user.token,
      { newItem: newItem }
    )
    .then(response => {
      this.props.enviarData();
      this.setState({updated: false})
      console.log("Se Guardo");
    })
    .catch(err => {
      alert('Ocurrió un error al intentar guardar')
      console.log("Se Guardo con error");
    });
  }

  saveOrder() {
    this.setState({updated: true})
  }
  /**
   * Guardo
   */
  moveImage(dragIndex, hoverIndex) {
    const draggedImage = this.state.listFiles[dragIndex];

    this.setState({
      listFiles: update(this.state.listFiles, {
        $splice: [[dragIndex, 1], [hoverIndex, 0, draggedImage]]
      })
    });

    this.saveOrder();
  }
  render() {
    const isTouchDevice = () => {
      if ("ontouchstart" in window) {
        return true;
      }
      return false;
    };

    const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend;
    return (
      <div className="clearfix content-inputCustomFile container-fluid">
        <div className="row">
          <div
            className={`inputCustomFile ${
              this.state.hightlight ? "Highlight" : ""
            }`}
            onDragOver={this.onDragOver}
            onDragLeave={this.onDragLeave}
            onDrop={this.onDrop}
            onClick={this.openFileDialog}
            style={{ cursor: this.props.disabled ? "default" : "pointer" }}
          >
            <input
              ref={this.fileInputRef}
              className="FileInput"
              type="file"
              multiple
              onChange={this.onFilesAdded}
            />

            <span>
              Subir imagenes Haga clic o arrastre sus imagenes a esta área
            </span>
          </div>
          <div className="left ant-upload-list ant-upload-list-picture-card">
            <DndProvider backend={backendForDND}>
              {this.state.listFiles ? (
                <CarFileList
                  images={this.state.listFiles}
                  remove={this.removeItem}
                  moveImage={this.moveImage}
                  preview={this.handlePreview}
                ></CarFileList>
              ) : (
                ""
              )}
            </DndProvider>
          </div>
          
        </div>
        {this.state.updated == true &&
        <Row>
            <Col sm={3}>
                <Button onClick={() => this.confirm_order()} className="w-100">Confirmar orden</Button>
            </Col>
            <Col sm={9}>
                <p>Nota: Debe confirmar el orden para que este se vea reflejado y sea guardado, de lo contrario, los cambios en el orden no seran guardados.</p>
            </Col>
          </Row>
        }

        {this.state.reflejar == true &&
        <Row>
            <Col sm={3}>
                <Button onClick={() => this.reflejar()} className="w-100">Reflejar cambios</Button>
            </Col>
            <Col sm={9}>
                <p>Nota: Los cambios han sido guardados exitosamente en nuestra base de datos, pero es necesario que usted presione el boton reflejar, para que estos cambios aparezcan en los portales publicados.</p>
            </Col>
          </Row>
        }
        <Modal
          visible={this.state.previewVisible}
          title={null}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img
            alt="example"
            style={{ width: "100%" }}
            src={this.state.previewImage}
          />
        </Modal>
      </div>
    );
  }
}

export default connect()(UpImage)
