import React from 'react'
import ImageGallery from "react-image-gallery";
import './SliderImage.css'
import "react-image-gallery/styles/css/image-gallery.css";

const SliderImages = ({ imagesCar }) => {
    const images = imagesCar ?  imagesCar.map((i) => ({
        original: i.image,
        thumbnail: i.image,
        sizes: "(max-width: 500px) 50vw, 500px",
    })):[]

  return (
    <div>
      <ImageGallery items={images} showPlayButton={false} />
    </div>
  );
};

export default SliderImages;