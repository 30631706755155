import { sidebarConstants } from "../_constants";
let itemMenu = require("../_data/menu");
const initialState = {
  items: itemMenu["items-0"],
  menuVisible: false
};

export function sidebar(state = initialState, action) {
  switch (action.type) {
    case sidebarConstants._CHANGE:
      return {
        ...state,
        items: action.items
      };
    case sidebarConstants.TOGGLE_SIDEBAR:
      return {
        ...state,
        menuVisible: state.menuVisible ? false : true
      };

    default:
      return state;
  }
}
/*
const toggleSidebar = () => {
  _store.menuVisible = !_store.menuVisible;
  this.emit(Constants.CHANGE);
};
*/
/*
export function users(state = {}, action) {
  switch (action.type) {
    case userConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETALL_SUCCESS:
      return {
        items: action.users
      };
    case userConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    case userConstants.DELETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        items: state.items.map(user =>
          user.id === action.id ? { ...user, deleting: true } : user
        )
      };
    case userConstants.DELETE_SUCCESS:
      // remove deleted user from state
      return {
        items: state.items.filter(user => user.id !== action.id)
      };
    case userConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user
      return {
        ...state,
        items: state.items.map(user => {
          if (user.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...userCopy } = user;
            // return copy of user with 'deleteError:[error]' property
            return { ...userCopy, deleteError: action.error };
          }

          return user;
        })
      };
    default:
      return state;
  }
}
*/
