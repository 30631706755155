//import config from "config";
import { authHeader } from "../_helpers";
import { API_URL } from "../_config";
import axios from "axios";
import { Upload, Popover, Modal, Button, Icon, notification} from 'antd';
import React, {useState, useEffect} from 'react';
const config = {
  //apiUrl: API_URL + "api/:4000"

  apiUrl: "https://authentication.nuestrosautos.cca.org.ar",
  site_uri: API_URL 
};
export const userService = {
  login,
  logout,
  register,
  getAll,
  getById,
  update,
  delete: _delete
};

function login(username, password) {
  
  //console.log(config.apiUrl);
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ emai: username, assword: password }) //?email=${username}&password=${password}
  };
  return axios({
    method: "post",
    url: `${config.site_uri}/something?email=${username}&password=${password}`
  })
    .then(response => {

//      alert('Vardo',response);

      /// Verifico error
      if(response.data.error == true) {
        return false;
      }

      if (response.data.status == 200) {
        console.log("juan1!!!", response);
        let user = [{
          firstName: "cristianojeda",
          id: 2,
          lastName: "juanj",
          token: response.data.token,
          username: "juanj"
        }];
       localStorage .setItem('user', JSON.stringify(response.data));
        localStorage.setItem("user2", JSON.stringify(user));
        return response.data;
        //response.data.token;
        //localStorage.setItem("user", JSON.stringify(user));
        /* cookies.set("cca_token", response.data.token, { path: "/" });
        if (cookies.get("cca_token")) {
          console.log(true);
        } else {
          console.log("Error interno, 001");
        }*/
      } else if (response.data.status == 500){
           console.log('code 500');
         return false;

      }else if (response.data.status == "error") {
        if (response.data.message == "invalid_email_or_password") {
          console.log("Revise sus credenciales");
        }

        if (response.data.message == "failed_to_create_token") {
          console.log("Error interno, 002");
        }
        if(response.data.message =="Server Error"){

console.log('Server error');
        }
        return false;
      }
    })
    .catch(err => {
      return false;
    });

  /*fetch(
    `${config.site_uri}/something?email=${username}&password=${password}`,
    requestOptions
  ).then(user => {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    //localStorage.setItem("user", JSON.stringify(user));

    return user;
  });


  return fetch(`${config.apiUrl}/users/authenticate`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("user", JSON.stringify(user));

      return user;
    });
*/
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
}

function getAll() {
  let user = JSON.parse(localStorage.getItem("user"));
  return axios({
    method: "get",
    url: `${config.site_uri}/api/v2/user?token=${user.token}`
  }).then(handleResponse);

  /*const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);*/
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(
    handleResponse
  );
}

function register(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user)
  };

  return fetch(`${config.apiUrl}/users/register`, requestOptions).then(
    handleResponse
  );
}

function update(user) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(user)
  };

  return fetch(`${config.apiUrl}/users/${user.id}`, requestOptions).then(
    handleResponse
  );
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(
    handleResponse
  );
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        //logout();
        window.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
