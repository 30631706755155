import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'shards-react';

const ModalConfirmDesv = ({modalShowHide,selectDesvinAccount, desvinculacionFn,fnModalShow}) => {
  const titleAccount = selectDesvinAccount == "dm" && "Demotores" ||  selectDesvinAccount == "olx" && "Olx" || selectDesvinAccount == "da" && "De Autos" || selectDesvinAccount == "ac" && "Autocosmo" ||  selectDesvinAccount == "ml" && "Mercado Libre" ||  selectDesvinAccount == 
  "lv" && "La voz" ||  selectDesvinAccount == "fb" && "Facebook"

  function desvincRequest() {
          fnModalShow("")
          if(selectDesvinAccount === "ac") return desvinculacionFn.ac()
          if(selectDesvinAccount === "dm") return desvinculacionFn.dm()
          if(selectDesvinAccount === "da") return desvinculacionFn.da()
          if(selectDesvinAccount === "fb") return desvinculacionFn.fb()
          if(selectDesvinAccount === "lv") return desvinculacionFn.lv()
          if(selectDesvinAccount === "inst") return desvinculacionFn.inst()
          if(selectDesvinAccount === "ml") return desvinculacionFn.ml()
          if(selectDesvinAccount === "olx") return desvinculacionFn.olx()
  }

  return (
    <Modal size="md" open={modalShowHide} >
      <ModalHeader>
        Desvincular Cuenta {titleAccount} 
              <i
                style={{
                  position: "absolute",
                  right: "20px",
                  top: "15px",
                  fontSize: "25px",
                  cursor: "pointer"
                }}
                class="material-icons"
                onClick={() => fnModalShow("")}
              >
                clear
              </i>
      </ModalHeader>
      <ModalBody>
            <div>
                <div>
                  <h5>
                  ¿Estas seguro que quieres desvincular tu cuenta de {titleAccount}?, recuerda que al desvincular la cuenta los datos asociados a misma seran borrados.
                  </h5>
                </div>
              </div>
      </ModalBody>
      <ModalFooter>
              <div>
                  <button  className="btn btn-secondary m-2" onClick={()=> fnModalShow("")}>Cancelar</button>
                  <button className="btn btn-primary  m-2" onClick={desvincRequest} >Confirmar</button>
                </div>
      </ModalFooter>
    </Modal>
  )
}

export default ModalConfirmDesv
