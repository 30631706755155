const Validate = values => {
  const errors = {};
 /* console.log('|||Validate||',values)
  if (!values.sale_condition) {
    errors.sale_condition = "Required";
  }


  
  if (!values.sale_condition) {
    errors.sale_condition = "Required";
  }
  if (!values.titleML) {
    errors.titleML = "Required";
  }
  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.sex) {
    errors.sex = "Required";
  }
  if (!values.favoriteColor) {
    errors.favoriteColor = "Required";
  }*/
  return errors;
};

export default Validate;
