import React, { useState } from "react";
import "./style.css";

const CheckBoxSimple = ({
  input,
  label,
  kok,
  name,
  type,
  activado,
  id,
  meta: { touched, error }
}) => {
  const [estado, setEstado] = useState(activado);

  const setear = () => {
    activado = false;
  };

  return (
    <div>
      <section className="slider-checkbox">
        <input
          {...input}
          name={name}
          id={id}
          onChange={input.onChange}
          type={type}
        />
        <label className="label" for={id}>
          {label}
        </label>
      </section>
    </div>
  );
};

export default CheckBoxSimple;
