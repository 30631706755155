import { adminuserConstants } from "../_constants";

const initialState = {
  list: [],
  dealers: []
};

export function adminuser(state = initialState, action) {
  switch (action.type) {
    case adminuserConstants.GETADMINUSER_REQUEST:
      return {
        loading: true
      };
    case adminuserConstants.GETADMINUSER_SUCCESS:
      return {
        ...state,
        list: action.payload.data
      };
    case adminuserConstants.GETDEALERS_SUCCESS:
      return {
        ...state,
        dealers: action.payload.data
      };  
    case adminuserConstants.GETADMINUSER_FAILURE:
      return {
        error: action.error
      };

    default:
      return state;
  }
}
