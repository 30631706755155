import React from "react";
import ModalCanvaEdit from "./ModalCanvaEdit";

import { notification, Alert, Icon } from 'antd';
import axios from "axios";
import { API_URL } from "../../_config";
import "react-images-uploader/font.css";
import Cookies from "universal-cookie";

import { store } from "../../_helpers";
const cookies = new Cookies();
class UpdateFile extends React.Component {
  constructor(props) {
    super(props);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.setEditorRef = this.setEditorRef.bind(this);
    this.handleZoomSlider = this.handleZoomSlider.bind(this);
    this.state = {
      cropperOpen: false,
      img: null,
      zoom: 0.05,
      rotate: 0,
      croppedImg: this.props.imgDealer
    };
  }


  handleZoomSlider(value) {
    let state = this.state;
    state.zoom = value;
    this.setState(state);
  }

  handleFileChange(e) {

    const openNotificationWithIcon = (type, description) => {
      notification.destroy()
      notification[type]({
        message: 'Error al subir imagen',
        description: `${description}`,
        className: 'custom-class',
        style: {
          marginTop: "40px",
          zIndex: "999"
        },
        duration: 5,
      });
    };

    let UploadFile = e.target.files[0]

    if (!(/\.(jpg|jpeg|png|gif|svg|webp)$/i).test(UploadFile.name)) {
      return openNotificationWithIcon('error', 'El archivo a adjuntar no es una imagen, selecciona un archivo valido(JPG, PNG, GIF, SVG, WEBP).')
    } else {

      let img = new Image();

      if (UploadFile.size > 1048576) {
        return openNotificationWithIcon('error', 'El peso de la imagen no puede exceder los 1MB!')
      }
      else {
        img.src = URL.createObjectURL(UploadFile);
        let state = this.state;
        state.img = img.src;
        state.cropperOpen = true;
        this.setState(state);
      }
    }
  }
  handleSave(e) {

    const openNotificationWithIcon = (type,message="Error al subir imagen", description) => {
      notification.destroy()
      notification[type]({
        message: `${message}`,
        description: `${description}`,
        className: 'custom-class',
        style: {
          marginTop: "40px",
          zIndex: "999"
        },
        duration: 5,
      });
    };

    const canvasScaled = this.editor.getImageScaledToCanvas();
    const croppedImg = canvasScaled.toDataURL();
    let state = this.state;
    //state.img = null;
    state.cropperOpen = false;
    state.croppedImg = croppedImg;
    this.setState(state);
    axios
      .post(
        API_URL + 'api/dealer/' + cookies.get("dealer").id + '/logo/upload?token=' +
        store.getState().authentication.user.token, {
        logo: croppedImg
      }
      )
      .then(response => {
        if(response.status === 200){
          openNotificationWithIcon('success','Imagen Actualizada', 'Su imagen se cargo correctamente.')
        }else{
          openNotificationWithIcon('error', 'Hemos tenido un error, intenta nuevamente.')
        }
      }).catch((err) => {
        openNotificationWithIcon('error', `Hemos tenido un error ${err.response}, intenta nuevamente.`)
      })
  }
  handleCancel() {
    let state = this.state;
    state.cropperOpen = false;
    this.setState(state);
  }
  setEditorRef(editor) {
    this.editor = editor;
  }

  render() {
    return (
      <>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
          <div style={{ background: "#f9f9f9" }}>
            <img
              src={this.state.croppedImg}
              style={{ width: "100%", borderRadius: "7px" }}
            />
          </div>
          <div className="d-flex mt-4">
            <div style={{width: "180px" }}>
            <label>
              <div style={{cursor: "pointer"}}>
                <i style={{ fontSize: '50px', border: '4px dashed #595959', padding: '15px', borderRadius: '60%' }} class="material-icons">photo_library</i>
                <p style={{ paddingTop: '4px' }}>Actualizar Logo</p>
              </div>
              <input
                ref="in"
                style={{ visibility: "hidden" }}
                type="file"
                accept="image/*"
                onChange={this.handleFileChange}
              />
            </label>
            </div>
            <div style={{textAlign: 'center', cursor : "pointer"  }} onClick={() => this.props.removeImgDealer()}>
              <i style={{fontSize: '50px', border: '4px dashed #595959', padding: '15px', borderRadius: '60%'}} class="material-icons">delete</i>
              <p style={{textAlign: 'center', paddingTop: '4px'}}>Eliminar Imagen</p>
            </div>
          </div>
          <ModalCanvaEdit scaleImage={this.state.zoom} open={this.state.cropperOpen} setEditorRef={this.setEditorRef} state={this.state} handleZoomSlider={this.handleZoomSlider} handleCancel={this.handleCancel} handleSave={this.handleSave} />
        </div>
      </>
    );
  }
}
export default UpdateFile
