import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Col, Button, Modal, ModalBody, ModalHeader } from "shards-react";

class PolyLoader extends React.Component {

    constructor(props) {
      super(props);
      console.log(props);
    }

    render() {
      const { open, text } = this.props;
      return (
        <div>
          <Modal open={open} >
            <ModalBody>
            
                <center>
                <img src="/img/loadingnew.gif" style={{maxWidth:'200px', width: '100%'}}></img>
             
                <h3>{text}</h3>
                </center>
                <br /> <br />
            </ModalBody>
          </Modal>
        </div>
      );
    }
  }

PolyLoader.propTypes = {
  open: PropTypes.bool,
  text: PropTypes.string
};


export default PolyLoader;
