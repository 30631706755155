import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardHeader, CardBody, Button, Container, FormCheckbox } from "shards-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
         faCheckCircle
         } from '@fortawesome/free-solid-svg-icons'
//import ImageUploader from 'react-images-upload';

class Paso8 extends React.Component {
  constructor(props) {
		super(props);
		 this.state = { pictures: [] };
		 this.onDrop = this.onDrop.bind(this);
	}

	onDrop(pictureFiles, pictureDataURLs) {
		this.setState({
            pictures: this.state.pictures.concat(pictureFiles),
        });
	}

    render() {
        return (
          <p> vale por una foto</p>
        );
    }
}


export default Paso8;
