import React, { useState, useEffect } from 'react';
import { Form, reduxForm, Field, getFormValues } from "redux-form";
import { connect } from "react-redux";
import InputField from "../_common/InputField";
import SelectSimple from "../_common/SelectSimple";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {
    Button
  } from "shards-react";
  import { API_URL, PREAPI_URL } from "../_config";
const LinkAC = props => {
  const { handleSubmit, pristine, reset, submitting, tipo } = props

  const [provincias, setProvincias] = useState([]);
  const [useCities, setuseCities] = useState(false);
  const [city_state, setCity] = useState(false);
  const [ciudades, setCiudades] = useState([]);

  useEffect(() => {
    axios.get(PREAPI_URL + 'ac_provinces').then(response => {
      setProvincias(response.data);
      console.log(response.data)
    });
  }, []);

  const select_province = (event) => {
    axios.get(PREAPI_URL + 'ac_cities/' + event.target.value).then(response => {
      setCiudades(response.data)
      setuseCities(true);
    });
  }

  const select_city = (event) => {
    setCity(true);
  }
  return (
    <Form onSubmit={handleSubmit}>
      
          
          {props.tipo == 'login' ? (
                    
                    <Field
                    name="email"
                    component={InputField}
                    onChange={select_city}
                    type="text"
                    label="E-mail"
                  />
          ) : (
            <div>
            <Field
              name="email"
              component={InputField}
              type="text"
              label="E-mail"
            />

            <Field
              name="zip_code"
              component={InputField}
              type="text"
              label="Código postal"
            />

            <label>Provincia</label>
            <div>
              <Field name="provincia" onChange={select_province} className="form-control" component="select">
                <option>Seleccionar Provincia</option>
                  {provincias.map(function(provincia, index){
                    return <option key={index} value={provincia.id}>{provincia.name}</option>;
                })}
              </Field>
            </div>

            {useCities == true &&
              <div>
                <label>Ciudad</label>
                <div>
                <Field name="city" onChange={select_city} className="form-control" component="select">
                  <option>Seleccionar Ciudad</option>
                    {ciudades.map(function(ciudad, index){
                      return <option key={index} value={ciudad.ac_id}>{ciudad.name}</option>;
                  })}
                </Field>
                </div>
              </div>
           }

              </div>
          )}
                  <br/>
                  <Button
                      
                      style={{ width: "100%", fontSize: "17px" }}
                      type="submit"
                      disabled={pristine || submitting || !city_state}
                    >
                      Vincular
                    </Button>

       
    </Form>
  )
}

export default reduxForm({
  form: 'linkautocosmos' 
})(LinkAC)