import { notificationsConstants } from "../_constants";
import { store } from "../_helpers";
import Cookies from "universal-cookie";
import axios from "axios";
import { API_URL, PREAPI_URL } from "../_config";
const cookies = new Cookies();

export const notificacionsActions = {
  getNotifications
};

function getNotifications() {
    var dato = JSON.parse(localStorage.getItem('user'));

    var user_id = dato.user.id;

    return dispatch => {
    axios({
      method: "get",
      url:
      PREAPI_URL + "notificaciones_per_user/" + user_id + "?token=" + store.getState().authentication.user.token
    }).then(response => {
        dispatch({
          type: notificationsConstants.GETNOTIFICATIONS_SUCCESS,
          payload: response.data.data,
        });
      });
    };
}
