import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import WizardPublish from "./WizardPublish";
import { connect } from "react-redux";
import { store } from "../../_helpers";
import showResults from "./publishVehicles";

const rootEl = document.getElementById("root");

class VehiclesPublishWizard extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <div style={{ padding: 15 }}>

          <WizardPublish onClose={this.props.onClose} checkData={this.props.publisVehicules} onSubmit={showResults} />
        </div>
      </Provider>
    );
  }
}

function mapState(state) {
  const { users, authentication, form } = state;
  const { user } = authentication;
  const { publisVehicules } = form;
  return { user, users, publisVehicules };
}

const actionCreators = {};

//const connectedVehiclePublish = connect(mapState, actionCreators)(VehiclePublish);
//export { connectedVehiclePublish as VehiclePublish };

const connectedVehiclesPublishWizard = connect(
  mapState,
  actionCreators
)(VehiclesPublishWizard);
export { connectedVehiclesPublishWizard as VehiclesPublishWizard };
