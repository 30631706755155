import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { reduxForm, Field, isDirty, getFormValues } from "redux-form";
import InputField from "../_common/InputField";
import SelectSimple from "../_common/SelectSimple"; 
import PageTitle from "../_components/utils/PageTitle";
import { Input, Pagination, Table, Button, Icon } from 'antd';
import { adminuserActions } from "../_actions";
import { store } from "../_helpers";
import CheckBoxSimple from "../_common/CheckBoxSimple";
import axios from "axios";
import { API_URL } from "../_config";
import "react-tabs/style/react-tabs.css";
import Highlighter from 'react-highlight-words';
import {
  Row,
  Col,
  Container,
  Card,
  CardBody
} from "shards-react";

const Formularios = props => {
  const { handleSubmit, handleChange,  pristine, reset, submitting } = props

  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchInput, setSearchInput] = useState('');
 
  useEffect(() => {

    axios({
        method: "get",
        url:
          API_URL + "api/services?token=" + store.getState().authentication.user.token
      }).then(response => {
          
        setData(response.data.data);
      });


  }, [])

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
 
  };
 
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            setSearchInput(node);
          }}
          placeholder={`Buscar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Resetear
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const columns = [

    { 
      title: 'Elementos',
      dataIndex: 'elementos',
      key: 'elementos',
      sorter: (a, b) => a.elementos.localeCompare(b.elementos),
      ...getColumnSearchProps('elementos')
    },
    {
      title: 'Importe por Unidad',
      dataIndex: 'importe',
      key: 'importe',
      sorter: (a, b) => a.importe.localeCompare(b.importe),
      ...getColumnSearchProps('importe')
    },
  ];

 

  return (
    <>
        <Row noGutters className="page-header py-4" style={{ width: "100%" }}>
            <PageTitle sm="5" title="Formularios" className="text-sm-left" />
            <Col sm="5">
           
            </Col>
            <Col sm="2">
        
            </Col>
          </Row>

        <Card>
            <CardBody style={{padding: '0px'}}>
                <div class="table-responsive">
                    <Table columns={columns} locale={{ emptyText: 'Obteniendo datos...' }} dataSource={data} />
                </div> 
            </CardBody>
        </Card>
          
    </>
  )
   
}


function mapState(state, props) {
   
    const { users, authentication, vehicles, adminuser } = state;
  
    const { user } = authentication;

    var initl = {};

    return { user, users, adminuser};
  }
  const actionCreators = {
  
  };

const po = reduxForm({
  form: 'Formularios', 
  enableReinitialize: true
})(Formularios);

const pox = connect(
    mapState,
    actionCreators
  )(po);
  
export { pox as Formularios };