import React, { Component } from "react";
import PropTypes from "prop-types";
//import PropTypes from "prop-types";

class SelectDynamic2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputselect: ""
    };
  }

  //On the change event for the select box pass the selected value back to the parent

  handleChange = event => {
    let selectedValue = event.target.value;
    // this.props.onSelectChange(selectedValue);
    console.log("evento" + selectedValue);

    this.props.callback(selectedValue);
  };

  render() {
    const {
      input,
      meta: { touched, error }
    } = this.props;
    //const { callback } = this.props
    /*const myCallback = (val) => {

            callback(val)

        }*/
    let arrayOfData = this.props.item;
    let selecte = this.props.value ? this.props.value : "";
    if (!arrayOfData || arrayOfData == "" || this.props.onoff == true) {
      return (
        <select
          className="custom-search-select form-control custom-select"
          disabled
        ></select>
      );
    } else {
      let $filter = this.props.filter
        ? this.props.filter.split("|")
        : ["id !", null];

      console.log("filtro :", $filter[1]);
      var options = '';
      if(arrayOfData && arrayOfData.data && arrayOfData.data.list) {
      options = arrayOfData.data.list.map(data => {
     
          return (
            <option key={data.id} value={data.id} selects>
              {data.lavoz_name}
            </option>
          );
        
      });
    }else if(arrayOfData.data) {
      options = arrayOfData.data.map(data => {
     
        return (
          <option key={data.id} value={data.id} selects>
            {data.lavoz_name}
          </option>
        );
      
    });
    }

      return (
        /* <select className="custom-search-select form-control custom-select" onChange={() => callback(this.value)}>
                     {options}
                 </select >
                 
                 */
        <div>
          <select
            {...input}
            className="custom-search-select form-control custom-select"
          >
            <option></option>
            {options}
          </select>
          {touched && error && <span className="red-snow">{error}</span>}
        </div>
      );
    }
  }
}

export default SelectDynamic2;
