import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import {vehiclesActions} from '../../_actions'
import { Modal, Row,Col } from 'antd';
import { Button } from 'shards-react';
import ReactToPrint from 'react-to-print';
import InfoDealer from '../../RedNuestrosAutos/FichaRedNuestrosAutos/SeccionInfo/InfoDealer'
import QRCode from "react-qr-code";
import './ModalQr.css'



class ComponentToPrint extends React.Component {
    render() {
      return (
        <div className="modalQrToPrint">
              <div >
                  <QRCode size={400} value={`https://app.nuestrosautos.com.ar/detalle/${this.props.idCar}`} />
              </div>
              <div >
                  <InfoDealer priceCar="ToPrint" dataInfoCar={this.props.dataCar} buttonContact={false}/>
              </div>
          </div>
      );
    }
  }
   


const ModalQr = ({idCar,modalOpen,modalShowHide}) => {

    const dispatch = useDispatch()

    const state = useSelector(state => state.vehicles.detailVehicle)

    React.useEffect(() => {
        idCar && dispatch(vehiclesActions.getDetailVehicle(idCar))  
    }, [idCar])
    
    const componentRef = useRef();

    return (
        <Modal
        title="Imprimir QR"
        centered
        visible={modalOpen}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onCancel={() => modalShowHide()}
        width={690}
      >
          <Row gutter={8} justify="center" align="middle" className="containerQr">
              {/* DATOS DEL AUTO Y QR */}
              <Col span={12} className="QrGenerate">
              <div >
                  <QRCode size={300} value={`https://app.nuestrosautos.com.ar/detalle/${idCar}`} />
              </div>
              </Col>
              <Col span={12} className="infoDealerQrContainer">
              <div >
                  <InfoDealer dataInfoCar={state.datos} buttonContact={false} create={false}/>
              </div>
              </Col>       
                {/* BOTON DE IMPRIMIR */}
                <div className="ButtonToPrintQR">
                <ReactToPrint trigger={() => <Button theme="primary">Impimir QR</Button>}
                content={() => componentRef.current}/>
                </div>


                {/* COMPONENTE A IMPRIMIR OCULTO */}
                <div className="modalShow">
                    <ComponentToPrint ref={componentRef} dataCar={state.datos} idCar={idCar} />
                </div>
          </Row>
      </Modal>
    )
}

export default ModalQr
