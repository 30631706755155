import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { reduxForm, Field, isDirty, getFormValues } from "redux-form";
import InputField from "../_common/InputField";
import SelectSimple from "../_common/SelectSimple"; 
import { adminuserActions } from "../_actions";
import { store } from "../_helpers";
import { API_URL, PREAPI_URL } from "../_config";

import CheckBoxSimple from "../_common/CheckBoxSimple";
import axios from "axios";
import {
  Alert,
  Row,
  Col,
  Card, 
  CardBody, 
  CardTitle, 
  CardSubtitle,
  Button
} from "shards-react";
import { Table, AutoComplete, Icon} from 'antd';

const ConsUsuario = props => {
  const { handleSubmit, handleChange,  pristine, reset, submitting } = props
 
  const [loading, setLoading] = useState(true);
  const [dlz, setDlz] = useState([{}]); 
  const [dat, setDat] = useState([]);

  const [confirmar, setConfirmar] = useState(false);
  const [confirmarData, setConfirmarData] = useState([]);

  const [list, setList] = useState([]);
  const [messageR, setMessageR] = useState('');

  const usuario = props.userazo;

  const desvincular = (concesionaria) => {
    setLoading(true);

    axios.post(API_URL+'api/user/' + usuario + '/desvincular/' + concesionaria + '?token=' +
    store.getState().authentication.user.token, []).then(response => {
      setLoading(false);
      if(response.data.error == false) {
      setLoading(true);
      get_datos();
      setMessageR('Ok');
      }else{
        setMessageR('Error');
    }
    });
  }
 
  const vincular = (concesionaria) => {

    setLoading(true);

    axios.post(API_URL+'api/user/' + usuario + '/vincular/' + concesionaria + '?token=' +
    store.getState().authentication.user.token, []).then(response => {
      setLoading(false);
      if(response.data.error == false) {
      setConfirmarData([]); 
      setConfirmar(false)
      setLoading(true);
      get_datos();
      setMessageR('Ok');
    }else{
      if(response.data.message == 'account_linked'){
        setConfirmarData([]); 
        setConfirmar(false)
        setMessageR('account_linked');
      }else{
        setMessageR('Error');
    }
    }
    });
    
  }

  const get_datos = () => {


    fetch(
      API_URL+"api/v2/dealer?token=" +
        props.user.token
    )
      .then(response => response.json())
      .then(json => {
        var pre = [];
      json.map(function(item, i){
        pre.push({value: item.id, text: item.name})
      })
      setDat(pre);
        setDlz(json);
       
      });

    axios.post(API_URL+'api/user/' + usuario + '/vinculadas?token=' +
    store.getState().authentication.user.token, []).then(response => {
        var pre = [];
        if(response.data.data && response.data.data != null) {
        response.data.data.map(function(item, i){
          if(item && item != null && item != undefined) {
            pre.push({id: item.id, name: item.name})
          }
        })
        setList(pre);
        }
        setLoading(false);
    });

   
   
  }

  useEffect(() => {
    get_datos();

    return () => {
      setDlz([{}]);
      setDat([]);
      setConfirmar(false);
      setConfirmarData([]);
      setList([]);
    }
  }, []); 
  
  if (loading == true) {
    return (<div style={{height:'500px', textAlign:'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}><img src="/img/loadingnew.gif" style={{maxWidth:'200px', width:'100%'}}/></div>);
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Acción',
      key: 'action',
      render: (text, record) => (
        <>
          <Button onClick={() => { window.confirm("¿Seguro que desea eliminar?") && desvincular(record.id)}} size="sm" theme="light">Desvincular</Button>
        </>
      ),
    },
  ];

  const pre_select = id => {
     // Primero busco los datos de este dealer
     const pro_dealer = dlz.filter(word => word.id == id);
     const el = pro_dealer[0];
     var dealer_selected = {
       id: el.id,
       name: el.name,
       picture: el.cropped_image_url,
       dealerUrl: el.url_company,
       dealerLogo: el.cropped_image_url ? (
         `<img className="user-avatar rounded-circle mr-2"  style="
           max-width: 6.5rem;
           max-height: 2.5rem;
           border-radius: 6px;
           border: 1px solid rgb(238, 238, 238);" 
          src="${el.cropped_image_url}" alt="${el.name}">`
       ) : (
         <p>{el.name}</p>
       )
     };

     setConfirmarData(dealer_selected)
     setConfirmar(true);
     
  }

  return (
    <form onSubmit={handleSubmit}>
       <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.2.0/css/font-awesome.min.css" rel="stylesheet"></link>

        <Alert class="my-4" dismissible={() => {setMessageR('')}} open={messageR == 'Ok'} theme="success">
              Operación Exitosa
        </Alert>

        <Alert class="my-4" dismissible={() => {setMessageR('')}} open={messageR == 'account_linked'} theme="warning">
              La agencia que intentas vincular se encontraba anteriormente vinculada
        </Alert>

        <Alert class="my-4" dismissible={() => {setMessageR('')}} open={messageR == 'error'} theme="danger">
              Error desconocido, por favor intente más tarde o contacte a soporte
        </Alert>     

      <div>
      

        <h6>Vincular concesionaria</h6>
        {confirmar == false ?
          <AutoComplete
            backfill
            optionLabelProp="children"
            dataSource={dat}
            onSelect={pre_select}
            style={{ width: '100%' }}
            filterOption={true}
            placeholder="Nombre de la concesionaria..."
            optionFilterProp="children"
          />
          :
          <Card>
      <CardBody>
        <Row>
          <Col sm="4">
          <img
            src={confirmarData.picture}
            class="rounded w-100"
          ></img>
          
          </Col>
          <Col sm="8">
            <CardTitle className='page-title'>{confirmarData.name}</CardTitle>
            <CardSubtitle className='my-2'>Concesionaria Nro. {confirmarData.id}</CardSubtitle>
            <Row className="w-100">
              <Col sm="6">
                <Button onClick={() => vincular(confirmarData.id)}>Confirmar</Button>
              </Col>
              <Col sm="6">
                <Button theme="dark" onClick={() => {setConfirmarData([]); setConfirmar(false)}}>Cancelar</Button>
              </Col>
            </Row>
         </Col>
        </Row>
      </CardBody>
    </Card>
}
      </div>
      <hr class="my-4"/>
      <div>
        <h6>Concesionarias Vinculadas</h6>
        <Table columns={columns} dataSource={list} pagination={false}/>
      </div>
  
        

      <br/>
         <Button type="submit" disabled={pristine || submitting}>
            Actualizar
        </Button>
    </form>
  )
  
}


function mapState(state, props) {
   
    const { users, authentication, vehicles, adminuser } = state;
  
    const { user } = authentication;

    var initl = {};

    props.activedX.map(function(item, i){
      
      
      initl = Object.assign({
        ['dealer' + item.id]: true
      }, initl);


   });

    return { user, users, adminuser, initialValues: initl};
  }
  const actionCreators = {
   
  };

const po = reduxForm({
  form: 'ConsUsuario', 
  enableReinitialize: true
})(ConsUsuario);

const pox = connect(
    mapState,
    actionCreators
  )(po);
  
export { pox as ConsUsuario };