import React, {useState, useEffect} from 'react'
import axios from 'axios';
import { Table,notification } from 'antd';
import {
  Row,
  Col,
  Card,
  Button as BTX,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from "shards-react";
import { API_URL } from "../../_config";
import { store } from "../../_helpers";
import Cookies from "universal-cookie";
import UpdateFileFeedback from './FileUpdateTeam/UpdateFileFeedback';

const cookies = new Cookies();



const FeedbackClient = () => {
  
  const openNotificationWithIcon = (type,message="Oops! Hemos tenido un error", description) => {
    notification.destroy()
    notification[type]({
      message: `${message}`,
      description: `${description}`,
      className: 'custom-class',
      style: {
        marginTop: "40px",
        zIndex: "999"
      },
      duration: 5,
    });
  };

  const [FeedbackList,setFeedbackList] = useState([])
  useEffect(() => {
    getListUserTeam()
  },[])


  const [showHideTeamState, setShowHideTeamState] = useState(false)
  const [showHideDeleteTeam, setShowHideDeleteTeam] = useState(false)
  const [deleteUserId, setDeleteUserId] = useState(null)
  const [dataUserSelect, setDataUserSelect] = useState(null)

  function getListUserTeam() {
   return axios.get(`${API_URL}api/dealer/${cookies.get("dealer").id}/cardealer/feedback?token=${store.getState().authentication.user.token}`)
    .then(res => setFeedbackList(res.data))
    .catch(error => openNotificationWithIcon("error","Oops! Hemos tenido un error,", "Tuvimos un error intentalo mas tarde."))
  }
  function showHideTeamFn(){
    setShowHideTeamState(!showHideTeamState)
  }

  function deleteTeamId (id){
    if(id){
      return axios.post(`${API_URL}api/dealer/${cookies.get("dealer").id}/cardealer/${id}/feedback/delete?token=${store.getState().authentication.user.token}`)
      .then(res =>{
        setShowHideDeleteTeam(false)
        getListUserTeam()
        openNotificationWithIcon("success","Cliente Eliminado","Su cliente se elimino correctamente.")
      })
      .catch(error => {
        setShowHideDeleteTeam(false)
        openNotificationWithIcon("error","Error al eliminar","Tuvimos un problema intentalo mas tarde.")
      })
    }
    return;
  }
  const columns = [
      {
        title: '#',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Nombre',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: 'Cliente/Empresa',
        dataIndex: 'empresa',
        key: 'empresa',
        
      },
      {
        title: 'Feedback',
        dataIndex: 'descripcion',
        key: 'descripcion',
        
      },
      {
        title: "Acción",
        dataIndex: "id",
        key: "accion",
        render: (text,record) => (
          <div className="pof" style={{ padding: "0.45rem !important" }}>
            <i
              className="material-icons"
              title="Editar"
              style={{ cursor: "pointer", display: "inline", fontSize: "23px" }}
              onClick={() => {
                setDataUserSelect(record)
                showHideTeamFn()
              } }
            >
              edit
            </i>
            <i
              className="material-icons"
              title="Eliminar"
              style={{ cursor: "pointer", display: "inline", fontSize: "23px" }}
              onClick={() => {
                setShowHideDeleteTeam(true)
                setDeleteUserId(record.id)
              }}
            >
              delete
            </i>
          </div>
        )
      }
  ]
  return (
    <>
    <Card>
      <CardBody>
        <Row>
          <Col sm="9">
          <h4 style={{display:'inline'}}>Mis clientes</h4>
          </Col>
          <Col sm="3">
          <BTX onClick={showHideTeamFn}>
           Agregar Feedback
          </BTX>
          </Col>
      </Row>
        <div style={{paddingTop: '15px'}}>
         <div class="table-responsive">
              <Table rowKey="id" columns={columns} locale={{ emptyText: FeedbackList.length > 0 ? "Obteniendo clientes...":"No hay clientes"  }} dataSource={FeedbackList} pagination={false} />
        </div>
        </div>
          </CardBody>
      </Card>
      <UpdateFileFeedback showHideTeamFn={showHideTeamFn} getListUserTeam={getListUserTeam} setUserData={setDataUserSelect} showHideTeamState={showHideTeamState} editDataUser={dataUserSelect} />
      <Modal open={showHideDeleteTeam}>
    <ModalHeader>Eliminar cliente</ModalHeader>
    <ModalBody >
      <p>¿Seguro que quieres elimirar este cliente?</p>
    </ModalBody>
    <ModalFooter>
      <button className="btn btn-secondary" onClick={() => setShowHideDeleteTeam(false)} >Cancelar</button>
    <BTX onClick={() => deleteUserId &&  deleteTeamId(deleteUserId)}>Eliminar</BTX>
    </ModalFooter >
      </Modal>
    </>
  )
}

export default FeedbackClient