import React from "react";
import Cookies from "universal-cookie";
import CanvaEditFeedback from "./CanvaEditFeedback";
import axios from "axios";
import { API_URL } from "../../../_config";
import { store } from "../../../_helpers";

import { notification, Alert, Icon } from 'antd';
import { Modal, ModalBody, ModalHeader } from 'shards-react';
import "react-images-uploader/font.css";
const cookies = new Cookies();

class UpdateFileFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.setEditorRef = this.setEditorRef.bind(this);
    this.handleZoomSlider = this.handleZoomSlider.bind(this);
    this.hanbleSubmitData = this.hanbleSubmitData.bind(this);
    this.hanbleSubmitDataEdit = this.hanbleSubmitDataEdit.bind(this);
    this.cancelAll = this.cancelAll.bind(this);
    this.inputsData= this.inputsData.bind(this);
    this.state = {
      cropperOpen: false,
      img :"",
      zoom: 0.08,
      dataForm : {
        original_image_url: null,
        car_dealer_id : cookies.get("dealer").id,
      },
      dataFormEdit : {}
    };
  }


  componentWillReceiveProps(nextProps){
      this.setState({dataFormEdit : nextProps.editDataUser  })
  }

  cancelAll(){
    this.setState({
      cropperOpen: false,
      img :"",
      zoom: 0.05,
      dataForm : {
        original_image_url: null ,
        car_dealer_id : cookies.get("dealer").id,
      }
    })
    this.props.showHideTeamFn()
    this.props.setUserData(null)
  }
  handleZoomSlider(value) {
    let state = this.state;
    state.zoom = value;
    this.setState(state);
  }

  handleFileChange(e) {

    const openNotificationWithIcon = (type, description) => {
      notification.destroy()
      notification[type]({
        message: 'Error al subir imagen',
        description: `${description}`,
        className: 'custom-class',
        style: {
          marginTop: "40px",
          zIndex: "999"
        },
        duration: 5,
      });
    };

    let UploadFile = e.target.files[0]

    if (!(/\.(jpg|jpeg|png|gif|svg|webp)$/i).test(UploadFile.name)) {
      this.cancelAll()
      return openNotificationWithIcon('error', 'El archivo a adjuntar no es una imagen, selecciona un archivo valido(JPG, PNG, GIF, SVG, WEBP).')
    } else {
      
      let img = new Image();
      
      if (UploadFile.size > 1048576) {
        this.cancelAll()
        return openNotificationWithIcon('error', 'El peso de la imagen no puede exceder los 1MB!')
      }
      else {
        img.src = URL.createObjectURL(UploadFile);
        let state = this.state;
        state.img = img.src;
        state.cropperOpen = true;
        
        this.setState(state);
      }
    }
  }
  handleSave(e) {
    const canvasScaled = this.editor.getImageScaledToCanvas();
    const croppedImg = canvasScaled.toDataURL();
    let state = this.state;
    state.img = null;
    state.cropperOpen = false;
    state.dataForm.original_image_url = croppedImg;
    // state.dataFormEdit.picture = croppedImg;
    this.setState(state);
  }
  handleCancel() {
    let state = this.state;
    state.cropperOpen = false;
    this.setState(state);
  };

  setEditorRef(editor) {
    this.editor = editor;
  }

  inputsData(e){
    this.setState({dataForm : {...this.state.dataForm ,[e.target.name]: e.target.value }})
  }
  
  hanbleSubmitData(e){
    e.preventDefault()
    const openNotificationWithIcon = (type,message="Error al subir imagen", description) => {
      notification.destroy()
      notification[type]({
        message: `${message}`,
        description: `${description}`,
        className: 'custom-class',
        style: {
          marginTop: "40px",
          zIndex: "999"
        },
        duration: 5,
      });
    };
    
    axios
    .post(
      API_URL + 'api/dealer/' + cookies.get("dealer").id + '/cardealer/feedback?token=' +
      store.getState().authentication.user.token,this.state.dataForm)
      .then(response => {
        if(response.status === 200){
          openNotificationWithIcon('success','Nuevo cliente', 'Su nuevo cliente se guardo correctamente.')
          this.props.getListUserTeam()
        }else{
          openNotificationWithIcon('error', 'Hemos tenido un error, intenta nuevamente.')
        }
      }).catch((err) => {
        openNotificationWithIcon('error', `Hemos tenido un error ${err.response}, intenta nuevamente.`)
        this.cancelAll()
      })
      this.cancelAll()
  }
  hanbleSubmitDataEdit(e){
    const openNotificationWithIcon = (type,message, description) => {
      notification.destroy()
      notification[type]({
        message: `${message}`,
        description: `${description}`,
        className: 'custom-class',
        style: {
          marginTop: "40px",
          zIndex: "999"
        },
        duration: 5,
      });
    };
    e.preventDefault()
    const dataEdit = this.state.dataFormEdit &&  {
      car_dealer_id : this.state.dataFormEdit.car_dealer_id,
      id: this.state.dataFormEdit.id,
      name : this.state.dataFormEdit.name,
      descripcion : this.state.dataFormEdit.descripcion,
      empresa : this.state.dataFormEdit.empresa,
      original_image_url : this.state.dataForm.original_image_url ?  this.state.dataForm.original_image_url : null,
    }
    axios
    .post(
      API_URL + 'api/dealer/' + cookies.get("dealer").id + '/cardealer/'+ this.state.dataFormEdit.id + '/feedback?token=' +
      store.getState().authentication.user.token,dataEdit)
      .then(response => {
        if(response.status === 200){
            openNotificationWithIcon('success','Cliente Editado', 'Su cliente se edito correctamente.')
            this.props.getListUserTeam()
          }else{
              openNotificationWithIcon('error', 'Hemos tenido un error, intenta nuevamente.')
            }
          }).catch((error) => {
        openNotificationWithIcon('error', `Hemos tenido un error ${error.response.status}, intenta nuevamente.`)
        this.cancelAll()
      })
      this.cancelAll()
  }
  render() {
    return (
      <Modal size="lg" open={this.props.showHideTeamState} >
         <ModalHeader>{this.props.editDataUser ? <>Editar Cliente </> : <>Nuevo Cliente</> } <i style={{position:'absolute', right: '20px', top: '15px', fontSize: '25px', cursor: 'pointer'}} class="material-icons" onClick={this.cancelAll}>clear</i></ModalHeader>
        <ModalBody >
          <div className="d-flex">
        {this.state.dataForm.original_image_url && (
                <div className="mb-4">
                  <p>Nueva Imagen</p>
                  <img
                    src={this.state.dataForm.original_image_url }
                    style={{ width: "200px" , height :"200px", borderRadius: "50%" }}
                  />
                </div>
              )}
              {this.props.editDataUser && this.props.editDataUser.original_image_url && (
                <div className="mb-4 ml-2">
                  <p>Imagen Actual</p>
                  <img
                    src={this.props.editDataUser.original_image_url}
                    style={{ width: "200px" , height :"200px", borderRadius: "50%" }}
                  />
                </div>
              )}
          </div>
        <div>
        {this.state.cropperOpen ? (
                  <CanvaEditFeedback scaleImage={this.state.zoom} open={this.state.cropperOpen} setEditorRef={this.setEditorRef} state={this.state} handleZoomSlider={this.handleZoomSlider} handleCancel={this.handleCancel} handleSave={this.handleSave} />
                ) : (
                  <div>
                  <label>
                    <input
                      ref="in"
                      type="file"
                      accept="image/*"
                      onChange={this.handleFileChange}
                      />
                  </label>
              </div>
                ) }
             
          </div>
          {this.props.editDataUser ? (
          <>
            <form onSubmit={this.hanbleSubmitDataEdit}>
             {/* Name */}
             <div style={{marginBottom: 10}}>
               <label style={{marginBottom: 1}}>Nombre Completo:</label>
               <div>
                 <input onChange={(e) => this.setState({dataFormEdit : {...this.state.dataFormEdit ,name : e.target.value} })} name="name" defaultValue={this.props.editDataUser && this.props.editDataUser.name } placeholder="Nombre Completo" className="form-control" type="text" required />
               </div>
           </div>
             {/* Descripción */}
             <div style={{marginBottom: 10}}>
               <label style={{marginBottom: 1}}>Descripción:</label>
               <div>
                 <input name="email" onChange={(e) => this.setState({dataFormEdit : {...this.state.dataFormEdit ,descripcion: e.target.value} })} defaultValue={this.props.editDataUser && this.props.editDataUser.descripcion } placeholder="Descripción" className="form-control" type="text" required />
               </div>
           </div>
             {/* Empresa/Cliente */}
             <div style={{marginBottom: 10}}>
               <label style={{marginBottom: 1}}>Empresa/Cliente:</label>
               <div>
                 <input  placeholder="Empresa/Cliente" name="empresa"  defaultValue={this.props.editDataUser && this.props.editDataUser.empresa } onChange={(e) => this.setState({dataFormEdit : {...this.state.dataFormEdit ,empresa : e.target.value} })} className="form-control" type="text" required />
               </div>
           </div>
           {this.state.cropperOpen === false && (
           <div className="mb-4">
              <button className="btn btn-primary" type="submit">
             Guardar
             </button>

              <button className="btn btn-secondary ml-2" onClick={this.cancelAll}>
             Cancelar
             </button>
           </div>
           )  }
             </form>
          </>
          ):(
             <form onSubmit={this.hanbleSubmitData}>
             {/* Name */}
             <div style={{marginBottom: 10}}>
               <label style={{marginBottom: 1}}>Nombre Completo:</label>
               <div>
                 <input onChange={this.inputsData} name="name" placeholder="Nombre Completo" className="form-control" type="text" required />
               </div>
           </div>
             {/* Descripcion */}
             <div style={{marginBottom: 10}}>
               <label style={{marginBottom: 1}}>Descripción:</label>
               <div>
                 <input name="descripcion" onChange={this.inputsData} placeholder="Descripción" className="form-control" type="text" required />
               </div>
           </div>
             {/* Empresa/Cliente */}
             <div style={{marginBottom: 10}}>
               <label style={{marginBottom: 1}}>Empresa/Cliente:</label>
               <div>
                 <input  placeholder="Empresa/Cliente" name="empresa" onChange={this.inputsData} className="form-control" type="text" required />
               </div>
           </div>
           {this.state.cropperOpen === false && (
           <div className="mb-4">
              <button className="btn btn-primary" type="submit">
             Guardar
             </button>

              <button className="btn btn-secondary ml-2" onClick={this.cancelAll}>
             Cancelar
             </button>
           </div>
           )  }
             </form>
          )}
        </ModalBody>
          </Modal>
    );
  }
}
export default UpdateFileFeedback
