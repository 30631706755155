import { loadingGlobalConstants } from "../_constants";

const initialState = {
  loadingGlobal: false
};

export function loadingGlobal(state = initialState, action) {
  switch (action.type) {
    case loadingGlobalConstants.LOADING_SHOW:
      console.log("FUNCALOADING");
      return { ...state, loadingGlobal: true };
    case loadingGlobalConstants.LOADING_HIDE:
      console.log("FUNCALOADING");
      return { ...state, loadingGlobal: false };
    default:
      return state;
  }
}
