import React from 'react'
import { Modal, ModalBody, ModalHeader,ModalFooter } from 'shards-react';
import AvatarEditor from "react-avatar-editor";
import { Alert, Slider } from "antd";


const ModalCanvaEdit = ({open,setEditorRef,state,handleZoomSlider,handleCancel,handleSave,scaleImage}) => {
  return (
    <>
      <Modal open={open}>
            <ModalHeader>Recortar Imagen</ModalHeader>
            <ModalBody style={{background : "#f9f9f9"}}>
              <div className="pb-4">
            <Alert
              message="¡Atención!"
              description="Las medidas de las imagenes a subir deben ser: 600 * 400, para tener una mejor calidad."
              type="warning"
              showIcon
              closable
                />
              </div>
            <div
              className="cropper-wrapper"
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <AvatarEditor
                ref={setEditorRef}
                image={state.img}
                width={400}
                height={310}
                border={50}
                color={[150, 150, 160, 0.3]} // RGBA
                rotate={0}
                scale={state.zoom}
              />
              <div
              className="mt-4"
              >
                <h6>
                  Zoom  Scale : {scaleImage} 
                </h6>
                <Slider
                  min={-1} 
                  max={10}
                  value={state.zoom}
                  step={0.1}
                  onChange={handleZoomSlider}
                  style={{ width: 200 }}
                />
              </div>
            </div>
              </ModalBody>
              <ModalFooter>
              <div>
                <button className="btn btn-secondary "
                  onClick={handleCancel}
                >Cancelar</button>
                <button  className="btn btn-primary ml-4"
                  onClick={handleSave}
                >Guardar</button>
              </div>
              </ModalFooter>
          </Modal>
    </>
  )
}

export default ModalCanvaEdit
