import React from 'react'
import { Form, reduxForm, Field } from "redux-form";
import InputField from "../_common/InputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import './LinkingPage.css';

const LinkInstagram = props => {
  const { handleSubmit, pristine, reset, submitting } = props

  return (
    <Form onSubmit={handleSubmit}>
      
        <Field
            name="user"
            component={InputField}
            type="text"
            label="Usuario de Instagram"
          />
      
        <Field
            name="password"
            component={InputField}
            type="password"
            label="Contraseña"
          />
    
        <button type="submit" className="btn btn-primary" disabled={pristine || submitting}>
          <FontAwesomeIcon
                type="submit" disabled={pristine || submitting}
                icon={faArrowRight}
              />
        </button>
         
       
    </Form>
  )
}

export default reduxForm({
  form: 'linkinstagram' 
})(LinkInstagram)