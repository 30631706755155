import React from 'react';
import './style.css';
const TextareaField = ({ input, label, type, meta: { touched, error } }) => (
    <div style={{marginBottom: 10}}>
        <label style={{marginBottom: 1}}>{label}</label>
        <div>
            <textarea {...input} rows={3} placeholder={label} className="form-control" type={type} ></textarea>
            {touched && error && <span className="red-snow">{error}</span>}
        </div>
    </div>
);

export default TextareaField;
