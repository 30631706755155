import React from 'react'
import { reduxForm, Field, getFormValues } from "redux-form";
import { connect } from "react-redux";
import InputField from "../_common/InputField";
import SelectSimple from "../_common/SelectSimple";
import {
  Row,
  Col,
  Card,
  Button
} from "shards-react";
const required = value => value ? undefined : 'Required'
const CrearABMFormulario = props => {
  const { handleSubmit, pristine, reset, submitting } = props
  return (
    <form onSubmit={handleSubmit}>
      <div>
        
          <Field
            name="elementos"
            component={InputField}
            type="text"
            label="Elementos"
          />
          <Field
            name="importe"
            component={InputField}
            type="text"
            label="Importe por unidad"
          />
           <Field
                    name="type"
                    label="Categoría"
                    children={[
                      ["1", "Formularios"],
                    ]}
                    validate={[ required ]}
                    component={SelectSimple}
                  ></Field>
        
  
        </div>
  
     
        <br/>
        <Button type="submit"  disabled={pristine || submitting}>
            Actualizar
        </Button>
    </form>
  )
}

function mapState(state, props) {
   
  const { users, authentication, vehicles, adminuser } = state;

  const { user } = authentication;

  if(props.activedX) {
    return { user, users, adminuser, initialValues: props.activedX};
  }else{
    return { user, users, adminuser};
  }
}
const actionCreators = {

};

const po = reduxForm({
form: 'CrearABMFormulario', 
enableReinitialize: true
})(CrearABMFormulario);

const pox = connect(
  mapState,
  actionCreators
)(po);

export { pox as CrearABMFormulario };

