import React, {useState, useEffect} from 'react'
import { reduxForm, Field, getFormValues } from "redux-form";
import { connect } from "react-redux";
import axios from 'axios';
import { store } from "../_helpers";
import InputField from "../_common/InputField";
import SelectSimple from "../_common/SelectSimple";
import SelectSimpleParser from "../_common/SelectSimpleParser";
import SelectDynamic from "../_common/SelectSimple";
import CheckBoxSimple from "../_common/CheckBoxSimple";
import { API_URL } from "../_config";
import {
  Row,
  Col,
  Card,
} from "shards-react";

var provincia = undefined;

const required = value => value ? undefined : 'Ese campo es requerido'

const CrearServicio = props => {
 
    
    const [provincias, setProvincias] = useState([]);
    const [partidos, setPartido] = useState([]);
    const [barrios, setBarrio] = useState([]);

    useEffect(() => {
        axios({
            method: "get",
            url:
              API_URL + "api/region?token=" +
              store.getState().authentication.user.token
          }).then(response => {
            setProvincias(response.data);
          });

  }, []);

    const call_partido = val => {
        axios({
            method: "get",
            url:
              API_URL + "api/region-city/" + val
          }).then(response => {
            setPartido(response.data);
          });
    }
    const call_barrio = val => {
        axios({
            method: "get",
            url:
              API_URL + "api/region-state-city/" + val
          }).then(response => {
            setBarrio(response.data);
          });
    }


  const { handleSubmit, pristine, reset, submitting, error} = props
  return (
    <form onSubmit={handleSubmit}>
      <div>
      
        
          <Field
            name="name"
            component={InputField}
            type="text"
            label="Nombre del Servicio"
          />
          
        
        </div>
  
        <br/>
        {error && <strong>{error}</strong>}
        <button className="btn btn-primary" type="submit" disabled={submitting}>
            Crear nuevo servicio
        </button>
    </form>
  )
}

function mapState(state, props) {
   
  const { users, authentication, vehicles, adminuser } = state;

  const { user } = authentication;

  if(props.activedX) {
    return { user, users, adminuser, initialValues: props.activedX};
  }else{
    return { user, users, adminuser};
  }
}
const actionCreators = {

};

const po = reduxForm({
form: 'CrearServicio', 
enableReinitialize: true
})(CrearServicio);

const pox = connect(
  mapState,
  actionCreators
)(po);

export { pox as CrearServicio };

