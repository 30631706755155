import React, { useState, useEffect } from "react";

import { NavLink as RouteNavLink } from "react-router-dom";
import { NavItem, NavLink } from "shards-react";
import { useTrail, animated as a } from "react-spring";
import { Collapse } from 'antd';

import {perm_validate} from "../../../_helpers/perm_validate"
const config = { mass: 5, tension: 2000, friction: 200 };
const { Panel } = Collapse;
function NewSideNavItem(props) {

  console.log(props);
  console.log('guasito')
  const { items } = props;
  const [toggle, set] = useState(true);
  const trail = useTrail(items.length, {
    config,
    opacity: toggle ? 1 : 0,
    x: toggle ? 0 : 20,
    height: toggle ? 80 : 0,
    from: { opacity: 0, x: 20, height: 0 }
  });

  useEffect(() => {
    set(state => !state);
  }, [props.items]);
  return (
    <div className="trails-main">
       
       {perm_validate('Dashboard','list') == true ? (
       <Collapse accordion>
        {trail.map(({ x, height, ...rest }, index) => (
      
      items[index].main == "alone0" ? (

    
       
        perm_validate(items[index].categoria, items[index].permiso) == true || items[index].forever == true? (

  items[index].external == true ? (

    <li class="nav-item"><a class="nav-link" target="_blank" href={items[index].to}>
    {items[index].htmlBefore && (
    <div
      className="d-inline-block item-icon-wrapper"
      dangerouslySetInnerHTML={{ __html: items[index].htmlBefore }}
    />
  )}
  {items[index].title && <span>{items[index].title}</span>}
  {items[index].htmlAfter && (
    <div
      className="d-inline-block item-icon-wrapper"
      dangerouslySetInnerHTML={{ __html: items[index].htmlAfter }}
    />
  )}
  </a>
  </li>
  ):(  
  
  <NavItem key={index}>
  <NavLink onClick={props.movida} tag={RouteNavLink} to={items[index].to}>
    {items[index].htmlBefore && (
      <div
        className="d-inline-block item-icon-wrapper"
        dangerouslySetInnerHTML={{ __html: items[index].htmlBefore }}
      />
    )}
    {items[index].title && <span>{items[index].title}</span>}
    {items[index].htmlAfter && (
      <div
        className="d-inline-block item-icon-wrapper"
        dangerouslySetInnerHTML={{ __html: items[index].htmlAfter }}
      />
    )}
  </NavLink>
</NavItem>
  )):(
  <></>)
 ):(
  <></>)
    ))}
    {trail.map(({ x, height, ...rest }, index) => (
      
      items[index].main == "alone1" ? (

    
       
        perm_validate(items[index].categoria, items[index].permiso) == true || items[index].forever == true? (

  items[index].external == true ? (

    <li class="nav-item"><a class="nav-link" target="_blank" href={items[index].to}>
    {items[index].htmlBefore && (
    <div
      className="d-inline-block item-icon-wrapper"
      dangerouslySetInnerHTML={{ __html: items[index].htmlBefore }}
    />
  )}
  {items[index].title && <span>{items[index].title}</span>}
  {items[index].htmlAfter && (
    <div
      className="d-inline-block item-icon-wrapper"
      dangerouslySetInnerHTML={{ __html: items[index].htmlAfter }}
    />
  )}
  </a>
  </li>
  ):(  
  
  <NavItem key={index}>
  <NavLink onClick={props.movida} tag={RouteNavLink} to={items[index].to}>
    {items[index].htmlBefore && (
      <div
        className="d-inline-block item-icon-wrapper"
        dangerouslySetInnerHTML={{ __html: items[index].htmlBefore }}
      />
    )}
    {items[index].title && <span>{items[index].title}</span>}
    {items[index].htmlAfter && (
      <div
        className="d-inline-block item-icon-wrapper"
        dangerouslySetInnerHTML={{ __html: items[index].htmlAfter }}
      />
    )}
  </NavLink>
</NavItem>
  )):(
  <></>)
 ):(
  <></>)
    ))}

<Panel header="Reportes" key="1">
    {trail.map(({ x, height, ...rest }, index) => (
      
      items[index].main == "reportes" ? (

    
       
        perm_validate(items[index].categoria, items[index].permiso) == true || items[index].forever == true? (

  items[index].external == true ? (

    <li class="nav-item"><a class="nav-link" target="_blank" href={items[index].to}>
    {items[index].htmlBefore && (
    <div
      className="d-inline-block item-icon-wrapper"
      dangerouslySetInnerHTML={{ __html: items[index].htmlBefore }}
    />
  )}
  {items[index].title && <span>{items[index].title}</span>}
  {items[index].htmlAfter && (
    <div
      className="d-inline-block item-icon-wrapper"
      dangerouslySetInnerHTML={{ __html: items[index].htmlAfter }}
    />
  )}
  </a>
  </li>
  ):(  
  
  <NavItem key={index}>
  <NavLink onClick={props.movida} tag={RouteNavLink} to={items[index].to}>
    {items[index].htmlBefore && (
      <div
        className="d-inline-block item-icon-wrapper"
        dangerouslySetInnerHTML={{ __html: items[index].htmlBefore }}
      />
    )}
    {items[index].title && <span>{items[index].title}</span>}
    {items[index].htmlAfter && (
      <div
        className="d-inline-block item-icon-wrapper"
        dangerouslySetInnerHTML={{ __html: items[index].htmlAfter }}
      />
    )}
  </NavLink>
</NavItem>
  )):(
  <></>)
 ):(
  <></>)
    ))}

    </Panel>
    <Panel header="Admin" key="2">
    

    {trail.map(({ x, height, ...rest }, index) => (
      
      items[index].main == "admin" ? (

    
       
        perm_validate(items[index].categoria, items[index].permiso) == true || items[index].forever == true? (

  items[index].external == true ? (

    <li class="nav-item"><a class="nav-link" target="_blank" href={items[index].to}>
    {items[index].htmlBefore && (
    <div
      className="d-inline-block item-icon-wrapper"
      dangerouslySetInnerHTML={{ __html: items[index].htmlBefore }}
    />
  )}
  {items[index].title && <span>{items[index].title}</span>}
  {items[index].htmlAfter && (
    <div
      className="d-inline-block item-icon-wrapper"
      dangerouslySetInnerHTML={{ __html: items[index].htmlAfter }}
    />
  )}
  </a>
  </li>
  ):(  
  
  <NavItem key={index}>
  <NavLink onClick={props.movida} tag={RouteNavLink} to={items[index].to}>
    {items[index].htmlBefore && (
      <div
        className="d-inline-block item-icon-wrapper"
        dangerouslySetInnerHTML={{ __html: items[index].htmlBefore }}
      />
    )}
    {items[index].title && <span>{items[index].title}</span>}
    {items[index].htmlAfter && (
      <div
        className="d-inline-block item-icon-wrapper"
        dangerouslySetInnerHTML={{ __html: items[index].htmlAfter }}
      />
    )}
  </NavLink>
</NavItem>
  )):(
  <></>)
 ):(
  <></>)
    ))}


    </Panel>
    <Panel header="Servicios" key="3">
     
    {trail.map(({ x, height, ...rest }, index) => (
      
      items[index].main == "servicios" ? (

    
       
        perm_validate(items[index].categoria, items[index].permiso) == true || items[index].forever == true? (

  items[index].external == true ? (

    <li class="nav-item"><a class="nav-link" target="_blank" href={items[index].to}>
    {items[index].htmlBefore && (
    <div
      className="d-inline-block item-icon-wrapper"
      dangerouslySetInnerHTML={{ __html: items[index].htmlBefore }}
    />
  )}
  {items[index].title && <span>{items[index].title}</span>}
  {items[index].htmlAfter && (
    <div
      className="d-inline-block item-icon-wrapper"
      dangerouslySetInnerHTML={{ __html: items[index].htmlAfter }}
    />
  )}
  </a>
  </li>
  ):(  
  
  <NavItem key={index}>
  <NavLink onClick={props.movida} tag={RouteNavLink} to={items[index].to}>
    {items[index].htmlBefore && (
      <div
        className="d-inline-block item-icon-wrapper"
        dangerouslySetInnerHTML={{ __html: items[index].htmlBefore }}
      />
    )}
    {items[index].title && <span>{items[index].title}</span>}
    {items[index].htmlAfter && (
      <div
        className="d-inline-block item-icon-wrapper"
        dangerouslySetInnerHTML={{ __html: items[index].htmlAfter }}
      />
    )}
  </NavLink>
</NavItem>
  )):(
  <></>)
 ):(
  <></>)
    ))}


    </Panel>
    
  </Collapse>
  ):(
    <>
     {trail.map(({ x, height, ...rest }, index) => (
      
      1 == 1 ? (

    
       
        perm_validate(items[index].categoria, items[index].permiso) == true || items[index].forever == true? (

  items[index].external == true ? (

    <li class="nav-item"><a class="nav-link" target="_blank" href={items[index].to}>
    {items[index].htmlBefore && (
    <div
      className="d-inline-block item-icon-wrapper"
      dangerouslySetInnerHTML={{ __html: items[index].htmlBefore }}
    />
  )}
  {items[index].title && <span>{items[index].title}</span>}
  {items[index].htmlAfter && (
    <div
      className="d-inline-block item-icon-wrapper"
      dangerouslySetInnerHTML={{ __html: items[index].htmlAfter }}
    />
  )}
  </a>
  </li>
  ):(  
  
  <NavItem key={index}>
  <NavLink onClick={props.movida} tag={RouteNavLink} to={items[index].to}>
    {items[index].htmlBefore && (
      <div
        className="d-inline-block item-icon-wrapper"
        dangerouslySetInnerHTML={{ __html: items[index].htmlBefore }}
      />
    )}
    {items[index].title && <span>{items[index].title}</span>}
    {items[index].htmlAfter && (
      <div
        className="d-inline-block item-icon-wrapper"
        dangerouslySetInnerHTML={{ __html: items[index].htmlAfter }}
      />
    )}
  </NavLink>
</NavItem>
  )):(
  <></>)
 ):(
  <></>)
    ))}

    </>
  )}
    </div>
  );
}

export default NewSideNavItem;
