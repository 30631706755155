import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { reduxForm, Field, isDirty, getFormValues } from "redux-form";
import InputField from "../_common/InputField";
import SelectSimple from "../_common/SelectSimple"; 
import PageTitle from "../_components/utils/PageTitle";
import { Input, Pagination, Table, Alert, Button, Icon } from 'antd';
import { adminuserActions } from "../_actions";
import { store } from "../_helpers";
import CheckBoxSimple from "../_common/CheckBoxSimple";
import {TextareaSimple} from "../_common";
import axios from "axios";
import { API_URL } from "../_config";
import "react-tabs/style/react-tabs.css";
import Highlighter from 'react-highlight-words';

import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader
} from "shards-react";

const Form = props => {
  const { handleSubmit, handleChange,  pristine, reset, submitting } = props

  const [data, setData] = useState([]);
  const [pDisabled, setPDisabled] = useState(true);

  useEffect(() => {

    axios({
        method: "get",
        url:
          API_URL + "api/service_informes?token=" + store.getState().authentication.user.token
      }).then(response => {
        setData(response.data.data);
        setPDisabled(false);
      });


  }, [])


  return (
        <Card>
            <CardHeader>
                Datos del Solicitante
            </CardHeader>
            <CardBody>
                <form onSubmit={handleSubmit}>
                <h4 style={{borderBottom: '2px solid rgb(142, 141, 141)', color: 'rgb(95, 95, 95)', textTransform: 'unset', fontWeight: '350', borderRadius: '1px'}}>Ingrese el nombre de la Concesionaria</h4>
 
                <Field
            name="sociocca"
            component={InputField}
            type="text"
            label="Razón Social"
          />

           <Field
            name="solicitante"
            component={InputField}
            type="text"
            label="Solicitante"
          />

           <Field
            name="email"
            component={InputField}
            type="email"
            label="Email"
          />

            <h4 style={{marginTop: '25px', marginBottom: '10px', borderBottom: '2px solid rgb(142, 141, 141)', color: 'rgb(95, 95, 95)', textTransform: 'unset', fontWeight: '350', borderRadius: '1px'}}>Seleccione el tipo de informe</h4>
            <label>Categoría</label>
            <div>
            <Field name="tipo" component='select' disabled={pDisabled} className="form-control">
                {data.map(function(item, i){
                    if(item.hidden != true) {
                        return <option value={item.elementos} key={i}>{item.elementos + ' ($ ' + item.importe + ')'}</option>
                    }
                })}
            </Field>
          </div>

           
            <p style={{margin: '0px', marginTop: '25px'}}>Adicionales:</p>
            
            
            <Field
            name="adicional2"
            component={'input'}
            type="checkbox"
          /><label>Informe de Infracciones</label>

           <h4 style={{marginTop: '25px', marginBottom: '10px', borderBottom: '2px solid rgb(142, 141, 141)', color: 'rgb(95, 95, 95)', textTransform: 'unset', fontWeight: '350', borderRadius: '1px'}}>Observaciones</h4>
           <Field
            name="notas"
            component={TextareaSimple}
            type="text"
            label="Notas"
          />


<button type="submit" className="btn btn-primary mt-3"  disabled={submitting}>
            Guardar
        </button>
            </form>
            </CardBody>
        </Card>
        
  )
   
}


function mapState(state, props) {
   
    const { users, authentication, vehicles, adminuser } = state;
  
    const { user } = authentication;

    var initl = {};

    return { user, users, adminuser};
  }
  const actionCreators = {
  
  };

const po = reduxForm({
  form: 'Form', 
  enableReinitialize: true
})(Form);

const pox = connect(
    mapState,
    actionCreators
  )(po);
  
export { pox as Form };