import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import axios from "axios";
import RangeDatePicker from "../utils/RangeDatePicker";
import Chart from "../utils/chart";
import Cookies from "universal-cookie";
import { store } from "../../_helpers";
import { API_URL } from "../../_config";

class CanalTotalMensual extends React.Component {


  state = {
    response: [],
    data_enabled: false
  }


  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    const chartOptions = {
      ...{
        legend: false
      },
      ...this.props.chartOptions
    };

    const cookies = new Cookies();
    if(cookies.get("dealer")) {
    axios.get(API_URL + 'dashboard?token=' + store.getState().authentication.user.token).then(response => {
      this.setState({response: response, data_enabled: true}); 
    var datazo = response.data.totale;
console.log(response.data.totales.IG);
      const final =  {
        labels: [
          "Instagram",
          "MercadoLibre",
          "DeMotores",
          "Facebook",
          "NuestrosAutos",
          "OLX",
          "AutoCosmos",
          "LaVoz"
        ],
        datasets: [
          {
            label: "Canales",
            fill: "start",
            data: [response.data.totales.IG, response.data.totales.ML, response.data.totales.DM, response.data.totales.FB, response.data.totales.NA, response.data.totales.OX, response.data.totales.AC, response.data.totales.LV],
            backgroundColor: [
              "rgb(219, 203, 16)",
              "rgb(219, 56, 17)",
              "rgb(254, 152, 1)",
              "rgb(2, 151, 196)",
              "rgb(50, 101, 202)",
              "rgb(152, 1, 152)",
              "rgb(15, 149, 23)",
              "rgb(16, 149, 23)"
            ],
            pointBackgroundColor: "#ffffff",
            pointHoverBackgroundColor: "rgb(0,123,255)",
            borderWidth: 0,
            pointRadius: 0,
            pointHoverRadius: 3
          }
        ]
      };

    if (final.datasets != null && final.datasets.length < 1) {
     window.location.reload();
    }

    const BlogUsersOverview = new Chart(this.canvasRef.current, {
      type: "pie",
      data: final,
      options: chartOptions
    });

  
    // They can still be triggered on hover.
    const buoMeta = BlogUsersOverview.getDatasetMeta(0);
    buoMeta.data[0]._model.radius = 0;
    buoMeta.data[
      final.datasets[0].data.length - 1
    ]._model.radius = 0;
    BlogUsersOverview.render();
  });
}
    // Render the chart.
    
  }

  render() {
    const { title } = this.props;
    const { response } = this.state;
    return (
      <div style={{paddingBottom: '29px'}}>
        <br />
        <div style={{margin: 0}}>
          <div style={{paddingLeft: 20, paddingRight: 20}}>
            {this.state.data_enabled == true &&
            <>
           
           <p className="itemDash itemFB">{response.data.totales_historico.FB}</p> 
              
             
              <p className="itemDash itemOLX">{response.data.totales_historico.OX}</p> 
           
         
              <p className="itemDash itemAC">{response.data.totales_historico.AC}</p> 
           
        
              <p className="itemDash itemDM">{response.data.totales_historico.DM}</p> 
            

              <p className="itemDash itemML">{response.data.totales_historico.ML}</p> 
          

              <p className="itemDash itemIG">{response.data.totales_historico.IG}</p> 
            

              <p className="itemDash itemNA">{response.data.totales_historico.NA}</p> 
             
              <p className="itemDash itemLV">{response.data.totales_historico.LV}</p> 
             
               
            </>
            }

          </div>
        
        <div style={{paddingTop:'15px'}}>
        <canvas
        height="120"
        ref={this.canvasRef}
        style={{ maxWidth: "100% !important" }}
      />
         </div>
        </div>   
       
      </div>
    );
  }
}

CanalTotalMensual.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The chart dataset.
   */
  chartData: PropTypes.object,
  /**
   * The Chart.js options.
   */
  chartOptions: PropTypes.object
};

CanalTotalMensual.defaultProps = {
  title: "Publicados por canal total"
};

export default CanalTotalMensual;
