import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { reduxForm, Field, isDirty, getFormValues } from "redux-form";
import InputField from "../_common/InputField";
import SelectSimple from "../_common/SelectSimple"; 
import { adminuserActions } from "../_actions";
import { store } from "../_helpers";
import { API_URL } from "../_config";
import CheckBoxSimple from "../_common/CheckBoxSimple";
import axios from "axios";
import {
  Row,
  Col,
  Card,
} from "shards-react";

const ConsABMFormulario = props => {
  const { handleSubmit, handleChange,  pristine, reset, submitting } = props
 
  const [loading, setLoading] = useState(true);
  const [dlz, setDlz] = useState([{}]);
 
  useEffect(() => {
   
    if (localStorage.getItem("dealer_list") === null) {

      fetch(
        API_URL + "api/v2/dealer?token=" +
          props.user.token
      )
        .then(response => response.json())
        .then(json => {
          setDlz(json);
          setLoading(false);
          localStorage.setItem('dealer_list', JSON.stringify(json));
        });
         //...
      }else{
          setDlz(JSON.parse(localStorage.getItem("dealer_list")));
          setLoading(false);
      }
   
  }, []); 
  
  if (loading == true) {
    return (<div style={{height:'500px', textAlign:'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}><img src="/loading.gif" style={{maxWidth:'200px', width:'100%'}}/></div>);
  }

  return (
    <form onSubmit={handleSubmit}>
       <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.2.0/css/font-awesome.min.css" rel="stylesheet"></link>

      <div>

        {dlz.map(function(item, i){
              return (
                <Field
                  name={'dealer' + item.id}
                  label={item.name}
                  component={CheckBoxSimple}
                  type="checkbox"
                ></Field>
              )
        })}
      </div>
  
        

      <br/>
         <button type="submit" style={{borderColor: '#e1e5eb', color: '#585858'}} disabled={pristine || submitting}>
            Actualizar
        </button>
    </form>
  )
  
}


function mapState(state, props) {
   
    const { users, authentication, vehicles, adminuser } = state;
  
    const { user } = authentication;

    var initl = {};

    props.activedX.map(function(item, i){
      
      
      initl = Object.assign({
        ['dealer' + item.id]: true
      }, initl);


   });

    return { user, users, adminuser, initialValues: initl};
  }
  const actionCreators = {
  
  };

const po = reduxForm({
  form: 'ConsABMFormulario', 
  enableReinitialize: true
})(ConsABMFormulario);

const pox = connect(
    mapState,
    actionCreators
  )(po);
  
export { pox as ConsABMFormulario };