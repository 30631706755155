import React from "react";
import { connect } from "react-redux";
//import $ from 'jquery'
import { store } from "../_helpers";
import { Table, Divider, Tag, Input, Button as BTX, Icon } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { API_URL, PREAPI_URL } from "../_config";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import axios from "axios";
import { adminuserActions } from "../_actions";
import "./Usuarios.css";
import "react-tabs/style/react-tabs.css";
import {
  Badge,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Card,
  CardImg,
  ButtonToolbar,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  FormCheckbox,
  Container,
  FormTextarea,
  CardHeader,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  ButtonGroup,
  Alert
} from "shards-react";
import openNotificationWithIcon from "../_common/OpenNotificationWithIcon";
import { CrearUsuario, ConsUsuario, PermUsuario, EditarUsuario } from "./";
import PageTitle from "../_components/PageTitle";
import PolyLoader from '../_components/PolyLoader'
class Usuarios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      load : false,
      modalDeleteUser : false,
      confirmDeleteUser: "",
      nav_number: 0,
      cons: false,
      perm: false,
      id_user: 0,
      permisos_usados: {},
      dealers_usados: {},
      minValue: 0,
      maxValue: 9,
      minValue2: 0,
      maxValue2: 9,
      valor: "",
      searchText: "",
      searchedColumn: "",
      search: false,
      modal_id: undefined,
      userCreate : true,
    };

    //const items = this.props.vehicles;
    this.toggle = this.toggle.bind(this);
    this.dataUsers = this.dataUsers.bind(this);
    this.showHideModalDelete = this.showHideModalDelete.bind(this);
    this.refressh = this.refressh.bind(this);
    this.create_user = this.create_user.bind(this);
    this.assign_cons = this.assign_cons.bind(this);
    this.assign_perms = this.assign_perms.bind(this);
    this.mostrar_coso = this.mostrar_coso.bind(this);
    this.eliminar = this.eliminar.bind(this);
    this.set_coso = this.set_coso.bind(this);
 
  }

 componentWillMount() {
  //  llamamos a los usuarios y load
   this.refressh(true,false)
  }
  // Busca los usuarios de redux
  async dataUsers(){
    this.setState({load  : true})
    await this.props.getUsers()
  }

  // llama al modal load y si queremos le pasamos un notification
  async refressh(desactiveload,notification,event,title,description){
   this.dataUsers().then(res =>{
     if(desactiveload)
     setTimeout(()=> {this.setState({load : false})},2400)
    }).then(res => {
      if(notification){
       setTimeout(()=> {
         openNotificationWithIcon(event,title, description)
       },1400)
     }
   })
  }
     mostrar_coso(item) {
    this.setState({
      modal_id: undefined
    });

    if (this.state.modal_id == undefined) {
      this.setState({
        modal_id: (
          <EditarUsuario open={true} key={"modal" + item} id_user={item} />
        )
      });
    }
  }

  set_coso(item) {
    this.setState({
      modal_id: item
    });

    if (this.state.modal_id != undefined) {
    }
  }

  eliminar(item) {
    axios
      .delete(
        API_URL +
           "api/user/" +
          item +
          "?token=" +
          store.getState().authentication.user.token
      )
      .then(response => {
        this.setState({modalDeleteUser: false})
        this.refressh(true,true,'success','Listo!', 'Eliminado con exito!')
      }).catch(error => {
        this.setState({modalDeleteUser : false})
        this.refressh(true,true,'error','Ooops! hemos tenido un error', `${error.response}` )
      })
  }

  create_user(values) {
     axios
       .post(
         API_URL + "api/user?token=" + store.getState().authentication.user.token,
         {
           active: true,
           dealers: [],
           email: values.email,
           first_name: values.first_name,
           groups: [],
           last_name: values.last_name,
           password: values.password,
           phone: values.phone
         }
       )
       .then(response => {
         this.setState({
           id_user: response.data.id,
           permisos_usados: response.data.perms,
           dealers_usados: response.data.dealers,
           cons: true,
           perm: true,
           open : false,
           modal_id: (
            <EditarUsuario open={true} key={"modal" + response.data.id} id_user={response.data.id} usercreateSuccess={this.state.userCreate} />
          )
         });
        })
      .catch(error =>{
        this.setState({open : false})
        // alert(`Ooops! hemos tenido un error ${error.response.data.m || error.response.data.message}`)
        this.refressh(true,true,'error','Ooops! hemos tenido un error', `${error.response.data.m || error.response.data.message}`)
      })
  }

  assign_cons(values) {
    axios
      .post(
        API_URL +
           "api/user/" +
          this.state.id_user +
          "/dealers?token=" +
          store.getState().authentication.user.token,
        values
      )
      .then(response => alert(`${response}`)).catch(error => alert(`${error.response}`) )
  }

  assign_perms(values) {
    axios
      .post(
        API_URL +
          "api/user/" +
          this.state.id_user +
          "/perms?token=" +
          store.getState().authentication.user.token,
        values
      )
      .then(response => {
        // Ahora actualizo
        axios({
          method: "get",
          url:
            API_URL +
            "api/user/" +
            this.state.id_user +
            "?token=" +
            store.getState().authentication.user.token
        }).then(response => {
          this.setState({
            dealers_usados: response.data.dealers,
            permisos_usados: response.data.groups
          });
        });
      }).catch(error => alert(`${error.response}`))
  }

  getSize = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Buscar por ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: "100%", marginBottom: 8, display: "block" }}
        />
        <BTX
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: "100%", marginBottom: 8 }}
        >
          Buscar
        </BTX>
        <BTX
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: "100%" }}
        >
          Resetear
        </BTX>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  toggle() {
    this.setState({
      open: !this.state.open,
    });
  } 
  showHideModalDelete(value){
    this.setState({
      modalDeleteUser : !this.state.modalDeleteUser
    })
    this.setState({confirmDeleteUser : value})
  }
  render() {
    
    const { open } = this.state;

    const columns = [
      {
        title: "#",
        dataIndex: "id",
        key: "id",
        render: text => <a>{text}</a>,
        ...this.getColumnSearchProps("id")
      },
      {
        title: "Nombre",
        dataIndex: "nombre",
        key: "nombre",
        sorter: (a, b) => a.nombre.localeCompare(b.nombre),
        ...this.getColumnSearchProps("nombre")
      },
      {
        title: "Apellido",
        dataIndex: "apellido",
        key: "apellido",
        sorter: (a, b) => a.apellido.localeCompare(b.apellido),
        ...this.getColumnSearchProps("apellido")
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        sorter: (a, b) => a.email.localeCompare(b.email),
        ...this.getColumnSearchProps("email")
      },
      {
        title: "Acción",
        dataIndex: "accion",
        key: "accion",
        render: text => (
          <td className="pof" style={{ padding: "0.45rem !important" }}>
            <i
              className="material-icons"
              title="Editar"
              style={{ cursor: "pointer", display: "inline", fontSize: "23px" }}
              onClick={() => {
                this.mostrar_coso(text);
              }}
            >
              edit
            </i>
            <i
              className="material-icons"
              title="Eliminar"
              style={{ cursor: "pointer", display: "inline", fontSize: "23px" }}
              onClick={() => {
                this.showHideModalDelete(text);
              }}
            >
              delete
            </i>
          </td>
        )
      }
    ];

    var data = [
      this.props.adminuser.list.map((item, i) => ({
        id: item.id,
        nombre: item.first_name,
        apellido: item.last_name,
        email: item.email,
        accion: item.id
      }))
    ];

    return (
      <div>
        <>
          <Row noGutters className="page-header py-4" style={{ width: "100%" }}>
            <PageTitle sm="4" title="Usuarios" className="text-sm-left" />
            <Col sm="6"></Col>
            <Col sm="2">
              <Button onClick={this.toggle}>Crear Usuario</Button>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card small className="mb-4">
                <CardBody className="p-0 pb-3">
                  <div class="table-responsive">
                    <Table
                      columns={columns}
                      locale={{ emptyText: "Obteniendo datos..." }}
                      dataSource={data[0]}
                      pagination={{ pageSize: 20 }}
                      defaultSorting={[{ id: "total", desc: true }]}
                    />
                  </div>

                  {this.state.modal_id != undefined ? (
                    this.state.modal_id
                  ) : (
                    <div></div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* Modal eliminar usuario */}
          <Modal size="md" open={this.state.modalDeleteUser} toggle={() => {}}>
          <ModalHeader>
              Eliminar Usuario
              <i
                style={{
                  position: "absolute",
                  right: "20px",
                  top: "15px",
                  fontSize: "25px",
                  cursor: "pointer"
                }}
                class="material-icons"
                onClick={this.showHideModalDelete}
              >
                clear
              </i>
            </ModalHeader>
            <ModalBody>
              <div>
                <div>
                  <h5>¿Estas seguro que deseas eliminar este usuario?</h5>
                  <p>Recuerda que si eliminas este usuario, ya no tendra acceso a la plataforma.</p>
                </div>
                <div>
                  <button  className="btn btn-secondary m-2" onClick={this.showHideModalDelete} >Cancelar</button>
                  <button className="btn btn-primary  m-2" onClick={() => this.eliminar(this.state.confirmDeleteUser)}>Confirmar</button>
                </div>
              </div>
            </ModalBody>
          </Modal>
          {/* Modal Agregar usuario */}
          <Modal size="lg" open={open} toggle={() => {}}>
            <ModalHeader>
              Agregar Usuario 
              <i
                style={{
                  position: "absolute",
                  right: "20px",
                  top: "15px",
                  fontSize: "25px",
                  cursor: "pointer"
                }}
                class="material-icons"
                onClick={this.toggle}
              >
                clear
              </i>
            </ModalHeader>
            <ModalBody>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    active={this.state.nav_number == 0 ? true : false}
                    onClick={() => {
                      this.setState({
                        nav_number: 0
                      });
                    }}
                  >
                    Datos
                  </NavLink>
                </NavItem>
                <NavItem active={this.state.nav_number == 1 ? true : false}>
                  <NavLink
                    onClick={() => {
                      this.setState({
                        nav_number: 1
                      });
                    }}
                    href="#"
                    disabled={this.state.cons == true ? false : true}
                  >
                    Concesionarias
                  </NavLink>
                </NavItem>
                <NavItem active={this.state.nav_number == 2 ? true : false}>
                  <NavLink
                    onClick={() => {
                      this.setState({
                        nav_number: 2
                      });
                    }}
                    disabled={this.state.perm == true ? false : true}
                    href="#"
                  >
                    Permisos
                  </NavLink>
                </NavItem>
              </Nav>
              <br />
              <br />

              {this.state.nav_number == 0 ? (
                <CrearUsuario onSubmit={this.create_user} patchUbication={"createUser"} />
              ) : (
                <div />
              )}

              {this.state.nav_number == 1 ? (
                <ConsUsuario
                  userazo={this.state.id_user}
                  activedX={this.state.dealers_usados}
                  onSubmit={this.assign_cons}
                />
              ) : (
                <div />
              )}

              {this.state.nav_number == 2 ? (
                <PermUsuario
                  activedX={this.state.permisos_usados}
                  onSubmit={this.assign_perms}
                />
              ) : (
                <div />
              )}
            </ModalBody>
          </Modal>

          <br />
          <br />
        </>
        {/* Loading page */}
        <PolyLoader open={this.state.load} text="Obteniendo datos" />
      </div>
    );
  }
}

function mapState(state) {
  const { users, authentication, vehicles, adminuser } = state;

  const { user } = authentication;

  return { user, users, adminuser };
}

const actionCreators = {
  getUsers: adminuserActions.getUsers
};

const connectedUsuarios = connect(
  mapState,
  actionCreators
)(Usuarios);
export { connectedUsuarios as Usuarios };
