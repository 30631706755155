import React from "react";
import { Drawer, notification } from "antd";
//import "./style.css";
const openNotificationWithIcon = (type, til, msg) => {
  let $congf = {
    message: til,
    description: msg,
    top: 70
  };
  notification[type]($congf);
};
export default openNotificationWithIcon;
