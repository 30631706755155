import Cookies from "universal-cookie";
import axios from "axios";
import { store, history } from "../../_helpers";
import { API_URL, PREAPI_URL } from "../../_config";
const cookies = new Cookies();

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export default (async function showResults(values) {
  localStorage.removeItem("vehicle_list");
  await axios
    .post(
      PREAPI_URL +
        "api/v2/dealer/" +
        cookies.get("dealer").id +
        "/vehicles?token=" +
        store.getState().authentication.user.token,
      { data: values }
    )
    .then(response => {
      //window.alert(response);
      if (response.data.id) {
        history.push("/vehiculos/editar/" + response.data.id + "#editartema");
        window.scrollTo(0, 1350);
      } else {
        alert("Tuvimos un problema al Publicar tu auto");
      }
    });
});
