import React from 'react'
import { Modal, ModalBody, ModalHeader,ModalFooter } from 'shards-react';
import AvatarEditor from "react-avatar-editor";
import { Alert, Slider } from "antd";


const CanvaEditTeam = ({setEditorRef,state,handleZoomSlider,handleCancel,handleSave,scaleImage}) => {
  return (
    <div className="mb-4">
    <div>
      <h6>Recorta tu imagen y guardala</h6>
            <div
              className="cropper-wrapper"
              style={{
                width: "300",
                height: "360",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <AvatarEditor
                ref={setEditorRef}
                image={state.img}
                width={300}
                height={360}
                border={50}
                color={[150, 150, 160, 0.8]} // RGBA
                rotate={0}
                scale={state.zoom}
              />
              <div
              className="mt-4"
              >
                <h6>
                  Zoom  Scale : {scaleImage} 
                </h6>
                <Slider
                  min={-1} 
                  max={10}
                  value={state.zoom}
                  step={0.1}
                  onChange={handleZoomSlider}
                  style={{ width: 200 }}
                />
              </div>
            </div>
              </div>
              <div className="mt-4">
                <button className="btn btn-secondary "
                  onClick={handleCancel}
                >Cancelar</button>
                <button  className="btn btn-primary ml-4"
                  onClick={handleSave}
                >Guardar Imagen</button>
              </div>
          </div>
  )
}

export default CanvaEditTeam
