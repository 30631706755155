import React from "react";
import { connect } from "react-redux";
import PolyLoader from "../_components/PolyLoader";
import { Input, List } from "antd";
import { vehiclesActions, loadingGloabal } from "../_actions";
import "react-tabs/style/react-tabs.css";
import { API_URL } from "../_config";

import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormRadio
} from "shards-react";
import Paso1 from "./Pasos/Paso1";
import Paso2 from "./Pasos/Paso2";
import Paso3 from "./Pasos/Paso3";
import Paso4 from "./Pasos/Paso4";
import { store } from "../_helpers";
import Cookies from "universal-cookie";
import axios from "axios";
import Paso5 from "./Pasos/Paso5";
import Paso6 from "./Pasos/Paso6";
import Paso7 from "./Pasos/Paso7";
import Paso8 from "./Pasos/Paso8";
import Paso9 from "./Pasos/Paso9";
import PageTitle from "../_components/PageTitle";
import VehiclesList from "./VehiclesList";
import { VehiclesPublishWizard } from "./WizardForm/VehiclesPublishWizard";
import SearchResults from "react-filter-search";
import "./Vehicles.css";

const cookies = new Cookies();
const { Search } = Input;
class Vehicles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      minValue: 0,
      maxValue: 9,
      valor: "",
      search: false,
      vehicle_list: [],
      vendidos_list: [],
      showVendidos: false,
      cargando: true,
      order: "asc",
      options: false
    };

    //const items = this.props.vehicles;
    //console.log('aca ?', items);
    this.toggle = this.toggle.bind(this);
    console.log("vehi" + this.props.vehicles);
    this.handleChange = this.handleChange.bind(this);
    this.refreshVehicles = this.refreshVehicles.bind(this);
    this.searchys = this.searchys.bind(this);
    this.changeOrder = this.changeOrder.bind(this);
    this.mostrarVendidos = this.mostrarVendidos.bind(this);
    this.ocultarVendidos = this.ocultarVendidos.bind(this);
    this.toggleOptions = this.toggleOptions.bind(this);
  }

  ocultarVendidos = () => {
    this.setState({ showVendidos: false });
  };

  mostrarVendidos = () => {
    // LOADER
    this.setState({ cargando: true });

    // Primero obtengo el listado de autos vendidos
    axios({
      method: "get",
      url:
        API_URL +
        "api/dealer/" +
        cookies.get("dealer").id +
        "/vehicles_new?brand&fuel&model&page=1&region&tag&type&vendidos=true&version&year&token=" +
        store.getState().authentication.user.token
    }).then(response => {
      if (this.state.order == "desc") {
        this.setState({
          vendidos_list: response.data.data.sort((a, b) =>
            a.itemM > b.itemM ? 1 : -1
          ),
          showVendidos: true,
          cargando: false
        });
      } else {
        this.setState({
          vendidos_list: response.data.data,
          showVendidos: true,
          cargando: false
        });
      }
      this.setState({
        vendidos_list: response.data.data,
        showVendidos: true,
        cargando: false
      });
    });
  };

  handleChange = value => {
    if (value <= 1) {
      this.setState({
        minValue: 0,
        maxValue: 9
      });
    } else {
      this.setState({
        minValue: this.state.maxValue,
        maxValue: value * 9
      });
    }
  };

  componentWillMount() {
    if (localStorage.getItem("vehicle_list") === null) {
      axios({
        method: "get",
        url:
          API_URL +
          "api/dealer/" +
          cookies.get("dealer").id +
          "/vehicles_new?brand&fuel&model&page=1&region&tag&type&vendidos&version&year&token=" +
          store.getState().authentication.user.token
      }).then(response => {
        localStorage.setItem(
          "vehicle_list",
          JSON.stringify(response.data.data)
        );
        this.setState({
          vehicle_list: response.data.data,
          cargando: false
        });
      });
    } else {
      this.setState({
        vehicle_list: JSON.parse(localStorage.getItem("vehicle_list")),
        cargando: false
      });
    }
    //this.props.loadingGShow();
  }

  componentWillUnmount() {
    this.setState({
      vehicle_list: []
    });
  }

  toggleOptions() {
    this.setState(prevState => {
      return { options: !prevState.options };
    });
  }

  renderVehicles() {
    /*return this.props.vehicles.map((vehicle) => {
      return (
        <tr key={vehicle.id}>
          <td>{vehicle.brand_name}</td>
        </tr>
      )
    })*/
  }

  searchys(value) {
    if (value == "") {
      this.setState({
        valor: value,
        search: false
      });
    } else {
      this.setState({
        valor: value,
        search: true
      });
    }
  }

  refreshVehicles() {
    /** Refresco los vehiculos */
    localStorage.removeItem("vehicle_list");
    this.setState({
      cargando: true
    });

    axios({
      method: "get",
      url:
        API_URL +
        "api/dealer/" +
        cookies.get("dealer").id +
        "/vehicles_new?brand&fuel&model&page=1&region&tag&type&vendidos&version&year&token=" +
        store.getState().authentication.user.token
    }).then(response => {
      localStorage.setItem("vehicle_list", JSON.stringify(response.data.data));
      this.setState({
        vehicle_list: response.data.data,
        cargando: false
      });
    });
  }
  getSize = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }

    console.log(size);
    return size;
  };

  toggle() {
    this.setState({
      open: !this.state.open
    });
  }

  changeOrder(value) {
    if (this.state.order == "asc") {
      this.setState({
        order: "desc",
        vehicle_list: this.state.vehicle_list.sort((a, b) =>
          a.itemM > b.itemM ? 1 : -1
        ),
        vendidos_list: this.state.vendidos_list.sort((a, b) =>
          a.itemM > b.itemM ? 1 : -1
        )
      });
    } else {
      this.setState({
        order: "asc",
        vehicle_list: this.state.vehicle_list.reverse(),
        vendidos_list: this.state.vendidos_list.reverse()
      });
    }
  }

  render() {
    const { open, options, showVendidos, order } = this.state;
    const vehicle_list = this.state.vehicle_list;
    const steps = [
      { name: "PASO 1", component: <Paso1 /> },
      { name: "PASO 2", component: <Paso2 /> },
      { name: "PASO 3", component: <Paso3 /> },
      { name: "PASO 4", component: <Paso4 /> },
      { name: "PASO 5", component: <Paso5 /> },
      { name: "PASO 6", component: <Paso6 /> },
      { name: "PASO 7", component: <Paso7 /> },
      { name: "PASO 8", component: <Paso8 /> },
      { name: "PASO 9", component: <Paso9 /> }
    ];

    return (
      <div>
        <Row className="page-header pt-4 mb-4 mr-0">
          <PageTitle title="Vehículos" />
          <div className="col-12 col-md-2 col-xs-6">
            <Button className="w-100" theme="primary" onClick={this.toggle}>
              Publicar Vehículo
            </Button>
          </div>
          <Search
            className="col-12 col-md-4 col-sm-12"
            placeholder="Buscar"
            onSearch={value => this.searchys(value)}
          />
          <Dropdown
            className="col-12 col-md-2 col-xs-6"
            group
            open={options}
            toggle={this.toggleOptions}
          >
            <DropdownToggle theme="secondary" className="flex-fill">
              Ordenar por
            </DropdownToggle>
            <DropdownToggle theme="secondary" split />
            <DropdownMenu>
              <DropdownItem tag="div">
                <FormRadio
                  name="orden"
                  checked={order == "asc"}
                  onChange={() => {
                    this.changeOrder("asc");
                  }}
                >
                  Más recientes
                </FormRadio>
              </DropdownItem>
              <DropdownItem>
                <FormRadio
                  name="orden"
                  checked={order == "desc"}
                  onChange={() => {
                    this.changeOrder("desc");
                  }}
                >
                  Más antiguos
                </FormRadio>
              </DropdownItem>

              <DropdownItem tag="div">
                <FormRadio
                  name="vendidos"
                  checked={showVendidos == true}
                  onChange={() => {
                    showVendidos == true
                      ? this.ocultarVendidos()
                      : this.mostrarVendidos();
                  }}
                >
                  Sólo vendidos
                </FormRadio>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Row>
        {this.state.search == true ? (
          <SearchResults
            value={this.state.valor}
            data={
              this.state.showVendidos == true
                      ? this.state.vendidos_list.filter(
                          item => item.state == "VENDIDO"
                        )
                      : vehicle_list
            }
            renderResults={results => (
              <>
                <List
                  grid={{
                    gutter: 30,
                    column: 3,
                    xs: 1,
                    sm: 2,
                    md: 3,
                    lg: 4,
                    xl: 4,
                    xxl: 4
                  }}
                  pagination={{
                    pageSize: 12,
                    position: "bottom",
                    center: true,
                    onChange(page, pageSize) {
                      window.scroll(0, 0);
                    }
                  }}
                  dataSource={results}
                  renderItem={(item, key) => (
                    <List.Item className="mb-4">
                      <VehiclesList
                        key={key}
                        item={item}
                        auth_token={store.getState().authentication.user.token}
                        user={this.props.user}
                        refresh={() => {
                          this.refreshVehicles();
                        }}
                      />
                    </List.Item>
                  )}
                ></List>
              </>
            )}
          />
        ) : (
          <>
            <List
              grid={{
                gutter: 30,
                column: 3,
                xs: 1,
                sm: 2,
                md: 3,
                lg: 4,
                xl: 4,
                xxl: 4
              }}
              pagination={{
                pageSize: 12,
                position: "bottom",
                center: true,
                onChange(page, pageSize) {
                  window.scroll(0, 0);
                }
              }}
              dataSource={
                this.state.showVendidos == true
                  ? this.state.vendidos_list.filter(
                      item => item.state == "VENDIDO"
                    )
                  : vehicle_list
              }
              renderItem={(item, key) => (
                <List.Item className="mb-4">
                  <VehiclesList
                    key={key}
                    item={item}
                    auth_token={store.getState().authentication.user.token}
                    user={this.props.user}
                    refresh={() => {
                      this.refreshVehicles();
                    }}
                  />
                </List.Item>
              )}
            ></List>
          </>
        )}
        <Modal open={open} size="lg" toggle={() => console.log("Toggle")}>
          <ModalHeader>
            Publicar un Vehiculo
            <i
              style={{
                position: "absolute",
                right: "20px",
                top: "15px",
                fontSize: "25px",
                cursor: "pointer"
              }}
              class="material-icons"
              onClick={this.toggle}
            >
              clear
            </i>
          </ModalHeader>
          <ModalBody>
            <VehiclesPublishWizard onClose={this.toggle}></VehiclesPublishWizard>
          </ModalBody>
        </Modal>
        <PolyLoader
          open={this.state.cargando}
          text="Cargando Vehículos"
        ></PolyLoader>
      </div>
    );
  }
}

function mapState(state) {
  const { users, authentication, vehicles } = state;

  const { user } = authentication;
  console.log("esto ->", vehicles);

  return { user, users, vehicles };
}
const actionCreators = {
  getVehicles: vehiclesActions.getVehicles,
  loadingGShow: loadingGloabal.loadingGShow,
  loadingGHide: loadingGloabal.loadingGHide
};

const connectedVehicles = connect(
  mapState,
  actionCreators
)(Vehicles);
export { connectedVehicles as Vehicles };
