import React from "react";
import { NavItem, NavLink, Badge, Row, Col, Card, CardBody, Collapse, DropdownItem, Button, Modal, ModalBody, ModalHeader  } from "shards-react";
import axios from 'axios';
import { database } from "../../../../init-fcm";
import { store } from "../../../../_helpers";
import { Drawer, Divider } from "antd";
import Cookies from "universal-cookie";
import {
  reduxForm,
  Field,
  getFormValues,
  change,
  formValueSelector
} from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { API_URL } from "../../../../_config";
import { notificacionsActions } from "../../../../_actions";
import PolyLoader from "../../../../_components/PolyLoader";
import { withRouter } from 'react-router-dom';
import ModalNotificaciones from "../../../../_common/ModalNotificaciones";

const cookies = new Cookies();

class Notifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      visible_drawer: false,
      data: [],
      selectedTitle: "",
      selectedBody: "",
      selectedDate: "",
      cargar: false,
      open: false,
      allowCampana: false,
      actual_id: 0,
      play: false,
      countAlert: 0,
      countAlertRead: JSON.parse(localStorage.getItem('lastCountNotificationsRead'))
    };

    this.toggleNotifications = this.toggleNotifications.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggle_drawer = this.toggle_drawer.bind(this);
    this.closeModal = this.closeModal.bind(this);

  }

  closeModal() {
    this.setState({
      open: false
    });
    
  }

  componentDidMount() {
    if(database && database != undefined && database !== null) {
      const dato  = JSON.parse(localStorage.getItem('user'));
      let self = this;
      const user_id = dato.user.id;

      var dbData = database.collection("notificaciones").where("id_user", "<=", user_id).where("id_user", ">=", user_id).orderBy('id_user')
      .orderBy('laravelID','desc').limit(5);
        
      dbData.onSnapshot(function(snapshot) {
          var nots = [];

          snapshot.forEach(function(doc) {
              nots.push(doc.data());
          });

          self.setState({ 
            data: nots
          });
  
          snapshot.docChanges().forEach(function(change) {
            if (change.type === "added") {
              if(self.state.countAlert > 0 && self.state.countAlert < change.doc.data().laravelID) {
                const audio = document.getElementsByClassName("audio-element")[0];
                audio.play()

                self.setState({ 
                  countAlert: change.doc.data().laravelID
                });
              }else{
                self.setState({ 
                  countAlert: change.doc.data().laravelID
                });
                
                console.log(change.doc.data().laravelID);
                console.log(self.state.countAlert);
                console.log("nanana");
              }
            }
          });
      });
    }
    
  }


  componentDidUpdate(nextProps, prevState) {
    if (nextProps.notifications !== this.props.notifications) {
      console.log(this.props.notifications);

      if (!cookies.get("notificacion_" + this.props.notifications.list[0].id)) {
        this.setState({
          allowCampana: true,
          actual_id: this.props.notifications.list[0].id
        });
      }
    }
  }

  toggleNotifications() {
    if (this.state.visible == false && this.state.actual_id != 0) {
      const create_cookie = cookies.set(
        "notificacion_" + this.state.actual_id,
        true
      );
      this.setState({
        allowCampana: false
      });
    }

    this.setState({
      visible: !this.state.visible
    });
  }

  reintentar(id, url, type) {
    this.setState({
      cargar: true
    });

    // Primero se reintenta la operacion
    axios({
      method: type,
      url: url
    })
      .then(response => {
        // Funciono, se devuelve Ok
        alert(
          "La operacion tuvo exito, ya puede verla reflejada en el sistema"
        );
        // Saco el loader
        this.setState({
          cargar: false
        });
      })
      .catch(err => {
        // No funciono, se devueve el mensaje
        alert(
          "La operacion ha fallado nuevamente, por favor, le solicitamos que contacte a soporte para solucionar el tema."
        );
        // Saco el loader
        this.setState({
          cargar: false
        });
      });

    // Se restringen proximos reintentos
    axios
      .put(
        API_URL + "api/ac_notificacions/" +
          id +
          "?token=" +
          store.getState().authentication.user.token,
        { button_for: "admin" }
      )
      .then(response => {
        this.setState({
          selectedButton_for: "admin"
        });
      });


  }

  toggle_drawer() {
    if (this.state.visible == true) {
      this.setState({
        visible: false
      });
    }

    this.setState({
      visible_drawer: !this.state.visible_drawer
    });
  }

  toggle(person) {
    console.log(this.state);
    if (this.state.open == false) {
      console.log(person);
      console.log("adore");

    
  }else{

    // Llamo denuevo
    //this.props.getNoti();   

    var dato = JSON.parse(localStorage.getItem('user'));

    var user_id = dato.user.id;

   
    
     
    this.setState({
      visible_drawer: false,
      open: false,
      selectedTitle: '',
      selectedBody: '',
      selectedDate: '',
      selectedButton: '',
      selectedButton_for: '',
      selectedButton_id: '',
      selectedButton_api: '',
    });
  }
  }

  toggleAndRead(person) {
    if (this.state.open == false) {
      var dato = JSON.parse(localStorage.getItem("user"));

      var user_id = dato.user.id;

    
        this.setState({
          visible_drawer: false,
          open: true,
          selectedTitle: person.title,
          selectedBody: person.text,
          selectedButton: person.button,
          selectedButton_id: person.id,
          selectedButton_for: person.button_for,
          selectedButton_api: person.button_api,
          selectedDate: person.created_at
        });
        
   

     
    }else{
      this.setState({
        visible_drawer: false,
        open: false,
        selectedTitle: "",
        selectedBody: "",
        selectedDate: "",
        selectedButton: "",
        selectedButton_id: "",
        selectedButton_for: "",
        selectedButton_api: ""
      });
    }
  }

  routeChange(person) {
    
    const datosx  = JSON.parse(localStorage.getItem('user'));

    const userr_id = datosx.user.id;

    if(person.leido == false) {
      var g = database.collection("notificaciones").where("laravelID", "==", parseInt(person.laravelID))
    .get()
    .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          var q = database.collection("notificaciones").doc(doc.id).update({
            "leido": true
          })
          
            console.log(doc.id, " => ", doc.data());
        });
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });

      
     // database.ref('notificaciones/notificacion_' + person.id).set({
       // ...person, leido: true
     // });
    }

    axios({
      method: "get",
      url:
        "https://nuestrosautos.cca.org.ar/notificaciones/leer/" + person.laravelID + "/" + userr_id + "?token=" + store.getState().authentication.user.token
    }).then(response => {
      let path = "/consultas/view/" + person.referenceID;
      this.props.history.push(path);
    });
  }
 
  render() {

    const { open, visible_drawer, visible, data } = this.state;

    const dato  = JSON.parse(localStorage.getItem('user'));

    const user_id = dato.user.id;

    const leidos2 = false;
    //const leidos2 = data.find(element => element.leido === false && element.id_user == 0);
    const leidos = data.find(element => element.leido === false && element.id_user == user_id);

   
    return (
      <>
      <NavItem className="border-right border-left dropdown notifications">
      
        <NavLink
          className="nav-link-icon text-center"
          onClick={this.toggleNotifications}
        >
          
        
          <div className="nav-link-icon__wrapper">
            <i className="material-icons">&#xE7F4;</i>
            {leidos ?
            <Badge pill theme="danger">
              1
            </Badge>
            : 
            <>
              {leidos2 &&
                <Badge pill theme="danger">
                1
              </Badge>
              }
            </>
            }
          </div>
          
        </NavLink>
      
        <Collapse
          open={visible}
          className="dropdown-menu dropdown-menu-small"
        >
          {data.slice(0, 10).map((person, index) => (
             person.id_user == user_id||person.id_user == 0||person.id_user == '0'? (
              person.leido == true ? (
                <DropdownItem onClick={() => person.type == 'message' ?  this.routeChange(person) : this.toggle(person)} style={{background: '#eee', cursor: 'pointer'}} title="La notificación se encuentra leida"> 
                <div className="notification__icon-wrapper">
                  <div className="notification__icon">
                    <i className="material-icons">{person.type}</i>
                  </div>
                </div>
                <div className="notification__content">
                        <span className="notification__category">{person.type == 'warning' ? 'Alerta' : person.type == 'error' ? 'Urgente' : person.type == 'message' ? 'Nuevo mensaje en ' + person.portal : 'Información'} </span>
                        <p>
                          {person.title}
                        </p>
                        {person.dealerName != undefined &&
                          <small>En {person.dealerName}</small>
                        }
                      </div>
              </DropdownItem>
                    ):(
                      <DropdownItem onClick={() => person.type == 'message' ?  this.routeChange(person) : this.toggleAndRead(person)} style={{cursor: 'pointer'}} title="Todavia no se ha leido">
                      <div className="notification__icon-wrapper">
                        <div className="notification__icon">
                          <i className="material-icons">{person.type}</i>
                        </div>
                      </div>
                      <div className="notification__content">
                      <span className="notification__category">{person.type == 'warning' ? 'Alerta' : person.type == 'error' ? 'Urgente' : person.type == 'message' ? 'Nuevo mensaje en ' + person.portal : 'Información'} </span>
                        <p>
                          {person.title}
                        </p>
                        {person.dealerName != undefined &&
                          <small>En {person.dealerName}</small>
                        }
                        
                      </div>
                    </DropdownItem>
                    )
            ) : (
                <></>
            )
    ))}
       
          <DropdownItem onClick={() => this.props.history.push('/notificaciones')} className="notification__all text-center">
            Ver todas las notificaciones
          </DropdownItem>

  
        
        </Collapse>
      </NavItem>

      <ModalNotificaciones closeModal={() => this.closeModal()} open={open} data={this.state}></ModalNotificaciones>
                        


        <audio className="audio-element">
          <source src="/sound.mp3"></source>
        </audio>

        <PolyLoader open={this.state.cargar} text="Reintentando operación"></PolyLoader>

      </>
    );
  }
}

function mapState(state, ownprops) {
  const { users, authentication, notifications } = state;
  const { user } = authentication;
  return {
    user,
    users,
    notifications
  };
}

const actionCreators = {
  getNoti: notificacionsActions.getNotifications
};

const connectEditVehicles = connect(
  mapState,
  actionCreators
);

export default withRouter(compose(
  connectEditVehicles,
  reduxForm({
    form: "notifications",
    enableReinitialize: true
  })
)(Notifications));

