import { alertConstants } from "../_constants";

export const alertActions = {
  success,
  error,
  clear,
  boom,
  showLoading,
  hide
};

function success(message) {
  return { type: alertConstants.SUCCESS, message };
}

function error(message) {
  return { type: alertConstants.ERROR, message };
}

function clear() {
  return { type: alertConstants.CLEAR };
}
function hide() {
  return { type: alertConstants.SHOW, mystate: false };
}
function boom(boms) {
  return { type: alertConstants.BOOM };
}
function showLoading() {
  console.log("ALET@@@##");
  return dispatch => {
    dispatch({ type: alertConstants.ALERT_SHOW });
  };

  //return dispatch => { type: alertConstants.SHOW, mystate: true };
}
