import React, { useState } from 'react'
import { API_URL } from "../_config";
import { store } from "../_helpers";
import axios from 'axios'
import { Card, Table } from 'antd';
import { Button } from 'shards-react';
import Axios from 'axios';
import Cookies from "universal-cookie";

const cookies = new Cookies();



const PoppupListItems = ({itemsSelected,keysSelected, DesactiveFusion ,setItemsKeys,setItemsSelected,type}) => {


  const [heightCardAnimate, setHeightCardAnimate] = useState(50)
  const [dataFinal,setDataFinal] = useState({})
  const [submitAccept,setSubmitAccept] =useState(true)

  const columns = [
    {
      title: "Acción",
      dataIndex: "id",
      key: "accion",
      render: (text,record) => (
        <>
          <i
            className="material-icons"
            title="Eliminar"
            style={{ cursor: "pointer", display: "inline", fontSize: "23px" }}
            onClick={() => {
              setItemsSelected( itemsSelected.filter(i => i.id !== record.id ) )
              setItemsKeys(keysSelected.filter(i => i !== record.id))
            } }
          >
            delete
          </i>
        </>
      )
    },
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "name",
      dataIndex: "name",
      key: "name",
    },
    {

      title: "type_vehicle_id",
      dataIndex: "type_vehicle_id",
      key: "type_vehicle_id",
      
    },
  
    {
      title: "mercadolibre_name",
      dataIndex: "mercadolibre_name",
      key: "mercadolibre_name",
      render: text =><>{text === null ? <p style={{color : "#ff0042", fontSize: "12px",fontWeight: "semiBold"}}>null</p> : <p>{text}</p>}</>,
    },
    {
      title: "mercadolibre_id",
      dataIndex: "mercadolibre_id",
      key: "mercadolibre_id",
      render: text =><>{text === null ? <p style={{color : "#ff0042", fontSize: "12px",fontWeight: "semiBold"}}>null</p> : <p>{text}</p>}</>,
    },

    {
      title: "autocosmos_name",
      dataIndex: "autocosmos_name",
      key: "autocosmos_name",
      render: text =><>{text === null ? <p style={{color : "#ff0042", fontSize: "12px",fontWeight: "semiBold"}}>null</p> : <p>{text}</p>}</>,
    },
    {
      title: "autocosmos_id",
      dataIndex: "autocosmos_id",
      key: "autocosmos_id",
      render: text =><>{text === null ? <p style={{color : "#ff0042", fontSize: "12px",fontWeight: "semiBold"}}>null</p> : <p>{text}</p>}</>,
    },
    {
      title: "deautos_name",
      dataIndex: "deautos_name",
      key: "deautos_name",
      render: text =><>{text === null ? <p style={{color : "#ff0042", fontSize: "12px",fontWeight: "semiBold"}}>null</p> : <p>{text}</p>}</>,
    },
    {
      title: "deautos_id",
      dataIndex: "deautos_id",
      key: "deautos_id",
      render: text =><>{text === null ? <p style={{color : "#ff0042", fontSize: "12px",fontWeight: "semiBold"}}>null</p> : <p>{text}</p>}</>,
    },
    {
      title: "demotores_name",
      dataIndex: "demotores_name",
      key: "demotores_name",
      render: text =><>{text === null ? <p style={{color : "#ff0042", fontSize: "12px",fontWeight: "semiBold"}}>null</p> : <p>{text}</p>}</>,
    },
    {
      title: "demotores_id",
      dataIndex: "demotores_id",
      key: "demotores_id",
      render: text =><>{text === null ? <p style={{color : "#ff0042", fontSize: "12px",fontWeight: "semiBold"}}>null</p> : <p>{text}</p>}</>,
    },
    {
      title: "olx_name",
      dataIndex: "olx_name",
      key: "olx_name",
      render: text =><>{text === null ? <p style={{color : "#ff0042", fontSize: "12px",fontWeight: "semiBold"}}>null</p> : <p>{text}</p>}</>,
    },
    {
      title: "olx_id",
      dataIndex: "olx_id",
      key: "olx_id",
      render: text =><>{text === null ? <p style={{color : "#ff0042", fontSize: "12px",fontWeight: "semiBold"}}>null</p> : <p>{text}</p>}</>,
    },
    {
      title: "autofoco_name",
      dataIndex: "autofoco_name",
      key: "autofoco_name",
      render: text =><>{text === null ? <p style={{color : "#ff0042", fontSize: "12px",fontWeight: "semiBold"}}>null</p> : <p>{text}</p>}</>,
    },

  ];



  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if(selectedRowKeys){
        setDataFinal({
          idSelected : parseInt(selectedRowKeys),
          idFusion: keysSelected.filter(i => i !== selectedRows[0].id)
        })
        setSubmitAccept(false)
      }
    },
  };

  const submitFusion = ()=> {
    if(type === "modelos"){
      return axios
      .post(
        API_URL + 'api/dealer/' + cookies.get("dealer").id + '/abm/model?token=' +
        store.getState().authentication.user.token,dataFinal)
        .then(res => {
        setSubmitAccept(true)
        setDataFinal({})
        DesactiveFusion() 
      }).catch(error =>{
         setSubmitAccept(true)
         setDataFinal({})
         DesactiveFusion()  
        })
    }
    if(type === "marcas"){
      return axios
      .post(
        API_URL + 'api/dealer/' + cookies.get("dealer").id + '/abm/brand?token=' +
        store.getState().authentication.user.token,dataFinal)
        .then(res => {
        setSubmitAccept(true)
        setDataFinal({})
        DesactiveFusion() 
      }).catch(error =>{
         setSubmitAccept(true)
         setDataFinal({})
         DesactiveFusion()  
        })
    }
    return
  }

  return (
    <div style={{
      wordWrap: "break-word",
      background: "#fff",
      borderRadius: "2px",
      boxShadow: "0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d",
      width :"900px", 
      height: `${heightCardAnimate}px`,
      zIndex: "1200",
      position : "fixed",
      bottom : 10,
      right : 10 ,
      transition: "height 1s ease"  
    }}>
       <Card title={`${type} Seleccionados ${itemsSelected ? itemsSelected.length : "0"}`} bordered={false} style={{ width: "100%"}}   extra={heightCardAnimate === 50 ? <span onClick={() => setHeightCardAnimate(600)} class="material-icons" style={{cursor :"pointer"}}>
        arrow_drop_up
          </span>:
           <span class="material-icons" onClick={() => setHeightCardAnimate(50)} style={{cursor :"pointer"}}>
            arrow_drop_down
      </span>} 
      actions={[
      <button className="btn btn-secondary" onClick={DesactiveFusion}>Cancelar</button>,
      <Button onClick={submitFusion} disabled={submitAccept} >Guardar</Button>,
    ]}>
         <div style={{width: "100%" ,height:"420px", overflow: "scroll" }}>
        
           {itemsSelected && itemsSelected.length > 0 ?
            <Table
            columns={columns}
            rowKey={(key) => key.id}
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
            pagination={false}
            locale={{ emptyText: "Obteniendo datos..." }}
            dataSource={itemsSelected}
          />
            : <p>0 items Seleccionados</p> }
         </div>
    </Card>

  </div>
  )
}

export default PoppupListItems
