import React from 'react'
import { Modal, Button } from 'antd';


const ModalContactoDetail = ({showHide,showHideFunc,DataDealer}) => {
    const nombreAgencia = DataDealer.datos && DataDealer.datos.dealer_name
    const Whatsapp =  DataDealer.datos && DataDealer.datos.phone2
    const contacto =  DataDealer.datos && DataDealer.datos.celular
    const horariosAtencion =  DataDealer.datos && DataDealer.datos.work_hours
    const emailDealer =  DataDealer.datos && DataDealer.datos.email
    const directionDealer =  DataDealer.datos && `${DataDealer.datos.address} ${DataDealer.datos.barrio_name} ${DataDealer.datos.region_name}`

    return (
        <Modal
        centered
        visible={showHide}
        onOk={() => showHideFunc(false)}
        onCancel={() => showHideFunc(false)}
      >
        <h5>{nombreAgencia}</h5>
        <h6>Whatsapp:</h6>
        <p>{Whatsapp}</p>
        <h6>Contactanos:</h6>
        <p>{contacto}</p>
        <h6>Horarios de Atención:</h6>
        <p>{horariosAtencion}</p>
        <h6>Email:</h6>
        <p>{emailDealer}</p>
        <h6>Dirección:</h6>
        <p>{directionDealer}</p>
      </Modal>
    )
}

export default ModalContactoDetail