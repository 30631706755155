import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export class TextareaSimple extends Component {
  constructor(props) {
    super(props);

    //this.getRef = this.getRef.bind(this);
  }

  render() {
    const {
      label,
      input,
      name,
      meta: { touched, error }
    } = this.props;

    return (
      <div>
        <label style={{marginBottom: 1}}>{label}</label>
        <div>
          <textarea
            {...input}
            placeholder={label}
            name={name}
            className="form-control"
            rows="8"
            cols="40"
          ></textarea>
          {touched && error && <span className="red-snow">{error}</span>}
        </div>
      </div>
    );
  }
}

TextareaSimple.propTypes = {
  /**
   * The class name.
   */
  className: PropTypes.string,
  /**
   * The children nodes.
   */
  children: PropTypes.node,
  /**
   * The size.
   */
  size: PropTypes.string,
  /**
   * Whether it is valid, or not.
   */
  valid: PropTypes.bool,
  /**
   * Whether it is invalid, or not.
   */
  invalid: PropTypes.bool
  /**
   * The inner ref.
   */
};

//export default TextareaSimple;
