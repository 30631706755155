import React, {useState, useEffect} from 'react';
import { Upload, Popover, Modal, Button, Icon, notification} from 'antd';
import { userConstants } from "../_constants";
import { userService } from "../_services";
import { API_URL, PREAPI_URL } from "../_config";
import { alertActions } from "./";
import { history } from "../_helpers";
import Cookies from "universal-cookie";
import axios from "axios";
import { store } from "../_helpers";
const cookies = new Cookies();

export const userActions = {
  login,
  logout,
  register,
  getAll,
  delete: _delete,
  notifyTest
};

function notifyTest() {
  return dispatch => {
   
    notification.open({
      message: 'Cargando',
      description:
        'Espere un momento por favor',
      icon: <Icon type="loading" style={{ color: '#4caf50' }} />,
      style: {zIndex: 999999},
      top: 70
    });
  }
}

function login(username, password) {
  return dispatch => {
    dispatch(request({ username }));

    userService.login(username, password).then(
      user => {
        dispatch(success(user));
        if(user == false) {
          notification.open({
            message: 'Usuario o Contraseña incorrecta',
            description:
              'Revise que los datos esten ingresados correctamente. Si la situación persiste, contacte a Soporte para obtener más información',
            icon: <Icon type="stop" style={{ color: '#d32f2f' }} />,
            top: 70
          });
        }else{
    

        // Guardo los permisos
        localStorage.setItem('permission', JSON.stringify(user.permission));
        // console.log(user);
        localStorage.removeItem('dealer_list')
        cookies.remove("dealer");
        console.log('memazo')
        if (user.is_admin) {
          history.push("/select_dealer");
        } else {

          if(user.dealersList) {
            // Si es mayor a uno, es decir que maneja varias agencias
            if(user.dealersList.length > 1) {
              localStorage.setItem('dealer_list', JSON.stringify(user.dealersList));
              history.push("/select_dealer");
            }else{
              // Sino significa que tiene una sola agencia
              axios({
                method: "get",
                url:
                  API_URL + "api/dealer/" +
                  user.dealer.dealer_id +
                  "/2?token=" +
                  store.getState().authentication.user.token
              }).then(response => {
               
                var dealer_selected = {
                  id: user.dealer.dealer_id,
                  name: user.dealer.dealer_name,
                  picture: response.data.cropped_image_url,
                  dealerUrl: response.data.url_company ,
                  dealerLogo: 
                    `<img className="user-avatar rounded-circle mr-2"  style="
                      max-width: 6.5rem;
                      max-height: 2.5rem;
                      border-radius: 6px;
                      border: 1px solid rgb(238, 238, 238);" 
                     src="${response.data.cropped_image_url}" alt="${user.dealer.dealer_name}">`
                  
                };
      
                
                cookies.set("dealer", dealer_selected, { maxAge: "86400" });
                
                history.push("/panel");
    
              });
    
            }
          }else{
            // Sino significa que tiene una sola agencia
            axios({
              method: "get",
              url:
                  API_URL + "api/dealer/" +
                user.dealer.dealer_id +
                "/2?token=" +
                store.getState().authentication.user.token
            }).then(response => {
              
              var dealer_selected = {
                id: user.dealer.dealer_id,
                name: user.dealer.dealer_name,
                picture: response.data.cropped_image_url,
                dealerUrl: response.data.url_company ,
                dealerLogo: 
                  `<img className="user-avatar rounded-circle mr-2"  style="
                    max-width: 6.5rem;
                    max-height: 2.5rem;
                    border-radius: 6px;
                    border: 1px solid rgb(238, 238, 238);" 
                   src="${response.data.cropped_image_url}" alt="${user.dealer.dealer_name}">`
                
              };
    
              cookies.set("dealer", dealer_selected, { maxAge: "86400" });
              history.push("/panel");
  
            });
  
          }
        
        }
          
          
        }

        // history.push("/dashboard");
        // return (<Redirect to="/dashboard" />)
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    ).catch(err => {
      return 'Ocurrio un error';
    });
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

function register(user) {
  return dispatch => {
    dispatch(request(user));

    userService.register(user).then(
      user => {
        dispatch(success());
        history.push("/login");
        dispatch(alertActions.success("Registration successful"));
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function getAll() {
  return dispatch => {
    dispatch(request());

    userService
      .getAll()
      .then(
        users => dispatch(success(users)),
        error => dispatch(failure(error.toString()))
      );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GETALL_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    userService
      .delete(id)
      .then(
        user => dispatch(success(id)),
        error => dispatch(failure(id, error.toString()))
      );
  };

  function request(id) {
    return { type: userConstants.DELETE_REQUEST, id };
  }
  function success(id) {
    return { type: userConstants.DELETE_SUCCESS, id };
  }
  function failure(id, error) {
    return { type: userConstants.DELETE_FAILURE, id, error };
  }
}
