import React from "react";
import { connect } from "react-redux";
import { store } from "../_helpers";
import { Table, Input, Button, Icon } from "antd";
import Highlighter from "react-highlight-words";
import axios from "axios";
import PolyLoader from "../_components/PolyLoader";
import "react-tabs/style/react-tabs.css";
import { Row, Col, Card, CardBody } from "shards-react";
import PageTitle from "../_components/PageTitle";
import { API_URL } from "../_config";

class Permisos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      nav_number: 0,
      minValue: 0,
      maxValue: 9,
      minValue2: 0,
      maxValue2: 9,
      valor: "",
      searchText: "",
      searchedColumn: "",
      search: false,
      modal_id: undefined,
      perm_data_entry: [],
      perm_common: [],
      perm_admin: [],
      perm_superadmin: [],
      loading_modal: true
    };

    //const items = this.props.vehicles;
    //console.log('aca ?', items);
    this.toggle = this.toggle.bind(this);
    this.revertir = this.revertir.bind(this);
    this.load = this.load.bind(this);
  }

  componentWillMount() {
    this.load();
  }

  getSize = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Buscar por ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: "100%", marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: "100%", marginBottom: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: "100%" }}
        >
          Resetear
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  toggle() {
    this.setState({
      open: !this.state.open
    });
  }

  load() {
    axios({
      method: "get",
      url:
        API_URL + "api/permissions?token=" +
        store.getState().authentication.user.token
    }).then(response => {
      console.log(response.data);
      // Permisos para superadmin
      let superadmin = response.data.filter(function(user) {
        return user.group_name === "Super-Admin";
      });

      // Permisos para admin
      let admin = response.data.filter(function(user) {
        return user.group_name === "Admin - Concesionaria ";
      });

      // Permisos para usuario comun
      let common = response.data.filter(function(user) {
        return user.group_name === "Usuario - Concesionaria ";
      });

      // Permisos para data entry
      let dataentry = response.data.filter(function(user) {
        return user.group_name === "Data Entry - Concesionaria";
      });

      // Subo todo
      this.setState({
        perm_data_entry: dataentry,
        perm_common: common,
        perm_admin: admin,
        perm_superadmin: superadmin,
        loading_modal: false
      });
    });
  }

  revertir(data, variable) {
    this.setState({
      loading_modal: true
    });

    if (data[variable] == 1) {
      // Prohibo
      data[variable] = 0;
    } else if (data[variable] == 0) {
      // Permito
      data[variable] = 1;
    }

    axios
      .post(
        API_URL + "api/permissions?token=" +
          store.getState().authentication.user.token,
        { data: [data] }
      )
      .then(response => {
        // Ahora actualizo
        this.load();
      });
  }

  render() {
    const { open } = this.state;

    const columns = [
      {
        title: "Modulo",
        dataIndex: "module_name",
        key: "module_name",
        sorter: (a, b) => a.module_name.localeCompare(b.module_name),
        ...this.getColumnSearchProps("module_name")
      },
      {
        title: "Listar",
        dataIndex: "list",
        key: "list",
        render: (text, record) => (
          <td className="pof" style={{ padding: "0.45rem !important" }}>
            {text == 1 ? (
              <i
                className="material-icons"
                title="Prohibir"
                style={{
                  cursor: "pointer",
                  display: "inline",
                  fontSize: "23px"
                }}
                onClick={() => {
                  this.revertir(record, "list");
                }}
              >
                lock
              </i>
            ) : (
              <i
                className="material-icons"
                title="Permitir"
                style={{
                  cursor: "pointer",
                  display: "inline",
                  fontSize: "23px"
                }}
                onClick={() => {
                  this.revertir(record, "list");
                }}
              >
                lock_open
              </i>
            )}
          </td>
        )
      },
      {
        title: "Leer",
        dataIndex: "read",
        key: "read",
        render: (text, record) => (
          <td className="pof" style={{ padding: "0.45rem !important" }}>
            {text == 1 ? (
              <i
                className="material-icons"
                title="Prohibir"
                style={{
                  cursor: "pointer",
                  display: "inline",
                  fontSize: "23px"
                }}
                onClick={() => {
                  this.revertir(record, "read");
                }}
              >
                lock
              </i>
            ) : (
              <i
                className="material-icons"
                title="Permitir"
                style={{
                  cursor: "pointer",
                  display: "inline",
                  fontSize: "23px"
                }}
                onClick={() => {
                  this.revertir(record, "read");
                }}
              >
                lock_open
              </i>
            )}
          </td>
        )
      },
      {
        title: "Crear",
        dataIndex: "create",
        key: "create",
        render: (text, record) => (
          <td className="pof" style={{ padding: "0.45rem !important" }}>
            {text == 1 ? (
              <i
                className="material-icons"
                title="Prohibir"
                style={{
                  cursor: "pointer",
                  display: "inline",
                  fontSize: "23px"
                }}
                onClick={() => {
                  this.revertir(record, "create");
                }}
              >
                lock
              </i>
            ) : (
              <i
                className="material-icons"
                title="Permitir"
                style={{
                  cursor: "pointer",
                  display: "inline",
                  fontSize: "23px"
                }}
                onClick={() => {
                  this.revertir(record, "create");
                }}
              >
                lock_open
              </i>
            )}
          </td>
        )
      },
      {
        title: "Modificar",
        dataIndex: "update",
        key: "update",
        render: (text, record) => (
          <td className="pof" style={{ padding: "0.45rem !important" }}>
            {text == 1 ? (
              <i
                className="material-icons"
                title="Prohibir"
                style={{
                  cursor: "pointer",
                  display: "inline",
                  fontSize: "23px"
                }}
                onClick={() => {
                  this.revertir(record, "update");
                }}
              >
                lock
              </i>
            ) : (
              <i
                className="material-icons"
                title="Permitir"
                style={{
                  cursor: "pointer",
                  display: "inline",
                  fontSize: "23px"
                }}
                onClick={() => {
                  this.revertir(record, "update");
                }}
              >
                lock_open
              </i>
            )}
          </td>
        )
      },
      {
        title: "Eliminar",
        dataIndex: "delete",
        key: "delete",
        render: (text, record) => (
          <td className="pof" style={{ padding: "0.45rem !important" }}>
            {text == 1 ? (
              <i
                className="material-icons"
                title="Prohibir"
                style={{
                  cursor: "pointer",
                  display: "inline",
                  fontSize: "23px"
                }}
                onClick={() => {
                  this.revertir(record, "delete");
                }}
              >
                lock
              </i>
            ) : (
              <i
                className="material-icons"
                title="Permitir"
                style={{
                  cursor: "pointer",
                  display: "inline",
                  fontSize: "23px"
                }}
                onClick={() => {
                  this.revertir(record, "delete");
                }}
              >
                lock_open
              </i>
            )}
          </td>
        )
      }
    ];

    return (
      <div>
        <>
          <Row noGutters className="page-header py-4" style={{ width: "100%" }}>
            <PageTitle sm="4" title="Permisos" className="text-sm-left" />
            <Col sm="4">
            </Col>
            <Col sm="4">
              <div className="d-flex">
              <p>
                Permitido: <i className="material-icons" title="Prohibir" style={{ display: "inline", fontSize: "23px"}}>lock</i> 
              </p>
              <p className="ml-2">
                Prohibido: <i className="material-icons" title="Permitir" style={{ display: "inline", fontSize: "23px",}}>lock_open</i>
              </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="12" className="py-2">
            </Col>
          </Row>
          <Row>
            <Col>
              <h6>Super-Admin</h6>
              <Card small className="mb-4">
                <CardBody className="p-0 pb-3">
                  <div class="table-responsive">
                    <Table
                      columns={columns}
                      locale={{ emptyText: "..." }}
                      dataSource={this.state.perm_superadmin}
                      pagination={{ pageSize: 5 }}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <h6>Admin - Concesionaria</h6>
              <Card small className="mb-4">
                <CardBody className="p-0 pb-3">
                  <div class="table-responsive">
                    <Table
                      columns={columns}
                      locale={{ emptyText: "..." }}
                      dataSource={this.state.perm_admin}
                      pagination={{ pageSize: 5 }}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <h6>Usuario - Concesionaria</h6>
              <Card small className="mb-4">
                <CardBody className="p-0 pb-3">
                  <div class="table-responsive">
                    <Table
                      columns={columns}
                      locale={{ emptyText: "..." }}
                      dataSource={this.state.perm_common}
                      pagination={{ pageSize: 5 }}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <h6>Data Entry - Concesionaria</h6>
              <Card small className="mb-4">
                <CardBody className="p-0 pb-3">
                  <div class="table-responsive">
                    <Table
                      columns={columns}
                      locale={{ emptyText: "..." }}
                      dataSource={this.state.perm_data_entry}
                      pagination={{ pageSize: 5 }}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <br />
          <br />
          <PolyLoader
            open={this.state.loading_modal}
            text="Cargando"
          ></PolyLoader>
        </>
      </div>
    );
  }
}

function mapState(state) {
  const { users, authentication, vehicles, adminuser } = state;

  const { user } = authentication;
  console.log("esto ->", vehicles);

  return { user, users, adminuser };
}

const actionCreators = {};

const connectedUsuarios = connect(
  mapState,
  actionCreators
)(Permisos);
export { connectedUsuarios as Permisos };
