import React from "react";
import { Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
//import { Store } from "../../../flux";
import { sidebarActions } from "../../../_actions";
import { connect } from "react-redux";

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navItems: this.props.sidebar.items
    };

    //this.onChange = this.onChange.bind(this);
  }

  handleToggleSidebar() {
    return this.props.toggleSidebar();
  }
  /*
  componentWillMount() {
    this.props.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    this.props.removeChangeListener(this.onChange);
  }
  
  onChange() {
    this.setState({
      ...this.state,
      navItems2: this.props.getSidebarItems
    });
  }
*/
  render() {
    const { navItems: items } = this.state;

    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          {items.map((item, idx) => (
            <SidebarNavItem onClick={this.handleToggleSidebar} key={idx} item={item} />
          ))}
        </Nav>
      </div>
    );
  }
}

//export default SidebarNavItems;

function mapState(state) {
  const { users, authentication, sidebar } = state;
  const { user } = authentication;
  return { user, users, sidebar };
  // const { users, authentication } = state;
}

const actionCreators = {
  getSidebarItems: sidebarActions.getSidebarItems,
  addChangeListener: sidebarActions.addChangeListener,
  removeChangeListener: sidebarActions.removeChangeListener,
  toggleSidebar: sidebarActions.toggleSidebar
};

//export default MainSidebar;

const connectedSidebarNavItems = connect(
  mapState,
  actionCreators
)(SidebarNavItems);
export { connectedSidebarNavItems as SidebarNavItems };
