import React from "react";

// Aca hacemos importaciones externas
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  Form,
  FormInput,
  FormGroup,
  FormTextarea,
  Card,
  CardBody,
  CardImg
} from "shards-react";
import { reduxForm, Field, getFormValues } from "redux-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Upload, Icon, Modal, notification, Tooltip } from 'antd';
import { adminuserActions } from "../_actions";
import { UploadWrapper } from './';
import ImagesUploader from "react-images-uploader";
import "react-images-uploader/styles.css";
import { API_URL } from "../_config";
import "react-images-uploader/font.css";
import {
  faCodeBranch,
  faChalkboardTeacher,
  faBars,
  faHandshake,
  faChessRook,
  faPhone,
  faEnvelope
} from "@fortawesome/free-solid-svg-icons";
import {
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Collapse
} from "shards-react";
import PageTitle from "../_components/PageTitle";
import { connect } from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import { store } from "../_helpers";
import { SideX } from "./";
import CheckBoxSimple from "../_common/CheckBoxSimple";
// Aca hacemos importaciones de la vista propia
import "../myDealer/myDealer.css";
import ModalFormMyDealer from "./WizardFormMyDealer/ModalFormMyDealer/ModalFormMyDealer";

import UpdateFile from "./FileUpdateLogo/UpdateFile";
import MyTeam from "./MyTeam/MyTeam";
import FeedbackClient from "./FeedbackClient/FeedbackClient";
import ServicesOfAgency from "./ServicesOfAgency/ServicesOfAgency";
const cookies = new Cookies();
var endImages = [];
var predata = [];



class myDealer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dealer: [],
      ok: false,
      previewVisible: false,
      url: '',
      previewImage: '',
      domain_loading: false,
      fileList: [],
      openModalFormMyDealer: false,
    };

    this.change_url_company = this.change_url_company.bind(this);
    this.openModalFormMyDealerFn = this.openModalFormMyDealerFn.bind(this);
  }

  componentWillMount() {
    this.props.getAyuda();
  }

  componentDidMount() {
    axios({
      method: "get",
      url:
        API_URL + "api/dealer/" +
        cookies.get("dealer").id +
        "/2?token=" +
        store.getState().authentication.user.token
    }).then(response => {
      this.setState({
        dealer: response.data,
        url: response.data.url_company,
        ok: true
      });
    });

    axios({
      method: "get",
      url:
        API_URL + "api/dealer/" + cookies.get("dealer").id + "/cover_image/image?token=" +
        store.getState().authentication.user.token
    }).then(response => {
      

      this.setState({
        fileList: response.data.images,
      });

      console.log(this.state.fileList);
    });

  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  handleChange = ({ fileList }) => this.setState({ fileList });

  edit(values) {
    notification.open({
      message: 'Editando Concesionaria',
      description:
        'Este proceso puede demorar unos minutos',
      icon: <Icon type="loading" style={{ color: '#4caf50' }} />,
      top: 70
    });
    axios
      .post(
        API_URL + "api/dealer/" +
          cookies.get("dealer").id +
          "?token=" +
          store.getState().authentication.user.token,
        {
          dealer: values,
        }
      )
      .then(response => {
            notification.destroy()
            notification.open({
              message: 'Operación exitosa',
              icon: <Icon type="check-circle" style={{ color: '#4caf50' }} />,
              top: 70
            });
      });
  }

  imageTrue(starts) {
    console.log("|||||IMAGENES ||||", starts);
  }

  change_url_company(newUrl) {
      // Envío la movida al backend
      notification.open({
        message: 'Cambiando URL, espera unos segundos',
        icon: <Icon type="loading" style={{ color: '#4caf50' }} />,
        top: 70
      });
      
      this.setState({
        domain_loading: true,
        url: newUrl
      });

      
      // if(event.target.value == '') {
      //   // Envío notificacion de que no puede estar vacío
      //   alert('Esta vacio');
      // }
      axios
      .post(
        API_URL + "api/dealer/" +
          cookies.get("dealer").id +
          "/chage_url_company?token=" +
          store.getState().authentication.user.token,
        {
          urlCompany: newUrl
        }
      )
      .then(response => {
            notification.destroy()
            notification.open({
              message: 'Operación exitosa',
              icon: <Icon type="check-circle" style={{ color: '#4caf50' }} />,
              top: 70
            });
        this.setState({
          domain_loading: false,
        });
      }).catch(error => {
        notification.destroy()
        notification.open({
          message: `Hemos tenido un error, ${error.response.data.error} Vuelve a intentarlo nuevamente.`,
          icon: <Icon type="close-circle" style={{ color: 'red' }} />,
          top: 70
        });
        this.setState({
          domain_loading: false,
        });
      })
    

  }
  refresh_data() {

    axios({
      method: "get",
      url:
        API_URL + "api/dealer/" +
        cookies.get("dealer").id +
        "/2?token=" +
        store.getState().authentication.user.token
    }).then(response => {
      this.setState({
        dealer: response.data,
        url: response.data.url_company,
        ok: true
      });
    });

    axios({
      method: "get",
      url:
        API_URL + "api/dealer/" + cookies.get("dealer").id + "/cover_image/image?token=" +
        store.getState().authentication.user.token
    }).then(response => {
      

      this.setState({
        fileList: response.data.images,
      });

      console.log(this.state.fileList);
    });

  }

  cambiar_perfil(file) {

    notification.open({
      message: 'Cambiando foto de perfil',
      description:
        'Este proceso puede demorar unos minutos',
      icon: <Icon type="loading" style={{ color: '#4caf50' }} />,
      top: 70
    });

    const data= new FormData()
			data.append('file', file)
			const config= {
				"headers": {
					"content-type": 'multipart/form-data'
				}
      }
      axios.post(API_URL + 'api/dealer/' + cookies.get("dealer").id + '/image/upload?token=' +
      store.getState().authentication.user.token, data, config).then((response) => {
            notification.destroy()
            notification.open({
              message: 'Operación exitosa',
              icon: <Icon type="check-circle" style={{ color: '#4caf50' }} />,
              top: 70
            });
        this.refresh_data();
	    }).catch((err) => {
				console.log(err)
			})
  }
  remover(message) {
    notification.open({
      message: 'Eliminando foto de perfil',
      description:
        'Este proceso puede demorar unos minutos',
      icon: <Icon type="loading" style={{ color: '#4caf50' }} />,
      top: 70
    });

    axios
      .delete(
        API_URL + "api/dealer/" +
          cookies.get("dealer").id +
          "/imagen/logo?token=" +
          store.getState().authentication.user.token
      )
      .then(response => {
            notification.destroy()
            notification.open({
              message: 'Operación exitosa',
              icon: <Icon type="check-circle" style={{ color: '#4caf50' }} />,
              top: 70
            });
        this.refresh_data();
      });
  }

  openModalFormMyDealerFn(){
    this.setState({openModalFormMyDealer: !this.state.openModalFormMyDealer})
  }

  render() {
    const { previewVisible, previewImage, fileList } = this.state;
    const propsx = {
      name: 'file',
      action: '#',
      headers: {},
      beforeUpload: file => this.cambiar_perfil(file),
      showUploadList: false,
      onChange(info) {
        
      },
    };
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Subir</div>
      </div>
    );

    return (
      <div>
        <>
          <Row noGutters className="page-header py-4">
          {/* <Button onClick={this.openModalFormMyDealerFn}>ABRIR FORM MODAL</Button>
          <ModalFormMyDealer openClose={this.openModalFormMyDealerFn} openState={this.state.openModalFormMyDealer} /> */}
          </Row>

         
          {this.state.ok && (
            <>
                      <SideX
                        except={true}
                        activedX={this.state.dealer}
                        style={{ width: "100%" }}
                        ownurl={this.state.url}
                        change_url_company={this.change_url_company}
                        domain_loading={this.state.domain_loading}
                        onSubmit={this.edit}
                      ></SideX>
                   
              
            <Row>
              <Col sm="12">
              <ServicesOfAgency/>
              <br/>
              </Col>
              <Col sm="12">
              <MyTeam/>
              <br/>
              </Col>
              <Col sm="12">
              <FeedbackClient/>
              <br/>
              </Col>
            </Row>
              <Row>
              <Col sm="6">
              <Card>
                <CardBody>
                {this.props.adminuser.list.find(element => element.number == 3) &&
                <Tooltip overlayStyle={{zIndex: '10000'}} title={this.props.adminuser.list.find(element => element.number == 3).text + '(3)'}>
                   <a style={{fontSize: '16px', marginTop: '2px', marginRight: '5px',float: 'left'}}><Icon type="question-circle"></Icon></a>
              </Tooltip>}
                  <h4 style={{textAlign: 'center'}}>Logo de su empresa</h4>
                  <hr className="mb-2" />
                    <UpdateFile imgDealer={this.state.dealer.cropped_image_url} removeImgDealer={this.remover}/>
                    </CardBody>
              </Card>
                  <br/><br/>
              </Col>
              <Col sm="6">
              <Card>
                    <CardBody>
                    <h4 style={{textAlign: 'center'}}>Fotos de Portada</h4>
                    <hr className="mb-2" />
                  
                    {this.state.fileList != [] && (
                    <UploadWrapper fileList={this.state.fileList}></UploadWrapper>
                  )}
                </CardBody>
              </Card>
              </Col>
              </Row>
              

              <br />
             
            </>
          )}
        </>
      </div>
    );
  }
}

function mapState(state) {
  const { users, authentication, adminuser } = state;

  const { user } = authentication;

  return { user, users, adminuser };
}

const actionCreators = {
  getAyuda: adminuserActions.getAyuda
};

const po = reduxForm({
  form: 'mydealer', 
  enableReinitialize: true
  })(myDealer);

const connectedVehicles = connect(
  mapState,
  actionCreators
)(po);
export { connectedVehicles as myDealer };
