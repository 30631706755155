import React, {useState} from 'react'
import { reduxForm, Field, getFormValues } from "redux-form";
import { connect } from "react-redux";
import InputField from "../_common/InputField";
import SelectSimple from "../_common/SelectSimple";
import {
  Row,
  Col,
  Button,
  Card,
} from "shards-react";

const CrearUsuario = props => {

  const [typePassword, setPassType] = useState(true)
  const [validationData, setValidationData] = useState({})
  const [enviar, setEnviar] = useState(true)
  const { handleSubmit, pristine, reset, submitting ,patchUbication } = props
  // boton ver password 
  const toggle = () => {
    if(typePassword == true) {
        setPassType(false);
      } else{
        setPassType(true);
      }
    }

  // render
  if(patchUbication === "createUser"){
    return (
      <form onSubmit={handleSubmit}>
        <div>
          <Row>
            <Col sm="6">
            <Field
              name="first_name"
              component={InputField}
              type="text"
              label="Nombre"
              required={true}
              
            />
            <Field
              name="last_name"
              component={InputField}
              type="text"
              label="Apellido"
              required={true}
              
            />
            </Col>
            <Col sm="6">
            <Field
              name="email"
              component={InputField}
              type="email"
              label="E-mail"
              required={true}
              
            />
            <Field
              name="phone"
              component={InputField}
              type="text"
              label="Teléfono"
              required={true}
              
            />
            </Col>
          </Row>
            <Field
              name="password"
              component={InputField}
              type={typePassword == true ? 'password' : 'text'}
              label="Contraseña"
              required={true}
              
            />
          <Field
              name={'view_password'}
              component="input"
              type="checkbox"
              onClick={toggle}
              checked={!typePassword}
          ></Field> Mostrar contraseña
          </div>
          <br/>
          <Button type="submit" disabled={pristine || submitting}>
              Confirmar
          </Button>
      </form>
    )
  }
  if(patchUbication === "editUser"){
    return  (
      <form onSubmit={handleSubmit}>
        <div>
          <Row>
            <Col sm="6">
            <Field
              name="first_name"
              component={InputField}
              type="text"
              label="Nombre"
            />
            <Field
              name="last_name"
              component={InputField}
              type="text"
              label="Apellido"
            />
            </Col>
            <Col sm="6">
            <Field
              name="email"
              component={InputField}
              type="email"
              label="E-mail"
            />
             <Field
              name="phone"
              component={InputField}
              type="text"
              label="Teléfono"
            />
            </Col>
          </Row>
            
        
            <Field
              name="password"
              component={InputField}
              type={typePassword == true ? 'password' : 'text'}
              label="Contraseña"
            />
  
          <Field
              name={'view_password'}
              component="input"
              type="checkbox"
              onClick={toggle}
              checked={!typePassword}
          ></Field> Mostrar contraseña
        
    
          </div>
    
       
          <br/>
          <Button type="submit" disabled={pristine || submitting}>
              Actualizar
          </Button>
      </form>
    )
  }
}


function mapState(state, props) {
  const { users, authentication, vehicles, adminuser } = state;
  const { user } = authentication;
  if(props.activedX) {
    return { user, users, adminuser, initialValues: props.activedX};
  }else{
    return { user, users, adminuser};
  }
}
const actionCreators = {
};
const po = reduxForm({
form: 'CrearUsuario', 
enableReinitialize: true
})(CrearUsuario);

const pox = connect(
  mapState,
  actionCreators
)(po);

export { pox as CrearUsuario };

