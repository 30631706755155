import { notificationsConstants } from "../_constants";

const initialState = {
  list: [],
};

export function notifications(state = initialState, action) {
  switch (action.type) {
    case notificationsConstants.GETNOTIFICATIONS_REQUEST:
      return {
        loading: true
      };
    case notificationsConstants.GETNOTIFICATIONS_SUCCESS:
      return {
        list: action.payload
      };
    case notificationsConstants.GETNOTIFICATIONS_SUCCESS:
      return {
        // lista de vehiculos

        ...state,
        list: action.payload.data
      };
    case notificationsConstants.GETNOTIFICATIONS_SHOW:
      //solo un vehiculo
      return {
        ...state,
        list: action.payload
      };
    case notificationsConstants.GETNOTIFICATIONS_FAILURE:
      return {
        error: action.error
      };
    
    default:
      return state;
  }
}
