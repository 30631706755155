import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {perm_validate} from "../_helpers/perm_validate"
export const AdminRoute = ({ categoria, permiso, component: Component, ...rest }) => (
    // Aca debo obtener los datos de las props que vengan de route y validar los permisos
    <Route exact {...rest} render={props => (
        localStorage.getItem('user') && perm_validate(categoria, permiso) == true
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    )} />
)