import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";
import SelectDynamic from "../_common/SelectDynamic";
import SelectSimple from "../_common/SelectSimple";
import validate from "../_common/Validate";
import InputField from "../_common/InputField";
import ImagesUploader from "react-images-uploader";
import "react-images-uploader/styles.css";
import { API_URL, PREAPI_URL } from "../_config";

import "react-images-uploader/font.css";
import axios from "axios";
//import PropTypes from "prop-types";
import { BrowserView } from "react-device-detect";
import {
  Row,
  Col,
  Button,
  FormCheckbox,
  Container,
  FormTextarea,
  CardHeader,
  Form,
  FormGroup,
  FormInput,
  FormSelect
} from "shards-react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
let type = require("./type.json");
let brands = require("./brands.json");

class VehiclePublish extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pictures: [],
      result: 0,
      typeVehicles: 1,
      brand: "",
      versions: "",
      condicion: 0,
      tipo: null
    };

    this.onDrop = this.onDrop.bind(this);
  }

  getResponse = e => {
    this.setState({ typeVehicles: e.target.value });
    //console.log('state ' + this.state.typeVehicles);
  };
  selectBrands = e => {
    // console.log('esteee ' + event.target.value)
    this.setState({ brand: e.target.value });

    axios({
      method: "get",
      url: API_URL + "api/v2/vehicle/models?token=" + this.props.user.token
    }).then(response => {
      //console.log()
      this.setState({ models: response });
      //const model = response.data;
    });
  };
  onDrop(picture) {
    this.setState({
      pictures: this.state.pictures.concat(picture)
    });
  }
  selectModel = e => {
    this.setState({ model: e.target.value });
    axios({
      method: "get",
      url:
        API_URL +
        "api/v2/vehicles/model/" +
        e.target.value +
        "/versions?token=" +
        this.props.user.token
    }).then(response => {
      //console.log(response)
      //this.setState({ versions: 'camiislsl ' });
      //const model = response.data;
      this.setState({ versions: response });

      // console.log('versiones ', this.state.versions);
    });
  };

  render() {
    const { handleSubmit, publisVehicules } = this.props;

    //console.log('que viene hoy'+publisVehicules.values.condicion)
    // const { typeVehicle } = this.state;
    // console.log('viene state ' + this.state.typeVehicles)
    return (
      <BrowserView>
        <Tabs>
          <TabList>
            <Tab>Datos</Tab>
            <Tab>Fotos</Tab>
            <Tab>Descripción</Tab>
            <Tab>Carácteristicas</Tab>
            <Tab>Publicaciones</Tab>
          </TabList>

          <TabPanel>
            <Row>
              <Col sm="6">
                <form onSubmit={handleSubmit}>
                  <FormGroup>
                    <label>Condición</label>

                    <Field
                      name="condicion"
                      children={[
                        ["0km", "0km"],
                        ["Usado", "Usado"],
                        ["Consignación", "Consignación"]
                      ]}
                      component={SelectSimple}
                    ></Field>
                  </FormGroup>

                  <FormGroup>
                    <label>Tipo</label>
                    <Field
                      name="tipo"
                      item={type}
                      onChange={e => this.getResponse(e)}
                      component={SelectDynamic}
                    ></Field>
                  </FormGroup>
                  <FormGroup>
                    <label>Marca {this.state.typeVehicles}</label>

                    <Field
                      name="brands"
                      item={brands}
                      onChange={e => this.selectBrands(e)}
                      component={SelectDynamic}
                      filter={"type_vehicle_id|" + this.state.typeVehicles}
                    ></Field>
                  </FormGroup>

                  <FormGroup>
                    <label>Modelo</label>
                    <Field
                      name="models"
                      item={this.state.models}
                      onChange={e => this.selectModel(e)}
                      component={SelectDynamic}
                      filter={"type_vehicle_brand_id|" + this.state.brand}
                    ></Field>
                  </FormGroup>

                  <FormGroup>
                    <label>Versión</label>
                    <Field
                      name="version"
                      item={this.state.versions}
                      component={SelectDynamic}
                    ></Field>
                  </FormGroup>
                  <FormGroup>
                    <label for="brand">
                      Título de MercadoLibre/OLX/Demotores
                      <small className="text-muted">
                        (Obligatorio) max 60 <span id="max_chars"></span>
                      </small>
                    </label>
                    <Field name="titleML" type="text" component={InputField} />
                  </FormGroup>

                  <FormGroup>
                    <label>Año</label>
                    <Field name="year" type="text" component={InputField} />
                  </FormGroup>

                  <FormGroup>
                    <label>Nº de Chasis</label>
                    <Field
                      name="chasis_number"
                      type="text"
                      component={InputField}
                    />
                  </FormGroup>

                  <FormGroup>
                    <label>Dirección</label>
                    <Field
                      name="direction"
                      children={[
                        ["Mecánica", "Mecánica"],
                        ["Hidráulica", "Hidráulica"],
                        ["Asistida", "Asistida "],
                        ["Eléctrica", "Eléctrica"]
                      ]}
                      component={SelectSimple}
                    ></Field>
                  </FormGroup>
                </form>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <label>Cantidad de Puertas</label>
                  <Field
                    name="doors"
                    children={[
                      ["2", "2"],
                      ["2", "2"],
                      ["3", "3"],
                      ["4", "4"],
                      ["5", "5"]
                    ]}
                    component={SelectSimple}
                  ></Field>
                </FormGroup>

                <FormGroup>
                  <label>Combustible</label>
                  <Field
                    name="fuel"
                    children={[
                      ["2", "Diésel"],
                      ["1", "Nafta"],
                      ["3", "Nafta/Gnc"]
                    ]}
                    component={SelectSimple}
                  ></Field>
                </FormGroup>

                <FormGroup>
                  <label>Color</label>
                  <Field
                    name="color"
                    className="form-control custom-select"
                    component="select"
                  >
                    <option />
                    <option value="25">A elección del cliente</option>
                    <option value="34">Aguamarina</option>
                    <option value="22">Almendra</option>
                    <option value="10">Amarillo</option>
                    <option value="19">Anis</option>
                    <option value="35">Arena</option>
                    <option value="9">Azul</option>
                    <option value="8">Beige</option>
                    <option value="31">Berenjena</option>
                    <option value="27">Bicolor</option>
                    <option value="4">Blanco</option>
                    <option value="6">Bordó</option>
                    <option value="33">Bronce</option>
                    <option value="11">Celeste</option>
                    <option value="13">Champagne</option>
                    <option value="26">Cobre</option>
                    <option value="32">Crema</option>
                    <option value="29">Dorado</option>
                    <option value="3">Gris</option>
                    <option value="21">Gris intermedio</option>
                    <option value="16">Gris Oscuro</option>
                    <option value="5">Gris Plata</option>
                    <option value="20">Gris Verdoso</option>
                    <option value="12">Marrón</option>
                    <option value="30">Mostaza</option>
                    <option value="18">Naranja</option>
                    <option value="2">Negro</option>
                    <option value="15">Ocre</option>
                    <option value="36">Orce</option>
                    <option value="23">Perlado</option>
                    <option value="37">Plateado</option>
                    <option value="14">Rojo</option>
                    <option value="38">Rosa</option>
                    <option value="24">Rosado</option>
                    <option value="28">Turquesa</option>
                    <option value="17">Varios disponibles</option>
                    <option value="1">Verde</option>
                    <option value="7">Violeta</option>
                  </Field>
                </FormGroup>
                <FormGroup>
                  <label>Kilometros</label>
                  <Field name="kilometers" type="text" component={InputField} />
                </FormGroup>
                <FormGroup>
                  <label>Títular</label>
                  <Field name="owner" type="text" component={InputField} />
                </FormGroup>

                <FormGroup>
                  <label>Precio</label>
                  <Field name="price" type="text" component={InputField} />
                </FormGroup>
                <FormGroup>
                  <label for="currency">Moneda</label>
                  <Field
                    name="currency"
                    className="form-control custom-select"
                    component="select"
                  >
                    <option value="1" className="">
                      ARS
                    </option>
                    <option value="2" className="">
                      USD
                    </option>
                  </Field>
                </FormGroup>

                <FormGroup>
                  <label>Transmision</label>
                  <Field
                    name="transmission"
                    className="form-control custom-select"
                    component="select"
                  >
                    <option value="CVT">CVT </option>
                    <option value="Secuencial">Secuencial </option>
                    <option value="Manual">Manual </option>
                    <option value="Automática">Automática </option>
                    <option value="N/E Autos">N/E Autos </option>
                  </Field>
                </FormGroup>
                <FormGroup>
                  <label for="heating">Climatización</label>
                  <Field
                    name="heating"
                    className="form-control custom-select"
                    component="select"
                  >
                    <option value="N/E">N/E </option>
                    <option value="Doble aire">Doble aire </option>
                    <option value="Calefacción">Calefacción </option>
                    <option value="Aire acondicionado">
                      Aire acondicionado{" "}
                    </option>
                  </Field>
                </FormGroup>
              </Col>

              <Col sm={{ size: 8, order: 2, offset: 10 }}>
                <Button disabled theme="success">
                  Guardar
                </Button>
              </Col>
            </Row>
          </TabPanel>
          <TabPanel>
            <FormGroup>
              <label>Link de Youtube</label>
              <FormInput name="precio" required />
            </FormGroup>
            <br />
            <div
              style={{
                width: "100%",
                padding: "15px",
                border: "3px dashed #9a9a9a"
              }}
            >
              <Row>
                <ImagesUploader
                  url="https://admin.nuestrosautos.com.ar/upload/no.php"
                  optimisticPreviews
                  inputId="file"
                  multiple={false}
                  onLoadEnd={err => {
                    if (err) {
                      console.error(err);
                    }
                  }}
                  label="Upload multiple images"
                />
              </Row>
            </div>
          </TabPanel>
          <TabPanel>
            <label>Descripción</label>
            <FormTextarea onChange={() => this.handleChange} rows="5" />
            <Button>Quitar URL</Button>
          </TabPanel>
          <TabPanel>
            <h5>Confort</h5>
            <hr />
            <FormCheckbox
              toggle
              checked={() => this.state.ac}
              onChange={() => this.handleChangeac}
            >
              Airbag conductor
            </FormCheckbox>

            <FormCheckbox
              toggle
              checked={() => this.state.asiento_regulable}
              onChange={() => this.handleChangeasiento_regulable}
            >
              Airbag pasajero
            </FormCheckbox>

            <FormCheckbox
              toggle
              checked={() => this.state.asiento_regulable}
              onChange={() => this.handleChangeasiento_regulable}
            >
              Apoya cabeza en asientos traseros
            </FormCheckbox>

            <FormCheckbox
              toggle
              checked={() => this.state.asiento_regulable}
              onChange={() => this.handleChangeasiento_regulable}
            >
              Control de estabilidad
            </FormCheckbox>

            <FormCheckbox
              toggle
              checked={() => this.state.asiento_regulable}
              onChange={() => this.handleChangeasiento_regulable}
            >
              Faros antinieblas delanteros
            </FormCheckbox>

            <FormCheckbox
              toggle
              checked={() => this.state.asiento_regulable}
              onChange={() => this.handleChangeasiento_regulable}
            >
              Frenos ABS
            </FormCheckbox>

            <FormCheckbox
              toggle
              checked={() => this.state.asiento_regulable}
              onChange={() => this.handleChangeasiento_regulable}
            >
              Regulador de velocidad
            </FormCheckbox>
            <h5>Seguridad</h5>
            <hr />
            <FormCheckbox
              toggle
              checked={() => this.state.asiento_regulable}
              onChange={() => this.handleChangeasiento_regulable}
            >
              Airbag de cortina
            </FormCheckbox>

            <FormCheckbox
              toggle
              checked={() => this.state.asiento_regulable}
              onChange={() => this.handleChangeasiento_regulable}
            >
              Airbag trasero
            </FormCheckbox>

            <FormCheckbox
              toggle
              checked={() => this.state.asiento_regulable}
              onChange={() => this.handleChangeasiento_regulable}
            >
              Blindado
            </FormCheckbox>
            <h5>Exterior y Equipamento</h5>
            <hr />
            <FormCheckbox
              toggle
              checked={() => this.state.asiento_regulable}
              onChange={() => this.handleChangeasiento_regulable}
            >
              Control de tracción
            </FormCheckbox>

            <FormCheckbox
              toggle
              checked={() => this.state.asiento_regulable}
              onChange={() => this.handleChangeasiento_regulable}
            >
              Faros antinieblas traseros
            </FormCheckbox>

            <FormCheckbox
              toggle
              checked={() => this.state.asiento_regulable}
              onChange={() => this.handleChangeasiento_regulable}
            >
              Inmovilizador de motor
            </FormCheckbox>

            <FormCheckbox
              toggle
              checked={() => this.state.asiento_regulable}
              onChange={() => this.handleChangeasiento_regulable}
            >
              Repartidor electrónico de fuerza de frenado
            </FormCheckbox>

            <FormCheckbox
              toggle
              checked={() => this.state.asiento_regulable}
              onChange={() => this.handleChangeasiento_regulable}
            >
              Airbag laterales
            </FormCheckbox>

            <FormCheckbox
              toggle
              checked={() => this.state.asiento_regulable}
              onChange={() => this.handleChangeasiento_regulable}
            >
              Alarma
            </FormCheckbox>

            <FormCheckbox
              toggle
              checked={() => this.state.asiento_regulable}
              onChange={() => this.handleChangeasiento_regulable}
            >
              Cierre de puertas automático en movimiento
            </FormCheckbox>
            <h5>Sonido y Adicionales</h5>
            <hr />
            <FormCheckbox
              toggle
              checked={() => this.state.asiento_regulable}
              onChange={() => this.handleChangeasiento_regulable}
            >
              Doble tracción
            </FormCheckbox>

            <FormCheckbox
              toggle
              checked={() => this.state.asiento_regulable}
              onChange={() => this.handleChangeasiento_regulable}
            >
              Faros de xenón
            </FormCheckbox>

            <FormCheckbox
              toggle
              checked={() => this.state.asiento_regulable}
              onChange={() => this.handleChangeasiento_regulable}
            >
              Isofix
            </FormCheckbox>

            <FormCheckbox
              toggle
              checked={() => this.state.asiento_regulable}
              onChange={() => this.handleChangeasiento_regulable}
            >
              Tercer stop
            </FormCheckbox>
          </TabPanel>
          <TabPanel>
            <Row>
              <Col>
                <img
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    margin: "4px",
                    boxShadow: "-1px 4px 16px -11px rgba(0,0,0,0.75)"
                  }}
                  src="/img/meli.png"
                />
                <FormCheckbox
                  style={{ alingItems: "center", justifyContent: "center" }}
                  toggle
                  checked={() => this.state.asiento_regulable}
                  onChange={() => this.handleChangeasiento_regulable}
                ></FormCheckbox>
              </Col>
              <Col>
                <img
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    margin: "4px",
                    boxShadow: "-1px 4px 16px -11px rgba(0,0,0,0.75)"
                  }}
                  src="/img/olx.png"
                />
                <FormCheckbox
                  style={{ alingItems: "center", justifyContent: "center" }}
                  toggle
                  checked={() => this.state.asiento_regulable}
                  onChange={() => this.handleChangeasiento_regulable}
                ></FormCheckbox>
              </Col>
              <Col>
                <img
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    margin: "4px",
                    boxShadow: "-1px 4px 16px -11px rgba(0,0,0,0.75)"
                  }}
                  src="/img/dm.png"
                />
                <FormCheckbox
                  style={{ alingItems: "center", justifyContent: "center" }}
                  toggle
                  checked={() => this.state.asiento_regulable}
                  onChange={() => this.handleChangeasiento_regulable}
                ></FormCheckbox>
              </Col>
              <Col>
                <img
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    margin: "4px",
                    boxShadow: "-1px 4px 16px -11px rgba(0,0,0,0.75)"
                  }}
                  src="/img/autocosmos.png"
                />
                <FormCheckbox
                  style={{ alingItems: "center", justifyContent: "center" }}
                  toggle
                  checked={() => this.state.asiento_regulable}
                  onChange={() => this.handleChangeasiento_regulable}
                ></FormCheckbox>
              </Col>
              <Col>
                <img
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    margin: "4px",
                    boxShadow: "-1px 4px 16px -11px rgba(0,0,0,0.75)"
                  }}
                  src="/img/na.png"
                />
                <FormCheckbox
                  style={{ alingItems: "center", justifyContent: "center" }}
                  toggle
                  checked={() => this.state.asiento_regulable}
                  onChange={() => this.handleChangeasiento_regulable}
                ></FormCheckbox>
              </Col>
              <Col>
                <img
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    margin: "4px",
                    boxShadow: "-1px 4px 16px -11px rgba(0,0,0,0.75)"
                  }}
                  src="/img/www.png"
                />
                <FormCheckbox
                  style={{ alingItems: "center", justifyContent: "center" }}
                  toggle
                  checked={() => this.state.asiento_regulable}
                  onChange={() => this.handleChangeasiento_regulable}
                ></FormCheckbox>
              </Col>
              <Col>
                <img
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    margin: "4px",
                    boxShadow: "-1px 4px 16px -11px rgba(0,0,0,0.75)"
                  }}
                  src="/img/facebook.png"
                />
                <FormCheckbox
                  style={{ alingItems: "center", justifyContent: "center" }}
                  toggle
                  checked={() => this.state.asiento_regulable}
                  onChange={() => this.handleChangeasiento_regulable}
                ></FormCheckbox>
              </Col>
              <Col>
                <img
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    margin: "4px",
                    boxShadow: "-1px 4px 16px -11px rgba(0,0,0,0.75)"
                  }}
                  src="/img/instagram.png"
                />
                <FormCheckbox
                  style={{ alingItems: "center", justifyContent: "center" }}
                  toggle
                  checked={() => this.state.asiento_regulable}
                  onChange={() => this.handleChangeasiento_regulable}
                ></FormCheckbox>
              </Col>
            </Row>
          </TabPanel>
        </Tabs>
      </BrowserView>
    );
  }
}

function mapState(state) {
  const { users, authentication, form } = state;
  const { user } = authentication;
  const { publisVehicules } = form;
  return { user, users, publisVehicules };
}

const actionCreators = {};

//const connectedVehiclePublish = connect(mapState, actionCreators)(VehiclePublish);
//export { connectedVehiclePublish as VehiclePublish };

VehiclePublish = connect(
  mapState,
  actionCreators
)(VehiclePublish);

export default reduxForm({
  form: "publisVehicules", // a unique name for this form
  forceUnregisterOnUnmount: true,
  validate
})(VehiclePublish);
