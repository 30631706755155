import React from "react";
import { messaging } from "./init-fcm";
import { Router, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { history } from "./_helpers";
import { PrivateRoute, AdminRoute, PrivateRouteX } from "./_components";
import routes from "./routes";
import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const registerPushListener = () => {
  navigator.serviceWorker.addEventListener("message", ({ data }) => {
    const notificationData = data.data || data["firebase-messaging-msg-data"].notification;
    if (Notification.permission === "granted") {
      new Notification(notificationData.title, { body: notificationData.body });
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission(permission => {
        if (permission === "granted") {
          new Notification(notificationData.title, { body: notificationData.body });
        }
      });
    }
  });
};

class App extends React.Component {
  constructor(props) {
    super(props);
    history.listen((location, action) => {
      // clear alert on location change
    });
  }

  async componentDidMount() {
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          localStorage.removeItem("permission");
          localStorage.removeItem("user2");
          localStorage.removeItem("dealer_list");
          cookies.remove("dealer");
          window.location.replace("/");
        }
        return Promise.reject(error);
      }
    );

    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const token = await messaging.getToken();
        localStorage.setItem("push_token", token);
        registerPushListener();
      } else {
        console.log("Permission wasn't granted. Allow a retry.");
      }
    } catch (err) {
      console.log("Unable to get permission to notify.", err);
    }
  }

  render() {
    const { alert } = this.props;
    return (
      <Router history={history}>
        <div>
          <PrivateRouteX
            path="/"
            exact
            component={() => <Redirect to="/dashboard" />}
          />
          <PrivateRoute
            path="/logout"
            exact
            component={() => {
              localStorage.removeItem("user");
              localStorage.removeItem("user2");
              cookies.remove("dealer");
              return <Redirect to="/" />;
            }}
          />
          {routes.map((route, index) => {
            if (route.admin) {
              return (
                <AdminRoute
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  categoria={route.categoria}
                  permiso={route.permiso}
                  component={props => (
                    <route.layout {...props}>
                      {alert.message && (
                        <div className={`alert ${alert.type}`}>{alert.message}</div>
                      )}
                      <route.component {...props} />
                    </route.layout>
                  )}
                />
              );
            } else if (route.private) {
              return (
                <PrivateRoute
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={props => (
                    <route.layout {...props}>
                      <route.component {...props} />
                    </route.layout>
                  )}
                />
              );
            } else {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={props => (
                    <route.layout {...props}>
                      <route.component {...props} />
                    </route.layout>
                  )}
                />
              );
            }
          })}
        </div>
      </Router>
    );
  }
}

const mapStateToProps = state => {
  const { alert } = state;
  return { alert };
};

const connectedApp = connect(mapStateToProps)(App);

export default connectedApp;



