import React from 'react'
import { reduxForm, Field, getFormValues } from "redux-form";
import { connect } from "react-redux";
import InputField from "../_common/InputField";
import SelectSimple from "../_common/SelectSimple";
import WysiwygEditor from "../_common/WysiwygEditor";
import {
  Row,
  Col,
  Card,
  Button
} from "shards-react";

const CrearPerfil = props => {
  const { handleSubmit, pristine, reset, submitting } = props
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Row>
          <Col sm="6">


          <Field
            name="title"
            component={InputField}
            type="text"
            label="Nombre"
          />



          </Col>
          <Col sm="6">

          <Field
          name="type"
          label="Tipo"
          children={[
            ["warning", "Alerta"],
            ["error", "Urgente"],
            ["info", "Información"]
          ]}
          component={SelectSimple}
        ></Field>
        
          </Col>
        </Row>

        <br/>

          <label>Mensaje</label>  
          <div style={{width:'100%'}}>
            <Field
              name="text"
              component={WysiwygEditor}
              label="Mensaje"
              style={{width:'100%'}}
              rows={4}
            />
          </div>
          
      
      
  
        </div>
  
     
        <br/>
        <Button type="submit" disabled={pristine || submitting}>
            Actualizar
        </Button>
    </form>
  )
}

function mapState(state, props) {
   
  const { users, authentication, vehicles, adminuser } = state;

  const { user } = authentication;

  if(props.activedX) {
    return { user, users, adminuser, initialValues: props.activedX};
  }else{
    return { user, users, adminuser};
  }
}
const actionCreators = {

};

const po = reduxForm({
form: 'CrearPerfil', 
enableReinitialize: true
})(CrearPerfil);

const pox = connect(
  mapState,
  actionCreators
)(po);

export { pox as CrearPerfil };

