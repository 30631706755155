import React from "react";
import PropTypes from "prop-types";

import { Col, Nav } from "shards-react";
import classNames from "classnames";

import { SidebarMainNavbar } from "./SidebarMainNavbar";
import SidebarSearch from "./SidebarSearch";
import { SidebarNavItems } from "./SidebarNavItems";

import NewSideNavItem from "./NewSideNavItem";
//import { SidebarNavItemsSecond } from "./SidebarNavItemsSecond";
import { connect } from "react-redux";
import { sidebarActions } from "../../../_actions";

class MainSidebar extends React.Component {
  constructor(props) {
    super(props);
    /* this.state = {
      navItems: this.props.sidebar.items
    };*/
    this.handleToggleSidebar = this.handleToggleSidebar.bind(this);
  }

  handleToggleSidebar() {
    return this.props.toggleSidebar();
  }
  /* omponentWillMount() {
    //this.props.addChangeListener(this.onChange);
    
  }
 
  componentWillUnmount() {
   // this.props.removeChangeListener(this.onChange);
  // this.props.changeItems(0);
  }

  
 
  onChange() {
    this.setState({
      ...this.state,
      //  menuVisible: this.props.getMenuState(),
      sidebarNavItems: this.props.getSidebarItems
    });
  }*/
  changeMenu(e, menu) {
    e.preventDefault();
    return this.props.changeItems(menu);
  }
  render() {
    const classes = classNames(
      "main-sidebar",
      "px-0",
      "col-12",
      "noprinter",
      this.props.sidebar.menuVisible && "open"
    );

    const items = this.props.sidebar.items;
    return (
      <Col tag="aside" className={classes} lg={{ size: 2 }} md={{ size: 3 }} >
        <SidebarMainNavbar hideLogoText={this.props.hideLogoText} />
        <div className="nav-wrapper">
          <Nav className="nav--no-borders flex-column">
            <NewSideNavItem movida={this.handleToggleSidebar} items={items} />
          </Nav>
        </div>
      </Col>
    );
  }
}

MainSidebar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};
/*
MainSidebar.defaultProps = {
  hideLogoText: false
};
*/

function mapState(state) {
  const { users, authentication, sidebar } = state;

  //console.log("aca ta ->", sidebar);
  const { user } = authentication;
  return { user, users, sidebar };
  // const { users, authentication } = state;
}

const actionCreators = {
  changeItems: sidebarActions.getSidebarItems,
  toggleSidebar: sidebarActions.toggleSidebar
  //getSidebarItems: sidebarActions.getSidebarItems()
  //addChangeListener: sidebarActions.addChangeListener,
  //removeChangeListener: sidebarActions.removeChangeListener
};

//export default MainSidebar;

const connectedMainSidebar = connect(
  mapState,
  actionCreators
)(MainSidebar);
export { connectedMainSidebar as MainSidebar };
