  const validate  = values => {
    const errors = {};
    if (!values.price) {
      errors.price = "Complete este campo para continuar";
    }
  
    if (!values.currency_id) {
      errors.currency_id = "Complete este campo para continuar";
    }
  
    if (!values.year) {
      errors.year = "Complete este campo para continuar";
    }
  
    if (!values.doors) {
      errors.doors = "Complete este campo para continuar";
    }

    if (!values.type_vehicle_fuel_id) {
      errors.type_vehicle_fuel_id = "Complete este campo para continuar";
    }
   /* console.log('|||Validate||',values)
    if (!values.sale_condition) {
      errors.sale_condition = "Complete este campo para continuar";
    }
  
  
    
    if (!values.sale_condition) {
      errors.sale_condition = "Complete este campo para continuar";
    }
    if (!values.titleML) {
      errors.titleML = "Complete este campo para continuar";
    }
    if (!values.email) {
      errors.email = "Complete este campo para continuar";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }
    if (!values.sex) {
      errors.sex = "Complete este campo para continuar";
    }
    if (!values.favoriteColor) {
      errors.favoriteColor = "Complete este campo para continuar";
    }*/
    console.log(errors);
    console.log('rexd');
    return errors;
  };
  
  export default validate;
  
