import { vehiclesConstants, alertConstants } from "../_constants";
import { API_URL, PREAPI_URL } from "../_config";
import { vehiclesServices } from "../_services";
import { store } from "../_helpers";
import Cookies from "universal-cookie";
import axios from "axios";
const cookies = new Cookies();

//console.log('Cook', cookies.get('dealer').id)
/*
export function vehiclesActions() {

  return (dispatch, getState) => {
    axios.get(API_URL + 'api/churrasco?brand=&fuel=&model=&page=1&region=&tag=&type=&vendidos=&version=&year=')
      .then((response) => {
        dispatch({ type: vehiclesConstants.GETVEHICLES_SUCCESS, payload: response.data })
      })
  }

}

*/

export const vehiclesActions = {
  getVehicles,
  getSharedVehicles,
  showVehicle,
  getConcesionarias,
  getDetailVehicle,
  getDealerVehicles
};

function getVehicles() {
  return dispatch => {
    // axios.get(API_URL + 'api/churrasco?brand=&fuel=&model=&page=1&region=&tag=&type=&vendidos=&version=&year=')
    if (localStorage.getItem("vehicle_list") === null) {
      axios({
        method: "get",
        url:
            API_URL + "api/dealer/" +
          cookies.get("dealer").id +
          "/vehicles_new?brand&fuel&model&page=1&region&tag&type&vendidos&version&year&token=" +
          store.getState().authentication.user.token
      }).then(response => {
        localStorage.setItem("vehicle_list", JSON.stringify(response.data));
        dispatch({
          type: vehiclesConstants.GETVEHICLES_SUCCESS,
          payload: response.data
        });
      });
    } else {
      dispatch({
        type: vehiclesConstants.GETVEHICLES_SUCCESS,
        payload: JSON.parse(localStorage.getItem("vehicle_list"))
      });
    }
  };
}

function getConcesionarias() {
  return dispatch => {
    // axios.get(API_URL + 'api/churrasco?brand=&fuel=&model=&page=1&region=&tag=&type=&vendidos=&version=&year=')
    axios({
      method: "get",
      url:
          API_URL + "api/dealer?token=" +
        store.getState().authentication.user.token
    }).then(response => {
      dispatch({
        type: vehiclesConstants.GETCONCESIONARIAS_SUCCESS,
        payload: response.data
      });
    });
  };
}

function getSharedVehicles() {
  return dispatch => {
    // axios.get(API_URL + 'api/churrasco?brand=&fuel=&model=&page=1&region=&tag=&type=&vendidos=&version=&year=')
    axios({
      method: "get",
      url:
          API_URL + "api/dealer/" +
        cookies.get("dealer").id +
        "/nuestros-autos/vehicles/share_new?brand=&fuel=&model=&page=1&region=&tag=&type=&vendidos=&version=&year=&token=" +
        store.getState().authentication.user.token
    }).then(response => {
      dispatch({
        type: vehiclesConstants.GETSHAREDVEHICLES_SUCCESS,
        payload: response.data,
        total: response.total
      });
    });
  };
}

function showVehicle(id) {
  return dispatch => {
    vehiclesServices.showVehicle(id).then(response => {
      console.log("PAYLOAD", response);
      dispatch(show(id, response));
    });
  };
}
function getDetailVehicle(id) {
  return dispatch => {
    axios({
      method: "get",
      url:`${API_URL}/api/classifieds/detail/${id}`
    }).then(response => {
      dispatch({
        type: vehiclesConstants.GETDATADETAILVEHICLE,
        payload: response.data
      });
    });
  };
  };
function getDealerVehicles(id) {
  return dispatch => {
    axios({
      method: "get",
      url:`${API_URL}api/classifieds/vehicles-dealers/${id}`
    }).then(response => {
      dispatch({
        type: vehiclesConstants.GETDATADEALERVEHICLES,
        payload: response.data
      });
    });
  };
  };

function show(id, payload) {
  return { type: vehiclesConstants.GETVEHICLE_SHOW, id, payload };
}

/**
 * 
 * 
 *  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
 */
