import React from "react";
import { Modal, Form, Input } from "antd";  
const ModalFormComponent = ({ visible, onCancel, onCreate, form }) => {
  const { getFieldDecorator } = form;
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 10 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  return (
    <Modal
      visible={visible}
      okText="Enviar"
      onCancel={onCancel}
      onOk={onCreate}
      title="Formulario de ingreso"
    >
      <Form onFinish={onCreate} {...layout} >
        <Form.Item label="Usuario">
          {getFieldDecorator("usuario", {
            rules: [
              {
                placeholder: "Usuario",
                required: true,
                type: 'email',
                message: "Por favor ingrese su correo electrónico con el cual se registró"
              }
            ]
            
          })(<Input onKeyDown={(e)=> e.keyCode == 13 ? onCreate(e): ''}/>)}
        </Form.Item>
        <Form.Item label="Contraseña">
          {getFieldDecorator("pass",{rules: [
            {
              required: true,
              message: "Necesita ingresar una contraseña válida"
            }
          ]})(<Input onKeyDown={(e)=> e.keyCode == 13 ? onCreate(e): ''} type="password" />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

const ModalForm = Form.create({ name: "modal_form" })(ModalFormComponent);

export default ModalForm;
