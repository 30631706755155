import { notification } from "antd";
import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { store } from "../_helpers";
import { connect } from "react-redux";
import axios from "axios";
import UpImage from "./UpImage/UpImage";
import { API_URL } from "../_config";

const cookies = new Cookies();

const openNotificationWithIcon = (type, til, msg) => {
  let $congf = {
    message: til,
    description: msg,
    top: 70
  };
  notification[type]($congf);
};

const SubirImagenes = React.memo(({ onChange: onChangeInitial, ...props }) => {
  const [fileList, setFileList] = useState(null);

  useEffect(() => {
    localStorage.removeItem("vehicle_list");
    axios({
      method: "get",
      url:
        API_URL +
       "api/dealer/" +
        cookies.get("dealer").id +
        "/vehicles/" +
        props.id_vehicle +
       "/images?token=" +
        store.getState().authentication.user.token
    }).then(response => {
      setFileList(response.data.images);
      var data = [];
    });
  }, []);
  const onChange = ({ fileList }) => {
    setFileList(fileList);
  };

  console.log(props.imagenesData);
  console.log('aca esta andado')
  return (
    <div>
        {fileList ? (
        <UpImage
          filelist={fileList}
          id_vehicle={props.id_vehicle}
          onFilesAdded={console.log}
          enviarData={props.enviarData()}
          prop={props.prop}
        />
        ) : (
          <a
          className="nav-link  text-nowrap px-3"
          href="#"
          style={{
            paddingBottom: "2px",
            marginBottom: "20px",
     
          }}
        >
          <img
            class="user-avatar rounded-circle mr-2"
            src="/img/loadingnew2.gif"
            alt="User Avatar"
            style={{
              width: "60px",
              /* font-size: 17px!important; */
              marginTop: "-7px"
            }}
          />
          <span style={{ fontSize: "19px" }}>
            Cargando las imagenes
          </span>
        </a>
         )}
    </div>
  );
});

function mapState(state) {
  const { users, authentication, vehicles } = state;

  const { user } = authentication;

  return { user, users, vehicles };
}
const actionCreators = {};

const connectedVehicles = connect(
  mapState,
  actionCreators
)(SubirImagenes);
export { connectedVehicles as SubirImagenes };
