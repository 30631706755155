
import React from 'react'
import { Collapse ,Col,Row} from 'antd';

const { Panel } = Collapse;

const DescriptionCar = ({dataDescription}) => {
    const datosDescription =dataDescription.datos &&  dataDescription.datos.description
    const dataCaracteristicas =  dataDescription && dataDescription.features
    return (
        <div >
            <div className="paddingsButtons">
            <h5>Descripción</h5>
            <Col>
            <p className="fontTextDescription">{datosDescription}</p>
            </Col>
            </div>

            <h5>Características</h5>
            <Col className="paddingsButtons">
            <Collapse accordion={false} className={`collapse-container-custom`} >

                <Panel header="CONFORT" key="1" className="collapse-panel-custom">
                    {dataCaracteristicas && dataCaracteristicas.CONFORT.length > 0 ? 
                    dataCaracteristicas.CONFORT.map(i => (
                        <p key={i.id} >{i.name}</p>
                    )): <p>No se encuentran características para este vehiculo</p>                    
                }
                </Panel>

                <Panel header="EXTERIOR" key="2" className="collapse-panel-custom">
                {dataCaracteristicas && dataCaracteristicas.EXTERIOR.length > 0 ? 
                    dataCaracteristicas.CONFORT.map(i => (
                        <p key={i.id} >{i.name}</p>
                    )): <p>No se encuentran características para este vehiculo</p>                    
                }
                </Panel>

                <Panel header="SEGURIDAD" key="3" className="collapse-panel-custom">
                {dataCaracteristicas && dataCaracteristicas.SEGURIDAD.length > 0 ? 
                    dataCaracteristicas.CONFORT.map(i => (
                        <p key={i.id} >{i.name}</p>
                    )): <p>No se encuentran características para este vehiculo</p>                    
                }
                </Panel>

                <Panel header="SONIDO" key="4" className="collapse-panel-custom">
                {dataCaracteristicas && dataCaracteristicas.SONIDO.length > 0 ? 
                    dataCaracteristicas.CONFORT.map(i => (
                        <p key={i.id} >{i.name}</p>
                    )): <p>No se encuentran características para este vehiculo</p>                    
                }
                </Panel>

            </Collapse>
            </Col>
        </div>
    )
}

export default DescriptionCar
