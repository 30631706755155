import React, {useState, useEffect} from 'react'
import { reduxForm, Field, getFormValues } from "redux-form";
import { connect } from "react-redux";
import axios from 'axios';
import { store } from "../_helpers";
import InputField from "../_common/InputField";
import SelectSimple from "../_common/SelectSimple";
import SelectSimpleParser from "../_common/SelectSimpleParser";
import SelectDynamic from "../_common/SelectSimple";
import CheckBoxSimple from "../_common/CheckBoxSimple";
import { API_URL } from "../_config";
import {
  Row,
  Col,
  Card,
  Button
} from "shards-react";

var provincia = undefined;

const EditarConcesionaria = props => {
 
    const [provincias, setProvincias] = useState([]);
    const [partidos, setPartido] = useState([]);
    const [barrios, setBarrio] = useState([]);
    const [whatsapp, setWhatsApp] = useState(props.activedX.whatsapp);

    useEffect(() => {
        axios({
            method: "get",
            url:
              API_URL + "api/region?token=" +
              store.getState().authentication.user.token
          }).then(response => {
            setProvincias(response.data);
          });
 
          axios({
            method: "get",
            url:
              API_URL + "api/region-city/" + props.activedX.region_id
          }).then(response => {
            setPartido(response.data);
          }); 

          axios({
            method: "get",
            url:
              API_URL + "api/region-state-city/" + props.activedX.region_city_id
          }).then(response => {
            setBarrio(response.data);
          });
    
  }, [props.activedX]);

    const call_partido = val => {
        axios({
            method: "get",
            url:
              API_URL + "api/region-city/" + val
          }).then(response => {
            setPartido(response.data);
          });
    }
    const call_barrio = val => {
        axios({
            method: "get",
            url:
              API_URL + "api/region-state-city/" + val
          }).then(response => {
            setBarrio(response.data);
          });
    }

    const wp_toggle = () => {
        if(whatsapp == 0) {
            setWhatsApp(1);
        }else{
            setWhatsApp(0);
        }
    }


  const { handleSubmit, pristine, reset, submitting, except } = props
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Row>
          <Col sm="6">
          <Field
            name="partner"
            component={InputField}
            type="text"
            label="Socio"
          />
          <Field
            name="company"
            component={InputField}
            type="text"
            label="Empresa"
          />

            <Field
            name="email"
            component={InputField}
            type="text"
            label="Email"
          />

          <Field
            name="address"
            component={InputField}
            type="text"
            label="Dirección"
          />
          <Field
          name="dni_type"
          children={[
            ["CI", "CI"],
            ["DU", "DU"],
            ["LC", "LC"],
            ["LE", "LE"]
          ]}
          label="Tipo de Documento"
          component={SelectSimple}
        ></Field>
          <Field
            name="dni"
            component={InputField}
            type="text"
            label="Número de Documento"
          />

          <Field
            name="region_id"
            children={provincias}
            component={SelectSimpleParser}
            label="Provincia"
            onChange={(e) => {
                const val = e.target.value
                if(val != ''){
                   call_partido(val);
                }
            }}
          />

            <Field 
            name="region_city_id"
            children={partidos}
            component={SelectSimpleParser}
            label="Partido"
            onChange={(e) => {
                const val = e.target.value
                if(val != ''){
                   call_barrio(val);
                }
            }}
          />
          

         <Field
            name="region_barrio_id"
            children={barrios}
            component={SelectSimpleParser}
            label="Barrio"
          />


         {!except && 
         <>
         <label for="validatedCheck">Es Socio de la CCA</label>
         <Field
            name={'validated'}
            label={'Es Socio de la CCA'}
            component='input'
            type="checkbox"
            id="validatedCheck"
        ></Field>
        </>
         }
        <p style={{marginTop:'0px', marginLeft: '0px', fontSize: '12px'}}>(Podrá acceder a todas las características del sistema) </p>
          </Col>
          <Col sm="6">
          <Field
            name="cuit"
            component={InputField}
            type="number"
            label="CUIT"
          />
           <Field
            name="last_name"
            component={InputField}
            type="text"
            label="Apellido"
          />
            <Field
            name="name"
            component={InputField}
            type="text"
            label="Nombre"
          />

         <Field
            name="phone"
            component={InputField}
            type="number"
            label="Teléfono"
          />

          <Field
            name="phone2"
            component={InputField}
            type="number"
            label="Segundo Telefono"
          />


          <Field
            name="cellphone"
            component={InputField}
            type="number"
            label="Celular"
          />

        <Field
            name={'whatsapp'}
            component="input"
            type="checkbox"
            onClick={wp_toggle}
            checked={whatsapp == 0 ? false : true}
        ></Field> WhatsApp 
          
          <br/><br/>
          <Field
            name="contact"
            component={InputField}
            type="text"
            label="Contacto"
          />

          <Field
            name="work_hours"
            component={InputField}
            type="text"
            label="Horarios"
          />

          <Field
            name="zip_code"
            component={InputField}
            type="text"
            label="Código Postal"
          />
          
          </Col>
        </Row>
        </div>
  
        <br/>
        <Button type="submit" disabled={pristine || submitting}>
            Editar
        </Button>
    </form>
  )
}

function mapState(state, props) {
   
  const { users, authentication, vehicles, adminuser } = state;

  const { user } = authentication;

  if(props.activedX) {
    return { user, users, adminuser, initialValues: props.activedX};
  }else{
    return { user, users, adminuser};
  }
}
const actionCreators = {

};

const po = reduxForm({
form: 'EditarConcesionaria', 
enableReinitialize: true
})(EditarConcesionaria);

const pox = connect(
  mapState,
  actionCreators
)(po);

export { pox as EditarConcesionaria };

