import React from 'react'
import { Field, reduxForm } from 'redux-form';
import RenderFieldMyDealer from '../RenderFieldMyDealer';
import validate from '../validate';

const WizardFormFirstPageMyDealer = ({ handleSubmit }) => {
  return (
    <div>
      <form onSubmit={handleSubmit}>
      <Field
        name="socio"
        type="text"
        component={RenderFieldMyDealer}
        label="Socio Número:"
      />
      <Field
        name="empresa"
        type="text"
        component={RenderFieldMyDealer}
        label="Empresa:"
      />
      <Field
        name="cuit"
        type="text"
        component={RenderFieldMyDealer}
        label="CUIT:"
      />
      <Field
        name="direccion"
        type="text"
        component={RenderFieldMyDealer}
        label="Dirección:"
      />
      <Field
        name="provincia"
        type="text"
        component={RenderFieldMyDealer}
        label="Provincia:"
      />
      <Field
        name="partido"
        type="text"
        component={RenderFieldMyDealer}
        label="Partido:"
      />
      <Field
        name="barrio"
        type="text"
        component={RenderFieldMyDealer}
        label="Barrio:"
      />
      <Field
        name="cp"
        type="text"
        component={RenderFieldMyDealer}
        label="Codigo Postal:"
      />
      <Field
        name="pais"
        type="text"
        component={RenderFieldMyDealer}
        label="País:"
      />
      <div style={{paddingTop : "20px"}}>
        <button className="btn btn-primary" type="submit"> Siguiente </button>
      </div>
    </form>
    </div>
  )
}
export default reduxForm({
  form: 'wizardFormMyDealer', 
  // validate,
  destroyOnUnmount: false, 
  forceUnregisterOnUnmount: true,
})(WizardFormFirstPageMyDealer);
