import React from 'react'
import { Row, Col } from 'antd';
import "react-image-gallery/styles/css/image-gallery.css";
import SliderImages from './SliderImages';
import InfoDealer from './InfoDealer'

const SectionInfoContainer = ({dataInfo,showHide,showHideFunc}) => {
    return (
        <Row gutter={16}>
            <Col span={16} className="sliderImageSectionDetail">
                <SliderImages imagesCar={dataInfo.images}/>
            </Col>
            <Col span={8} className="infoDealerSectionDetail">
                <InfoDealer dataInfoCar={dataInfo.datos} showHideFunc={showHideFunc} showHide={showHide} />
            </Col>
        </Row>
    )
    
}

export default SectionInfoContainer
