import React from "react";
import { connect } from "react-redux";
//import { Dispatcher, Constants } from "../../../flux";
import { sidebarActions } from "../../../_actions";
import "./style.css";
class NavbarToggle extends React.Component {
  constructor(props) {
    super(props);
    //const dispatch = useDispatch();
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    /*return () =>
      dispatch({
        actionType: TOGGLE_SIDEBAR
      });*/
    return this.props.toggleSidebar();
  }

  // returndispatch({ type: sidebarActions.TOGGLE_SIDEBAR });

  render() {
    return (
      <nav className="nav mobile">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href="#"
          onClick={this.handleClick}
          id="toggleSidebar"
          className="nav-link nav-link-icon toggle-sidebar text-center d-md-none"
        >
        <span>
        <i className="material-icons">&#xE5D2;</i>
        </span>
         <img src="/img/iso-logo.png" id="logo-small"/>
        </a>
      </nav>
    );
  }
}

//export default NavbarToggle;
function mapState(state) {
  //navItems: this.props.getSidebarItems();

  const { navItems, menuVisible } = state;
  //const { user } = authentication;
  return { navItems, menuVisible };
  // const { users, authentication } = state; */
}

const actionCreators = {
  toggleSidebar: sidebarActions.toggleSidebar
};

const connectedNavbarToggle = connect(
  mapState,
  actionCreators
)(NavbarToggle);
export { connectedNavbarToggle as NavbarToggle };
