import React,{useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import RedVehiclesList from '../../RedVehiclesList'
import {vehiclesActions} from '../../../_actions'
import {Col,Row} from 'antd'
import './Otraspublicaciones.css'


const OtrasPublicacionesContainer = ({dataInfo}) => {
    const dealerId = dataInfo.datos && dataInfo.datos.dealer_id
    const dealerName = dataInfo.datos && dataInfo.datos.company  
    const dealerName2 = dataInfo.datos && dataInfo.datos.dealer_name  
    const dispatch = useDispatch()
    const state = useSelector(state => state.vehicles.dealerVehicle)

    useEffect(() => {
      dispatch(vehiclesActions.getDealerVehicles(dealerId))  
    }, [dealerId])
    return (
        <div className="OtherPublicationDealer ">
            <h5 className="paddingsButtons">Otras Publicaciones de {dealerName || dealerName2} </h5>
            <Row gutter={8}>
            {state.data && state.data.length > 0 ? 
            state.data.map(item => (
                <Col xs={24} sm={6} key={item.id}>
                <RedVehiclesList item={item}/>
                </Col>
            )) : 
            <p>Esta agencia no tienes autos publicados</p>}
            </Row>
        </div>
    )
}

export default OtrasPublicacionesContainer
