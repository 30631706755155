import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import axios from "axios";
import RangeDatePicker from "../utils/RangeDatePicker";
import Chart from "../utils/chart";
import Cookies from "universal-cookie";
import { store } from "../../_helpers";
import { API_URL } from "../../_config";

class ResumenActividad extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      response: {}
    };
    
    this.canvasRef = React.createRef();
  } 


  componentDidMount() {

     var final = [];
     var $duse = [];
     var $sesiones = [];
     var $publish = [];
     var $questions = [];
     var $sales = [];
     var $dpxm = [];
     var $dcxm = [];
     var $dvxm = [];
     var $yearToday = new Date().getFullYear(); //( YEAR)
      var $mont = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
      const cookies = new Cookies();
      if(cookies.get("dealer")) {
    axios.get(API_URL + 'dashboard?token=' + store.getState().authentication.user.token).then(response => {
      var datazo = response.data;
      var ventas = datazo.ventas_por_mes;
      var consultas = datazo.consultas_por_mes;
      var publicados = datazo.publish_por_mes;
      var sesiones = datazo.usuario_sesiones;

      var pxm = publicados.filter(function(xmes) {
        return xmes.years == $yearToday;
      });
      var cxm = consultas.filter(function(xmes) {
        return xmes.years == $yearToday;
      });
      var vxm = ventas.filter(function(xmes) {
        return xmes.years == $yearToday;
      });
      var use = sesiones.filter(function(xmes) {
        return xmes.years == $yearToday;
      });

      for (var i = 0; i < 12; i++) {
        if (pxm[i]) {
          $publish[pxm[i].month] = pxm[i].vehicles;
        }

        if (cxm[i]) {
          $questions[cxm[i].month] = cxm[i].questions;
        }
        if (vxm[i]) {
          $sales[vxm[i].month] = vxm[i].sales;
        }

        if (use[i]) {
          $sesiones[use[i].month] = use[i].visitas;
        }
      }

        for (var i = 0; i < 12; i++) {

          if ($publish[$mont[i]]) {
            $dpxm.push($publish[$mont[i]]);
          } else {
            $dpxm.push(0);
          }
          if ($questions[$mont[i]]) {
            $dcxm.push($questions[$mont[i]]);
          } else {
            $dcxm.push(0);
          }
          if ($sales[$mont[i]]) {
            $dvxm.push($sales[$mont[i]]);
          } else {
            $dvxm.push(0);
          }
          if ($sesiones[$mont[i]]) {
                $duse.push($sesiones[$mont[i]]);
          } else {
                $duse.push(0);
          }
            
        }

                    console.log($duse);
    const chartData = {
      labels: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
      ],
      datasets: [
        {
          data: $duse,
          label: "Visitas en la Página",
          borderColor: "#109618",
          fill: false
        },
        {
          data: $dpxm,
          label: "Autos Totales Publicados",
          borderColor: "#efc031",
          fill: false
        },
        {
          data: $dcxm,
          label: "Consultas Totales",
          borderColor: "#fd0202",
          fill: false
        },
        {
          data: $dvxm,
          label: "Autos Vendidos",
          borderColor: "#3b77d6",
          fill: false
        }
      ]
    
    };

    const chartOptions = {
      ...{
        legend: { display: false },
        responsive: true
      },
      ...this.props.chartOptions
    };

    if (chartData.datasets != null && chartData.datasets.length < 1) {
     window.location.reload();
    }

    const BlogUsersOverview = new Chart(this.canvasRef.current, {
      type: "LineWithLine",
      data: chartData,
      options: chartOptions
    });

    // They can still be triggered on hover.
    const buoMeta = BlogUsersOverview.getDatasetMeta(0);
    buoMeta.data[0]._model.radius = 0;
    buoMeta.data[
      chartData.datasets[0].data.length - 1
    ]._model.radius = 0;

    // Render the chart.
    BlogUsersOverview.render();
  });
}
  }

  render() {
    const { title } = this.props;
    return (
      <canvas
        height="120"
        ref={this.canvasRef}
        style={{ maxWidth: "100% !important" }}
      />
    );
  }
}

ResumenActividad.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The chart dataset.
   */
  chartData: PropTypes.object,
  /**
   * The Chart.js options.
   */
  chartOptions: PropTypes.object,
  query: PropTypes.object
};

ResumenActividad.defaultProps = {
  title: "Resumen Actividad",
};

export default ResumenActividad;
