import React from 'react'
import { reduxForm, Field, getFormValues } from "redux-form";
import { connect } from "react-redux";
import InputField from "../_common/InputField";
import SelectSimple from "../_common/SelectSimple";
import { store } from "../_helpers";
import {
  Row,
  Col,
  Card,
} from "shards-react";

const Contacto = props => {
  const { handleSubmit, pristine, reset, submitting } = props
  
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Row>
          <Col sm="6">
          <Field
            name="fullname"
            component={InputField}
            type="text"
            label="Nombre"
          />

          <Field
            name="email"
            component={InputField}
            type="email"
            label="E-mail"
          />

          <Field
            name="phone"
            component={InputField}
            type="tel"
            label="Telefono"
          />

          <label>Descripción</label>  
          <div>
            <Field
              name="message"
              component={'textarea'}
              className="form-control"
              label="Mensaje"
              rows={4}
            />
          </div>

          </Col>
          <Col sm="6">

          <h3 style={{marginTop: '2px', marginBottom: '2px !important'}}>{props.activedX.name}</h3>
          <h6 style={{marginTop: '2px', marginBottom: '2px !important'}}>Email: {props.activedX.email}</h6>
          <h6 style={{marginTop: '2px', marginBottom: '2px !important'}} >Teléfono: {props.activedX.phone} / {props.activedX.phone2 }</h6>
          <h6 style={{marginTop: '2px', marginBottom: '2px !important'}}>Dirección: {props.activedX.address}</h6>
          <h6 style={{marginTop: '2px', marginBottom: '2px !important'}} >Atención: {props.activedX.work_hours}</h6>
        
          </Col>
        </Row>
          
      
      
  
        </div>
  
     
        <br/>
        <button type="submit" className="btn btn-primary" disabled={pristine || submitting}>
            Enviar
        </button>
    </form>
  )
}

function mapState(state, props) {
   
  const { users, authentication, vehicles, adminuser } = state;

  const { user } = authentication;

  if(props.activedX) {
    return { user, users, adminuser, initialValues: props.activedXX};
  }else{
    return { user, users, adminuser};
  }
}
const actionCreators = {

};

const po = reduxForm({
form: 'Contacto', 
enableReinitialize: true
})(Contacto);

const pox = connect(
  mapState,
  actionCreators
)(po);

export { pox as Contacto };

