import React from 'react'
import { Row, Col } from 'antd';
import DescriptionCar from './DescriptionCar'
import './Description.css'
import OtrosSitios from './OtrosSitios';

const DescriptionContainer = ({dataInfo}) => {
    const stylePadding = {
        padding : {padding : "40px 0px"}
    }

    return (
    <div style={stylePadding.padding}>
        <Row gutter={16}>
            <Col span={16} className="descriptionContainerSectionDetail">
                <DescriptionCar dataDescription={dataInfo}/>
            </Col>
            <Col span={8} className="otrosSitiosContainer" >
                <Col >
                <OtrosSitios dataSitios={dataInfo.datos}/>
                </Col>
            </Col>
        </Row>
    </div>
    )
}

export default DescriptionContainer
