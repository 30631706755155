import { alertConstants } from "../_constants";
const intialState = {
  alertState: false
};
export function alert(state = intialState, action) {
  switch (action.type) {
    /* case alertConstants.SUCCESS:
      return {
        type: "alert-success",
        message: action.message
      };
    case alertConstants.ERROR:
      return {
        type: "alert-danger",
        message: action.message
      };
    case alertConstants.CLEAR:
      return { ...state, type: "alert-show", mystate: false };
    case alertConstants.BOOM:
      return {
        type: "alert-success",
        message: action.booms
      };*/
    case alertConstants.ALERT_SHOW:
      console.log("ALERTTTT");
      return { ...state, alertState: true };

    default:
      return state;
  }
}
