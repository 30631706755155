import { adminuserConstants } from "../_constants";
import { store } from "../_helpers";
import Cookies from "universal-cookie";
import axios from "axios";
import { API_URL, PREAPI_URL } from "../_config";
const cookies = new Cookies();

//console.log('Cook', cookies.get('dealer').id)
/*
export function vehiclesActions() {

  return (dispatch, getState) => {
    axios.get(API_URL + 'api/churrasco?brand=&fuel=&model=&page=1&region=&tag=&type=&vendidos=&version=&year=')
      .then((response) => {
        dispatch({ type: vehiclesConstants.GETVEHICLES_SUCCESS, payload: response.data })
      })
  }

}

*/

export const adminuserActions = {
  getUsers,
  getAllDealers,
  getAyuda,
  getPerfiles,
  getNotificaciones,
  perm_validate
};

function perm_validate(categoria, tipo) {
  // Primero obtengo el listado de permisos
  if(localStorage.getItem('permission')) {
    var permisos = JSON.parse(localStorage.getItem('permission'));

    var permiso = permisos[categoria][tipo];
    if(permiso && permiso == 1) {

      // Existe
      return true;

    }else{
      return false;
    }

  }else{
    // No tiene registro de los permisos
    return false;
  }


}

function getUsers() {
  return dispatch => {
    // axios.get(API_URL + 'api/churrasco?brand=&fuel=&model=&page=1&region=&tag=&type=&vendidos=&version=&year=')
    axios({
      method: "get",
      url:
          API_URL + "api/user_new?token=" +
        store.getState().authentication.user.token
    }).then(response => {
      dispatch({
        type: adminuserConstants.GETADMINUSER_SUCCESS,
        payload: response.data
      });
    });
  };
}

function getAllDealers() {
  return dispatch => {
    // axios.get(API_URL + 'api/churrasco?brand=&fuel=&model=&page=1&region=&tag=&type=&vendidos=&version=&year=')
    axios({
      method: "get",
      url:
          API_URL + "api/v2/dealer?token=" +
        store.getState().authentication.user.token
    }).then(response => {
      dispatch({
        type: adminuserConstants.GETDEALERS_SUCCESS,
        payload: response.data
      });
    });
  };
}

function getPerfiles() {
  return dispatch => {
    // axios.get(API_URL + 'api/churrasco?brand=&fuel=&model=&page=1&region=&tag=&type=&vendidos=&version=&year=')
    axios({
      method: "get",
      url:
          API_URL + "api/group?token=" +
        store.getState().authentication.user.token
      }).then(response => {
        dispatch({
          type: adminuserConstants.GETADMINUSER_SUCCESS,
          payload: response
        });
      });
  };
}

function getAyuda() {
  return dispatch => {
    // axios.get(API_URL + 'api/churrasco?brand=&fuel=&model=&page=1&region=&tag=&type=&vendidos=&version=&year=')
    axios({
      method: "get",
      url:
          API_URL + "help_message?token=" +
        store.getState().authentication.user.token
      }).then(response => {
        dispatch({
          type: adminuserConstants.GETADMINUSER_SUCCESS,
          payload: response.data
        });
      });
  };
} 

function getNotificaciones() {
  return dispatch => {
    // axios.get(API_URL + 'api/churrasco?brand=&fuel=&model=&page=1&region=&tag=&type=&vendidos=&version=&year=')
    axios({
      method: "get",
      url:
        PREAPI_URL + "ac_notificacions?token=" +
        store.getState().authentication.user.token
      }).then(response => {
        dispatch({
          type: adminuserConstants.GETADMINUSER_SUCCESS,
          payload: response.data
        });
      });
  };
}


function show(id, payload) {
  return { type: adminuserConstants.GETADMINUSER_SHOW, id, payload };
}

/**
 * 
 * 
 *  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
 */
