import React from 'react'
import { Field, reduxForm } from 'redux-form';
import RenderFieldMyDealer from '../RenderFieldMyDealer'
import validate from '../validate';

const WizardFormFourPageMyDealer = ({onSubmit,previousPage}) => {
  return (
    <div>
      <form onSubmit={onSubmit}>
        <h6>Mis Servicios</h6>
        <p>Selecciona los servicios que se van a mostrar en tu sitio web (min 4)</p>
        <Field
            name="gestoria"
            component={RenderFieldMyDealer}
            type="checkbox"
            label="Gestoria Integral del Automotor"
          />
        <Field
            name="transferencias"
            component={RenderFieldMyDealer}
            type="checkbox"
            label="Transferencias"
          />
        <Field
            name="seguros"
            component={RenderFieldMyDealer}
            type="checkbox"
            label="Seguros"
          />
        <Field
            name="garantia"
            component={RenderFieldMyDealer}
            type="checkbox"
            label="Garantia"
          />
        <Field
            name="financiacion"
            component={RenderFieldMyDealer}
            type="checkbox"
            label="Financiacion"
          />
        <Field
            name="asesoramiento"
            component={RenderFieldMyDealer}
            type="checkbox"
            label="Asesoramiento"
          />
      <div  style={{paddingTop : "20px"}}>
      <button className="btn btn-primary"  style={{ marginRight : "10px" }}  onClick={previousPage}> Atras </button>
      <button className="btn btn-primary" type="submit"> Guardar </button>
      </div>
    </form>
    </div>
  )
}

export default reduxForm({
  form: 'wizardFormMyDealer', 
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  // validate
})(WizardFormFourPageMyDealer)