import React from "react";
import { connect } from "react-redux";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import { Input, Pagination, List } from 'antd';
import { vehiclesActions } from "../_actions";
import "react-tabs/style/react-tabs.css";
import { alertActions } from "../_actions";
import {
  Badge,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Card,
  CardImg,
  ButtonToolbar,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  FormCheckbox,
  Container,
  FormTextarea,
  CardHeader,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  ButtonGroup
} from "shards-react";
import StepZilla from "react-stepzilla";
import PageTitle from "../_components/PageTitle";
import RedVehiclesList from "./RedVehiclesList";
import SearchResults from "react-filter-search";

import "./RedNuestrosAutos.css";

const { Search } = Input;
const numEachPage = 4;

class RedNuestrosAutos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      minValue: 0,
      maxValue: 9,
      minValue2: 0,
      maxValue2: 9,
      valor: '',
      search: false
    };

    //const items = this.props.vehicles;
    //console.log('aca ?', items);
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.searchys = this.searchys.bind(this);
    console.log(this.props.vehicles);
  }

  componentDidMount() {
    this.props.getSharedVehicles();
  }
  

  componentWillUpdate() {
   
  }

  renderVehicles() {
    /*return this.props.vehicles.map((vehicle) => {
      return (
        <tr key={vehicle.id}>
          <td>{vehicle.brand_name}</td>
        </tr>
      )
    })*/
  }
  toggle() {
    this.setState({
      open: !this.state.open
    });
  }

  searchys(value) {

    if(value == ''){

      this.setState({
        valor: value,
        search: false
      });

    }else{
    this.setState({
      valor: value,
      search: true 
    });
  }
  }
  handleChange = value => {
    if (value <= 1) {
      this.setState({
        minValue: 0,
        maxValue: 9
      });
    } else {
      this.setState({
        minValue: this.state.maxValue,
        maxValue: value * 9
      });
    }
  };

  handleChange2 = value => {
    if (value <= 1) {
      this.setState({
        minValue2: 0,
        maxValue2: 9
      });
    } else {
      this.setState({
        minValue2: this.state.maxValue2,
        maxValue2: value * 9
      });
    }
  };

  getSize = obj => {
    var size = 0, key;
      for (key in obj) {
          if (obj.hasOwnProperty(key)) size++;
      }
      return size;
  }

  
  render() {
    const { open } = this.state;

    return (
      <div>
        <>
          <Row noGutters className="page-header py-4" style={{ width: "100%" }}>
           
            <PageTitle sm="7" style={{paddingTop: '6px'}} title="Vehículos compartidos en Nuestros Autos" className="text-sm-left" />
            
            <Col sm="5">
            <Search
                placeholder="Buscar"
                onSearch={value => this.searchys(value)}
                size="large"
                style={{ width: '100%' }}
            />
            </Col>
          </Row>

           
          {this.state.search == true ? (
          <SearchResults
          value={this.state.valor}
          data={this.props.vehicles.list}
          renderResults={results => (
            <List
            grid={{ gutter: 30, column: 4, xs: 1,
              sm: 2,
              md: 2,
              lg: 3,
              xl: 4,
              xxl: 4 }}
            pagination={{
              pageSize: 12,
              position: 'bottom',
              center: true,
              onChange(page, pageSize) {
                window.scroll(0,0)
               } 
            }}
          
            dataSource={results}
            renderItem={(item, idx) => (
              <List.Item>
                 <RedVehiclesList
                              key={idx}
                              item={item}
                              user={this.props.user}
                            />
          </List.Item>
            )}></List>
  
           )}
        />
        ) : (


          <List
          grid={{ gutter: 30, column: 4, xs: 1,
            sm: 2,
            md: 2,
            lg: 3,
            xl: 4,
            xxl: 4 }}
          pagination={{
            pageSize: 12,
            position: 'bottom',
            center: true,
            onChange(page, pageSize) {
                window.scroll(0,0)
               } 
          }}
        
          dataSource={this.props.vehicles.list}
          renderItem={(item, idx) => (
            <List.Item>
               <RedVehiclesList
                            key={idx}
                            item={item}
                            user={this.props.user}
                          />
        </List.Item>
          )}></List>

         )}
        </>
      </div>
    );
  }
}

function mapState(state) {
  const { users, authentication, vehicles, loaded } = state;

  const { user } = authentication;
  console.log("esto ->", vehicles);

  return { user, users, vehicles, loaded };
}
const actionCreators = {
  getSharedVehicles: vehiclesActions.getSharedVehicles,
  showAlert: alertActions.show,
  hideAlert: alertActions.hide
};

const connectedVehicles = connect(
  mapState,
  actionCreators
)(RedNuestrosAutos);
export { connectedVehicles as RedNuestrosAutos };
