/*eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Navbar, NavbarBrand } from "shards-react";
import {perm_validate} from "../../../_helpers/perm_validate"
import { NavLink as RouteNavLink } from "react-router-dom";
import { NavLink } from "shards-react";
import Logo  from '../../../assets/images/LogoNuestrosAutosGradient.svg'
//import { Dispatcher, Constants } from "../../../flux";
import { sidebarActions } from "../../../_actions";
import { connect } from "react-redux";


class SidebarMainNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.handleToggleSidebar = this.handleToggleSidebar.bind(this);
  }

  handleToggleSidebar() {
    return this.props.toggleSidebar();
  }

  render() {
    const { hideLogoText } = this.props;
    return (
      <div className="main-navbar">
        <Navbar
          className="align-items-stretch bg-white border-bottom p-0"
          type="light"
        >
          <div style={{ width: '100%', overflow: 'hidden' }}>
            {!hideLogoText && (
              perm_validate('Vehiculos', 'create') == true ? (
              <NavLink className="navbar-brand w-100 mj-100" id="header-logo" tag={RouteNavLink} to={'/panel'}
              >
                <img src={Logo} alt="Logo Navbar" style={{width : "100%"}} />
              </NavLink> ):(
              <NavLink className="navbar-brand" id="header-logo" tag={RouteNavLink} to={'/dashboard'}>
              <img src={Logo} alt="Logo Navbar" style={{width : "100%"}} />
              </NavLink>
              )
            )}
          </div>

          {/* eslint-disable-next-line */}
          <a
            className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
            onClick={this.handleToggleSidebar}
          >
            <i className="material-icons">&#xE5C4;</i>
          </a>
        </Navbar>
      </div>
    );
  }
}

SidebarMainNavbar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

SidebarMainNavbar.defaultProps = {
  hideLogoText: false
};

//export default SidebarMainNavbar;

/* */
function mapState(state) {
  const { users, authentication, sidebar } = state;
  const { user } = authentication;
  return { user, users, sidebar };
  // const { users, authentication } = state;
}

const actionCreators = {
  getSidebarItems: sidebarActions.getSidebarItems,
  addChangeListener: sidebarActions.addChangeListener,
  removeChangeListener: sidebarActions.removeChangeListener,
  toggleSidebar: sidebarActions.toggleSidebar
};

//export default MainSidebar;

const connectedMainSidebar = connect(
  mapState,
  actionCreators
)(SidebarMainNavbar);
export { connectedMainSidebar as SidebarMainNavbar };
