import React from 'react'
import { Form, reduxForm, Field, getFormValues } from "redux-form";
import { connect } from "react-redux";
import InputField from "../_common/InputField";
import SelectSimple from "../_common/SelectSimple";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import {
    Button
  } from "shards-react";

const LinkOLX = props => {
  const { handleSubmit, pristine, reset, submitting } = props

  return (
    <Form onSubmit={handleSubmit}>
      
        <Field
            name="email"
            component={InputField}
            type="text"
            label="E-mail"
          />
      
        <Field
            name="user"
            component={InputField}
            type="text"
            label="User"
          />
    
                  <br/>
                  <Button
                      
                      style={{ width: "100%", fontSize: "17px" }}
                      type="submit"
                      disabled={pristine || submitting}
                    >
                      Vincular
                    </Button>
         
       
    </Form>
  )
}

export default reduxForm({
  form: 'linkolx' 
})(LinkOLX)