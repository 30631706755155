
export function perm_validate(categoria, tipo) {

    // Primero obtengo el listado de permisos
    if(localStorage.getItem('permission')) {
      var permisos = JSON.parse(localStorage.getItem('permission'));
   
      if(permisos[categoria] && permisos[categoria][tipo] && permisos[categoria][tipo] == 1) {
  
        // Existe
        return true;
  
      }else{
        return false;
      }
  
    }else{
      // No tiene registro de los permisos
      return false;
    }
  
  
  }