import React,{useState,useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {vehiclesActions} from '../../_actions'
import SectionInfoContainer from './SeccionInfo/SectionInfoContainer'
import DescriptionContainer from './SeccionDescripcion/DescriptionContainer'
import OtrasPublicacionesContainer from './OtrasPublicaciones/OtrasPublicacionesContainer'
import ModalContactoDetail from './ModalContactoDetail/ModalContactoDetail'

export const FichaContainer = ({match,history}) => {

    const [modalContacto, setModalContacto] = useState(false)

    const dispatch = useDispatch()

    const state = useSelector(state => state.vehicles.detailVehicle)

    useEffect(() => {
      dispatch(vehiclesActions.getDetailVehicle(match.params.id))  
    }, [match.params.id])

    const stylePadding = {
        padding : {padding : "20px 0px"}
    }
    
    return (
        <div style={stylePadding.padding}>
        <SectionInfoContainer dataInfo={state} showHide={modalContacto} showHideFunc={setModalContacto} />
        <DescriptionContainer dataInfo={state} />
        <OtrasPublicacionesContainer dataInfo={state}/>
        <ModalContactoDetail  showHide={modalContacto} DataDealer={state} showHideFunc={setModalContacto}  />
        </div>
    )
}


