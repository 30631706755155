import React from "react";
import { connect } from "react-redux";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import VehiclePublish from "./VehiclePublish";
import "./Vehicles.css";
import { vehiclesActions } from '../_actions'
import "react-tabs/style/react-tabs.css";
import {

    Modal,
    ModalBody,
    ModalHeader,

} from "shards-react";

import StepZilla from "react-stepzilla";
import Paso1 from "./Pasos/Paso1";
import Paso2 from "./Pasos/Paso2";
import Paso3 from "./Pasos/Paso3";
import Paso4 from "./Pasos/Paso4";
import Paso5 from "./Pasos/Paso5";
import Paso6 from "./Pasos/Paso6";
import Paso7 from "./Pasos/Paso7";
import Paso8 from "./Pasos/Paso8";
import Paso9 from "./Pasos/Paso9";

class PublishModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true
        };


        //const items = this.props.vehicles;
        //console.log('aca ?', items);
        this.toggle = this.toggle.bind(this);
        console.log('vehi' + this.props.vehicles);

    }

    componentWillMount() {
        this.props.getVehicles()
    }

    renderVehicles() {


        /*return this.props.vehicles.map((vehicle) => {
          return (
            <tr key={vehicle.id}>
              <td>{vehicle.brand_name}</td>
            </tr>
          )
        })*/
    }
    toggle() {
        this.setState({
            open: !this.state.open
        });
    }


    render() {

        const { open } = this.state;
        const steps = [
            { name: "PASO 1", component: <Paso1 /> },
            { name: "PASO 2", component: <Paso2 /> },
            { name: "PASO 3", component: <Paso3 /> },
            { name: "PASO 4", component: <Paso4 /> },
            { name: "PASO 5", component: <Paso5 /> },
            { name: "PASO 6", component: <Paso6 /> },
            { name: "PASO 7", component: <Paso7 /> },
            { name: "PASO 8", component: <Paso8 /> },
            { name: "PASO 9", component: <Paso9 /> }
        ];

        return (
            <>

                <Modal open={open} size="lg" toggle={() => console.log('Toggle')}>
                    <ModalHeader>Publicar un Vehiculo  <i style={{position:'absolute', right: '20px', top: '15px', fontSize: '25px', cursor: 'pointer'}} class="material-icons" onClick={this.toggle}>clear</i></ModalHeader>
                    <ModalBody>
                        <VehiclePublish></VehiclePublish>
                        <MobileView>
                            <StepZilla
                                steps={steps}
                                stepsNavigation={false}
                                showSteps={false}
                                backButtonText="Atras"
                                nextButtonText="Siguiente"
                                backButtonCls="left_bottom_po"
                                nextButtonCls="right_bottom_po"
                                prevBtnOnLastStep={false}
                            />
                        </MobileView>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

function mapState(state) {
    //console.log(' aca -',state)
    const { users, authentication, vehicles } = state;
    //const { list } = state.vehicle;
    //console.log(list);
    //const { list } = vehicle;
    const { user } = authentication;
    console.log('esto ->', vehicles);

    return { user, users, vehicles };

}
const actionCreators = {
    getVehicles: vehiclesActions.getVehicles
};

const connectedPublishModal = connect(
    mapState,
    actionCreators
)(PublishModal);
export { connectedPublishModal as PublishModal };
