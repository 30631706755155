import { combineReducers } from "redux";

import { authentication } from "./authentication.reducer";
import { registration } from "./registration.reducer";
import { users } from "./users.reducer";
import { alert } from "./alert.reducer";
import { sidebar } from "./sidebar.reducer";
import { vehicles } from "./vehicles.reducer";
import { adminuser } from "./adminuser.reducer";
import { loadingGlobal } from "./loadingGlobal.reducer";
import { reducer as reducerForm } from "redux-form";
import { notifications } from "./notifications.reducer";

const rootReducer = combineReducers({
  authentication,
  registration,
  users,
  loadingGlobal: loadingGlobal,
  alert: alert,
  sidebar,
  form: reducerForm,
  vehicles: vehicles,
  adminuser: adminuser,
  notifications: notifications
});

export default rootReducer;
