import { vehiclesConstants } from "../_constants";

const initialState = {
  list: [],
  showVehicle: {},
  total: 0,
  loaded: false,
  id_vehicle: null,
  concesionarias: [],
  detailVehicle : {},
  dealerVehicle : {}
};

export function vehicles(state = initialState, action) {
  switch (action.type) {
    case vehiclesConstants.GETVEHICLES_REQUEST:
      return {
        loading: true
      };
    case vehiclesConstants.GETCONCESIONARIAS_SUCCESS:
      return {
        concesionarias: action.payload
      };
    case vehiclesConstants.GETVEHICLES_SUCCESS:
      return {
        // lista de vehiculos

        ...state,
        list: action.payload.data
      };
    case vehiclesConstants.GETVEHICLE_SHOW:
      //solo un vehiculo
      return {
        ...state,
        showVehicle: action.payload,
        id_vehicle: action.id
      };
    case vehiclesConstants.GETVEHICLES_FAILURE:
      return {
        error: action.error
      };

    case vehiclesConstants.GETSHAREDVEHICLES_REQUEST:
      return {
        loading: true
      };
    case vehiclesConstants.GETSHAREDVEHICLES_SUCCESS:
      return {
        // lista de vehiculos
        ...state,
        list: action.payload.data,
        total: action.total,
        loaded: true
      };
    case vehiclesConstants.GETSHAREDVEHICLES_FAILURE:
      return {
        error: action.error
      };
    case vehiclesConstants.GETDATADETAILVEHICLE:
      return {
        ...state,
        detailVehicle : action.payload
      };
    case vehiclesConstants.GETDATADEALERVEHICLES:
      return {
        ...state,
        dealerVehicle : action.payload
      };

    default:
      return state;
  }
}
