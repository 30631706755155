import React from "react";
import { connect } from "react-redux";
//import $ from 'jquery'
import { Table, Divider, Tag, Icon, notification } from "antd";
import { store } from "../_helpers";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import axios from "axios";
import { adminuserActions } from "../_actions";
import { API_URL } from "../_config";
import "react-tabs/style/react-tabs.css";
import {
  Badge,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Card,
  CardImg,
  ButtonToolbar,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  FormCheckbox,
  Container,
  FormTextarea,
  CardHeader,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  ButtonGroup
} from "shards-react";
import { CrearPerfil, ConsUsuario, PermUsuario } from ".";
import PageTitle from "../_components/PageTitle";
import { Perfiles } from "./Perfiles";

class EditarPerfil extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      nav_number: 0,
      cons: true,
      perm: true,
      id_user: 0,
      user_data: {},
      permisos_usados: {},
      dealers_usados: {},
      loadingx: true
    };

    //const items = this.props.vehicles;
    //console.log('aca ?', items);
    this.toggle = this.toggle.bind(this);
    this.update_user = this.update_user.bind(this);
    this.assign_cons = this.assign_cons.bind(this);
    this.assign_perms = this.assign_perms.bind(this);
  }

  componentWillMount() {
    axios({
      method: "get",
      url:
        API_URL + "api/group/" +
        this.props.id_user +
        "?token=" +
        store.getState().authentication.user.token
    }).then(response => {
      this.setState({
        id_user: response.data.id,
        user_data: response.data,
        loadingx: false
      });
    });
  }

  update_user(values) {
    console.log("wggg");
    console.log(values);

    notification.open({
      message: "Cargando",
      description: "Espere un momento por favor",
      icon: <Icon type="loading" style={{ color: "#4caf50" }} />,
      style: { zIndex: 100000 },
      top: 70
    });

    axios
      .post(
        API_URL + "api/group/" +
          this.props.id_user +
          "?token=" +
          store.getState().authentication.user.token,
        values
      )
      .then(response => {
        notification.destroy();
        notification.open({
          message: "Operación exitosa",
          icon: <Icon type="check-circle" style={{ color: "#4caf50" }} />,
          top: 70,
          style: { zIndex: 100000 }
        });
      })
      .catch(error => {
        notification.destroy();
        return notification.open({
          message: "Ha ocurrido un error",
          description:
            "No hemos podido completar la solicitud, por favor, intente más tarde",
          icon: <Icon type="minus-circle" style={{ color: "#f54842" }} />,
          style: { zIndex: 100000 },
          top: 70
        });
      });
  }

  assign_cons(values) {
    axios
      .post(
        API_URL + "api/user/" +
          this.state.id_user +
          "/dealers?token=" +
          store.getState().authentication.user.token,
        values
      )
      .then(response => {
        console.log(response);
      });
  }

  assign_perms(values) {
    axios
      .post(
        API_URL + "api/user/" +
          this.state.id_user +
          "/perms?token=" +
          store.getState().authentication.user.token,
        values
      )
      .then(response => {
        console.log(response);
      });
  }

  toggle() {
    this.setState({
      open: !this.state.open
    });
  }

  render() {
    const { open } = this.state;

    if (open == false) {
      return ''
    } else {
      return (
        <div>
          <Modal size="lg" open={open} toggle={() => console.log('Toggle')}>
            <ModalHeader>Editar Perfil  <i style={{position:'absolute', right: '20px', top: '15px', fontSize: '25px', cursor: 'pointer'}} class="material-icons" onClick={this.toggle}>clear</i></ModalHeader>
            <ModalBody>
              <CrearPerfil
                activedX={this.state.user_data}
                edit={true}
                onSubmit={this.update_user}
              />
            </ModalBody>
          </Modal>
        </div>
      );
    }
  }
}

function mapState(state, props) {
  const { users, authentication, vehicles, adminuser } = state;

  const { user } = authentication;
  console.log("esto ->", vehicles);

  return { user, users, adminuser };
}

const actionCreators = {};

const connectedUsuarios = connect(
  mapState,
  actionCreators
)(EditarPerfil);
export { connectedUsuarios as EditarPerfil };
