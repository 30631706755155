import React, { useState , useEffect} from 'react'
import { Field, reduxForm } from 'redux-form';
import validate from '../validate';
import RenderFieldMyDealer from '../RenderFieldMyDealer'
import { Col, Icon, notification, Row, Upload } from 'antd';
import axios from 'axios';
import { API_URL } from '../../../_config';
import Cookies from 'universal-cookie';
import { store } from '../../../_helpers';

const cookies = new Cookies();


const WizardFormThirdPageMyDealer = ({onSubmit,previousPage}) => {

  const [dealerData, setDealerData] = useState([])

  useEffect(() => {
    axios({
      method: "get",
      url:
        API_URL + "api/dealer/" +
        cookies.get("dealer").id +
        "/2?token=" +
        store.getState().authentication.user.token
    }).then(response => setDealerData(response.data));
  }, [])


const cambiar_perfil = (file) => {

    notification.open({
      message: 'Guardando Logo de su agencia',
      description:
        'Este proceso puede demorar unos minutos',
      icon: <Icon type="loading" style={{ color: '#4caf50' }} />,
      top: 70
    });

    const data = new FormData()
			data.append('file', file)
			const config= {
				"headers": {
					"content-type": 'multipart/form-data'
				}
      }
      axios.post(API_URL + 'api/dealer/' + cookies.get("dealer").id + '/image/upload?token=' +
      store.getState().authentication.user.token, data, config).then((response) => {
            notification.destroy()
            notification.open({
              message: 'Operación exitosa',
              icon: <Icon type="check-circle" style={{ color: '#4caf50' }} />,
              top: 70
            });
	    }).catch((err) => {
				console.log(err)
			})

  }
  const propsx = {
    name: 'file',
    action: '#',
    headers: {},
    beforeUpload: file => cambiar_perfil(file),
    showUploadList: false,
    onChange(info) {
      console.log(info,'INFO')
    },
  };
  const remover = () => {

    notification.open({
      message: 'Eliminando Logo de su empresa',
      description:
        'Este proceso puede demorar unos minutos',
      icon: <Icon type="loading" style={{ color: '#4caf50' }} />,
      top: 70
    });

    axios
      .delete(
        API_URL + "api/dealer/" +
          cookies.get("dealer").id +
          "/imagen/logo?token=" +
          store.getState().authentication.user.token
      )
      .then(response => {
            notification.destroy()
            notification.open({
              message: 'Operación exitosa',
              icon: <Icon type="check-circle" style={{ color: '#4caf50' }} />,
              top: 70
            });
      });
  }


  return (
    <div>
      <form onSubmit={onSubmit}>
      <Field
        name="web"
        type="text"
        component={RenderFieldMyDealer}
        label="Nombre de mi Sitio Web:"
      />
    <div>
      <p>Color Principal de su web:</p>
          <Field
            name="color"
            component="input"
            type="color"
            label="Color"
            class="form-control"
            style={{height:'45px'}}
          />
    </div>
    <div className="mt-3" >
      <p>Logo de su Web:</p>
                    <Row>
                      <Col sm="6" style={{width :"100%",textAlign : "center"}}>
                      {dealerData.cropped_image_url ? 
                  <img
                    src={dealerData.cropped_image_url}
                    style={{ width: "400px",height :"300px", borderRadius: '5px', marginBottom: '15px' }}
                  />:<h6>Debes subir una imagen</h6>
                  }
                      </Col>
                      <Col sm="6" style={{width :"100%"}}>
                        <div>
                        <Upload {...propsx}>
                          {dealerData.cropped_image_url && <div style={{textAlign: 'center'}}>
                          <i style={{fontSize: '50px', border: '4px dashed #595959', padding: '15px', borderRadius: '60%',cursor : "pointer"}} class="material-icons">photo_library</i>
                          <p style={{textAlign: 'center', paddingTop: '4px'}}>Subir Imagen</p>
                        </div> 
                        } 
                        </Upload>
                        </div>
                      </Col>
                    </Row>
    </div>


       <div  style={{paddingTop : "20px"}}>
      <button className="btn btn-primary"  style={{ marginRight : "10px" }}  onClick={previousPage}> Atras </button>
      <button className="btn btn-primary" type="submit"> Siguiente </button>
      </div>
    </form>
    </div>
  )
}

export default reduxForm({
  form: 'wizardFormMyDealer', 
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  // validate
})(WizardFormThirdPageMyDealer)
