import React from "react";

// Aca hacemos importaciones externas
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  Form,
  FormInput,
  FormGroup,
  FormTextarea,
  Card,
  CardBody,
  CardImg
} from "shards-react";
import { reduxForm, Field, getFormValues } from "redux-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API_URL } from "../_config";
import { Upload, Icon, Modal, notification } from 'antd';
import { UploadWrapper } from '.';
import ImagesUploader from "react-images-uploader";
import "react-images-uploader/styles.css";
import "react-images-uploader/font.css";
import {
  faCodeBranch,
  faChalkboardTeacher,
  faBars,
  faHandshake,
  faChessRook,
  faPhone,
  faEnvelope
} from "@fortawesome/free-solid-svg-icons";
import {
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Collapse
} from "shards-react";
import PageTitle from "../_components/PageTitle";
import { connect } from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import { store } from "../_helpers";
import { SideX } from ".";
import ImgCrop from "antd-img-crop";
import CheckBoxSimple from "../_common/CheckBoxSimple";
// Aca hacemos importaciones de la vista propia
import "../myProfile/myProfile.css";

const cookies = new Cookies();
var endImages = [];
var predata = [];



class myProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dealer: [],
      ok: false,
      previewVisible: false,
      url: '',
      previewImage: '',
      domain_loading: false,
      fileList: [],
    };
  }

  componentDidMount() {
    
    axios({
      method: "get",
      url:
        API_URL + "api/user/" + store.getState().authentication.user.user.id + "?token=" +
        store.getState().authentication.user.token
    }).then(response => {

      
      this.setState({
        dealer: response.data,
        ok: true
      });
    });


  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  handleChange = ({ fileList }) => this.setState({ fileList });

  edit(values) {
    notification.open({
      message: 'Editando perfil',
      description:
        'Este proceso puede demorar unos minutos',
      icon: <Icon type="loading" style={{ color: '#4caf50' }} />,
      top: 70
    });
    axios
      .post(
        API_URL + "api/user/" + store.getState().authentication.user.user.id + "?token=" +
        store.getState().authentication.user.token, {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          phone: values.phone,
          password: ((values.password == '') ? false : values.password)
        }
      )
      .then(response => {
            notification.destroy()
            notification.open({
              message: 'Operación exitosa',
              icon: <Icon type="check-circle" style={{ color: '#4caf50' }} />,
              top: 70
            });
      });
  }

  refresh_data() {

    axios({
      method: "get",
      url:
        API_URL + "api/user/" + store.getState().authentication.user.user.id + "?token=" +
        store.getState().authentication.user.token
    }).then(response => {
      this.setState({
        dealer: response.data,
        ok: true
      });
    });

  }

  cambiar_perfil(file) {

    notification.open({
      message: 'Cambiando foto de perfil',
      description:
        'Este proceso puede demorar unos minutos',
      icon: <Icon type="loading" style={{ color: '#4caf50' }} />,
      top: 70
    });

    const data= new FormData()
      data.append('imageFiles', file)
      const config= {
        "headers": {
          "content-type": 'multipart/form-data'
        }
      }


      
      axios.post('https://admin.nuestrosautos.com.ar/upload/no.php', data, config).then((response) => {

      const jony = response.data[0].fileName.path;
      axios.post(API_URL + 'api/user/' + store.getState().authentication.user.user.id + '/image/assign?token=' +
      store.getState().authentication.user.token, {enlace: jony}, {}).then((response) => {
            notification.destroy()
            notification.open({
              message: 'Operación exitosa',
              icon: <Icon type="check-circle" style={{ color: '#4caf50' }} />,
              top: 70
            });
        this.refresh_data();
      }).catch((err) => {
        console.log(err)
      })

      }) 

  }

 
  render() {
    
  const propsx = {
        name: 'file',
        action: '#',
        headers: {},
        beforeUpload: file => this.cambiar_perfil(file),
        showUploadList: false,
        onChange(info) {
          
        },
      };
 
    return (
      <div>
        <>
          <Row noGutters className="page-header py-4">
            <PageTitle
              sm="4"
              title="Mi Perfil"
              className="text-sm-left"
            />
          </Row>

          <br />
          {this.state.ok && (
            <>


            
                      <SideX
                        except={true}
                        activedX={this.state.dealer}
                        style={{ width: "100%" }}
                        onSubmit={this.edit}
                      ></SideX>
              <br />
             
            </>
          )}

              <Card className="mb-4">
                <CardBody>

                  <h4>Foto de perfil</h4>
                  <hr className="mb-2" />

                      <Row>
                        <Col sm="6"> 
                      <img
                        src={this.state.dealer.cropped_image_url}
                        style={{ width: "100%",borderRadius: '6px' }}
                      ></img>
                      

                      <br/>
                      </Col>
                      <Col sm="6" style={{display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'}}>
                    
                      <ImgCrop width={200} height={200}  modalTitle="Recortar Imagen">
                        <Upload {...propsx}>
                        <div style={{textAlign: 'center'}}>
                          <i style={{fontSize: '50px', border: '4px dashed #595959', padding: '15px', borderRadius: '60%'}} class="material-icons">photo_library</i>
                          <p style={{textAlign: 'center', paddingTop: '4px'}}>Cambiar Imagen</p>
                        </div>
                        </Upload>
                        </ImgCrop>
                      
                    </Col>
                    </Row>
                </CardBody>
              </Card> 
        </>
      </div>
    );
  }
}

function mapState(state) {
  const { users, authentication } = state;

  const { user } = authentication;

  return { user, users };
}
const actionCreators = {};


const po = reduxForm({
  form: 'myProfile', 
  enableReinitialize: true
  })(myProfile);

const connectedVehicles = connect(
  mapState,
  actionCreators
)(po);
export { connectedVehicles as myProfile };
