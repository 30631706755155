import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";


class Selecciona extends Component {
    constructor(props) {
        super(props);

        //this.getRef = this.getRef.bind(this);
    }



    render() {
        const {
            className,
            children,
            size,
            valid,
            invalid,
            label,
            input,
            touched,
            error,
            ...attrs
        } = this.props;

        /* const classes = className(
             className,
             "form-control",
             "custom-select",
             valid && "is-valid",
             invalid && "is-invalid",
             size && `form-control-${size}`,
             size && `custom-select-${size}`
         );
 */
        //const datos = [[1, 'algp'], [2, 'pedo']];

        const option = children.map(function (d, x) {
          
            return (< option value={d}> {d}</option >)
        })
        return (
            <div style={{marginBottom: 10}}>
        <label style={{marginBottom: 1}}>{label}</label>
        <div>
        <select {...input} className="form-control custom-select">
          <option value=""></option>
          {option}
        </select>
        {touched && error && <span className="red-snow">{error}</span>}
        </div>
      </div>

          
        );
    }
}

Selecciona.propTypes = {
    /**
     * The class name.
     */
    className: PropTypes.string,
    /**
     * The children nodes.
     */
    children: PropTypes.node,
    /**
     * The size.
     */
    size: PropTypes.string,
    /**
     * Whether it is valid, or not.
     */
    valid: PropTypes.bool,
    /**
     * Whether it is invalid, or not.
     */
    invalid: PropTypes.bool,
    /**
     * The inner ref.
     */
    innerRef: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.func,
        PropTypes.string
    ])
};

export default Selecciona;
