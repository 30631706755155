import React, { useState } from "react";
import Script from 'react-load-script';

const FacebookLibrary = () => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [scriptError, setScriptError] = useState(false);


  const handleScriptCreate = () => {
    setScriptLoaded(false);
  }
  
  const handleScriptError = () => {
    setScriptError(true);
  }
  
  const handleScriptLoad = () => {
      if(scriptLoaded == false) {
          setScriptLoaded(true);
    
      }
    
  }

  return (
     <Script
      url="https://connect.facebook.net/es_LA/sdk.js?version=v4.0"
      attributes={{ async: true, defer: true }}
    />
  );
};

export default FacebookLibrary;
