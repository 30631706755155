import React from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink,
  Modal,
  ModalBody, 
  ModalHeader
} from "shards-react";
import Cookies from "universal-cookie";
import { history } from "../../../../_helpers";
const cookies = new Cookies();
export default class Servicios extends React.Component {
  constructor(props) {
    super(props);
  
    if (
      !cookies.get("dealer") &&
      history.location.pathname != "/select_dealer"
    ) {
      console.log("current history" + history.location);
      history.push("/select_dealer");
    }
    this.state = {
      visible: false,
      open: false
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  toggle() {
    this.setState({
      open: !this.state.open
    });
  }

  render() {

    const { open } = this.state;

    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
       
        <Modal size="sm" open={open} toggle={() => console.log('Toggle')}>
          <ModalHeader>Ayuda Chat en Vivo <i style={{position:'absolute', right: '20px', top: '15px', fontSize: '25px', cursor: 'pointer'}} class="material-icons" onClick={this.toggle}>clear</i></ModalHeader>
            <ModalBody style={{height: '420px', padding: '0px'}}>
              <iframe src="https://mesadeayuda.cca.org.ar/index.php?p=start&sp=1&ssp=es&sssp=1&ssssp=none" frameborder="0" style={{width:'100%', marginTop: '5px', height: '100%'}}></iframe>
            </ModalBody>
        </Modal>

        <DropdownToggle tag={NavLink} className="text-nowrap px-3 justify-content-center align-items-center">
          <h6 className="justify-content-center align-items-center" style={{marginTop: '8px',}}>Servicios para socios</h6>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small style={{width: '115%'}} open={this.state.visible}>


          <DropdownItem>
            <a target="_blank" href="https://cca.org.ar/servicios/" style={{color: '#212529'}}><i className="material-icons" style={{color: '#c3c7cc'}}>menu_book</i> Servicios</a>
          </DropdownItem>
          <DropdownItem>
          <a target="_blank" href="https://www.buenosaires.gob.ar/justiciayseguridad/contacto-comerciante-habitualista" style={{color: '#212529'}}><i style={{color: '#c3c7cc'}} className="material-icons">directions_car</i> VPA a domicilio</a>
          </DropdownItem>
          <DropdownItem>
            <a target="_blank" href="https://www.habitualista.com.ar/" style={{color: '#212529'}}><i style={{color: '#c3c7cc'}} className="material-icons">receipt</i> Solicitar T.Habitualita</a>
          </DropdownItem>
          <DropdownItem tag={Link} to="/pedidoinformes">
          <i className="material-icons">assignment</i> Pedido de informes
          </DropdownItem>
          <DropdownItem tag={Link} to="/formularios">
          <i className="material-icons">input</i> Formularios
          </DropdownItem>
          <DropdownItem>
            <a target="_blank" href="#" style={{color: '#212529'}}><i style={{color: '#c3c7cc'}} className="material-icons">receipt</i> Cotizar</a>
          </DropdownItem>


       
        </Collapse>
      </NavItem>
    );
  }
}
