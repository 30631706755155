import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  reduxForm,
  Field,
  FieldArray,
  getFormValues,
  change
} from "redux-form";
//import renderField from './renderField';
import SelectSimple from "../../_common/SelectSimple";
import SelectDynamic from "../../_common/SelectDynamic";
import SelectSimpleParser from "../../_common/SelectSimpleParser";
import validate from "../../_common/ValidatePublishOne";
import InputField from "../../_common/InputField";
import axios from "axios";
import {
  Row,
  Col,
  Button,
  FormCheckbox,
  Container,
  FormTextarea,
  CardHeader,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  Alert
} from "shards-react";
import { API_URL, PREAPI_URL } from "../../_config";
let types = require("../type.json");

function WizardFormOnePage(props) {


  const [brand, setBrand] = useState("");
  const [typeVehicles, setTypeVehicles] = useState(1);
  const [brands, setBrands] = useState([]);
  const [condicion, setCondicion] = useState(0);
  const [models, setModels] = useState([]);
  const [versiones, setVersiones] = useState([]);
  const [version, setVersion] = useState([]);
  const [model, setModel] = useState("");
  const [newversion, setNewv] = useState(true);
  const [tituloML, setTituloML] = useState("");
  const [colors, setColors] = useState([]);

  const [titleText, setTitleText] = useState("");
  const [brandText, setBrandText] = useState("");
  const [modelText, setModelText] = useState("");


  const [sugeridoson, setSugeridoson] = useState("");
  //const [visible, setVisible] = useState(false);
  //const [versions, setVersio] = useState([]);

  useEffect(() => {
    props.dispatch(change("publisVehicules", "titleML", titleText));
  }, [titleText]);

  function getResponse(e) {
    setTypeVehicles(e.target.value);
    //this.setState({ typeVehicles: e.target.value });
    //console.log('state ' + this.state.typeVehicles);
  }
  function selectBrands(e, init = null) {
    if (init != null) {
      setBrand(e);
      var idazo = e;
    } else {
      setBrand(e.target.value);
      var idazo = e.target.value;
    }

    console.log(e);
    console.log("i tried");

    if (
      localStorage.getItem("brands_list") === null ||
      localStorage.getItem("models_list") === null
    ) {
      axios({
        method: "get",
        url:
          PREAPI_URL + "api/vehicle/brands?token=" +
          props.user.token
      }).then(response => {
        // Procedo a guardarlo en una cookie
        localStorage.setItem("brands_list", JSON.stringify(response.data));
      });

      // Se obtienen los modelos para guardarlos en una cookie
      axios({
        method: "get",
        url:
          PREAPI_URL + "api/v2/vehicle/models?token=" +
          props.user.token
      }).then(response => {
        // Procedo a guardarlo en una cookie
        localStorage.setItem("models_list", JSON.stringify(response.data));
      });

      // Se obtienen los colores para guardarlos en una cookie
      axios({
        method: "get",
        url:
          PREAPI_URL + "api/vehicle/colors?token=" +
          props.user.token
      }).then(response => {
        // Procedo a guardarlo en una cookie
        localStorage.setItem("colors_list", JSON.stringify(response.data));
      });

      const lcd1 = JSON.parse(localStorage.getItem("brands_list"));
      var lcd_search1 = lcd1.find(element => element.id == idazo);

      setTitleText(lcd_search1.name);
      setBrandText(lcd_search1.name);
    } else {
      const lcd1 = JSON.parse(localStorage.getItem("brands_list"));
      var lcd_search1 = lcd1.find(element => element.id == idazo);

      setTitleText(lcd_search1.name);
      setBrandText(lcd_search1.name);
    }

    if (localStorage.getItem("models_list") === null) {
      axios({
        method: "get",
        url:
          PREAPI_URL + "api/v2/vehicle/models?token=" +
          props.user.token
      }).then(response => {
        //console.log()
        //this.setState({ models: response });
        setModels(response);
        console.log("MODELOS", response);
        //const model = response.data;
      });
    } else {
      setModels({ data: JSON.parse(localStorage.getItem("models_list")) });
    }
  }
  function selectNumericVersion(e) {
    const lcd1 = version.data;
    var lcd_search1 = lcd1.find(element => element.id == e.target.value);

    setTitleText(brandText + " " + modelText + " " + lcd_search1.name);
  }

  function selectTextVersion(e) {
    setTitleText(brandText + " " + modelText + " " + e.target.value);
    // props.dispatch(change('publisVehicules', 'titleML', props.formValues.titleML + ' ' + e.target.value));
  }

  function selectModel(e, init = null) {
    let $model;
    if (init != null) {
      setModel(e);
      $model = e;
      var idazo = e;
    } else {
      setModel(e.target.value);
      $model = e.target.value;
      var idazo = e.target.value;
    }

    const lcd2 = JSON.parse(localStorage.getItem("models_list"));
    var lcd_search2 = lcd2.find(element => element.id == idazo);

    setTitleText(brandText + " " + lcd_search2.name);
    setModelText(lcd_search2.name);

    axios({
      method: "get",
      url:
        PREAPI_URL + "api/v2/vehicles/model/" +
        $model +
        "/versions?token=" +
        props.user.token
    }).then(response => {
      //this.setState({ versions: 'camiislsl ' });
      //const model = response.data;
      // this.setState({ versions: response });
      setVersion(response);
      console.log("VERSIONES ", versiones);
      // console.log('versiones ', this.state.versions);
    });
  }

  useEffect(() => {
    if (newversion == false) {
      props.dispatch(change("publisVehicules", "version_id", '999999999'));
    } else {
      props.dispatch(change("publisVehicules", "version_id", ''));
      props.dispatch(change("publisVehicules", "new_version", ""));
    }
  }, [newversion]);

  useEffect(() => {
    if (localStorage.getItem("brands_list") === null) {
      axios({
        method: "get",
        url:
          PREAPI_URL + "api/vehicle/brands?token=" +
          props.user.token
      }).then(response => {
        setBrands(response.data);
        localStorage.setItem("brands_list", JSON.stringify(response.data));
      });
    } else {
      setBrands({ data: JSON.parse(localStorage.getItem("brands_list")) });
    }

    if (localStorage.getItem("colors_list") === null) {
      axios({
        method: "get",
        url:
          PREAPI_URL + "api/vehicle/colors?token=" +
          props.user.token
      }).then(response => {
        setColors(response.data);
        localStorage.setItem("colors_list", JSON.stringify(response.data));
      });
    } else {
      setColors(JSON.parse(localStorage.getItem("colors_list")));
    }

    if (props.publisVehicules) {
      console.log(
        "sE CARGO!!!!!!!!!@###@#@#@#@#@#@#",

        props.publisVehicules.values
      );
      if (typeof props.publisVehicules.values != "undefined") {
        if (typeof props.publisVehicules.values.type != "undefined") {
          setTypeVehicles(props.publisVehicules.values.type);
        }
        if (props.publisVehicules.values.brand_id != "undefined") {
          selectBrands(props.publisVehicules.values.brand_id, 1);
        }
        if (props.publisVehicules.values.model_id != "undefined") {
          selectModel(props.publisVehicules.values.model_id, 1);
        }

        console.log("nuevo yo");
      }
    }


  }, []);

  const { handleSubmit, pristine, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>
      <FormGroup>
        <Field
          name="sale_condition"
          label="Condición (*)"
          children={[
            ["0km", "0km"],
            ["Usado", "Usado"],
            ["Consignación", "Consignación"]
          ]}
          component={SelectSimple}
        ></Field>
      </FormGroup>

      <FormGroup>
        <label>Tipo (*)</label>
        <Field
          name="type"
          item={types}
          onChange={e => getResponse(e)}
          component={SelectDynamic}
        ></Field>
      </FormGroup>
      <FormGroup>
        <label>Marca</label>
        <Field
          name="brand_id"
          value={brands}
          item={brands}
          onChange={e => selectBrands(e)}
          component={SelectDynamic}
          filter={"type_vehicle_id|" + typeVehicles}
        ></Field>
      </FormGroup>

      <FormGroup>
        <label>Modelo (*)</label>
        <Field
          name="model_id"
          item={models}
          onChange={e => selectModel(e)}
          component={SelectDynamic}
          filter={"type_vehicle_brand_id|" + brand}
        ></Field>
      </FormGroup>

      <FormGroup>
        <label>
          Versión (*)
          <b>
            (Quiero ingresar una nueva Versión
            <input
              type="checkbox"
              onClick={event => setNewv(event.target.checked ? false : true)}
            />)&nbsp;&nbsp;
            (Solo con precio sugeridos
            <input className="text-sm-left"
              type="checkbox"
              onClick={event => setSugeridoson(event.target.checked ? "camara_id|null|v" : "")}
            />

            )
          </b>

        </label>

        <Field
          name="version_id"
          item={version}
          onChange={e => selectNumericVersion(e)}
          component={SelectDynamic}
          onoff={!newversion}
          filter={sugeridoson}
        ></Field>
      </FormGroup>
      {newversion == false && (
        <Field
          name="new_version"
          type="text"
          onChange={e => selectTextVersion(e)}
          label="Ingresar la nueva versión"
          component={InputField}
          value="otro"
        ></Field>
      )}
      <FormGroup>
        <Field
          name="color_id"
          children={colors}
          component={SelectSimpleParser}
          label="Color (*)"
          filter="color_id"
        ></Field>
      </FormGroup>
      <FormGroup>
        <Field
          name="titleML"
          label="Título de MercadoLibre/OLX (*.)"
          type="text"
          component={InputField}
        />
      </FormGroup>
      <br />
      <div>
        <Button
          type="submit"
          disabled={pristine || submitting}
          className="float-right"
        >
          Siguiente
        </Button>
      </div>
    </form>
  );
}
function mapState(state) {
  const { users, authentication, form } = state;
  const { user } = authentication;
  const { publisVehicules } = form;
  const formValues = getFormValues("publisVehicules")(state) || {};
  return { user, users, publisVehicules, formValues };
}

const actionCreators = {};

WizardFormOnePage = connect(
  mapState,
  actionCreators
)(WizardFormOnePage);
export default reduxForm({
  form: "publisVehicules", //                 <------ same form name
  validate,
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true // <------ unregister fields on unmount
})(WizardFormOnePage);
