import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Col, Button, Modal, ModalBody, ModalHeader } from "shards-react";

class NotFound extends React.Component {

    constructor(props) {
      super(props);
      console.log(props);
    }

    render() {
      const { open, text } = this.props;
      return (
        <div>
          <Modal open={open} >
            <ModalBody style={{background: '#f9f9fa'}}>
            
                <center>
                <img src="/404.png" style={{maxWidth:'200px', width: '100%'}}></img>
             
                <h3 className='mt-5'>El recurso que intentas obtener no existe o no tienes permisos para acceder a el.</h3>
                
                <Button onClick={() => window.location.href = '/'}>Ir al inicio</Button>
                </center>
                <br /> <br />
            </ModalBody>
          </Modal>
        </div>
      );
    }
  }

NotFound.propTypes = {
  open: PropTypes.bool,
  text: PropTypes.string
};


export default NotFound;
