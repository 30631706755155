import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { Icon } from "antd";
const type = "Image"; // Need to pass which type element can be draggable

const Image = ({ image, index, moveImage, remove, preview }) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: type,
    hover(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Move the content
      moveImage(dragIndex, hoverIndex);
      // Update the index for dragged item directly to avoid flickering when half dragged
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type, id: image.id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  // initialize drag and drop into the element
  drag(drop(ref));

  return (
    <div
      ref={ref}
      style={{ opacity: isDragging ? 0 : 1 }}
      className="file-item"
    >
      <span alt={`img - ${image.id}`}>
        <div className="ant-upload-list-item">
          {image.state == "load" ? (
            <div
              alt={`img - ${image.id}`}
              className="card ant-upload-list-item-info"
            >
              <span>
                <img
                  alt={`img - ${image.id}`}
                  src="/img/loadingnew.gif"
                  className="ant-upload-list-item-image"
                />
              </span>
            </div>
          ) : (
            <>
              <div
                alt={`img - ${image.id}`}
                className="card ant-upload-list-item-info"
              >
                <span>
                  <a
                    className="ant-upload-list-item-thumbnail"
                    href="https://www.frasesmuybonitas.net/wp-content/uploads/2015/02/lindas-frases-para-hacer-feliz-a-una-mujer.jpg"
                    target="_blank"
                  >
                    <img
                      alt={`img - ${image.id}`}
                      src={image.url}
                      className="ant-upload-list-item-image item-img"
                    />
                  </a>
                </span>
              </div>
              <span className="ant-upload-list-item-actions">
                <Icon
                  type="eye"
                  onClick={() => {
                    preview(image.image_order);
                  }}
                />

                <Icon
                  type="delete"
                  onClick={() => {
                    remove(image.image_order, image.id, image.url);
                  }}
                />
              </span>
            </>
          )}
        </div>
      </span>
    </div>
  );
};

const CarFileList = ({ images, moveImage, remove, preview }) => {
  const renderImage = (image, index) => {
    return (
      <Image
        image={image}
        index={index}
        key={`${image.id}-image`}
        moveImage={moveImage}
        remove={remove}
        preview={preview}
      />
    );
  };

  return <section className="file-list">{images.map(renderImage)}</section>;
};

export default CarFileList;
