import React from 'react';
import './style.css';
const inputField = ({ required=false ,input, label, type, meta: { touched, error } }) => (
    <div style={{marginBottom: 10}}>
        <label style={{marginBottom: 1}}>{label}</label>
        <div>
        {required ? (
            <input {...input} placeholder={label} className="form-control" type={type} required />

        ):(

            <input {...input} placeholder={label} className="form-control" type={type}  />
        )
        }
            {touched && error && <span className="red-snow">{error}</span>}
        </div>
    </div>
);

export default inputField;
