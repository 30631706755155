import React, {useState, useEffect} from 'react'
import { reduxForm, Field, getFormValues, change } from "redux-form";
import { connect } from "react-redux";
import axios from 'axios';
import { store } from "../_helpers";
import InputField from "../_common/InputField";
import SelectSimple from "../_common/SelectSimple";
import SelectSimpleParser from "../_common/SelectSimpleParser";
import SelectDynamic from "../_common/SelectSimple";
import CheckBoxSimple from "../_common/CheckBoxSimple";
import GoogleMapReact from 'google-map-react';
import {Popover, Button} from 'antd';
import {
  Row,
  Col,
  Card,
  CardBody,
} from "shards-react";

var provincia = undefined;


const SomePoint = ({ text }) => <div>{text}</div>;

const SideX = props => {
 
    const [provincias, setProvincias] = useState([]);
    const [partidos, setPartido] = useState([]);
    const [barrios, setBarrio] = useState([]);
    const [whatsapp, setWhatsApp] = useState(props.activedX.whatsapp);


    const [mapsApiLoaded, setMapsApiLoaded] = useState(false);
    const [mapInstance, setMapInstance] = useState(null);
    const [mapsapi, setMapsApi] = useState(null);     
    const [latitud, setLatitud] = useState(props.activedX.latitude);
    const [longitud, setLongitud] = useState(props.activedX.longitude);
    const [first, setFirst] = useState(false);
    


  const { handleSubmit, pristine, reset, submitting, except } = props
  return (
    <form onSubmit={handleSubmit}>

              <Card className="mb-4">
                <CardBody>

                  <h4>Datos</h4>
                  <hr className="mb-2" />

                  <Row>
                    <Col>


      <div>

     
        <Row>
          <Col sm="6">

          <Field
            name="first_name"
            component={InputField}
            type="text"
            label="Nombre"
          />
           <Field
            name="last_name"
            component={InputField}
            type="text"
            label="Apellido"
          />

          
          </Col>
          <Col sm="6">
          

         <Field
            name="email"
            component={InputField}
            type="email"
            label="Email"
          />

          <Field
            name="phone"
            component={InputField}
            type="number"
            label="Teléfono"
          />

          </Col>
        </Row>

        <Field
            name="password"
            component={InputField}
            type="password"
            label="Cambiar Contraseña"
        />
       
                 

        </div>
  
        <br/>
        <button type="submit" className="btn btn-primary" disabled={submitting}>
            Guardar
        </button>

        </Col>
        </Row>
        
                </CardBody>
              </Card>       
    </form>
  )
}

function mapState(state, props) {
   
  const { users, authentication, vehicles, adminuser } = state;

  const { user } = authentication;

  if(props.activedX) {
    return { user, users, adminuser, initialValues: props.activedX};
  }else{
    return { user, users, adminuser};
  }
}
const actionCreators = {

};

const po = reduxForm({
form: 'SideX', 
enableReinitialize: true
})(SideX);

const pox = connect(
  mapState,
  actionCreators
)(po);

export { pox as SideX };

