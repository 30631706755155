import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormInput,
  FormGroup,
  Tooltip,
  ListGroup,
  ListGroupItem
} from "shards-react";
import SearchResults from "react-filter-search";
import { Redirect } from "react-router-dom";
import PageTitle from "../_components/utils/PageTitle";
import IframeSimple from "../_common/IframeSimple";
import "./SegurosPage.css";
import "../SelectDealerPage/SelectDealerPage.css";
import { alertActions } from "../_actions";
import Cookies from "universal-cookie";
let dealer = require("../_data/dealer.json");
const cookies = new Cookies();
const date = new Date();

class SegurosPage extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      id_usuario: "",
      open: false
    };
  }
  toggle() {
    this.setState({
      open: !this.state.open
    });
  }

  componentWillMount = () => {
    var dato = JSON.parse(localStorage.getItem("user"));

    var user_id = dato.user.id;
    this.setState({
      id_usuario: user_id
    });
  };

  render() {
    return (
      <>
        <Row noGutters className="page-header py-4" style={{ width: "100%" }}>
          <PageTitle
            sm="4"
            title="Cotizador de seguros"
            className="text-sm-left"
          />
        </Row>

        <Card className={"mb-4 alert-danger"}>
          <CardBody>
            <Row>
              <Col sm="4">
                <img
                  src="/lauro.png"
                  style={{
                    width: "100%",
                    borderRadius: "6px",
                    paddingBottom: "40px"
                  }}
                ></img>
              </Col>
              <Col sm="8"></Col>
              <Col sm="3">
                <img
                  src="/instructivo.png"
                  style={{ width: "100%", borderRadius: "6px" }}
                ></img>
              </Col>

              <Col sm="9">
                <h5 style={{ color: "white" }}>Importante:</h5>
                <p style={{ margin: 0 }}>
                  Para poder comisionar debe cargar su número de identificador
                  de agencia (<strong>{this.state.id_usuario}</strong>) en el
                  campo formulario 3 del paso Solicitud de Seguro.
                </p>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <div
          style={{
            maxWidth: 600,
            width: "100%"
          }}
        >
          <IframeSimple
            src="https://secure.smartix.com.ar/Ventas/B2B2C/3aeaa898-f391-4f6e-8dc2-6557e96124dc"
            height="100vh"
            width="100%"
          />
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { users, authentication, alert } = state;
  const { user } = authentication;

  return { user, users, alert };
}
const actionCreators = {
  showAlert: alertActions.show,
  hideAlert: alertActions.hide
};

const connectedDashboard = connect(
  mapState,
  actionCreators
)(SegurosPage);
export { connectedDashboard as SegurosPage };
