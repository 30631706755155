import React from 'react'
import { Row, Col } from 'antd';
import './infoDealer.css'

const InfoDealer = ({dataInfoCar,showHideFunc,showHide, priceCar ,create = true,buttonContact = true}) => {
    
    return (
        <div>
            {/* INFO AUTO */}
            <div className="containerInfoCarSectionDetail">
            <Col>
            <p className="fontConcecionaria">{dataInfoCar && dataInfoCar.sale_condition} | {dataInfoCar && dataInfoCar.company}</p>
            </Col>
            <Col>
            <p className="fontInfoCar">{dataInfoCar && dataInfoCar.brand_name} {dataInfoCar && dataInfoCar.model} {dataInfoCar && dataInfoCar.version_name}</p>
            </Col>
            <Col>

            {priceCar === "ToPrint" ? <p className="fontPriceCarToPrint">{dataInfoCar && `${dataInfoCar.currency_name} ${dataInfoCar.price}`}</p>  : <p className="fontPriceCar">{dataInfoCar && `${dataInfoCar.currency_name} ${dataInfoCar.price}`}</p>
             }
            
            </Col>
            <Col>
            <p className="fontTextSecondary">{dataInfoCar && dataInfoCar.year} | {dataInfoCar && dataInfoCar.kilometers} </p>
            </Col>
            <Col>
            <p className="fontLocationCar">{dataInfoCar && dataInfoCar.region_name}, {dataInfoCar && dataInfoCar.address}</p>
            <p className="fontCreateData">{dataInfoCar && create && dataInfoCar.created}</p>
            </Col>
            </div>
            {/* INFO AGENCIA */}
            <div className="containerInfoDealerSectionDetail">
            <Col>
                <p className="fontDealerInfo">{dataInfoCar && dataInfoCar.company}</p>
                <p className="fontDealerInfoHorus">Horarios de atención:</p>
                <p className="fontDealerInfoHorusDays">{dataInfoCar && dataInfoCar.work_hours}</p>
            </Col>
            </div>
            {/* Button CTA */}
            {buttonContact && 
            <div>
                <button className="btn btn-primary button-primary-info-dealer" onClick={() => showHideFunc(!showHide)} >Datos de Contacto</button>
            </div>
            }
        </div>

    )
}

export default InfoDealer
