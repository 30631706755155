import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import axios from "axios";
import RangeDatePicker from "../utils/RangeDatePicker";
import Chart from "../utils/chart";
import Cookies from "universal-cookie";
import { API_URL } from "../../_config";

class CanalPorMes extends React.Component {
  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    const chartOptions = {
      ...{
        legend: false,
        scales: {
          xAxes: [
            {
              stacked: true
            }
          ],
          yAxes: [
            {
              stacked: true
            }
          ]
        }
      },
      ...this.props.chartOptions
    };

    const cookies = new Cookies();
    if (cookies.get("dealer")) {
      axios
        .get(
          API_URL + "dashboard/" +
            cookies.get("dealer").id +
            "/dealer"
        )
        .then(response => {
          var datazo = response.data;

          var $yearToday = new Date().getFullYear(); //( YEAR)
          var $mont = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
          ];

          var {
            $dvxcOLX,
            $dvxcML,
            $dvxcFB,
            $dvxcAC,
            $dvxcIG,
            $dvxcCCA,
            $dvxcDM,
            $dvxcLV
          } = [];

          var $sales = [];
          var $salesFB = [];
          var $salesAC = [];
          var $salesML = [];
          var $salesDM = [];
          var $salesCCA = [];
          var $salesIG = [];
          var $salesOLX = [];
          var $salesLV = [];

          var $dvxcOLX = [];
          var $dvxcML = [];
          var $dvxcFB = [];
          var $dvxcAC = [];
          var $dvxcIG = [];
          var $dvxcCCA = [];
          var $dvxcDM = [];
          var $dvxcLV = [];

          var vxc = response.data.publish_por_canal.filter(function(xmes) {
            return xmes.years == $yearToday;
          });
          for (var i = 0; i < 12; i++) {
            if (vxc[i]) {
              $salesFB[vxc[i].month] = vxc[i].FB;
              $salesML[vxc[i].month] = vxc[i].ML;
              $salesAC[vxc[i].month] = vxc[i].AC;
              $salesDM[vxc[i].month] = vxc[i].DM;
              $salesCCA[vxc[i].month] = vxc[i].CCA;
              $salesIG[vxc[i].month] = vxc[i].IG;
              $salesOLX[vxc[i].month] = vxc[i].OX;
              $salesLV[vxc[i].month] = vxc[i].LV;
            }
          }
          for (var i = 0; i < 12; i++) {
            if ($salesOLX[$mont[i]]) {
              $dvxcOLX.push($salesOLX[$mont[i]]);
            } else {
              $dvxcOLX.push(0);
            }

            if ($salesML[$mont[i]]) {
              $dvxcML.push($salesML[$mont[i]]);
            } else {
              $dvxcML.push(0);
            }

            if ($salesML[$mont[i]]) {
              $dvxcLV.push($salesLV[$mont[i]]);
            } else {
              $dvxcLV.push(0);
            }

            if ($salesFB[$mont[i]]) {
              $dvxcFB.push($salesFB[$mont[i]]);
            } else {
              $dvxcFB.push(0);
            }

            if ($salesAC[$mont[i]]) {
              $dvxcAC.push($salesAC[$mont[i]]);
            } else {
              $dvxcAC.push(0);
            }

            if ($salesCCA[$mont[i]]) {
              $dvxcCCA.push($salesCCA[$mont[i]]);
            } else {
              $dvxcCCA.push(0);
            }
            if ($salesIG[$mont[i]]) {
              $dvxcIG.push($salesIG[$mont[i]]);
            } else {
              $dvxcIG.push(0);
            }

            if ($salesDM[$mont[i]]) {
              $dvxcDM.push($salesDM[$mont[i]]);
            } else {
              $dvxcDM.push(0);
            }
          }

          const final = {
            labels: [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre"
            ],
            datasets: [
              {
                label: "Facebook",
                backgroundColor: [
                  "#0297c4",
                  "#0297c4",
                  "#0297c4",
                  "#0297c4",
                  "#0297c4",
                  "#0297c4",
                  "#0297c4",
                  "#0297c4",
                  "#0297c4",
                  "#0297c4",
                  "#0297c4",
                  "#0297c4"
                ],
                data: $dvxcFB
              },
              {
                label: "OLX",
                backgroundColor: [
                  "#980198",
                  "#980198",
                  "#980198",
                  "#980198",
                  "#980198",
                  "#980198",
                  "#980198",
                  "#980198",
                  "#980198",
                  "#980198",
                  "#980198",
                  "#980198"
                ],
                data: $dvxcOLX
              },
              {
                label: "Autocosmos",
                backgroundColor: [
                  "#0f9517",
                  "#0f9517",
                  "#0f9517",
                  "#0f9517",
                  "#0f9517",
                  "#0f9517",
                  "#0f9517",
                  "#0f9517",
                  "#0f9517",
                  "#0f9517",
                  "#0f9517",
                  "#0f9517"
                ],
                data: $dvxcAC
              },
              {
                label: "DeMotores",
                backgroundColor: [
                  "#fe9801",
                  "#fe9801",
                  "#fe9801",
                  "#fe9801",
                  "#fe9801",
                  "#fe9801",
                  "#fe9801",
                  "#fe9801",
                  "#fe9801",
                  "#fe9801",
                  "#fe9801",
                  "#fe9801"
                ],
                data: $dvxcDM
              },
              {
                label: "Mercadolibre",
                backgroundColor: [
                  "#db3811",
                  "#db3811",
                  "#db3811",
                  "#db3811",
                  "#db3811",
                  "#db3811",
                  "#db3811",
                  "#db3811",
                  "#db3811",
                  "#db3811",
                  "#db3811",
                  "#db3811"
                ],
                data: $dvxcML
              },
              {
                label: "Instagram",
                backgroundColor: [
                  "#dbcb10",
                  "#dbcb10",
                  "#dbcb10",
                  "#dbcb10",
                  "#dbcb10",
                  "#dbcb10",
                  "#dbcb10",
                  "#dbcb10",
                  "#dbcb10",
                  "#dbcb10",
                  "#dbcb10",
                  "#dbcb10"
                ],
                data: $dvxcIG
              },
              {
                label: "LaVoz",
                backgroundColor: [
                  "#980198",
                  "#980198",
                  "#980198",
                  "#980198",
                  "#980198",
                  "#980198",
                  "#980198",
                  "#980198",
                  "#980198",
                  "#980198",
                  "#980198",
                  "#980198"
                ],
                data: $dvxcLV
              },
              {
                label: "Nuestros Autos",
                backgroundColor: [
                  "#3265ca",
                  "#3265ca",
                  "#3265ca",
                  "#3265ca",
                  "#3265ca",
                  "#3265ca",
                  "#3265ca",
                  "#3265ca",
                  "#3265ca",
                  "#3265ca",
                  "#3265ca",
                  "#3265ca"
                ],
                data: $dvxcCCA
              }
            ]
          };

          console.log(final);

          if (final.datasets != null && final.datasets.length < 1) {
            window.location.reload();
          }
          const BlogUsersOverview = new Chart(this.canvasRef.current, {
            type: "bar",
            data: final,
            options: chartOptions
          });

          // They can still be triggered on hover.
          const buoMeta = BlogUsersOverview.getDatasetMeta(0);
          buoMeta.data[0]._model.radius = 0;
          buoMeta.data[final.datasets[0].data.length - 1]._model.radius = 0;

          // Render the chart.
          BlogUsersOverview.render();
        });
    }
  }

  render() {
    const { title } = this.props;
    return (
      <div>
        <br />
        <canvas
          height="120"
          ref={this.canvasRef}
          style={{ maxWidth: "100% !important" }}
        />
      </div>
    );
  }
}

CanalPorMes.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The chart dataset.
   */
  chartData: PropTypes.object,
  /**
   * The Chart.js options.
   */
  chartOptions: PropTypes.object
};

CanalPorMes.defaultProps = {
  title: "Publicados por canal total"
};

export default CanalPorMes;
