import React from "react";
import { Button,Col, Row } from "shards-react";
import PageTitle from "../_components/PageTitle";

export const MergeBrandsAndModelsContainer = (props) => {
  return (
    <div style={{position: "relative"}}>
    <Row noGutters className="page-header py-4" style={{ width: "100%", position : "relative" }}>
            <PageTitle sm="12" title="Admin : Unificación de Marcas y Modelos" className="text-sm-left" />
            <div className="pt-4">
            <p>En esta seccion podras unificar las marcas y modelos existentes en nuestra base de datos</p>
            <p>¡Recuerda estar seguro de cuales quieres unificar ya que unificara todos los datos de las publicaciones activas!</p>
            </div>

    </Row>
    <Row className="pt-4">
      <Col sm="6" className="text-center" >
        <div>
          <h5>Unificar Marcas existentes</h5>
          <Button onClick={() => props.history.push("/admin_listas_uni/marcas")} style={{width : "100%"}}>Marcas</Button>
        </div>
      </Col>
      <Col sm="6" className="text-center">
        <div>
          <h5>Unificar Modelos existentes</h5>
          <Button onClick={() => props.history.push("/admin_listas_uni/modelos")} style={{width : "100%"}} >Modelos</Button>
        </div>
      </Col>
          </Row>
  </div>
  )
}

