import React from "react";
import { connect } from "react-redux";
import { API_URL, PREAPI_URL } from "../_config";

//import $ from 'jquery'
import { store } from "../_helpers";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import axios from "axios";
import { adminuserActions } from "../_actions";
import { Table, AutoComplete, notification, Icon, message } from 'antd';
import "react-tabs/style/react-tabs.css";
import {
  Badge,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Card,
  CardImg,
  ButtonToolbar,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  FormCheckbox,
  Container,
  FormTextarea,
  CardHeader,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  ButtonGroup,
  Alert
} from "shards-react";
import { CrearUsuario, ConsUsuario, PermUsuario } from "./";
import PageTitle from "../_components/PageTitle";
import openNotificationWithIcon from "../_common/OpenNotificationWithIcon";

class EditarUsuario extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      load : false,
      nav_number: 0,
      cons: true,
      perm: true,
      id_user: 0,
      user_data: {},
      permisos_usados: {},
      dealers_usados: {},
      loadingx: true,
      permUserMessage : "",
      openAlert : true
      };

    //const items = this.props.vehicles;
    this.toggle = this.toggle.bind(this);
    this.update_user = this.update_user.bind(this);
    this.assign_cons = this.assign_cons.bind(this);
    this.assign_perms = this.assign_perms.bind(this);
    this.getDataUser = this.getDataUser.bind(this);
    this.refressh = this.refressh.bind(this);
    this.closeMessagePerm = this.closeMessagePerm.bind(this);
  }

  componentWillMount() {
    this.getDataUser()
  }
  // Trae todos los datos del usuario seleccionado
  getDataUser(){
    axios({
      method: "get",
      url:
      API_URL+"api/user/"+ this.props.id_user +"?token=" +
        store.getState().authentication.user.token
    }).then(response => {
      this.setState({
        id_user: response.data.id,
        user_data: response.data,
        dealers_usados: response.data.dealers,
        permisos_usados: response.data.groups,
        loadingx:false
      });
    }).catch(error => openNotificationWithIcon("error", 'Ooops! hemos tenido un error', `${error.response}`))
  }
  // desactiva el modal y vuelve a recargar los usuarios
  refressh(notificationUse,event,title,description){
    this.setState({open: false})
    this.props.getUsers()
    if(notificationUse){
      openNotificationWithIcon(event, title, description)
    }
  }

  update_user(values) {
    axios.post(API_URL+'api/user/'+ this.props.id_user +'?token=' +
    store.getState().authentication.user.token, {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      phone: values.phone,
      password: values.password
    }).then(response => {
      this.refressh(true, 'success', 'Listo!', 'Usuario Actualizado correctamente!')
    }).catch(error => {
      alert(`Ooops! hemos tenido un error ${error.response} `)
    } 
      )
  }

  assign_cons(values) {

    axios.post(API_URL+'api/user/'+ this.state.id_user +'/dealers?token=' +
    store.getState().authentication.user.token, values)
    .then(response => {
      alert(`${response},actualizado con exito!`)
    }).catch(error => alert(`${error.response}`))


  } 
  assign_perms(values) {

    axios.post(API_URL+'api/user/'+ this.state.id_user +'/perms?token=' +
    store.getState().authentication.user.token, values).then(response => {
      axios({
        method: "get",
        url:
        API_URL+"api/user/"+ this.state.id_user +"?token=" +
          store.getState().authentication.user.token
      }).then(response => {
        this.setState({
          dealers_usados: response.data.dealers,
          permisos_usados: response.data.groups,
          permUserMessage : "actualizado",
        })
      }).then(res => this.refressh(true,"success", 'Listo','Usuario Actualizado correctamente!'))
    }).catch(error => alert(`${error.response}, error permisos`))
  } 

  toggle() {
    this.setState({
      open: !this.state.open
    });
  }
  closeMessagePerm(){
    this.setState({permUserMessage : false, openAlert : false})

  }
  
  render() {
    console.log(this.props,'PROPS')
    const { open } = this.state;
    if(open == false) {
      return (<></>);
    }else{
    return (
      <div>
      
          <Modal size="lg" open={open} toggle={() => console.log('Toggle')}>
            <ModalHeader>Editar Usuario  <i style={{position:'absolute', right: '20px', top: '15px', fontSize: '25px', cursor: 'pointer'}} class="material-icons" onClick={this.toggle}>clear</i></ModalHeader>
            <ModalBody>
              <Nav tabs>
                <NavItem active={this.state.nav_number == 0 ? true : false}>
                  <NavLink
                    onClick={() => {
                      this.setState({
                        nav_number: 0
                      });
                    }}
                    href="#"
                  >
                    Datos
                  </NavLink>
                </NavItem>
                <NavItem active={this.state.nav_number == 1 ? true : false}>
                  <NavLink
                    onClick={() => {
                      this.setState({
                        nav_number: 1
                      });
                    }}
                    href="#"
                    disabled={this.state.cons == true ? false : true}
                  >
                    Concesionarias
                  </NavLink>
                </NavItem>
                <NavItem active={this.state.nav_number == 2 ? true : false}>
                  <NavLink
                    onClick={() => {
                      this.setState({
                        nav_number: 2
                      });
                    }}
                    disabled={this.state.perm == true ? false : true}
                    href="#"
                  >
                    Permisos
                  </NavLink>
                </NavItem>
              </Nav>
              <br />
              <br />
              {this.props.usercreateSuccess && 
                <Alert class="my-4" dismissible={() => {this.closeMessagePerm()}} open={this.state.openAlert} theme="success">
                Usuario con ID {this.state.id_user} creado correctamente, Agregale concesionarias y permisos!
                </Alert>
              }
              {this.state.loadingx == true ? (
                <div style={{height:'500px', textAlign:'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}><img src="/loading.gif" style={{maxWidth:'200px', width:'100%'}}/></div>
              ): (
              <div>
              {this.state.nav_number == 0 ? (
                <CrearUsuario activedX={this.state.user_data} edit={true} onSubmit={this.update_user} patchUbication={"editUser"} />
              ) : (
                <div />
              )}

              {this.state.nav_number == 1 ? <ConsUsuario po={() => {
                notification.open({
                  message: 'Cargando',
                  description:
                    'Espere un momento por favor',
                  icon: <Icon type="loading" style={{ color: '#4caf50' }} />,
                  style: {zIndex: 999999},
                  top: 70
                });
              }} userazo={this.state.id_user} activedX={this.state.dealers_usados} onSubmit={this.assign_cons} /> : <div />}

              {this.state.nav_number == 2 ? <PermUsuario closeMessagePerm={this.closeMessagePerm} messagePerm={this.state.permUserMessage} activedX={this.state.permisos_usados} onSubmit={this.assign_perms} /> : <div />}
              </div>
              )}
              
              </ModalBody>
          </Modal>
          </div>

    );
              }
  }
}

function mapState(state, props) {
  const { users, authentication, vehicles, adminuser } = state;

  const { user } = authentication;
  console.log("esto ->", vehicles);

  return { user, users, adminuser };
}

const actionCreators = {
  getUsers: adminuserActions.getUsers
};

const connectedUsuarios = connect(
  mapState,
  actionCreators
)(EditarUsuario);
export { connectedUsuarios as EditarUsuario };
  