/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, FieldArray, getFormValues } from 'redux-form';
//import renderField from './renderField';
import SelectSimple from '../../_common/SelectSimple';
import SelectDynamic from '../../_common/SelectDynamic';
import SelectDynamic2 from '../../_common/SelectDynamic2';
import openNotificationWithIcon from '../../_common/OpenNotificationWithIcon';
import { API_URL, PREAPI_URL } from '../../_config';
//import PublishConflict from "./PublishConflict";

import axios from 'axios';
import { Row, Button, FormGroup } from 'shards-react';
let types = require('../type.json');
let brands = require('../brands.json');

function ResolveConflict(props) {
  const [brand, setBrand] = useState('');
  const [typeVehicles, setTypeVehicles] = useState(1);

  const [condicion, setCondicion] = useState(0);
  const [models, setModels] = useState([]);
  const [versiones, setVersiones] = useState([]);
  const [version, setVersion] = useState([]);
  const [model, setModel] = useState('');
  const [newversion, setNewv] = useState(true);

  // Variables para LaVoz
  const [formLaVoz, setFormLaVoz] = useState(false);
  const [marcasLaVoz, setMarcasLaVoz] = useState([]);
  const [typeVehicleLaVoz, setTypeVehicleLaVoz] = useState(0);

  const [enableVersion, setEnableVersion] = useState(false);
  const [versionLoad, setVersionLoad] = useState();

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    // CAMBIOS EN EL TIPO DE VEHICULO
    // Verifico si es de la Voz, para activar su respectivo protocolo
    if (props.dataConf && props.dataConf.portal == 'lavoz' && typeVehicleLaVoz != 0) {
      setMarcasLaVoz([]);
      setModels([]);
      setVersion([]);

      // Obtengo las marcas de la Voz
      axios({
        method: 'get',
        url: PREAPI_URL + 'lavoz_listBrands/' + typeVehicleLaVoz,
      }).then((response) => {
        setMarcasLaVoz(response);
      });
    }
  }, [typeVehicleLaVoz]);

  useEffect(() => {
    if (props.dataConf && props.dataConf.portal == 'lavoz') {
      // Confirmo
      setFormLaVoz(true);
    }
  }, []);

  async function PublishConflict(values) {
    //await sleep(500);
    console.log('|||| DADADADA |||| =', values);
    let caracteristicas = {
      brand: values.brand_id,
      model: values.model_id,
      version: values.version ? values.version : values.version_id,
      listing: props.dataConf.listing,
    };

    // console.log("|||| DADADADA", values);

    switch (props.dataConf.portal) {
      case 'mercadolibre':
        axios
          .post(
            ///api/dealer/'+$scope.currentDealer.id+'/mercadolibre/vehicle/'+$scope.vehicleId+'/update-vehicle
            API_URL +
              'api/dealer/' +
              props.dataConf.state_publish.dealer_id +
              '/mercadolibre/vehicle/' +
              props.dataConf.state_publish.vehicle_id +
              '/update-vehicle?token=' +
              props.user.token,
            caracteristicas,
          )
          .then((res) => {
            if (res.data == 'OK') {
              props.close();
              setTimeout(() => {
                props.close();
                props.cancelRc();
              }, 1000);
            }
          })
          .catch((err) => {
            openNotificationWithIcon('error', 'Ocurrio un problema', `${err.response.data}`);
          });

        break;
      case 'olx':
        // console.log("CARATTT",caracteristicas);
        // alert('EOLLLS => '+caracteristicas)

        // $resource('/api/dealer/'+$scope.currentDealer.id+'/olx/vehicle/'+$scope.vehicleId+'/update-vehicle').save(caracteristicas,function(){

        axios
          .post(
            API_URL +
              'api/dealer/' +
              props.dataConf.state_publish.dealer_id +
              '/olx/vehicle/' +
              props.dataConf.state_publish.vehicle_id +
              '/update-vehicle?token=' +
              props.user.token,
            caracteristicas,
          )
          .then((res) => {
            if (res.data == 'OK') {
              props.close();
              setTimeout(() => {
                props.close();
                props.visible('ol');
                //props.cancelRc();
              }, 1000);
              openNotificationWithIcon(
                'success',
                'Listo!',
                'Ya se hizo la resolucion de conflicto y estamos listos para republicar.',
              );
            }
          })
          .catch((err) => {
            openNotificationWithIcon('error', 'Ocurrio un problema', `${err.response.data}`);
          });

        break;

      case 'lavoz':
        axios
          .post(
            API_URL +
              'api/dealer/' +
              props.dataConf.state_publish.dealer_id +
              '/lavoz/vehicle/' +
              props.dataConf.state_publish.vehicle_id +
              '/update-vehicle?token=' +
              props.user.token,
            {
              type: values.vehicle_type,
              brand: values.brand_id,
              model: values.model_id,
              version: values.version ? values.version : values.version_id,
              listing: props.dataConf.listing,
            },
          )
          .then((res) => {
            if (res.data == 'OK') {
              props.close();
              setTimeout(() => {
                props.close();
                props.backLaVoz();
              }, 1000);
              openNotificationWithIcon(
                'success',
                'Listo!',
                'Ya se hizo la resolucion de conflicto y estamos listos para republicar.',
              );
            }
          })
          .catch((err) => {
            openNotificationWithIcon('error', 'Ocurrio un problema', `${err.response.data}`);
          });

        break;
      case 'autocosmos':
        // console.log("CARATTT",caracteristicas);
        // alert('EOLLLS => '+caracteristicas)

        // $resource('/api/dealer/'+$scope.currentDealer.id+'/olx/vehicle/'+$scope.vehicleId+'/update-vehicle').save(caracteristicas,function(){
        //  $resource('/api/dealer/'+$scope.currentDealer.id+'/autocosmos/vehicle/'+$scope.vehicleId+'/update-vehicle').save(caracteristicas,function(){

        axios
          .post(
            API_URL +
              'api/dealer/' +
              props.dataConf.state_publish.dealer_id +
              '/autocosmos/vehicle/' +
              props.dataConf.state_publish.vehicle_id +
              '/update-vehicle?token=' +
              props.user.token,
            caracteristicas,
          )
          .then((res) => {
            if (res.data == 'OK') {
              props.close();
              setTimeout(() => {
                props.close();
                props.visible('ac');
                //props.cancelRc();
              }, 1000);
              openNotificationWithIcon(
                'success',
                'Listo!',
                'Ya se hizo la resolucion de conflicto y estamos listos para republicar.',
              );
            }
          })
          .catch((err) => {
            openNotificationWithIcon('error', 'Ocurrio un problema', `${err.response.data}`);
          });

        break;
      case 'demotores':
        // console.log("CARATTT",caracteristicas);
        // alert('EOLLLS => '+caracteristicas)

        // $resource('/api/dealer/'+$scope.currentDealer.id+'/olx/vehicle/'+$scope.vehicleId+'/update-vehicle').save(caracteristicas,function(){
        //  $resource('/api/dealer/'+$scope.currentDealer.id+'/autocosmos/vehicle/'+$scope.vehicleId+'/update-vehicle').save(caracteristicas,function(){

        axios
          .post(
            API_URL +
              'api/dealer/' +
              props.dataConf.state_publish.dealer_id +
              '/demotores/vehicle/' +
              props.dataConf.state_publish.vehicle_id +
              '/update-vehicle?token=' +
              props.user.token,
            caracteristicas,
          )
          .then((res) => {
            if (res.data == 'OK') {
              props.close();
              setTimeout(() => {
                props.close();
                props.visible('dm');
                //props.cancelRc();
              }, 1000);
              openNotificationWithIcon(
                'success',
                'Listo!',
                'Ya se hizo la resolucion de conflicto y estamos listos para republicar.',
              );
            }
          })
          .catch((err) => {
            openNotificationWithIcon('error', 'Ocurrio un problema', `${err.response.data || err.response.data.message || err.response.data.error}`);
          });

        break;
      case 'deautos':
        // console.log("CARATTT",caracteristicas);
        // alert('EOLLLS => '+caracteristicas)

        // $resource('/api/dealer/'+$scope.currentDealer.id+'/olx/vehicle/'+$scope.vehicleId+'/update-vehicle').save(caracteristicas,function(){
        //  $resource('/api/dealer/'+$scope.currentDealer.id+'/autocosmos/vehicle/'+$scope.vehicleId+'/update-vehicle').save(caracteristicas,function(){

        axios
          .post(
            API_URL +
              'api/dealer/' +
              props.dataConf.state_publish.dealer_id +
              '/deautos/vehicle/' +
              props.dataConf.state_publish.vehicle_id +
              '/update-vehicle?token=' +
              props.user.token,
            caracteristicas,
          )
          .then((res) => {
            if (res.data == 'OK') {
              props.close();
              setTimeout(() => {
                props.close();
                props.visible('da');
                //props.cancelRc();
              }, 1000);
              openNotificationWithIcon(
                'success',
                'Listo!',
                'Ya se hizo la resolucion de conflicto y estamos listos para republicar.',
              );
            }
          })
          .catch((err) => {
            openNotificationWithIcon('error', 'Ocurrio un problema', `${err.response.data}`);
          });

        break;
    }
  }

  function selectBrandLaVoz(e) {
    axios({
      method: 'get',
      url: PREAPI_URL + 'lavoz_modelbyslug/' + e.target.value,
    }).then((response) => {
      let rfilter = null;
      rfilter = response.data.list.filter((d) => d.lavoz_id != null);
      setModels({ data: rfilter });
    });
  }

  function selectBrands(e, init = null) {
    if (init != null) {
      setBrand(e);
    } else {
      setBrand(e.target.value);
    }

    axios({
      method: 'get',
      url: API_URL + 'api/v2/vehicle/models?token=' + props.user.token,
    }).then((response) => {
      //console.log()
      //this.setState({ models: response });
      console.log('|LO QUE TENGO NO LO DOY', props.dataConf.state_publish.dealer_id);
      console.log('|Lo nuevo', props.dataConf);
      let rfilter = null;
      if (props.dataConf.portal == 'mercadolibre') {
        console.log('|PASSAAA|');
        rfilter = response.data.filter((d) => d.mercadolibre_id != null);
      }
      if (props.dataConf.portal == 'olx') {
        rfilter = response.data.filter((d) => d.olx_id != null);
      }
      if (props.dataConf.portal == 'demotores') {
        rfilter = response.data.filter((d) => d.demotores_id != null);
      }

      if (props.dataConf.portal == 'autocosmos') {
        rfilter = response.data.filter((d) => d.autocosmos_id != null);
      }

      if (props.dataConf.portal == 'lavoz') {
        rfilter = response.data.filter((d) => d.lavoz_id != null);
      }
      if (props.dataConf.portal == 'deautos') {
        rfilter = response.data.filter((d) => d.deautos_id != null);
      }

      setModels({ data: rfilter });
      //const model = response.data;
    });
  }
  function selectModel(e, init = null) {
     setVersionLoad(true)
    let $model;
    if (init != null) {
      setModel(e);
      $model = e;
    } else {
      setModel(e.target.value);
      $model = e.target.value;
    }
    var nportal = props.dataConf.portal == 'deautos' ? '/deautos' : '';

    if (props.dataConf.portal == 'deautos') {
      axios({
        method: 'get',
        url:
          API_URL +
          'api/v2/vehicles/model/' +
          $model +
          '/versions' +
          nportal +
          '/' +
          props.dataConf.year +
          '?token=' +
          props.user.token,
      }).then((response) => {
        //this.setState({ versions: 'camiislsl ' });
        //const model = response.data;
        // this.setState({ versions: response });
        setEnableVersion(true);
        setVersion(response);
      }).then(() => setVersionLoad(false))
      .catch(error => console.log(`error versiones1 ${error}`))
    } else {
      axios({
        method: 'get',
        url:
        API_URL +
        'api/v2/vehicles/model/' +
        $model +
        '/versions' +
        nportal +
        '?token=' +
        props.user.token,
      }).then((response) => {
        //this.setState({ versions: 'camiislsl ' });
        //const model = response.data;
        // this.setState({ versions: response });
        setEnableVersion(true);
        setVersion(response);
        // console.log('versiones ', this.state.versions);
      }).then(() =>  
        setVersionLoad(false))
      .catch(error => console.log(`error versiones2 ${error}`))
    }
  }
  /*
  if (props.showVehicle) {
    //selectBrands(props.showVehicle.brand_id, 1);
    setTypeVehicles(props.showVehicle.type);
    selectBrands(props.showVehicle.brand_id, 1);
  }*/
  const PropsBrandsModels = {
    brandId : props._reduxForm.showVehicle.brand_id,
    brandName : props._reduxForm.showVehicle.brand_name,
    modelId : props._reduxForm.showVehicle.model_id,
    modelName : props._reduxForm.showVehicle.model_name,

  }
 

  useEffect(() => {
    if (props.showVehicle) {
      console.log('|>00<|', props);
      //selectBrands(props.showVehicle.brand_id, 1);
      setTypeVehicles(props.showVehicle.type);
      selectBrands(props.showVehicle.brand_id, 1);
    }
    //props.showVehicleID(props.match.params.id);
  }, [props.showVehicle]);

  const { showVehicle } = props;
  console.log('LADATA!!!!', showVehicle);
  const { handleSubmit, pristine, submitting } = props;

  return (
    <form className="add-new-post" onSubmit={handleSubmit(PublishConflict)}>
      <div className="page-header row no-gutters py-4">
        <div className="col-12 col-sm-10 text-center text-sm-left mb-0">
          <h3 className="page-title">Resolver Conflictos</h3>
          <span className="text-uppercase page-subtitle">
            de {props.dataConf.portal} (Marca:{props.dataConf.result.brand_name || PropsBrandsModels.brandName} - Modelo:
            {props.dataConf.result.model_name || PropsBrandsModels.modelName})
          </span>
          <strong className="text-muted d-block mt-4">
            Se han dectectado conflictos a la hora de publicar en el portal seleccionado. Por favor,
            resuelva manualmente:
          </strong>
        </div>
      </div>

      {formLaVoz == true ? (
        <>
          <FormGroup>
            <label>Tipo de Vehiculo</label>

            <Field
              name="vehicle_type"
              item={{
                data: [
                  { id: 1, lavoz_name: 'Autos' },
                  { id: 2, lavoz_name: '4x4, 4x2 y todo terreno' },
                  { id: 3, lavoz_name: 'Utilitarios y camiones' },
                ],
              }}
              onChange={(e) => setTypeVehicleLaVoz(e.target.value)}
              component={SelectDynamic2}
              filter={'type_vehicle_id|2'}
            ></Field>
          </FormGroup>

          <FormGroup>
            <label>Marca</label>

            <Field
              name="brand_id"
              item={marcasLaVoz}
              onChange={(e) => selectBrandLaVoz(e)}
              component={SelectDynamic2}
              filter={'type_vehicle_id|2'}
            ></Field>
          </FormGroup>

          <FormGroup>
            <label>Modelo</label>
            <Field
              name="model_id"
              item={models}
              onChange={(e) => selectModel(e)}
              component={SelectDynamic2}
              filter={`type_vehicle_brand_id|${props.dataConf.result.brand}`}
            ></Field>
          </FormGroup>

          <FormGroup>
            <label>Versión</label>
            <Field name="version_id" item={version} component={SelectDynamic}></Field>
          </FormGroup>
        </>
      ) : (
        <>
          <FormGroup>
            <label>Marca</label>
            <Field
              name="brand_id"
              item={brands}
              onChange={(e) => selectBrands(e)}
              component={SelectDynamic}
              filter={'type_vehicle_id|2'}
            ></Field>
          </FormGroup>

          <FormGroup>
            <label>Modelo</label>
            <Field
              name="model_id"
              item={models}
              onChange={(e) => selectModel(e)}
              component={SelectDynamic}
              filter={`type_vehicle_brand_id|${props.dataConf.result.brand || PropsBrandsModels.brandId}`}
            ></Field>
          </FormGroup>
          {enableVersion && (
            <FormGroup>
              <label>Versión</label>
              <Field name="version_id" item={version} component={SelectDynamic}></Field>
            </FormGroup>
          )
          }
          {versionLoad && (<p>Buscando Versiones...</p>) }
        </>
      )}
      <Row>
        <div className="col-md-4 col-sm-4 col-xs-4 offset-md-4">
          <Button
            outline
            theme="secondary"
            onClick={() => {
              props.close();
            }}
          >
            Cancelar
          </Button>
        </div>
        <div className="col-md-4 col-sm-4 col-xs-4 pl-0">
          <Button disabled={pristine || submitting} outline theme="success" type="submit">
            Guardar
          </Button>
        </div>
      </Row>
    </form>
  );
}

function mapStateToProps(state, ownProps) {
  const { users, authentication, vehicles, dataConf } = state;

  if (!ownProps && !ownProps.dataConf && !ownProps.dataConf.result) {
    alert('Error al cargar resolucion de conflicto');
  }

  const { user } = authentication;
  const { showVehicle } = vehicles;
  console.log('||LO QEU VIEN|', ownProps.dataConf.result);
  const initialValues = {
    brand_id: ownProps.dataConf.result.brand,
  };

  //const { dataConf } = vehicles;

  //console.log(" d[d[ [d", props.dataConf);
  // const initialValues = dataConf.result; //showVehicle;
  //const { editVehicules } = form;

  return { user, users, showVehicle, initialValues };
}

const actionCreators = {};
/*
const connectedResolveConflict = connect(
  mapStateToProps,
  actionCreators
)(ResolveConflict);

export { connectedResolveConflict as ResolveConflict };
*/

const connectedResolveConflict = connect(mapStateToProps, actionCreators);

export default compose(
  connectedResolveConflict,
  reduxForm({
    // dataas: ["nuevos", "olivder"],
    form: 'resolveConflict', //                 <------ same form name
    //destroyOnUnmount: false, //        <------ preserve form data
    //forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    enableReinitialize: true, // que se cargue los initialstate
    //initialValues: { brand_id: props.showVehicle.brand_id }
    // Validate
  }),
)(ResolveConflict);
