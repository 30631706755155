import React from 'react'
import { Col } from 'antd'

const OtrosSitios = ({dataSitios}) => {

  const ListSites = [
    {sitio : "AC" , link : dataSitios && dataSitios.permalink_ac},
    {sitio : "DM" , link : dataSitios && dataSitios.permalink_dm},
    {sitio : "FB" , link: dataSitios && dataSitios.permalink_fb},
    {sitio : "IG" , link: dataSitios && dataSitios.permalink_ig},
    {sitio : "ML" , link: dataSitios && dataSitios.permalink_ml},
    {sitio : "OLX" , link: dataSitios && dataSitios.permalink_olx},
      
  ]
    return (
        <div className="containerInfoDealerSectionDetail">
            <h6>
                Encontrá este vehículo también en estos sitios:
            </h6>
            <Col>
            {ListSites && ListSites.map(item => {
            return (
                item.sitio === "AC" &&  item.link !== null && (<a target="_black" href={item.link}>< img className="imagesOtrosSitios" src="/img/autocosmos.png" /></a>)||
                item.sitio === "DM" &&  item.link !== null && (<a target="_black" href={item.link}>< img className="imagesOtrosSitios" src="/img/dm.png" /></a>)||
                item.sitio === "FB" &&  item.link !== null && (<a target="_black" href={item.link}>< img className="imagesOtrosSitios" src="/img/facebook.png" /></a>)||
                item.sitio === "IG" &&  item.link !== null && (<a target="_black" href={item.link}>< img className="imagesOtrosSitios" src="/img/instagram.png" /></a>)||
                item.sitio === "ML" &&  item.link !== null && (<a target="_black" href={item.link}>< img className="imagesOtrosSitios" src="/img/meli.png" /></a>)||
                item.sitio === "OLX" && item.link !== null &&  (<a target="_black" href={item.link}>< img className="imagesOtrosSitios" src="/img/olx.png" /></a>)
            )
            })}
            </Col>
        </div>
    )
}

export default OtrosSitios
