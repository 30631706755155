import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardHeader, CardBody, Button, Container, FormCheckbox } from "shards-react";

class Paso4 extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
          ac: false,
          asiento_regulable: false,
        };
    
        this.handleChangeac = this.handleChangeac.bind(this);
        this.handleChangeasiento_regulable = this.handleChangeasiento_regulable.bind(this);
      }
      handleChangeac() {
        this.setState({
          ac: !this.state.ac,
        });
      }

      handleChangeasiento_regulable() {
        this.setState({
          asiento_regulable: !this.state.asiento_regulable,
        });
      }
  render() {
    return (
    <div>
      <h6>Exterior</h6>
      <div style={{maxHeight: '300px', overflow: 'visible'}}>
        <FormCheckbox
            toggle
            checked={this.state.ac}
            onChange={this.handleChangeac}>
            Barra porta equipaje
        </FormCheckbox>

       
        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            Paragolpes pintados
        </FormCheckbox>

        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            Limpia/lava luneta
        </FormCheckbox>

        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            Vidrios polarizados
        </FormCheckbox>

        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            Llantas de aleación
        </FormCheckbox>

</div>

    </div>
    );
  }
}


export default Paso4;
