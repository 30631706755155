import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardHeader, CardBody, Button, Container, FormCheckbox } from "shards-react";

class Paso6 extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
          ac: false,
          asiento_regulable: false,
        };
    
        this.handleChangeac = this.handleChangeac.bind(this);
        this.handleChangeasiento_regulable = this.handleChangeasiento_regulable.bind(this);
      }
      handleChangeac() {
        this.setState({
          ac: !this.state.ac,
        });
      }

      handleChangeasiento_regulable() {
        this.setState({
          asiento_regulable: !this.state.asiento_regulable,
        });
      }
  render() {
    return (
    <div>
      <h6>Sonido</h6>
      <div style={{maxHeight: '300px', overflow: 'visible'}}>
        <FormCheckbox
            toggle
            checked={this.state.ac}
            onChange={this.handleChangeac}>
            AM/FM
        </FormCheckbox>

       
        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            Cargador de CD
        </FormCheckbox>

        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            DVD
        </FormCheckbox>

        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            Manos libres
        </FormCheckbox>

        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            Tarjeta SD
        </FormCheckbox>

        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            Bluetooth
        </FormCheckbox>

        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            CD
        </FormCheckbox>

        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            Entrada auxiliar
        </FormCheckbox>


        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            MP3
        </FormCheckbox>


        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            Volante multi-función
        </FormCheckbox>

        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            Caja de CD
        </FormCheckbox>

        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            Entrada USB
        </FormCheckbox>

        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            Comando satelital de stereo
        </FormCheckbox>

        <FormCheckbox
            toggle
            checked={this.state.asiento_regulable}
            onChange={this.handleChangeasiento_regulable}>
            Pasacassette
        </FormCheckbox>

    
</div>

    </div>
    );
  }
}


export default Paso6;
