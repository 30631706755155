import { Upload, Modal, Button, Icon, notification} from 'antd';
import en_US from 'antd/es/locale-provider/en_US';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import React, {useState, useEffect} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import './myDealer.css';
import Cookies from "universal-cookie";
import { store } from "../_helpers";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardTitle,
  CardImg,
  CardBody,
  CardFooter,
  Row,
  Col
} from "shards-react";
import axios from "axios";
import { API_URL } from "../_config";
const cookies = new Cookies();

const openNotificationWithIcon = (type,til,msg) => {
  let $congf = {
     message:  til ,
     description:msg,
     top:70
   };
   notification[type]($congf);
 };
const getBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

const UploadWrapper = React.memo(({ onChange: onChangeInitial, ...props }) => {
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState();
  const [checkList, setCheckList] = useState([]);

  useEffect(() => {
    axios({
      method: "get",
      url:
        API_URL + "api/dealer/" + cookies.get("dealer").id + "/cover_image/image?token=" +
        store.getState().authentication.user.token
    }).then(response => {
      setFileList(response.data.images);
      var data = [];
      fileList.forEach(element => {
        if(element.description == 'ON') {
          data[element.id] = true;
        }else{
          data[element.id] = false;
        }
      });
      console.log(data);
      console.log('guazo');
      console.log(data.join());
    });
  }, [])

  const onChange = ({ fileList }) => {
   
    setFileList(fileList);

    
  };

  const subir = file => {

    notification.open({
      message: 'Subiendo imagen',
      description:
        'Este proceso puede demorar unos minutos',
      icon: <Icon type="loading" style={{ color: '#4caf50' }} />,
      top: 70
    });
   
    const data= new FormData()
			data.append('imageFiles', file)
			const config= {
				"headers": {
					"content-type": 'multipart/form-data'
				}
			}
			axios.post('https://admin.nuestrosautos.com.ar/upload/no.php', data, config).then((response) => {

          const url = response.data[0].fileName.path;
          axios.post(API_URL + "api/dealer/" + cookies.get("dealer").id + "/cover_image/link_cover_photo?token=" +
          store.getState().authentication.user.token, {url: url}).then((response2) => {
            notification.destroy()
            notification.open({
              message: 'Imagen subida exitosamente',
              description:
                'La imagen que ingresó ya se subió exitosamente',
              icon: <Icon type="check-circle" style={{ color: '#4caf50' }} />,
              top: 70
            });
            refresh_data();
			    }).catch((err) => {
            notification.destroy()
            notification.open({
              message: 'No se pudo subir la imagen',
              description:
                'Ha ocurrido un error en el proceso, por favor, intente más tarde',
              icon: <Icon type="stop" style={{ color: '#d32f2f' }} />,
              top: 70
            });
          })
          
			}).catch((err) => {
        notification.destroy()
				notification.open({
          message: 'No se pudo subir la imagen',
          description:
            'Ha ocurrido un error en el proceso, por favor, intente más tarde.',
          icon: <Icon type="stop" style={{ color: '#d32f2f' }} />,
          top: 70
        });
			})

    
  };

  const onRemove = file => {
    const newFileList = cloneDeep(fileList).filter(item => parseInt(item.image_order) !== file.image_order);
    onChange({ fileList: newFileList });
  };

  const onDragEnd = ({ source, destination }) => {

    
    notification.open({
      message: 'Cambiando orden',
      icon: <Icon type="loading" style={{ color: '#4caf50' }} />,
      top: 70
    });
      
    if(source != null && destination != null) {
    const reorder = (list, startIndex, endIndex) => {
      const [removed] = list.splice(startIndex, 1);
      list.splice(endIndex, 0, removed);
      var data= new FormData();
      list.forEach(element => data.append('item[]', element.id));

      // API
      axios.post(API_URL + "api/dealer/" + cookies.get("dealer").id + "/cover_image/image/order-images?token=" +
          store.getState().authentication.user.token, data).then((response2) => {
            notification.destroy()
            notification.open({
              message: 'Se ha cambiado el orden exitosamente',
              icon: <Icon type="check-circle" style={{ color: '#4caf50' }} />,
              top: 70
            });
         
			    }).catch((err) => {
            notification.destroy()
				    notification.open({
              message: 'No se pudo cambiar el orden',
              description:
                'Ha ocurrido un error en el proceso, por favor, intente más tarde.',
              icon: <Icon type="stop" style={{ color: '#d32f2f' }} />,
              top: 70
            });
          })

      console.log(data);
      return list;
    };

 
    const newFileList = reorder(cloneDeep(fileList), source.index, destination.index);
    console.log(newFileList);
    onChange({ fileList: newFileList })
  }else{
    notification.destroy()
    notification.open({
      message: 'Ooops...',
      description:
        'Para cambiar el orden, debe arrastrar la imagen hacia un espacio disponible',
      icon: <Icon type="exception" style={{ color: '#4caf50' }} />,
      top: 70
    });
  }
  };

  const onPreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
  };

  const refresh_data = () => {
    
    axios({
      method: "get",
      url:
        API_URL + "api/dealer/" + cookies.get("dealer").id + "/cover_image/image?token=" +
        store.getState().authentication.user.token
    }).then(response => {
      setFileList(response.data.images);
    });
  }

  const change_check = (id, state) => {

    notification.open({
      message: 'Cargando',
      icon: <Icon type="loading" style={{ color: '#4caf50' }} />,
      top: 70
    });

    axios
      .post(
        API_URL + "api/dealer/" +
          cookies.get("dealer").id +
          "/cover_image/image/" + id + "?token=" +
          store.getState().authentication.user.token,
        {
          state: state,
        }
      )
      .then(response => {
        notification.destroy()
        notification.open({
          message: 'Operación exitosa',
          icon: <Icon type="check-circle" style={{ color: '#4caf50' }} />,
          top: 70
        });
        refresh_data();
      });
    
    
  } 

  const remover = (id) => {

    notification.open({
      message: 'Eliminando imagen',
      icon: <Icon type="loading" style={{ color: '#4caf50' }} />,
      top: 70
    });

    axios
      .delete(
        API_URL + "api/dealer/" +
          cookies.get("dealer").id +
          "/cover_image/image/" + id + "?token=" +
          store.getState().authentication.user.token
      )
      .then(response => {
        notification.destroy()
        notification.open({
          message: 'Imagen eliminada exitosamente',
          icon: <Icon type="check-circle" style={{ color: '#4caf50' }} />,
          top: 70
        });
        refresh_data();
      });
  } 

  return (
    <>
      <br/>
      <Upload {...props} style={{width:'100%'}} fileList={fileList} showUploadList={false} beforeUpload={file => subir(file)}>
        <Button style={{width:'100%', marginBottom: '5px'}}>
        <Icon type="upload" /> Subir
        </Button>
      </Upload>


      
      {!fileList && 
        console.log(fileList)
      }
      {fileList &&
      
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable direction="vertical" droppableId="droppable">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {fileList.map((item, index) => (
                  <Draggable key={parseInt(item.image_order)} draggableId={item.image_order} index={index}>
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        
                      >
                        
                        <Card style={{ maxWidth: "100%", marginBottom: '7px'}}>

                          <Row>
                            <Col sm="10">
                                <CardImg style={{ width:'100%', borderRadius: '.625rem' }} src={item.url} />
                            </Col>
                            <Col sm="2" style={{display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'}}>
                               {item.description == '' &&
                                <Icon type="delete" onClick={() => remover(item.id)}></Icon>
                               }

                              {item.description == 'ON' &&
                                <input type="checkbox" name="description" onClick={() => change_check(item.id, 'OFF')} defaultChecked={true} />
                              }

                              {item.description == 'OFF' &&
                                <input type="checkbox" name="description" onClick={() => change_check(item.id, 'ON')} defaultChecked={false}/>
                              }

                         
                            </Col>
                          </Row>
    
                        </Card>


                      
                      </div>
                    )}
                  </Draggable>
                ))}
                
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
}
  
      
      <Modal
        visible={previewImage}
        width="60%"
        footer={null}
        onCancel={() => setPreviewImage(null)}
      >
        <img style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
});

function mapState(state) {
  const { users, authentication, vehicles } = state;

  const { user } = authentication;
  console.log("esto ->", vehicles);

  return { user, users, vehicles };
}
const actionCreators = {
  
};

const connectedVehicles = connect(
  mapState,
  actionCreators
)(UploadWrapper);
export { connectedVehicles as UploadWrapper };
